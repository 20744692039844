import React from 'react';
import {Box, IconButton, Popover, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Delete} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 15,
    marginLeft: 5,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(0.6),
    borderRadius: 10,
  },
}));

export interface Props {
  value?: string;
  handleDeleteIcon?: (iconId: string) => void;
}

export const ButtonCategoryIconDelete = ({
  value,
  handleDeleteIcon,
}: Props): JSX.Element => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={classes.button}>
      {value && (
        <>
          <IconButton
            edge="end"
            size="small"
            component="span"
            color={'inherit'}
            aria-owns={open ? 'add-category-icon' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-haspopup="true"
            onClick={() => handleDeleteIcon && handleDeleteIcon(value)}>
            <Delete fontSize="small" />
          </IconButton>
          <Popover
            id="add-category-icon"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>
            <Typography>
              {t('category.Delete icon from the poi category icon list')}
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
};
