import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const InputStyles = makeStyles((theme) =>
  createStyles({
    error: {
      border: '2px solid red',
      borderRadius: 10,
    },
    errorMessage: {
      marginRight: 5,
      color: theme.palette.error.main,
    },
  })
);
export const InputCommonStyles = {
  error: {
    border: '2px solid red',
    borderRadius: '10px',
  },
  errorMessage: {
    marginRight: '5px',
    color: 'error.main',
  },
};
