import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import React, {useCallback} from 'react';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {ListItemsMenu} from '../ListItemsMenu';
import {HeaderMenuItem} from '../HeaderMenuItem';
import Divider from '@mui/material/Divider';
import {AddNewPoiTab} from './tabs/new-poi-tab/AddNewPoiTab';
import {ListOfPoisTab} from './tabs/poi-list-tab/ListOfPoisTab';
import {CategoryTab} from './tabs/category-list-tab/CategoriesTab';
import {Box} from '@mui/material';
import {StylesMenu} from '../StylesMenu';
import {useTranslation} from 'react-i18next';

export const RoutesPoiTab = (): JSX.Element => {
  const {t} = useTranslation();
  const classes = StylesMenu();
  const history = useHistory();
  const {path, url} = useRouteMatch();

  const handleOnClick = useCallback(
    (item) => history.push(`${url}/${item}`),
    [history, url]
  );

  return (
    <Box className={classes.menu}>
      <Route exact path={`${path}`}>
        <div>
          <HeaderMenuItem name={t('leftMenu.poi')} />
          <List>
            {ListItemsMenu.poi.items.map(({label, key}) => (
              <Box key={key}>
                <ListItem button key={key} onClick={() => handleOnClick(key)}>
                  <ListItemText primary={t(`leftMenu.${label}`)} />
                </ListItem>
                <Divider light={true} />
              </Box>
            ))}
          </List>
        </div>
      </Route>
      <Route path={`${path}/categories`}>
        <CategoryTab />
      </Route>
      <Route path={`${path}/newPoi`}>
        <AddNewPoiTab />
      </Route>
      <Route path={`${path}/poiList/:poiId?`}>
        <ListOfPoisTab />
      </Route>
    </Box>
  );
};
