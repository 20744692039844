import React from 'react';
import {Box, Typography} from '@mui/material';
import {Close, ModeComment} from '@mui/icons-material';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';

export interface Props {
  poiCategory?: PoiCategory;
  name?: string;
  closeButton?: boolean;
  onCloseClick?: () => void;
}

export const PoiIcon = ({
  poiCategory,
  name,
  closeButton = false,
  onCloseClick,
}: Props): JSX.Element => {
  const styles = {
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 100,
      margin: '4px',
    },
    iconBackground: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 50,
    },
    icon: {
      position: 'absolute',
      top: 8,
    },
    close: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'red',
      borderRadius: '100px',
      padding: '1px',
      top: -8,
      right: -5,
    },
    closeIcon: {
      width: 10,
      height: 10,
      cursor: 'pointer',
    },
    typography: {
      fontSize: 11,
      textAlign: 'center',
    },
  };

  return (
    <Box sx={styles.iconContainer}>
      <Box sx={styles.iconBackground}>
        <ModeComment
          style={{
            position: 'relative',
            width: 45,
            height: 50,
            color: poiCategory?.color,
            cursor: 'pointer',
          }}
        />
        <Box sx={styles.icon}>
          <img
            alt={poiCategory?.name}
            src={poiCategory && poiCategory.icon && poiCategory.icon.url}
            height={24}
            width={24}
          />
        </Box>
        {closeButton && (
          <Box sx={styles.close}>
            <Close
              color={'secondary'}
              sx={styles.closeIcon}
              onClick={onCloseClick}
            />
          </Box>
        )}
      </Box>
      {name && <Typography sx={styles.typography}>{name}</Typography>}
    </Box>
  );
};
