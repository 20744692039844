import React from 'react';
import {Box, Typography} from '@mui/material';
import {Control} from 'react-hook-form/dist/types/form';
import {PoiForm} from '../../DialogPoi';
import {Controller} from 'react-hook-form';
import TextInputPrimary from '../../../../components/text-inputs/TextInputPrimary';
import {checkIsNumber} from '../../../../validators/inputValidator';

export interface Props {
  control?: Control<PoiForm>;
  name: string;
  type: 'scale' | 'rotation' | 'object3dPosition';
}

export const Object3dProperty = ({control, type, name}: Props): JSX.Element => {
  return (
    <Box>
      <Box m={0.5}>
        <Typography variant="subtitle2" sx={{fontWeight: 600}}>
          {name}
        </Typography>
      </Box>
      <Controller
        name={`${type}.x`}
        control={control}
        defaultValue={undefined}
        rules={{
          required: true,
          validate: checkIsNumber,
        }}
        render={({field, fieldState: {error}}) => (
          <TextInputPrimary
            {...field}
            boldLabel
            label={'X:'}
            errorType={error?.type}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name={`${type}.y`}
        control={control}
        defaultValue={undefined}
        rules={{
          required: true,
          validate: checkIsNumber,
        }}
        render={({field, fieldState: {error}}) => (
          <TextInputPrimary
            {...field}
            boldLabel
            label={'Y:'}
            errorType={error?.type}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name={`${type}.z`}
        control={control}
        defaultValue={undefined}
        rules={{
          required: true,
          validate: checkIsNumber,
        }}
        render={({field, fieldState: {error}}) => (
          <TextInputPrimary
            {...field}
            boldLabel
            label={'Z:'}
            errorType={error?.type}
            errorMessage={error?.message}
          />
        )}
      />
    </Box>
  );
};
