import {setLoading} from '../LoadingSlice';
import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
} from '@skczu/czu-frontend-library';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  CategoryIcon,
  Configuration,
  PoiCategoryApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {CategoryIconWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory/api';
import {getToken} from '../../../keycloak';

const initialState: GenericState<CategoryIcon, CategoryIcon> = {
  baseObjList: [],
  objList: [],
  obj: null,
  hasMore: true,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const categoryIconsSlice = createGenericSlice({
  name: 'categoryIcons',
  initialState,
})({});

export const getCategoryIconList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const categoriesIconList = await new PoiCategoryApi(
      new Configuration({accessToken: await getToken()}),
      config.cmsRestUrl
    ).categoryIconGet();
    dispatch(categoryIconActions.setObjList(categoriesIconList?.data || []));
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteCategoryIcon =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).categoryIconIdDelete(id),
        (loading) => dispatch(categoryIconActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(categoryIconActions.setObj(null));
          dispatch(categoryIconActions.removeFromObjList(id));
        }
      )
    );
  };

export const createCategoryIcon =
  (categoryIcon: CategoryIconWithData): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CategoryIconWithData>(
        categoryIcon,
        async (obj) =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).categoryIconPost({...obj, objectType: 'CategoryIconWithData'}),
        (loading) => dispatch(categoryIconActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(categoryIconActions.setObj(null));
          dispatch(getCategoryIconList());
        }
      )
    );
  };

export const categoryIconActions = categoryIconsSlice.actions;

export const categoriesIconSelector = (
  state: RootState
): typeof state.categoryIcons => state.categoryIcons;

export default categoryIconsSlice.reducer;
