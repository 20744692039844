import React, {forwardRef} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {FormModel} from '../../../../hooks/useForm';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {InputLabel, InputLabelProps} from '../input-label/InputLabel';
import './Editor.scss';

export interface TextEditorProps extends FormModel, InputLabelProps {
  value?: string;
}

export const TextEditor = forwardRef(
  ({
    value,
    label,
    onChange,
    errorType,
    boldLabel,
    row,
    labelRowWidth,
    inputRowWidth,
    required,
  }: TextEditorProps): JSX.Element => {
    const handleInputChange = (val: string) => {
      if (onChange) {
        onChange && onChange(val);
      }
    };

    return (
      <InputLabel
        label={label}
        row={row}
        required={required}
        labelRowWidth={labelRowWidth}
        inputRowWidth={inputRowWidth ? inputRowWidth : '100%'}
        boldLabel={boldLabel}>
        <ReactQuill
          className={errorType ? 'quill-error' : 'quill-editor'}
          theme="snow"
          value={value}
          onChange={handleInputChange}
          // modules={{
          //   toolbar: [
          //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          //     // ['blockquote', 'code-block'],
          //     // [{size: ['small', false, 'large', 'huge']}], // custom dropdown
          //     [{header: 1}, {header: 2}], // custom button values
          //     [{list: 'ordered'}, {list: 'bullet'}],
          //     [{script: 'sub'}, {script: 'super'}], // superscript/subscript
          //     // [{indent: '-1'}, {indent: '+1'}], // outdent/indent
          //     // [{direction: 'rtl'}], // text direction
          //     [{header: [1, 2, 3, 4, 5, 6, false]}],
          //     [{color: []}, {background: []}], // dropdown with defaults from theme
          //     // [{font: []}],
          //     // [{align: []}],
          //     ['image', 'video'],
          //     ['clean'], // remove formatting button
          //   ],
          // }}
          modules={{
            toolbar: [
              [{header: [1, 2, 3, 4, 5, 6, false]}],
              ['bold', 'italic', 'underline', 'strike'],
              [{color: []}, {background: []}],
              [{list: 'ordered'}, {list: 'bullet'}],
              ['link', 'image', 'video'],
              ['clean'],
            ],
          }}
        />
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </InputLabel>
    );
  }
);
TextEditor.displayName = 'TextEditor';

export default TextEditor;
