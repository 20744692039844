import React from 'react';
import {Box, IconButton, InputBase} from '@mui/material';
import {Search} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const styles = {
  search: {
    backgroundColor: 'secondary.main',
    boxShadow: 2,
    position: 'relative',
    borderRadius: '10px',
    paddingLeft: '10px',
    width: '100%',
    textAlign: 'left',
  },
  searchIcon: {
    position: 'absolute',
    right: 10,
    top: 2,
  },
  filterSearch: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '5px',
  },
};

export interface Props {
  name: string;
  value?: string;
  onChangeValue?: (event: {inputName: string; value: string}) => void;
  onSearchClick?: () => void;
}

export const SearchInput = ({
  value,
  name,
  onChangeValue,
  onSearchClick,
}: Props): JSX.Element => {
  const {t} = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.name) {
      const event = {inputName: e.target.name, value: e.target.value};
      onChangeValue && onChangeValue(event);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick && onSearchClick();
    }
  };

  return (
    <Box sx={styles.filterSearch}>
      <Box sx={styles.search}>
        <InputBase
          placeholder={t('leftMenu.search')}
          sx={{
            '& .MuiInputBase-input': {
              paddingLeft: '10px',
            },
          }}
          inputProps={{'aria-label': 'search', onKeyPress: handleKeyPress}}
          name={name}
          value={value}
          onChange={handleInputChange}
        />
        <Box sx={styles.searchIcon}>
          <IconButton
            edge="end"
            aria-label="edit"
            size="small"
            onClick={() => onSearchClick && onSearchClick()}>
            <Search />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
