import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

export enum ContentLanguage {
  EN = 'en',
  CZ = 'cs',
}

interface LoadingState {
  selectedLanguage: ContentLanguage;
}

const initialState: LoadingState = {
  selectedLanguage: ContentLanguage.CZ,
};

export const contentLanguageSlice = createSlice({
  name: 'contentLanguage',
  initialState,
  reducers: {
    setSelectedLanguage: (state, {payload}: PayloadAction<ContentLanguage>) => {
      state.selectedLanguage = payload;
    },
  },
});

export const {setSelectedLanguage} = contentLanguageSlice.actions;

export const contentLanguageSelector = (
  state: RootState
): typeof state.contentLanguage => state.contentLanguage;

export default contentLanguageSlice.reducer;
