import React from 'react';
import {Box} from '@mui/material';
import {TabsNews} from './news/TabsNews';
import {TabsUsers} from './users/TabsUsers';
import {Route} from 'react-router-dom';
import {
  selectObjectPreview,
  setObjectPreviewView,
} from '../../../redux/reducers/DrawerSlice';
import {showImagePreview} from '../../../redux/reducers/ImagePreview';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {useTranslation} from 'react-i18next';
import {DialogObjectPreview} from '../../shared/dialogs/dialog-object-preview/DialogObjectPreview';
import {TabsSettings} from './settings/TabsSettings';
import {TabsEvents} from './events/TabsEvents';
import {TabsNotifications} from './notifications/TabsNotifications';
import {CustomUtils} from '../../shared/utils/CustomUtils';
import {Notification} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated';
import {Theme} from '@mui/material/styles';
import {DrawerWidth} from '../map-scene/drawer-left/drawer/DrawerLeft';

export interface MenuAdminProps {
  openDrawer: boolean;
}

export const MenuAdmin = ({openDrawer}: MenuAdminProps): JSX.Element => {
  const {t} = useTranslation();
  const {objectPreview, objectPreviewView} =
    useAppSelector(selectObjectPreview);
  const dispatch = useAppDispatch();

  const styles = {
    adminSceneContainer: {
      position: 'absolute',
      // width: `calc(100% - ${width + 20}px);`,
      // width: `100%`,
      height: '100%',
      zIndex: 3,
      overflow: 'auto',
      backgroundColor: 'secondary.main',
    },
    adminOpen: {
      transition: (theme: Theme) =>
        theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      width: `calc(100% - ${DrawerWidth}px);`,
    },
    adminClose: {
      transition: (theme: Theme) =>
        theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      width: (theme: Theme) => `calc(100% - ${theme.spacing(8)} - 1px);`,
    },
  };

  return (
    <Box
      sx={{
        ...styles.adminSceneContainer,
        ...(openDrawer ? styles.adminOpen : styles.adminClose),
      }}>
      <Route path="/news">
        <TabsNews />
      </Route>
      <Route path="/users">
        <TabsUsers />
      </Route>
      <Route path="/settings">
        <TabsSettings />
      </Route>
      <Route path="/events">
        <TabsEvents />
      </Route>
      <Route path="/notifications/:notificationId?/:add?">
        <TabsNotifications />
      </Route>
      <DialogObjectPreview
        title={
          objectPreview.objectMobileScene === 'notification'
            ? CustomUtils.getContentByLanguage(
                (objectPreview.objectPreviewData as Notification).title,
                (objectPreview.objectPreviewData as Notification).titleEn
              )
            : t('adminMenu.Preview')
        }
        objectMobileScene={objectPreview.objectMobileScene}
        objectPreviewData={objectPreview.objectPreviewData}
        openDialog={Boolean(
          objectPreviewView.openObjectPreview && objectPreview.objectPreviewData
        )}
        onCloseDialog={() =>
          dispatch(
            setObjectPreviewView({
              openObjectPreview: false,
            })
          )
        }
        dialogPreview={objectPreview.objectMobileScene === 'notification'}
        imagePreview={(selectedImage, images) =>
          dispatch(showImagePreview({selectedImage, images}))
        }
      />
    </Box>
  );
};
