import React from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {
  MobileScreenPreview,
  WysiwygBody,
  MobileWidth,
} from '@skczu/czu-react-components';
import {
  MobilePreviewScene,
  MobilePreviewSceneData,
  SelectedImagePreview,
} from '@skczu/czu-frontend-library';
import {CustomUtils} from '../../utils/CustomUtils';
import {useTranslation} from 'react-i18next';

const styles = {
  previewContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    display: 'flex',
  },
};

export interface Props {
  title: string;
  openDialog: boolean;
  objectMobileScene?: MobilePreviewScene;
  objectPreviewData?: MobilePreviewSceneData;
  onCloseDialog: () => void;
  imagePreview?: SelectedImagePreview;
  dialogPreview?: boolean;
}
export const DialogObjectPreview = ({
  openDialog,
  title,
  objectMobileScene,
  objectPreviewData,
  onCloseDialog,
  imagePreview,
  dialogPreview,
}: Props): JSX.Element => {
  const {t} = useTranslation();

  return (
    <DialogPrimary
      title={title}
      open={openDialog}
      width={MobileWidth}
      onCloseDialog={onCloseDialog}>
      {dialogPreview ? (
        objectPreviewData?.body ? (
          <WysiwygBody bodyTextHtml={objectPreviewData?.body} />
        ) : null
      ) : (
        <Box sx={styles.previewContainer}>
          <MobileScreenPreview
            t={t}
            objectMobileScene={objectMobileScene}
            objectPreviewData={
              objectPreviewData &&
              CustomUtils.getObjByLanguage(objectPreviewData)
            }
            getObjByLanguage={CustomUtils.getObjByLanguage}
            backButton={false}
            imagePreview={imagePreview}
          />
        </Box>
      )}
    </DialogPrimary>
  );
};
