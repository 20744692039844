import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../../store';
import {AxiosResponse} from 'axios';
import {
  KeycloakRole,
  KeycloakRoleResponse,
  RoleApi,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import {
  PoiApi,
  PoiFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {
  Configuration,
  ObjectRef,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {
  EventApi,
  EventFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event/api';
import {
  PoiCategory,
  PoiCategoryApi,
  PoiCategoryFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {ObjectRefFilterResponse} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route/api';
import {RouteApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {LayerApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/layer';
import {getToken} from '../../../keycloak';
import {
  KeycloakUser,
  UserApi,
} from '@skczu/czu-frontend-library/build/apis/keycloak-user-service-api/generated';

export interface SimpleObjectListsError {
  message: string;
}

interface SimpleObjectListsState {
  simplePoiList: PoiRef[];
  simpleGroupList: EventFilterRefObject[];
  simpleRouteList: ObjectRef[];
  simpleLayerList: ObjectRef[];
  simpleCategoryList: PoiCategory[];
  simpleRoleList: KeycloakRole[];
  simpleUserList: KeycloakUser[];
  setLoading: boolean;
  error: SimpleObjectListsError;
}

const initialState: SimpleObjectListsState = {
  setLoading: false,
  simplePoiList: [],
  simpleGroupList: [],
  simpleRouteList: [],
  simpleLayerList: [],
  simpleCategoryList: [],
  simpleRoleList: [],
  simpleUserList: [],
  error: {message: 'An Error occurred'},
};

export const simpleObjectListsSlice = createSlice({
  name: 'simpleObjectLists',
  initialState,
  reducers: {
    setLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.setLoading = payload;
    },
    setSimplePoiList: (state, {payload}: PayloadAction<PoiRef[]>) => {
      state.simplePoiList = payload;
    },
    setSimpleGroupList: (
      state,
      {payload}: PayloadAction<EventFilterRefObject[]>
    ) => {
      state.simpleGroupList = payload;
    },
    setSimpleRouteList: (state, {payload}: PayloadAction<ObjectRef[]>) => {
      state.simpleRouteList = payload;
    },
    setSimpleLayerList: (state, {payload}: PayloadAction<ObjectRef[]>) => {
      state.simpleLayerList = payload;
    },
    setSimpleCategoryList: (state, {payload}: PayloadAction<PoiCategory[]>) => {
      state.simpleCategoryList = payload;
    },
    setSimpleRoleList: (state, {payload}: PayloadAction<KeycloakRole[]>) => {
      state.simpleRoleList = payload;
    },
    setSimpleUserList: (state, {payload}: PayloadAction<KeycloakUser[]>) => {
      state.simpleUserList = payload;
    },
    setSimpleListFailed: (
      state,
      {payload}: PayloadAction<SimpleObjectListsError>
    ) => {
      state.error = payload;
    },
  },
});

export const getSimpleObjectList =
  <
    SimpleObj extends {id?: string},
    ReturnType extends {objects?: SimpleObj[]; totalCount?: number}
  >(
    fetchData: () => Promise<AxiosResponse<ReturnType>>,
    setToSimpleObjList: (data: SimpleObj[]) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetchData();
      if (response.data && response.data.objects) {
        setToSimpleObjList(response.data.objects);
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getSimplePoiList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<PoiRef, PoiFilterResponse>(
      async () =>
        new PoiApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).poiFilterGet(undefined, 999999, 0),
      (list) => dispatch(setSimplePoiList(list))
    )
  );
};

export const getSimpleGroupList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<EventFilterRefObject, EventFilterResponse>(
      async () =>
        new EventApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).eventFilterGet(undefined, 999999, 0, true, true),
      (list) => dispatch(setSimpleGroupList(list))
    )
  );
};

export const getSimpleRouteList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<ObjectRef, ObjectRefFilterResponse>(
      async () =>
        new RouteApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).routeFilterGet(undefined, 999999, 0),
      (list) => dispatch(setSimpleRouteList(list))
    )
  );
};

export const getSimpleLayerList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<ObjectRef, ObjectRefFilterResponse>(
      async () =>
        new LayerApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).layerFilterGet(undefined, 999999, 0),
      (list) => dispatch(setSimpleLayerList(list))
    )
  );
};

export const getSimpleCategoryList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<PoiCategory, PoiCategoryFilterResponse>(
      async () =>
        new PoiCategoryApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).poiCategoryFilterGet(undefined, 999999, 0),
      (list) => dispatch(setSimpleCategoryList(list))
    )
  );
};

export const getSimpleRoleList = (): AppThunk => async (dispatch) => {
  dispatch(
    getSimpleObjectList<KeycloakRole, KeycloakRoleResponse>(
      async () =>
        new RoleApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminRoleGet(undefined, undefined, undefined, undefined, 9999999, 0),

      (list) => dispatch(setSimpleRoleList(list))
    )
  );
};

export const getSimpleUserList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await new UserApi(
      new Configuration({accessToken: await getToken()}),
      config.keycloakDataRestUrl
    ).userAdminUsersGet(undefined, undefined, undefined, undefined, 0, 9999999);
    if (response.data && response.data.users) {
      dispatch(setSimpleUserList(response.data.users));
    }
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(setLoading(false));
  }
};

export const {
  setSimplePoiList,
  setLoading,
  setSimpleGroupList,
  setSimpleRouteList,
  setSimpleLayerList,
  setSimpleListFailed,
  setSimpleCategoryList,
  setSimpleRoleList,
  setSimpleUserList,
} = simpleObjectListsSlice.actions;

export const selectSimpleRoleList = (
  state: RootState
): typeof state.simpleObjectLists.simpleRoleList =>
  state.simpleObjectLists.simpleRoleList;

export const selectSimpleLayerList = (
  state: RootState
): typeof state.simpleObjectLists.simpleLayerList =>
  state.simpleObjectLists.simpleLayerList;

export const selectSimplePoiList = (
  state: RootState
): typeof state.simpleObjectLists.simplePoiList =>
  state.simpleObjectLists.simplePoiList;

export const selectSimpleGroupList = (
  state: RootState
): typeof state.simpleObjectLists.simpleGroupList =>
  state.simpleObjectLists.simpleGroupList;

export const selectSimpleCategoryList = (
  state: RootState
): typeof state.simpleObjectLists.simpleCategoryList =>
  state.simpleObjectLists.simpleCategoryList;

export const selectSimpleUserList = (
  state: RootState
): typeof state.simpleObjectLists.simpleUserList =>
  state.simpleObjectLists.simpleUserList;

export const selectSimpleRouteList = (
  state: RootState
): typeof state.simpleObjectLists.simpleRouteList =>
  state.simpleObjectLists.simpleRouteList;

export default simpleObjectListsSlice.reducer;
