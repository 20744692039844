import React, {ForwardedRef, forwardRef} from 'react';
import {Box, Switch, Typography} from '@mui/material';
import {FormModel} from '../../../../../hooks/useForm';
import {InputStyles} from '../../../styles/StylesInput';
import makeStyles from '@mui/styles/makeStyles';
import {InputErrorMessage} from '../../../components/custom-inputs/InputErrorMessage';

const useStyles = makeStyles(() => ({
  fileName: {
    margin: '5px -10px 0 10px',
  },
  label: {
    margin: '10px 0 0 15px',
  },
  container: {
    display: 'flex',
  },
}));

const SwitchHotspot = forwardRef(
  (
    {value, id, name, label, className, onChange, errorType}: FormModel,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const inputClasses = InputStyles();
    const classes = useStyles();

    return (
      <Box className={classes.container}>
        <Box className={classes.label}>
          <Typography variant="subtitle2" id={id} color={'secondary'}>
            {label}
          </Typography>
        </Box>
        <Switch
          id={id}
          name={name}
          ref={ref}
          checked={!!value}
          className={
            classes.fileName +
            ' ' +
            className +
            ' ' +
            (errorType ? inputClasses.error : ' ')
          }
          onChange={onChange}
          value={value}
        />
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);
SwitchHotspot.displayName = 'SwitchHotspot';

export default SwitchHotspot;
