import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PoiIcon} from './PoiIcon';
import {useAppSelector} from '../../../../hooks/hooks';
import {selectPoiCategoryList} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {CustomUtils} from '../../utils/CustomUtils';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';

export interface Props {
  poiList?: PoiRef[];
  onRemove?: (poi: string) => void;
  closeButton?: boolean;
  border?: boolean;
}
export const ContainerPoi = ({
  poiList,
  onRemove,
  closeButton = true,
  border = true,
}: Props): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    listContainer: {
      marginTop: 20,
      paddingTop: 15,
      minHeight: 95,
      width: '100%',
      borderRadius: 10,
      border: border ? `2px solid ${theme.palette.secondary.light}` : undefined,
      boxShadow: border ? theme.shadows[2] : undefined,
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row wrap',
    },
  }));
  const classes = useStyles();
  const poiCategoryList = useAppSelector(selectPoiCategoryList);

  const renderIcons = useMemo(() => {
    return poiList?.map((poi: PoiRef, index: number) => {
      const categoryIcon =
        poiCategoryList &&
        poiCategoryList.find((category) => category.id === poi.category);
      return (
        categoryIcon &&
        categoryIcon?.color &&
        categoryIcon?.icon && (
          <PoiIcon
            key={categoryIcon.id + '_' + index}
            poiCategory={categoryIcon}
            name={CustomUtils.getContentByLanguage(poi.name, poi.nameEn)}
            closeButton={closeButton}
            onCloseClick={() => onRemove && poi && poi.id && onRemove(poi.id)}
          />
        )
      );
    });
  }, [closeButton, onRemove, poiCategoryList, poiList]);

  return <Box className={classes.listContainer}>{renderIcons}</Box>;
};
