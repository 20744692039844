import React, {useCallback, useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {MobileScreenRouteDetail} from '@skczu/czu-react-components';
import {TabItem, TabsDialog} from '../shared/tabs-dialog/TabsDialog';
import {TabDetailRoute} from './tabs/TabDetailRoute';
import {StylesDialog} from '../StylesDialog';
import {TabRoutePoi} from './tabs/TabRoutePoi';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  poiSelectionSelector,
  removeFromPoiSelectionList,
  setOpenDialogAfterPoiSelection,
  setPoiSelection,
  setPoiSelectionList,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {useHistory} from 'react-router-dom';

import {useTranslation} from 'react-i18next';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {setObjectPreviewView} from '../../../../redux/reducers/DrawerSlice';
import {CustomUtils} from '../../utils/CustomUtils';
import {Utils} from '@skczu/czu-frontend-library';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdateRoute,
  Route,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {ObjectPreviewWithFormContainer} from '../../components/mobile-preview/ObjectPreviewWithFormContainer';

export type RouteForm = Omit<Route, 'id'>;

export interface Props {
  addNew?: boolean;
  route?: Route | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddRoute?: (category: CreateOrUpdateRoute) => void;
  onUpdateRoute?: (category: CreateOrUpdateRoute) => void;
  onObjectSelection?: (route: Route) => void;
}

export const DialogRoute = ({
  addNew,
  route,
  openDialog,
  loading,
  onCloseDialog,
  onAddRoute,
  onUpdateRoute,
  onObjectSelection,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = StylesDialog();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = React.useState<TabItem>({
    name: 'Info',
    label: t('route.Info'),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm<RouteForm>();
  const {openDialogAfterPoiSelection, poiSelection} =
    useAppSelector(poiSelectionSelector);
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const navigateTo = useCallback((path) => history.push(path), [history]);

  const clearInputs = useCallback(() => {
    reset();
    dispatch(setPoiSelectionList([]));
  }, [dispatch, reset]);

  useEffect(() => {
    if (route && openDialog) {
      setValue('name', route?.name);
      setValue('nameEn', route?.nameEn);
      setValue('body', route?.body);
      setValue('bodyEn', route?.bodyEn);
      setValue('layers', route?.layers);
    }
    if (openDialog && !route) {
      clearInputs();
    }
    dispatch(setOpenDialogAfterPoiSelection(false));
  }, [clearInputs, dispatch, openDialog, route, setValue]);

  useEffect(() => {
    openDialogAfterPoiSelection &&
      setActivePage(
        openDialogAfterPoiSelection
          ? {name: 'Poi', label: t('route.Poi')}
          : {name: 'Info', label: t('route.Info')}
      );
  }, [openDialogAfterPoiSelection, t]);

  const createRoute = (): Route => {
    const pois = poiSelection.poiList ? poiSelection.poiList : [];
    const newRoute: Route = {...getValues(), pois};
    if (route?.id) {
      newRoute.id = route.id;
    }
    return newRoute;
  };

  const handleChoosePoi = () => {
    route?.pois &&
      route.pois.length > 0 &&
      dispatch(setPoiSelectionList(route.pois));
    dispatch(
      setObjectPreviewView({
        openObjectPreview: false,
      })
    );
    dispatch(setPoiSelection({poiSelection: true, poiSelectionFrom: 'route'}));
    const tmpRoute = createRoute();
    onObjectSelection && tmpRoute && onObjectSelection(tmpRoute);
    navigateTo('/map/poi/poiList');
  };

  const onErrorSubmit: SubmitErrorHandler<RouteForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit: SubmitHandler<RouteForm> = (data) => {
    const poiIds = poiSelection.poiList?.map((poi) => poi.id);
    const newRoute = {...data, pois: poiIds ? (poiIds as string[]) : []};
    if (addNew) {
      onAddRoute && onAddRoute(newRoute);
    } else {
      onUpdateRoute &&
        onUpdateRoute({
          ...newRoute,
          id: route?.id,
        });
    }
  };

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const RouteTabs = [
    {
      name: 'Info',
      label: t('route.Info'),
      invalid: !!(errors.name || errors.body),
    },
    {name: 'Poi', label: t('route.Poi')},
  ];

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('route.Add new route') : t('route.Edit route')}
        open={openDialog}
        loading={loading}
        minWidth={'1280px'}
        onCloseDialog={handleCloseDialog}
        contentLanguage={true}>
        <Box className={classes.menu}>
          <TabsDialog
            activeItem={activePage}
            handleNavigation={(page) => setActivePage(page)}
            items={RouteTabs}
          />
        </Box>
        <ObjectPreviewWithFormContainer
          t={t}
          id={route?.id}
          objType={'route'}
          objPreview={
            <MobileScreenRouteDetail
              t={t}
              routePois={Utils.getObjListByLanguage(
                CustomUtils.getObjByLanguage,
                poiSelection.poiList
              )}
              routeName={
                selectedLanguage === ContentLanguage.CZ
                  ? watch('name')
                  : watch('nameEn')
              }
              bodyTextHtml={
                (selectedLanguage === ContentLanguage.CZ
                  ? watch('body')
                  : watch('bodyEn')) as string
              }
              parentActiveTab={activePage}
              onSetActiveTab={(page) => setActivePage(page)}
            />
          }
          objEditInputs={
            <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
              <Box marginLeft={4}>
                <Box
                  style={{
                    display: activePage.name === 'Info' ? 'block' : 'none',
                  }}>
                  <TabDetailRoute
                    control={control}
                    selectedLanguage={selectedLanguage}
                  />
                </Box>
                <Box
                  style={{
                    display: activePage.name === 'Poi' ? 'block' : 'none',
                  }}>
                  <TabRoutePoi
                    onPoiSelection={handleChoosePoi}
                    onRemove={(poiId) =>
                      dispatch(removeFromPoiSelectionList(poiId))
                    }
                  />
                </Box>
                <Box>
                  <ButtonAdd
                    name={
                      addNew ? t('route.Add route') : t('route.Update route')
                    }
                  />
                </Box>
              </Box>
            </form>
          }
        />
      </DialogPrimary>
    </Box>
  );
};
