import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import makeStyles from '@mui/styles/makeStyles';
import {contentLanguageSelector} from '../../../../../redux/reducers/ContentLanguage';
import {EditChangesTab} from './EditChangesTab';
import {EmptyChangesTab} from './EmptyChangesTab';
import {DialogChanges} from '../../../../shared/dialogs/dialog-changes/DialogChanges';
import {
  createWishlist,
  deleteWishlist,
  getWishlist,
  setOpenDialog,
  wishlistSelector,
} from '../../../../../redux/reducers/data-reducers/WishlistSlice';

export const ChangesTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const {openDialog, wishlist} = useAppSelector(wishlistSelector);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        {wishlist?.visible ? (
          <EditChangesTab
            wishlist={wishlist}
            onChangeChanges={() => dispatch(setOpenDialog(true))}
            onDeleteChanges={() => dispatch(deleteWishlist())}
          />
        ) : (
          <EmptyChangesTab onAddChanges={() => dispatch(setOpenDialog(true))} />
        )}
      </Box>
      <DialogChanges
        wishlist={wishlist}
        openDialog={openDialog}
        onCloseDialog={() => dispatch(setOpenDialog(false))}
        selectedLanguage={selectedLanguage}
        onUpdateChanges={(wishlist) => dispatch(createWishlist(wishlist))}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    height: 50,
    borderRadius: 10,
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.light,
    margin: 50,
  },
}));
