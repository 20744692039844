import makeStyles from '@mui/styles/makeStyles';
import {MobileHeight, MobileWidth} from '@skczu/czu-react-components';

export const StylesDialog = makeStyles(() => ({
  mobileScreenContainer: {
    boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
    width: MobileWidth,
    height: MobileHeight,
  },
  menu: {
    position: 'absolute',
    left: '32%',
    top: 35,
    zIndex: 1,
  },
  objectName: {
    width: 800,
    fontSize: 30,
  },
  objectNameNotPrimary: {
    width: '100%',
    fontSize: 30,
  },
  textEditor: {
    width: 800,
    padding: 5,
    marginTop: 20,
  },
  gallery: {
    display: 'flex',
    width: '100%',
  },
  rowContainer: {display: 'flex', marginTop: 20},
}));

export const DialogStyles = {
  rowContainer: {width: '100%', display: 'flex', marginTop: 2},
};
