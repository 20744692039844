import {
  Cartesian3,
  Cartographic,
  Color,
  defined,
  Event,
  formatError,
  ImageryProvider,
  Math as CesiumMath,
  PinBuilder,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer as CesiumViewer,
  WebMapServiceImageryProvider,
} from 'cesium';
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {CategoryPinBuilderIcon} from '@skczu/czu-frontend-library';
import {
  selectMapMarkers,
  setPoiCoords,
} from '../../../../redux/reducers/MapSlice';
import {CesiumMovementEvent} from 'resium/src/core/EventManager';
import {setMapLoading} from '../../../../redux/reducers/LoadingSlice';
import {CesiumComponentRef} from 'resium';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import viewerCesiumNavigationMixin from '@znemz/cesium-navigation';
import {useMapType} from './useMapType';
import {useMapPoiMarkers} from './useMapPoiMarkers';
import {useMapRoutePoiPreview} from './useMapRoutePoiPreview';
import {FeatureCollection, Point} from 'geojson';

export const CampusCoords = {longitude: 14.374688, latitude: 50.13016};

export const getCategoryPinFromCategory = (
  categoryId: string,
  categoryPinBuilderIcons: CategoryPinBuilderIcon[]
): HTMLCanvasElement | undefined => {
  if (categoryPinBuilderIcons) {
    return categoryPinBuilderIcons.find(
      (categoryPinBuilderIcon) =>
        categoryPinBuilderIcon.category?.id === categoryId
    )?.icon;
  }
  return undefined;
};
interface Map {
  viewer: CesiumViewer | null;
  mapLoaded: boolean;
  markersList?: FeatureCollection<Point>;
  categoryPinBuilderIcons: CategoryPinBuilderIcon[];
  onMapClick: (event: CesiumMovementEvent) => void;
  defaultImageryProvider: ImageryProvider;
  handleSelectPoi: (id: string) => void;
  ref: RefObject<CesiumComponentRef<CesiumViewer>> | null;
}
export const useMap = (
  imageryProviderUrl: string,
  imageryProviderLayers: string,
  onContextLost?: () => void
): Map => {
  const dispatch = useAppDispatch();
  const {mapFilter, markersList} = useAppSelector(selectMapMarkers);
  const [viewer, setViewer] = useState<CesiumViewer | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const pinBuilder = useMemo(() => new PinBuilder(), []);
  const {categoryPinBuilderIcons} = useMapPoiMarkers(viewer, mapFilter);
  const {handleSelectPoi, flyToPoint} = useMapRoutePoiPreview(viewer);
  useMapType(viewer, mapFilter.show3dObjects, flyToPoint);
  const ref = useRef<CesiumComponentRef<CesiumViewer> | null>(null);

  const defaultImageryProvider = useMemo(
    () =>
      new WebMapServiceImageryProvider({
        layers: imageryProviderLayers,
        url: imageryProviderUrl,
        parameters: {
          format: 'image/jpeg',
          tiled: true,
          transparent: true,
          gridSet: 'EPSG:4326',
        },
      }),
    [imageryProviderLayers, imageryProviderUrl]
  );

  useLayoutEffect(() => {
    function updateSize() {
      if (viewer) {
        viewer.resolutionScale = window.devicePixelRatio;
        viewer.scene.requestRender();
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [viewer]);

  useEffect(() => {
    if (ref.current?.cesiumElement) {
      const cesiumElement = ref.current.cesiumElement;
      cesiumElement.resolutionScale = window.devicePixelRatio;
      cesiumElement.scene.globe.maximumScreenSpaceError = 1.65;
      cesiumElement.scene.postProcessStages.fxaa.enabled = false;

      cesiumElement.scene.skyBox.show = false;
      cesiumElement.scene.sun.show = false;
      cesiumElement.scene.sunBloom = false;
      cesiumElement.scene.skyAtmosphere.show = false;
      cesiumElement.scene.shadowMap.enabled = false;
      cesiumElement.scene.moon.show = false;
      cesiumElement.scene.globe.enableLighting = false;

      cesiumElement.scene.renderError.addEventListener((params, error) => {
        console.log('cesium index boundle renderError error---');
        console.log(params);
        console.log(params.renderError);
        console.log(error);
        console.log(formatError(error));
      });

      const options: Record<string, unknown> = {};
      options.defaultResetView = Cartographic.fromDegrees(
        CampusCoords.longitude - 0.003,
        CampusCoords.latitude,
        2000
      );
      options.enableCompass = true;
      options.enableZoomControls = true;
      options.enableDistanceLegend = false;
      options.units = 'kilometers';
      cesiumElement.extend(viewerCesiumNavigationMixin, options);
      setViewer(cesiumElement);
      cesiumElement &&
        cesiumElement.camera.flyTo({
          duration: 0,
          destination: Cartesian3.fromDegrees(
            CampusCoords.longitude - 0.003,
            CampusCoords.latitude,
            2000.0
          ),
        });

      cesiumElement.screenSpaceEventHandler.removeInputAction(3);
      const element = document.getElementById('cesiumContainer');
      const handler = new ScreenSpaceEventHandler(cesiumElement.scene?.canvas);
      handler.setInputAction(function (movement) {
        const pickedObject = cesiumElement.scene?.pick(movement.endPosition);
        if (element?.style) {
          if (defined(pickedObject) && element?.style) {
            element.style.cursor = 'pointer';
          } else {
            element.style.cursor = 'default';
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
      let tilesListener: Event.RemoveCallback;
      if (!mapLoaded) {
        tilesListener =
          cesiumElement.scene.globe.tileLoadProgressEvent.addEventListener(
            () => {
              if (cesiumElement.scene.globe.tilesLoaded) {
                setMapLoaded(true);
              }
            }
          );
      }
      const handleContextLost = (event: any) => {
        console.log({
          cesiumError:
            'WebGL: CONTEXT_LOST_WEBGL: loseContext: context lost: ' +
            JSON.stringify(event),
        });
        event.preventDefault();
        console.log('WebGL context lost');
        ref.current?.cesiumElement?.destroy();
      };
      cesiumElement.canvas?.addEventListener(
        'webglcontextlost',
        handleContextLost
      );
      const handleContextRestored = () => {
        console.log('WebGL context restored');
        if (cesiumElement && cesiumElement.canvas && ref.current) {
          onContextLost && onContextLost();
          ref.current.cesiumElement = new CesiumViewer(cesiumElement.canvas);
        }
      };
      cesiumElement.canvas?.addEventListener(
        'webglcontextrestored',
        handleContextRestored
      );

      dispatch(setMapLoading(true));
      setTimeout(() => dispatch(setMapLoading(false)), 5000);
      return () => {
        cesiumElement.canvas.removeEventListener(
          'webglcontextlost',
          handleContextLost
        );
        cesiumElement.canvas?.removeEventListener(
          'webglcontextrestored',
          handleContextRestored
        );
        tilesListener &&
          cesiumElement?.scene &&
          cesiumElement.scene.globe.tileLoadProgressEvent.removeEventListener(
            tilesListener
          );
      };
    }
  }, [dispatch, mapLoaded, onContextLost, ref]);

  const onMapClick = useCallback(
    ({position}: CesiumMovementEvent) => {
      if (position && viewer) {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const cartesian = viewer.camera.pickEllipsoid(position, ellipsoid);
        if (cartesian) {
          const cartographic = ellipsoid.cartesianToCartographic(cartesian);
          const longitudeString = CesiumMath.toDegrees(
            cartographic.longitude
          ).toFixed(6);
          const latitudeString = CesiumMath.toDegrees(
            cartographic.latitude
          ).toFixed(6);
          const longitude = Number(longitudeString);
          const latitude = Number(latitudeString);
          if (longitude && latitude) {
            viewer.entities.removeById('new-poi');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newPoi: any = {
              id: 'new-poi',
              position: Cartesian3.fromDegrees(longitude, latitude),
              name: 'New POI',
              billboard: {
                image: pinBuilder
                  .fromText('+', Color.ROYALBLUE, 48)
                  .toDataURL(),
                verticalOrigin: 1,
              },
            };
            viewer.entities.add(newPoi);
            viewer.scene.requestRender();
            dispatch(
              setPoiCoords({
                longitude: String(longitude),
                latitude: String(latitude),
              })
            );
          }
        }
      }
    },
    [dispatch, pinBuilder, viewer]
  );
  return {
    ref,
    viewer,
    mapLoaded,
    markersList,
    categoryPinBuilderIcons,
    onMapClick,
    defaultImageryProvider,
    handleSelectPoi,
  };
};
