import React from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {GetApp} from '@mui/icons-material';
import {Utils} from '@skczu/czu-frontend-library';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

export interface Props {
  id?: string;
  objName?: string;
  openDialog: boolean;
  qrCode?: DamFileWithData;
  onCloseDialog: () => void;
}
export const DialogQrCode = ({
  id,
  objName,
  openDialog,
  qrCode,
  onCloseDialog,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <DialogPrimary
        title={t('allObjects.QR code')}
        open={openDialog}
        onCloseDialog={onCloseDialog}>
        {qrCode?.data && (
          <Box className={classes.content}>
            <Typography variant="h6">{objName}</Typography>
            <img
              alt={id}
              src={Utils.imageBackgroundBase64Source(qrCode)}
              height={200}
              width={200}
            />
            <Box className={classes.buttons}>
              <Button
                href={Utils.imageBackgroundBase64Source(qrCode)}
                target="_blank"
                download={qrCode.fileName}
                className={classes.button}>
                <GetApp fontSize="small" className={classes.buttonIcon} />
                <Typography variant="subtitle2">
                  {t('allObjects.Download QR')}
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </DialogPrimary>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  content: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },
  button: {
    justifyContent: 'left',
    textTransform: 'none',
    padding: 0,
  },
  buttonIcon: {
    marginRight: 10,
  },
}));
