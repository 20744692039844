import {useCallback, useState} from 'react';

export interface BaseObject {
  id?: string;
  name?: string;
  nameEn?: string;
}

export interface SelectAllMultiselectProps<T extends BaseObject> {
  list?: T[];
  multiple?: boolean;
  onChange?: (...event: any[]) => void;
}

export const useSelectAllMultiselect = <T extends BaseObject>({
  list,
  onChange,
  multiple,
}: SelectAllMultiselectProps<T>) => {
  const [all, setAll] = useState(false);

  const handleSelectAll = useCallback(() => {
    setAll((oldAll) => {
      const newValue = !oldAll ? list && list.map((obj) => obj.id) : [];

      onChange && onChange(newValue);
      return !oldAll;
    });
  }, [list, onChange]);

  const handleChangeMultiselect = useCallback(
    (e: any, props: any) => {
      const selectedVal = props?.props?.value;
      const selectValue = e.target.value;
      if (selectedVal) {
        switch (selectedVal) {
          case 'Select all': {
            handleSelectAll();
            break;
          }
          case 'Any': {
            onChange && onChange(multiple ? [] : '');
            setAll(false);
            break;
          }
          default: {
            selectValue && selectValue === 0 && all && setAll(false);
            onChange && onChange(selectValue);
            break;
          }
        }
      }
    },
    [all, handleSelectAll, multiple, onChange]
  );

  return {all, handleChangeMultiselect, setAll};
};
