import React, {useMemo} from 'react';
import {TableUsers} from './users/TableUsers';
import {useTranslation} from 'react-i18next';
import {ScreenTab, ScreenTabs} from '../ScreenTabs';
import {TableRoles} from './roles/TableRoles';

export const TabsUsers = (): JSX.Element => {
  const {t} = useTranslation();
  const tabs = useMemo(
    (): ScreenTab[] => [
      {key: 'users', tab: <TableUsers />, name: t('group.Users')},
      {key: 'roles', tab: <TableRoles />, name: t('role.Roles')},
    ],
    [t]
  );

  return <ScreenTabs tabs={tabs} />;
};
