import React, {useCallback, useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ButtonPrimary} from '../../components/buttons/ButtonPrimary';
import {ContainerPoi} from '../../components/poi/ContainerPoi';
import {ContainerRoute} from '../../components/route/ContainerRoute';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  removeFromPoiSelectionList,
  setPoiSelection,
  setPoiSelectionList,
  setOpenDialogAfterPoiSelection,
  selectSelectedPoi,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {useHistory} from 'react-router-dom';
import {
  setRouteSelection,
  removeFromRouteSelectionList,
  setOpenDialogAfterRouteSelection,
  setRouteSelectionList,
  selectSelectedRoute,
} from '../../../../redux/reducers/RouteListSelectionSlice';
import {SelectEvents} from '../../components/selects/select-multiple/SelectEvents';
import {useTranslation} from 'react-i18next';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdateLayer,
  Layer,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/layer';

export type LayerForm = Omit<Layer, 'id'>;

export interface Props {
  addNew?: boolean;
  layer?: Layer | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddLayer?: (layer: CreateOrUpdateLayer) => void;
  onUpdateLayer?: (layer: CreateOrUpdateLayer) => void;
  onObjectSelection?: (tmpLayer: Layer) => void;
}

export const DialogLayer = ({
  addNew = true,
  layer,
  loading,
  openDialog,
  onCloseDialog,
  onAddLayer,
  onUpdateLayer,
  onObjectSelection,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const routeSelection = useAppSelector(selectSelectedRoute);
  const {control, handleSubmit, reset, setValue, getValues} =
    useForm<LayerForm>();

  const useStyles = makeStyles(() =>
    createStyles({
      listContainer: {
        width: '40%',
      },
    })
  );
  const classes = useStyles();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const navigateTo = useCallback((path) => history.push(path), [history]);

  const clearInputs = useCallback(() => {
    dispatch(setPoiSelectionList([]));
    dispatch(setRouteSelectionList([]));
    reset();
  }, [dispatch, reset]);

  useEffect(() => {
    if (layer && openDialog) {
      setValue('name', layer?.name);
      setValue('nameEn', layer?.nameEn);
      setValue('events', layer?.events);
    }
    if (openDialog && !layer) {
      clearInputs();
    }
    dispatch(setOpenDialogAfterPoiSelection(false));
    dispatch(setOpenDialogAfterRouteSelection(false));
  }, [clearInputs, dispatch, layer, openDialog, setValue]);

  const onErrorSubmit: SubmitErrorHandler<LayerForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit: SubmitHandler<LayerForm> = (data) => {
    const poiIds = poiSelection.poiList?.map((poi) => poi.id);
    const routeIds = routeSelection.baseRouteList?.map((route) => route.id);
    const newLayer = {
      ...data,
      pois: poiIds ? (poiIds as string[]) : [],
      routes: routeIds ? (routeIds as string[]) : [],
    };
    if (addNew) {
      onAddLayer && onAddLayer(newLayer);
    } else {
      onUpdateLayer &&
        onUpdateLayer({
          ...newLayer,
          id: layer?.id,
        });
    }
  };

  const createLayer = (): Layer => {
    const pois = poiSelection.poiList ? poiSelection.poiList : [];
    const routes = routeSelection.baseRouteList;
    const newLayer: Layer = {...getValues(), pois, routes};
    if (layer?.id) {
      newLayer.id = layer.id;
    }
    return newLayer;
  };

  const handleChoosePoi = () => {
    layer?.pois &&
      layer.pois.length > 0 &&
      dispatch(setPoiSelectionList(layer.pois));
    dispatch(setPoiSelection({poiSelection: true, poiSelectionFrom: 'news'}));
    dispatch(setPoiSelection({poiSelection: true, poiSelectionFrom: 'layer'}));
    onObjectSelection && onObjectSelection(createLayer());
    navigateTo('/map/poi/poiList');
  };

  const handleChooseRoute = () => {
    layer?.routes &&
      layer.routes.length > 0 &&
      dispatch(setRouteSelectionList(layer.routes));
    dispatch(setRouteSelection({routeSelection: true, from: 'layer'}));
    onObjectSelection && onObjectSelection(createLayer());
    navigateTo('/map/routes/routeList');
  };

  const handleCloseDialog = () => {
    onCloseDialog();
    reset();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('layer.Add layer') : t('layer.Edit layer')}
        open={openDialog}
        loading={loading}
        minWidth={'900px'}
        contentLanguage={true}
        onCloseDialog={handleCloseDialog}>
        <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
          <Box display="flex" flexDirection="row">
            <Box marginLeft={4}>
              <TextInputObjectName
                selectedLanguage={selectedLanguage}
                control={control}
              />
              <Box
                display="flex"
                flexDirection="row"
                alignSelf={'flex-start'}
                marginTop={2}>
                <Box className={classes.listContainer}>
                  <ButtonPrimary
                    boldLabel
                    label={t('layer.Points of interest')}
                    name={t('layer.Choose POI')}
                    onClick={() => handleChoosePoi()}
                  />
                  <ContainerPoi
                    poiList={
                      poiSelection.poiList
                        ? poiSelection.poiList
                        : layer?.pois && layer.pois
                    }
                    onRemove={(poiId) =>
                      dispatch(removeFromPoiSelectionList(poiId))
                    }
                  />
                </Box>
                <Box marginLeft={6} className={classes.listContainer}>
                  <ButtonPrimary
                    boldLabel
                    label={t('layer.Routes')}
                    name={t('layer.Choose route')}
                    onClick={() => handleChooseRoute()}
                  />
                  <ContainerRoute
                    routeList={
                      routeSelection.baseRouteList
                        ? routeSelection.baseRouteList
                        : layer?.routes
                    }
                    onRemove={(route) =>
                      dispatch(removeFromRouteSelectionList(route))
                    }
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <Controller
                  name="events"
                  control={control}
                  defaultValue={[]}
                  render={({field, fieldState: {error}}) => (
                    <SelectEvents
                      {...field}
                      label={t(`layer.Groups`)}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box marginTop={10}>
                <ButtonAdd
                  name={addNew ? t('layer.Add layer') : t('layer.Update layer')}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </DialogPrimary>
    </Box>
  );
};
