import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Delete} from '@mui/icons-material';
import {CustomUtils} from '../../utils/CustomUtils';
import {Route} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';

export interface Props {
  routeList?: Route[];
  onRemove?: (route: Route) => void;
  border?: boolean;
}
export const ContainerRoute = ({
  routeList,
  onRemove,
  border = true,
}: Props): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    listContainer: {
      marginTop: 20,
      display: 'flex',
      minHeight: 115,
      border: border ? `2px solid ${theme.palette.secondary.light}` : undefined,
      width: '100%',
      borderRadius: 10,
      boxShadow: theme.shadows[2],
      flexDirection: 'column',
    },
    routeContainer: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.listContainer}>
      <Box m={1}>
        {routeList &&
          routeList.map((route: Route) => (
            <Box m={1} className={classes.routeContainer} key={route.id}>
              <Typography variant={'subtitle2'}>
                {CustomUtils.getContentByLanguage(route.name, route.nameEn)}
              </Typography>
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={() => onRemove && onRemove(route)}>
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
