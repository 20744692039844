import {
  Box,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {DialogPrimary} from '../DialogPrimary';
import React, {useEffect} from 'react';
import {
  CustomTable,
  CustomTableCell,
} from '../../components/custom-inputs/TableBase';
import {AddCircleOutline, Visibility} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  getPoi360,
  getPoiPoi360Ids,
  poi360Selector,
  updatePoi360,
} from '../../../../redux/reducers/data-reducers/Poi360Slice';
import {
  poi360SelectionSelector,
  setOpenLocalPoi360Dialog,
  setOpenPoi360Dialog,
  setPoi360Selection,
} from '../../../../redux/reducers/Poi360SelectionSlice';
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from 'react-i18next';
import {CustomUtils} from '../../utils/CustomUtils';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import {Poi360} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {ObjMapper} from '@skczu/czu-frontend-library';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `3px solid ${theme.palette.secondary.light}`,
  },
}));

export interface Props {
  basePoi: PoiRef;
  openDialog: boolean;
  onCloseDialog: () => void;
  onAddLocalPoi: () => void;
}

export const DialogLocal360 = ({
  basePoi,
  openDialog,
  onCloseDialog,
  onAddLocalPoi,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {poi360List, poi360} = useAppSelector(poi360Selector);
  const {poi360Selection} = useAppSelector(poi360SelectionSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    basePoi && basePoi.id && dispatch(getPoiPoi360Ids(basePoi.id));
  }, [basePoi, dispatch]);

  const handleLocalAdd = (selectedPoi360: Poi360) => {
    const hotspot = poi360Selection && poi360Selection.hotspot;
    const newHotspot = Object.assign({}, hotspot);
    if (newHotspot) {
      const poi360WithHotspot = Object.assign({}, poi360Selection.fromPoi360);
      newHotspot.poi360 = selectedPoi360.id;
      newHotspot.name = selectedPoi360.name;
      newHotspot.nameEn = selectedPoi360.nameEn;
      poi360WithHotspot.hotspot = poi360WithHotspot.hotspot
        ? [...poi360WithHotspot.hotspot, newHotspot]
        : [newHotspot];
      const localPoi360: Poi360WithData = {
        ...poi360WithHotspot,
        image: ObjMapper.getDamFileWithDataFromDamFile(poi360WithHotspot.image),
        hotspot: poi360WithHotspot.hotspot
          ? [...poi360WithHotspot.hotspot, newHotspot]
          : [newHotspot],
      };
      dispatch(updatePoi360(localPoi360));
      dispatch(setPoi360Selection(false));
      dispatch(setOpenPoi360Dialog(true));
      dispatch(setOpenLocalPoi360Dialog(false));
      onAddLocalPoi();
    }
  };

  const handleEditClick = (id: string) => {
    if (poi360) {
      poi360.id !== id && dispatch(getPoi360(id));
    } else {
      dispatch(getPoi360(id));
    }
    dispatch(setOpenPoi360Dialog(true));
  };

  return (
    <Box>
      <DialogPrimary
        title={t('localPoi360.Select local POI')}
        open={openDialog}
        minWidth={'900px'}
        onCloseDialog={onCloseDialog}>
        <Box marginLeft={2}>
          <Box m={0.5}>
            <Typography variant="subtitle2" id="text-editor">
              {t('localPoi360.Global POI name')}
            </Typography>
          </Box>
          <Box marginTop={-2} marginLeft={0.5}>
            <h3>{basePoi && basePoi.name}</h3>
          </Box>
          <Box width={800} marginBottom={5}>
            <Box m={0.5}>
              <Typography variant="subtitle2" id="text-editor">
                {t('localPoi360.Local POIs')}
              </Typography>
            </Box>
            <Box marginLeft={0.5}>
              <CustomTable classes={{root: classes.table}}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>{t('localPoi360.Name')}</CustomTableCell>
                    <CustomTableCell align="right">
                      {t('localPoi360.Action')}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {poi360List &&
                    poi360List.map((poi360Obj: Poi360) => (
                      <TableRow key={poi360Obj.id}>
                        <CustomTableCell component="th" scope="row">
                          {poi360Selection.poi360Selection &&
                            !poi360Selection.localSelection && (
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                size="small"
                                onClick={() =>
                                  poi360Obj.id && handleLocalAdd(poi360Obj)
                                }>
                                <Box marginLeft={1} marginRight={1}>
                                  <AddCircleOutline
                                    fontSize="small"
                                    color={'primary'}
                                  />
                                </Box>
                              </IconButton>
                            )}
                          {CustomUtils.getContentByLanguage(
                            poi360Obj.name,
                            poi360Obj.nameEn
                          )}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            size="small"
                            onClick={() =>
                              poi360Obj.id && handleEditClick(poi360Obj.id)
                            }>
                            <Visibility fontSize="small" />
                          </IconButton>
                        </CustomTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </DialogPrimary>
    </Box>
  );
};
