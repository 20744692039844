import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../store';
import {
  Coordinates,
  ObjectRef,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Feature, FeatureCollection, Point} from 'geojson';
import {Poi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {MapUtils} from '@skczu/czu-frontend-library';

export interface MapPoiFilter {
  selectedGroups: ObjectRef[];
  show3dObjects: boolean;
  layerIds: string[];
  selectedCategoryId?: string;
  tmpMapRender: number;
}

interface MapState {
  isFilter?: boolean;
  mapFilter: MapPoiFilter;
  coords?: Coordinates;
  isPoiCoords: boolean;
  markersList?: FeatureCollection<Point>;
}

const initialState: MapState = {
  mapFilter: {
    selectedGroups: [],
    layerIds: [],
    show3dObjects: false,
    tmpMapRender: 1,
  },
  isPoiCoords: false,
  isFilter: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setPoiCoords: (state, {payload}: PayloadAction<Coordinates>) => {
      state.coords = payload;
      state.isPoiCoords = true;
    },
    setFilter: (state, {payload}: PayloadAction<boolean>) => {
      state.isFilter = payload;
    },
    unsetPoiCoords: (state) => {
      state.coords = undefined;
      state.isPoiCoords = false;
    },
    setShow3dObjects: (state, {payload}: PayloadAction<boolean>) => {
      state.mapFilter.show3dObjects = payload;
    },
    setTmpMapRender: (state) => {
      state.mapFilter.tmpMapRender = state.mapFilter.tmpMapRender + 1;
    },
    setFilterLayerId: (state, {payload}: PayloadAction<string>) => {
      state.mapFilter.selectedGroups = [];
      state.mapFilter.layerIds =
        state.mapFilter.layerIds.indexOf(payload) > -1
          ? state.mapFilter.layerIds.filter((id) => payload !== id)
          : [...state.mapFilter.layerIds, payload];
    },
    setFilterLayerIds: (state, {payload}: PayloadAction<string[]>) => {
      state.mapFilter.layerIds = payload;
    },
    setFilterSelectedGroup: (state, {payload}: PayloadAction<ObjectRef>) => {
      state.mapFilter.layerIds = [];
      state.mapFilter.selectedGroups =
        state.mapFilter.selectedGroups
          .map((selectedGroup) => selectedGroup.id)
          .indexOf(payload.id) > -1
          ? state.mapFilter.selectedGroups.filter(
              (group) => payload.id !== group.id
            )
          : [...state.mapFilter.selectedGroups, payload];
    },
    setFilterSelectedGroups: (state, {payload}: PayloadAction<ObjectRef[]>) => {
      state.mapFilter.selectedGroups = payload;
    },
    setFilterCategoryId: (state, {payload}: PayloadAction<string>) => {
      state.mapFilter.selectedCategoryId =
        state.mapFilter.selectedCategoryId === payload ? '' : payload;
    },
    setMarkersList: (
      state,
      {payload}: PayloadAction<FeatureCollection<Point> | undefined>
    ) => {
      state.markersList = payload;
    },
  },
});

export const setPoiMarkers =
  (features: Feature<Point>[]): AppThunk =>
  async (dispatch, getState) => {
    const objectPreviewView = getState().drawer.objectPreviewView;
    const objectPreview = getState().drawer.objectPreview;
    const isSelectedPoi = !!(
      objectPreviewView.openObjectPreview &&
      objectPreview.objectMobileScene === 'poi' &&
      (objectPreview.objectPreviewData as Poi).coordinates?.longitude &&
      (objectPreview.objectPreviewData as Poi).coordinates?.latitude
    );
    const featuresList =
      isSelectedPoi &&
      !features.some(
        (feature: Feature<Point>) =>
          feature.properties?.mgnl_id ===
          (objectPreview.objectPreviewData as Poi).id
      )
        ? [
            ...MapUtils.createMarkersFromFeatures(features).features,
            isSelectedPoi &&
              MapUtils.createMarkerFromPoi(
                objectPreview.objectPreviewData as Poi
              ),
          ]
        : MapUtils.createMarkersFromFeatures(features).features;
    dispatch(
      setMarkersList({
        ...MapUtils.createMarkersFromFeatures(features),
        features: featuresList,
      })
    );
  };

export const {
  setPoiCoords,
  unsetPoiCoords,
  setFilter,
  setFilterLayerId,
  setFilterLayerIds,
  setFilterCategoryId,
  setTmpMapRender,
  setFilterSelectedGroup,
  setShow3dObjects,
  setFilterSelectedGroups,
  setMarkersList,
} = mapSlice.actions;

export const selectMapMarkers = (state: RootState) => {
  const {mapFilter, markersList} = state.map;
  return {mapFilter, markersList};
};
export const selectPoiCoords = (state: RootState) => {
  const {coords} = state.map;
  return {coords};
};
export const selectMapFilter = (state: RootState) => {
  const {mapFilter, isFilter} = state.map;
  return {mapFilter, isFilter};
};
export const selectShowMap3dObjects = (state: RootState): boolean =>
  state.map.mapFilter.show3dObjects;

export default mapSlice.reducer;
