import React from 'react';
import {Switch} from '@mui/material';
import {CellProps, Row} from 'react-table';
import {AdminAppsObj} from '../TableApps';

export interface AppsStatusCellProps {
  cell: CellProps<AdminAppsObj>;
  onEditClick?: (value: boolean, appId?: number) => void;
}
export const AppsStatus = ({
  cell,
  onEditClick,
}: AppsStatusCellProps): JSX.Element => {
  const getId = (row: Row<AdminAppsObj>): number | undefined => {
    return row.cells?.find((tableCell) => tableCell.column.id === 'action')
      ?.value;
  };
  const appId = getId(cell.row);

  return (
    <>
      <Switch
        name={'apps-status'}
        id={'apps-status'}
        checked={cell.value}
        onChange={() => {
          onEditClick && onEditClick(!cell.value, appId);
        }}
        color={'primary'}
      />
    </>
  );
};
