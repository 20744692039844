import {FilterProps} from 'react-table';
import {useAppSelector} from '../../../hooks/hooks';
import {selectSimpleLayerList} from '../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {SelectLayers} from '../components/selects/select-multiple/SelectLayers';
import React from 'react';
import {getObjIdsByName, getObjNameByIds} from '../utils/Utils';

export const SelectLayersFilter = <T extends Record<string, unknown>>({
  column: {filterValue, render, setFilter, id},
}: FilterProps<T>): JSX.Element => {
  const simpleLayerList = useAppSelector(selectSimpleLayerList);

  return (
    <SelectLayers
      name={id + '_id'}
      label={render('Header')}
      value={getObjIdsByName(simpleLayerList, filterValue) || []}
      onChange={(val) => {
        setFilter(getObjNameByIds(simpleLayerList, val as string[]));
      }}
    />
  );
};
