import React from 'react';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {useTranslation} from 'react-i18next';

export interface EmptyChangesTabProps {
  onAddChanges?: () => void;
}

export const EmptyChangesTab = ({
  onAddChanges,
}: EmptyChangesTabProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.buttonContainer}>
      <img
        alt={'iconSrc'}
        src={'/assets/icon/Whislist.svg'}
        className={classes.icon}
      />
      <Typography variant={'h5'} style={{fontWeight: 'bold', marginBottom: 30}}>
        {t('changes.Inform user about upcoming changes')}
      </Typography>
      <Box style={{maxWidth: '60%', marginBottom: 70}}>
        <Typography>
          {t('changes.There are no upcoming changes added yet')}
        </Typography>
      </Box>
      <ButtonAdd
        name={t('changes.Add upcoming changes')}
        onClick={onAddChanges}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  icon: {
    width: 400,
    height: 400,
    opacity: 0.3,
  },
}));
