import React from 'react';
import {Box} from '@mui/material';
import {TFunction} from 'react-i18next';
import {MobileObjectDetailButtons} from './MobileObjectDetailButtons';
import {StylesDialog} from '../../dialogs/StylesDialog';

export interface ObjectPreviewWithFormContainerProps {
  id?: string;
  objType?: 'event' | 'route' | 'poi' | 'news';
  objPreview?: React.ReactNode;
  objEditInputs?: React.ReactNode;
  t: TFunction;
}

export const ObjectPreviewWithFormContainer = ({
  t,
  id,
  objType,
  objPreview,
  objEditInputs,
}: ObjectPreviewWithFormContainerProps): JSX.Element => {
  const classes = StylesDialog();
  return (
    <Box display="flex" flexDirection="row">
      <MobileObjectDetailButtons t={t} id={id} objType={objType}>
        <Box className={classes.mobileScreenContainer}>
          <>{objPreview}</>
        </Box>
      </MobileObjectDetailButtons>
      <>{objEditInputs}</>
    </Box>
  );
};
