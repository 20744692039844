import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Folder, FolderOpen} from '@mui/icons-material';
import {Tile3D} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d/api';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

const useStyles = makeStyles((theme) => ({
  viewer: {
    width: 800,
    minHeight: 400,
    border: `3px solid ${theme.palette.secondary.light}`,
  },
  folderContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
}));

export interface Props {
  files?: Tile3D[] | DamFileWithData[];
  dirName?: string;
}

export const MultipleFileViewer = ({files, dirName}: Props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Box className={classes.viewer}>
      <Box m={2}>
        {dirName && files && files?.length > 0 && (
          <Box
            className={classes.folderContainer}
            onClick={() => setOpen(!open)}>
            {open && <FolderOpen className={classes.icon} />}
            {!open && <Folder className={classes.icon} />}
            <Typography variant={'subtitle2'}>{dirName}</Typography>
          </Box>
        )}
        <Box m={1}>
          {(open || !dirName) &&
            files &&
            files.map((file, index) => (
              <Box key={dirName + '/' + index}>
                <Typography variant={'subtitle1'}>
                  {(file as Tile3D).name
                    ? (file as Tile3D).name
                    : (file as DamFileWithData).fileName}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
