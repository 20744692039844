import React, {forwardRef, useEffect} from 'react';
import {Box, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {FormModel} from '../../../../hooks/useForm';
import {CategoryListIcon} from '../lists/CategoryListIcon';
import {CustomUtils} from '../../utils/CustomUtils';
import {
  getSimpleCategoryList,
  selectSimpleCategoryList,
} from '../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import SelectMultiplePrimary from './SelectMultiplePrimary';
import {useTranslation} from 'react-i18next';

const SelectCategory = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      onChange,
      className,
      disabled,
      onBlur,
      errorType,
    }: FormModel,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleCategoryList = useAppSelector(selectSimpleCategoryList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!simpleCategoryList || simpleCategoryList.length === 0) {
        dispatch(getSimpleCategoryList());
      }
    }, [dispatch, simpleCategoryList]);

    return (
      <Box>
        <SelectMultiplePrimary
          id={id}
          name={name}
          selectedValue={
            value ? (
              simpleCategoryList?.find((obj) => obj.id === value) ? (
                <ListItemIcon>
                  <CategoryListIcon
                    poiCategory={simpleCategoryList?.find(
                      (obj) => obj.id === value
                    )}
                  />
                  <ListItemText
                    sx={{
                      '& .MuiListItemText-primary': {
                        marginLeft: '10px',
                        marginTop: '2px',
                        color: 'secondary.contrastText',
                      },
                    }}
                    primary={CustomUtils.getContentByLanguage(
                      simpleCategoryList?.find((obj) => obj.id === value)?.name,
                      simpleCategoryList?.find((obj) => obj.id === value)
                        ?.nameEn
                    )}
                  />
                </ListItemIcon>
              ) : (
                ''
              )
            ) : (
              t('poi.Category') + ` (${t('allObjects.None')}) `
            )
          }
          disabled={disabled}
          label={label}
          className={className}
          ref={ref}
          value={value}
          multiple={false}
          onBlur={onBlur}
          errorType={errorType}
          onChange={onChange}
          list={simpleCategoryList}
          selectAny
          renderCustomMenuItem={(item) => (
            <MenuItem value={item.id} key={item.id}>
              <ListItemIcon>
                <CategoryListIcon poiCategory={item} />
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      marginLeft: '10px',
                      marginTop: '5px',
                      color: 'secondary.contrastText',
                    },
                  }}
                  primary={CustomUtils.getContentByLanguage(
                    item.name,
                    item.nameEn
                  )}
                />
              </ListItemIcon>
            </MenuItem>
          )}></SelectMultiplePrimary>
      </Box>
    );
  }
);

SelectCategory.displayName = 'SelectCategory';

export default SelectCategory;
