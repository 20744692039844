import {Math as CesiumMath, Rectangle, Viewer as CesiumViewer} from 'cesium';
import {useCallback, useEffect, useState} from 'react';
import {
  BBOX,
  CategoryPinBuilderIcon,
  MapUtils,
} from '@skczu/czu-frontend-library';
import axios from 'axios';
import config from '../../../../config';
import {MapPoiFilter, setPoiMarkers} from '../../../../redux/reducers/MapSlice';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {selectObjectPreview} from '../../../../redux/reducers/DrawerSlice';
import {defaultEventSelector} from '../../../../redux/reducers/data-reducers/GroupsSlice';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {selectPoiCategoryList} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';

export const getPoiCategoriesPinBuilderIcons = async (
  categories: PoiCategory[]
): Promise<CategoryPinBuilderIcon[]> => {
  const mapCategoryIcons: CategoryPinBuilderIcon[] = [];
  for (const category of categories) {
    const icon = await MapUtils.getCategoryPinIcon(category);
    icon && mapCategoryIcons.push({category, icon});
  }
  return mapCategoryIcons;
};

interface MapPoiMarkers {
  categoryPinBuilderIcons: CategoryPinBuilderIcon[];
}

export const useMapPoiMarkers = (
  viewer: CesiumViewer | null,
  mapFilter: MapPoiFilter
): MapPoiMarkers => {
  const dispatch = useAppDispatch();
  const {objectPreviewView, objectPreview} =
    useAppSelector(selectObjectPreview);
  const poiCategoryList = useAppSelector(selectPoiCategoryList);
  const defaultEvent = useAppSelector(defaultEventSelector);

  const [mapBBOX, setMapBBOX] = useState<BBOX | null>(null);
  const [categoryPinBuilderIcons, setCategoryPinBuilderIcons] = useState<
    CategoryPinBuilderIcon[]
  >([]);

  useEffect(() => {
    if (poiCategoryList) {
      getPoiCategoriesPinBuilderIcons(poiCategoryList).then(
        (poiCategoryPinBuilderIcons) =>
          setCategoryPinBuilderIcons(poiCategoryPinBuilderIcons)
      );
    }
  }, [poiCategoryList]);

  useEffect(() => {
    if (viewer) {
      viewer.camera.moveEnd.addEventListener(function () {
        if (viewer) {
          const scratchRectangle = new Rectangle();
          const rect = viewer.camera.computeViewRectangle(
            viewer.scene.globe.ellipsoid,
            scratchRectangle
          );
          if (rect) {
            const west = Number(CesiumMath.toDegrees(rect.west).toFixed(4));
            const south = Number(CesiumMath.toDegrees(rect.south).toFixed(4));
            const east = Number(CesiumMath.toDegrees(rect.east).toFixed(4));
            const north = Number(CesiumMath.toDegrees(rect.north).toFixed(4));
            west &&
              south &&
              east &&
              north &&
              setMapBBOX({
                west: west,
                south: south,
                east: east,
                north: north,
              });
          }
        }
      });
      viewer.scene.requestRenderMode = true;
    }
  }, [viewer]);

  const createBoundaryPoisUrl = useCallback(() => {
    const params = new URLSearchParams();
    params.append('SERVICE', 'WMS');
    params.append('VERSION', '1.1.1');
    params.append('REQUEST', 'GetMap');
    params.append('FORMAT', 'geojson');
    params.append('TRANSPARENT', 'true');
    params.append('exceptions', 'application%2Fvnd.ogc.se_inimage');
    params.append('SRS', 'EPSG:4326');
    params.append('WIDTH', String(window.innerWidth));
    params.append('HEIGHT', String(window.innerHeight));
    params.append(
      'BBOX',
      mapBBOX?.west +
        ',' +
        mapBBOX?.south +
        ',' +
        mapBBOX?.east +
        ',' +
        mapBBOX?.north
    );
    const layerIds = MapUtils.createLayerIdArray(
      mapFilter.layerIds,
      mapFilter.selectedGroups
    );
    params.append('LAYERS', 'czu:mgnl_poi');
    //TODO: napojit filter na kategorie
    const cqlFilter = MapUtils.createCQLFilterUrl(
      mapFilter.selectedCategoryId || null,
      layerIds.length === 0
        ? defaultEvent?.layers
          ? defaultEvent.layers.map((id) => id as string)
          : []
        : layerIds
    );
    if (mapFilter.tmpMapRender) {
      cqlFilter && params.append('CQL_FILTER', 'visible=true and ' + cqlFilter);
      !cqlFilter && params.append('CQL_FILTER', 'visible=true');
    }
    return params;
  }, [
    mapBBOX?.west,
    mapBBOX?.south,
    mapBBOX?.east,
    mapBBOX?.north,
    mapFilter.layerIds,
    mapFilter.selectedGroups,
    mapFilter.selectedCategoryId,
    mapFilter.tmpMapRender,
    defaultEvent?.layers,
  ]);

  useEffect(() => {
    let cancelled = false;
    if (
      mapBBOX &&
      !(
        objectPreviewView.openObjectPreview &&
        objectPreview.objectMobileScene === 'route'
      )
    ) {
      const layerIdList = MapUtils.getLayerIdsFromGroup(
        mapFilter.selectedGroups
      );
      if (!(mapFilter.selectedGroups.length > 0 && layerIdList.length === 0)) {
        axios
          .get(config.czuWmsUrl, {
            params: createBoundaryPoisUrl(),
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 10000,
          })
          .then((response) => {
            if (
              !cancelled &&
              response.data &&
              response.data.features &&
              response.data.features.length
            ) {
              dispatch(setPoiMarkers(response.data.features));
              setTimeout(() => {
                viewer?.scene.requestRender();
              }, 1);
            } else {
              dispatch(setPoiMarkers([]));
              viewer?.scene.requestRender();
            }
          })
          .catch((ex) => {
            // console.log(ex);
          });
      } else {
        dispatch(setPoiMarkers([]));
        viewer?.scene.requestRender();
      }
    }
    return () => {
      cancelled = true;
    };
  }, [
    mapBBOX,
    createBoundaryPoisUrl,
    objectPreviewView.openObjectPreview,
    mapFilter.selectedGroups,
    objectPreview.objectMobileScene,
    viewer?.scene,
    dispatch,
  ]);

  return {categoryPinBuilderIcons};
};
