import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  deleteObj,
  GenericState,
} from '@skczu/czu-frontend-library';
import {
  Configuration,
  RoleApi,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {RoleApi as KeycloakRoleApi} from '@skczu/czu-frontend-library/build/apis/keycloak-user-service-api/generated';
import {PayloadAction} from '@reduxjs/toolkit';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  KeycloakRole,
  Order,
  RoleSortBy,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated/api';
import {getToken} from '../../../keycloak';
import {getSimpleRoleList} from './SimpleObjectListsSlice';

export interface BaseRolesRequest {
  name?: string;
  minUser?: number;
  maxUser?: number;
  isCzuRole?: boolean;
  limit?: number;
  offset?: number;
  orderBy?: RoleSortBy;
  sortOrder?: Order;
}
export interface BaseRolesList {
  totalCount?: number;
  list: KeycloakRole[];
}

export interface RolesState extends GenericState<KeycloakRole, KeycloakRole> {
  adminList: BaseRolesList;
}

const initialState: RolesState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {list: []},
};

const rolesSlice = createGenericSlice({
  name: 'roles',
  initialState,
})({
  setAdminList: (
    state: RolesState,
    {payload}: PayloadAction<BaseRolesList>
  ) => {
    state.adminList = payload;
  },
  addToAdminList: (
    state: RolesState,
    {payload}: PayloadAction<KeycloakRole[]>
  ) => {
    state.adminList.list =
      state.baseObjSearch.offset !== 0
        ? state.adminList && [...state.adminList.list, ...payload]
        : payload;
  },
});

export const getBaseRoleList =
  ({
    name,
    minUser,
    maxUser,
    isCzuRole,
    limit,
    offset,
    orderBy,
    sortOrder,
  }: BaseRolesRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(rolesActions.setObjLoadingList(true));
      const response = await new RoleApi(
        new Configuration({accessToken: await getToken()}),
        config.userDataRestUrl
      ).adminRoleGet(
        name,
        minUser,
        maxUser,
        isCzuRole,
        limit,
        offset,
        orderBy,
        sortOrder
      );
      if (response.data.objects) {
        dispatch(
          rolesActions.setAdminList({
            list: response.data.objects ? response.data.objects : [],
            totalCount: response.data.totalCount,
          })
        );
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(rolesActions.setObjLoadingList(false));
    }
  };

export const getRoleFromList =
  (key: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(rolesActions.setObjLoadingList(true));
      const role = getState().roles.adminList.list.find(
        (role) => role.key === key
      );
      role && dispatch(rolesActions.setObj(role));
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(rolesActions.setObjLoadingList(false));
    }
  };

export const updateRole =
  (newRole: boolean, role: KeycloakRole, onEdit?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(rolesActions.setObjLoadingList(true));
      if (newRole) {
        await new RoleApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminRolePost(role);
      } else {
        if (role.id) {
          await new RoleApi(
            new Configuration({accessToken: await getToken()}),
            config.userDataRestUrl
          ).adminRoleRoleKeyPut(String(role.key), role);
        }
      }
      onEdit && onEdit();
      dispatch(rolesActions.setOpenObjDialog(false));
      dispatch(getSimpleRoleList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(rolesActions.setObjLoadingList(false));
    }
  };

export const deleteRole =
  (key: string, onDelete?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        key,
        async () =>
          new RoleApi(
            new Configuration({accessToken: await getToken()}),
            config.userDataRestUrl
          ).adminRoleRoleKeyDelete(key),
        (loading) => dispatch(rolesActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(rolesActions.setObj(null));
          dispatch(rolesActions.removeFromBaseObjList(key));
          onDelete && onDelete();
          dispatch(getSimpleRoleList());
        }
      )
    );
  };

export const deleteRoles =
  (roleIds: string[], onDelete?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(rolesActions.setObjLoadingList(true));
      await new KeycloakRoleApi(
        new Configuration({accessToken: await getToken()}),
        config.keycloakDataRestUrl
      ).roleRemoveDelete(roleIds);
      onDelete && onDelete();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(rolesActions.setObjLoadingList(false));
    }
  };
export const rolesActions = rolesSlice.actions;

export const rolesSelector = (state: RootState): typeof state.roles =>
  state.roles;

export default rolesSlice.reducer;
