import React, {useCallback} from 'react';

export interface InfoDialog {
  openDialog: boolean;
  id?: string;
}

export interface InfoDialogReturn {
  infoDialog: InfoDialog;
  setInfoDialog: (data: InfoDialog) => void;
  onSetAgree: (id?: string) => void;
  onCloseInfoDialog: () => void;
  showInfoDialog: (id: string) => void;
}
export interface Props {
  onAgree: (id?: string) => void;
}

export const useInfoDialog = ({onAgree}: Props): InfoDialogReturn => {
  const [infoDialog, setInfoDialog] = React.useState<InfoDialog>({
    openDialog: false,
  });

  const onSetAgree = useCallback(
    (id?: string) => {
      setInfoDialog({
        openDialog: false,
        id: undefined,
      });
      onAgree && onAgree(id);
    },
    [onAgree]
  );

  const onCloseInfoDialog = useCallback(() => {
    setInfoDialog({
      openDialog: false,
      id: undefined,
    });
  }, []);

  const showInfoDialog = useCallback((id: string) => {
    setInfoDialog({
      openDialog: true,
      id: id,
    });
  }, []);

  return {
    infoDialog,
    setInfoDialog,
    onSetAgree,
    onCloseInfoDialog,
    showInfoDialog,
  };
};
