import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {setLoading} from '../LoadingSlice';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';
import {UploadFile} from '../../../hooks/useUploadPhoto';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  GalleryPhotoApi,
  ImageApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {ObjMapper} from '@skczu/czu-frontend-library';
import {getToken} from '../../../keycloak';

export interface GalleryPhotoListError {
  message: string;
}

interface GalleryPhotoState {
  galleryPhotoList: UploadFile[];
  error: GalleryPhotoListError;
}

const initialState: GalleryPhotoState = {
  galleryPhotoList: [],
  error: {message: 'An Error occurred'},
};

export const galleryPhotoSlice = createSlice({
  name: 'galleryPhoto',
  initialState,
  reducers: {
    setGalleryPhotoList: (state, {payload}: PayloadAction<UploadFile[]>) => {
      state.galleryPhotoList = payload;
    },
    setGalleryPhotoListFailed: (
      state,
      {payload}: PayloadAction<GalleryPhotoListError>
    ) => {
      state.error = payload;
    },
    addToGalleryPhotoList: (state, {payload}: PayloadAction<UploadFile>) => {
      state.galleryPhotoList =
        state.galleryPhotoList.length > 0
          ? state.galleryPhotoList?.some(
              (photo) =>
                photo.galleryPhoto &&
                payload.galleryPhoto &&
                ObjMapper.geNameFromDam(photo.galleryPhoto) !==
                  ObjMapper.geNameFromDam(payload.galleryPhoto)
            )
            ? [...state.galleryPhotoList, payload]
            : state.galleryPhotoList
          : [payload];
    },
    updateGalleryPhotoInList: (state, {payload}: PayloadAction<UploadFile>) => {
      const galleryPhotoList = state.galleryPhotoList.map((galleryPhoto) =>
        galleryPhoto.galleryPhoto &&
        payload.galleryPhoto &&
        ObjMapper.geNameFromDam(galleryPhoto.galleryPhoto) ===
          ObjMapper.geNameFromDam(payload.galleryPhoto)
          ? payload
          : galleryPhoto
      );
      state.galleryPhotoList = [...galleryPhotoList];
    },
    removeFromGalleryPhotoList: (state, {payload}: PayloadAction<string>) => {
      const galleryPhotoList = state.galleryPhotoList.filter(
        (galleryPhoto) => galleryPhoto.galleryPhoto?.id !== payload
      );
      state.galleryPhotoList = [...galleryPhotoList];
    },
  },
});

export const deleteGalleryPhoto =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const success = await new GalleryPhotoApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).galleryPhotoIdDelete(id);
      if (success) {
        dispatch(removeFromGalleryPhotoList(id));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteVideo =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await new ImageApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).imageIdDelete(id);
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const {
  setGalleryPhotoList,
  addToGalleryPhotoList,
  updateGalleryPhotoInList,
  setGalleryPhotoListFailed,
  removeFromGalleryPhotoList,
} = galleryPhotoSlice.actions;

export const galleryPhotoSelector = (
  state: RootState
): typeof state.galleryPhoto => state.galleryPhoto;

export default galleryPhotoSlice.reducer;
