import {AppThunk, RootState} from '../../../store';
import {setActiveObjectPreview} from '../DrawerSlice';
import {setGalleryPhotoList} from './GalleryPhotoSlice';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getObj,
  updateObj,
} from '@skczu/czu-frontend-library';
import {PayloadAction} from '@reduxjs/toolkit';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {setPoiSelectionList} from '../PoiListSelectionSlice';
import {setRouteSelectionList} from '../RouteListSelectionSlice';
import {
  AdminNews,
  Configuration,
  CreateOrUpdateNews,
  News,
  NewsApi,
  NewsFilterRequest,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {getToken} from '../../../keycloak';
import moment from 'moment';
import {SaveOrEditNotificationRequest} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated/api';

export type NewsNotificationState =
  | 'new'
  | 'withoutNotification'
  | 'scheduledNotification'
  | 'sentNotification';

export const getNotificationState = (
  newNews?: boolean,
  pushNot?: boolean,
  sendDate?: string
): NewsNotificationState => {
  if (newNews) {
    return 'new';
  } else {
    if (pushNot) {
      if (moment(sendDate).isValid()) {
        if (moment(sendDate).isBefore(moment())) {
          return 'sentNotification';
        } else {
          return 'scheduledNotification';
        }
      } else {
        return 'withoutNotification';
      }
    } else {
      return 'withoutNotification';
    }
  }
};

interface NewsNotificationSelection {
  newsNotificationSelection: boolean;
  newsNotificationState?: NewsNotificationState;
  notificationData?: SaveOrEditNotificationRequest;
  notificationId?: number;
  newsId?: string;
}

export interface BaseList {
  totalCount?: number;
  list: AdminNews[];
}
export interface NewsState extends GenericState<AdminNews, News> {
  adminList: BaseList;
  newsNotificationSelection: NewsNotificationSelection;
}

const initialState: NewsState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {
    list: [],
  },
  newsNotificationSelection: {newsNotificationSelection: false},
};

const newsSlice = createGenericSlice({
  name: 'news',
  initialState,
})({
  setAdminList: (state: NewsState, {payload}: PayloadAction<BaseList>) => {
    state.adminList = payload;
  },
  addToAdminList: (state: NewsState, {payload}: PayloadAction<AdminNews[]>) => {
    state.adminList.list =
      state.baseObjSearch.offset !== 0
        ? state.adminList && [...state.adminList.list, ...payload]
        : payload;
  },
  setNewsNotificationSelection: (
    state: NewsState,
    {payload}: PayloadAction<NewsNotificationSelection>
  ) => {
    state.newsNotificationSelection = payload;
  },
});

export const getBaseNewsList =
  (request: NewsFilterRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(newsActions.setObjLoadingList(true));
      const response = await new NewsApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).newsAdminFilterPost(request);
      if (response?.data) {
        dispatch(
          newsActions.setAdminList({
            list: response.data.objects ? response.data.objects : [],
            totalCount: response.data.totalCount,
          })
        );
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(newsActions.setObjLoadingList(false));
    }
  };

export const createNews =
  (news: CreateOrUpdateNews, onCreate: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CreateOrUpdateNews>(
        news,
        async (obj) =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminPost({...obj, objectType: 'CreateOrUpdateNews'}),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          onCreate();
          dispatch(newsActions.setObj(null));
          dispatch(newsActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const getNews =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<News>(
        id,
        async () =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminIdGet(id),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(newsActions.setObj(obj));
          dispatch(setPoiSelectionList(obj.pois ? obj.pois : []));
          dispatch(setRouteSelectionList(obj.routes ? obj.routes : []));
          dispatch(
            setGalleryPhotoList(
              obj.photos
                ? obj.photos?.map((photo) => {
                    return {galleryPhoto: photo, progress: 0, state: 'new'};
                  })
                : []
            )
          );
        }
      )
    );
  };

export const getNewsForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<News>(
        id,
        async () =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminIdGet(id),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'news',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
          dispatch(
            setGalleryPhotoList(
              obj.photos
                ? obj.photos?.map((photo) => {
                    return {galleryPhoto: photo, progress: 0, state: 'new'};
                  })
                : []
            )
          );
        }
      )
    );
  };

export const deleteNews =
  (id: string, onDelete: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminIdDelete(id),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(newsActions.setObj(null));
          dispatch(newsActions.removeFromBaseObjList(id));
          onDelete();
        }
      )
    );
  };

export const updateNews =
  (
    news: CreateOrUpdateNews,
    onUpdate?: () => void,
    closeDialog = true
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<CreateOrUpdateNews>(
        news,
        async (obj) =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminPut({...obj, objectType: 'CreateOrUpdateNews'}),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        (error) => dispatch(showErrorMessage(error)),
        () => {
          dispatch(newsActions.setObj(null));
          closeDialog && dispatch(newsActions.setOpenObjDialog(false));
          onUpdate && onUpdate();
        }
      )
    );
  };

export const deleteMultipleNews =
  (newsIds: string[], onDeleteUser: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(newsActions.setObjLoadingList(true));
      await new NewsApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).newsAdminDelete(newsIds);
      onDeleteUser();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(newsActions.setObjLoadingList(false));
    }
  };

export const newsActions = newsSlice.actions;

export const newsSelector = (state: RootState) => {
  const {adminList, obj, openObjDialog, addNewObj, loadingDetail, loadingList} =
    state.newsList;
  return {adminList, obj, openObjDialog, addNewObj, loadingDetail, loadingList};
};

export const newsNotificationSelector = (state: RootState) =>
  state.newsList.newsNotificationSelection;

export default newsSlice.reducer;
