import React, {useMemo} from 'react';
import {TableNews} from './table/TableNews';
import {useTranslation} from 'react-i18next';
import {ScreenTab, ScreenTabs} from '../ScreenTabs';

export const TabsNews = (): JSX.Element => {
  const {t} = useTranslation();
  const tabs = useMemo(
    (): ScreenTab[] => [
      {key: 'news', tab: <TableNews />, name: t('news.News')},
    ],
    [t]
  );

  return <ScreenTabs tabs={tabs} />;
};
