import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {Room} from '@mui/icons-material';
import {PoiDialogType, usePoiHotspotStyles} from '../PoiHotspot';
import {useTranslation} from 'react-i18next';

export interface AddHotspotTabProps {
  addLocalHotspot: () => void;
  addGlobalHotspot: () => void;
  handleClose: () => void;
  setDialogType: (type: PoiDialogType) => void;
}

export const AddHotspotTab = ({
  addLocalHotspot,
  addGlobalHotspot,
  setDialogType,
}: AddHotspotTabProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = usePoiHotspotStyles();
  return (
    <Box className={classes.dialogMenu}>
      <Box className={classes.menuItem}>
        <IconButton onClick={() => setDialogType('upload')} size="large">
          <Room color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Add point of interest')}
          </Typography>
        </IconButton>
      </Box>
      <Box className={classes.menuItem}>
        <IconButton onClick={addLocalHotspot} size="large">
          <Room color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Choose from local POI')}
          </Typography>
        </IconButton>
      </Box>
      <Box className={classes.menuItem}>
        <IconButton onClick={addGlobalHotspot} size="large">
          <Room color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Choose from global POI')}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};
