import React, {forwardRef, useEffect} from 'react';
import {Box} from '@mui/material';
import {SelectMultiplePrimary} from '../SelectMultiplePrimary';
import {MultipleSelect} from './SelectCategories';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';

import {useTranslation} from 'react-i18next';
import {
  getSimpleUserList,
  selectSimpleUserList,
} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {KeycloakUser} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';

export interface SelectUsersProps extends MultipleSelect {
  row?: boolean;
  boldLabel?: boolean;
  publicNotif?: boolean;
  getChangedUser?: (id: string, name: string) => void;
}

export const SelectUsers = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      onBlur,
      disabled,
      errorType,
      boldLabel,
      selectAll = true,
      selectAny = true,
      publicNotif = false,
      row = false,
    }: SelectUsersProps,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleUserList = useAppSelector(selectSimpleUserList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getSimpleUserList());
    }, [dispatch]);

    return (
      <Box>
        <SelectMultiplePrimary
          boldLabel={boldLabel ? boldLabel : true}
          id={id}
          name={name}
          ref={ref}
          selectedValue={
            t('notification.users') +
            (value
              ? ` (${
                  value.length === 0 ? t('objectInputs.Public') : value.length
                })`
              : '')
          }
          disabled={disabled}
          label={label}
          className={className}
          value={value}
          onBlur={onBlur}
          row={row}
          errorType={errorType}
          onChange={onChange}
          selectAnyText={publicNotif ? t('allObjects.Public') : undefined}
          list={simpleUserList.map((user) => ({
            ...user,
            name: user.firstName,
            nameEn: user.lastName,
          }))}
          itemPrimaryTextFormatFunc={(obj) =>
            (obj.name || '') + ' ' + (obj.nameEn || '')
          }
          itemSecondaryTextFormatFunc={(obj) =>
            (obj as KeycloakUser).email || ''
          }
          selectAll={selectAll}
          selectAny={selectAny}>
          {/*{getFilteredOptionsByName(*/}
          {/*  simpleUserList.map((user) => ({*/}
          {/*    ...user,*/}
          {/*    name: user.firstName,*/}
          {/*    nameEn: user.lastName,*/}
          {/*  })),*/}
          {/*  query*/}
          {/*).map(*/}
          {/*  (user) =>*/}
          {/*    user.id && (*/}
          {/*      <MenuItem key={user.id} value={user.id}>*/}
          {/*        <Switch*/}
          {/*          checked={value && value.indexOf(user.id) > -1}*/}
          {/*          color={'primary'}*/}
          {/*        />*/}
          {/*        <ListItemText primary={user.name + ' ' + user.nameEn} />*/}
          {/*      </MenuItem>*/}
          {/*    )*/}
          {/*)}*/}
        </SelectMultiplePrimary>
      </Box>
    );
  }
);
SelectUsers.displayName = 'SelectUsers';

export default SelectUsers;
