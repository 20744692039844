import i18n from 'i18next';
import {LocalizedParams} from '@skczu/czu-frontend-library';
import {UploadFile} from '../../../hooks/useUploadPhoto';
import {
  DamFile,
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {CategoryIcon} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {Poi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {
  PoiRef,
  Video,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import {CreateVideoResponse} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {Event} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event/api';

export const CustomUtils = {
  createPhotoFromReaderResult: (
    file: File,
    readerResult: string | ArrayBuffer | null
  ): DamFileWithData => {
    let extension =
      file.name
        ?.substring(file.name.lastIndexOf('.') + 1, file.name.length)
        .toLowerCase() || 'jpeg';
    if (extension === 'jpg') {
      extension = 'jpeg';
    }
    let result: string;
    if (extension === 'svg') {
      result = (readerResult as string).replace(
        `data:image/${extension}+xml;base64,`,
        ''
      );
    } else if (extension === 'glb') {
      result = (readerResult as string).replace(
        'data:application/octet-stream;base64,',
        ''
      );
    } else if (extension === 'heic') {
      result = (readerResult as string).replace(
        'data:application/octet-stream;base64,',
        ''
      );
    } else if (extension === 'hevc') {
      result = (readerResult as string).replace(
        'data:application/octet-stream;base64,',
        ''
      );
    } else if (extension === 'mp4') {
      result = (readerResult as string).replace('data:video/mp4;base64,', '');
    } else if (extension === 'mov') {
      result = (readerResult as string).replace(
        'data:video/quicktime;base64,',
        ''
      );
    } else {
      result = (readerResult as string).replace(
        `data:image/${extension};base64,`,
        ''
      );
    }
    return {
      data: result,
      fileName: file.name,
    } as DamFileWithData;
  },
  createPhotoFromObject3dReaderResult: (
    file: File,
    readerResult: string | ArrayBuffer | null
  ): DamFileWithData => {
    const result = (readerResult as string).replace(
      'data:application/octet-stream;base64,',
      ''
    );
    return {
      data: result,
      fileName: file.name,
    } as DamFileWithData;
  },
  convertPoiCategoryIconToPhoto: (icon: CategoryIcon): DamFileWithData => {
    return {
      id: icon.id,
      data: icon.url,
      fileName: icon.name,
    } as DamFileWithData;
  },
  convertPoiToBasePoi: (poi: Poi): PoiRef => {
    return {
      id: poi.id,
      name: poi.name,
      nameEn: poi.nameEn,
      image: poi.image,
      showOnMap: poi.showOnMap,
      category: poi.category?.id,
      coordinates: poi.coordinates,
    };
  },
  convertPoiListToBasePoiList: (poiList: Poi[]): PoiRef[] => {
    return poiList.map((poi) => {
      return CustomUtils.convertPoiToBasePoi(poi);
    });
  },
  convertBasePoiListToPoiList: (basePoiList: PoiRef[]): Poi[] => {
    return basePoiList.map((basePoi) => {
      return {
        id: basePoi.id,
        name: basePoi.name,
        nameEn: basePoi.nameEn,
        image: basePoi.image,
        showOnMap: basePoi.showOnMap,
        category: {
          id: basePoi.category,
        },
        coordinates: basePoi.coordinates,
      };
    });
  },
  convertCreateVideoResponseToDamFile: (video?: CreateVideoResponse): Video => {
    return {
      ...video,
      fileName: video?.name as string,
      data: video?.url as string,
    };
  },
  convertDamFileToCreateVideoResponse: (
    damFile?: DamFile
  ): CreateVideoResponse => {
    return {
      ...damFile,
      name: damFile?.fileName,
      url: damFile?.url,
    };
  },
  getGroupsFromIds: (
    baseGroupList: EventFilterRefObject[],
    groupIds: string[]
  ): Event[] => {
    const groups: Event[] = [];
    for (const groupID of groupIds) {
      const foundGroup = baseGroupList.filter(
        (groupObj) => groupObj.id === groupID
      );
      if (foundGroup && foundGroup.length > 0) {
        groups.push({
          id: foundGroup[0].id,
          name: foundGroup[0].name,
          createdDate: foundGroup[0].createdDate,
          layers: foundGroup[0].layers?.map((layerId) => {
            return {id: layerId};
          }),
        });
      }
    }
    return groups;
  },
  getPhotosFromGalleryPhotos: (galleryPhotoList: UploadFile[]): DamImage[] => {
    const photos: DamImage[] = [];
    for (const photo of galleryPhotoList) {
      const foundGalleryPhoto = galleryPhotoList.filter(
        (galleryImage) =>
          galleryImage.galleryPhoto?.id === photo.galleryPhoto?.id
      );
      if (foundGalleryPhoto && foundGalleryPhoto.length > 0) {
        foundGalleryPhoto[0].galleryPhoto &&
          photos.push(foundGalleryPhoto[0].galleryPhoto);
      }
    }
    return photos;
  },
  hexToRGBA: (
    hex: string
  ): {red: number; green: number; blue: number} | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          red: parseInt(result[1], 16),
          green: parseInt(result[2], 16),
          blue: parseInt(result[3], 16),
        }
      : null;
  },
  isTextEditorEmpty: (text?: string): boolean | undefined => {
    return text ? text.trim() === ('<p></p>' as string).trim() : undefined;
  },
  getContentByLanguage: (czText?: string, enText?: string): string => {
    return (
      i18n.language === 'en'
        ? !enText ||
          CustomUtils.isTextEditorEmpty(enText) ||
          enText.trim() === ('<p>&nbsp;</p>' as string).trim()
          ? czText
          : enText
        : czText
    ) as string;
  },
  getObjByLanguage: <T extends LocalizedParams>(obj: T): T => {
    return {
      ...obj,
      name: CustomUtils.getContentByLanguage(obj?.name, obj?.nameEn),
      body: CustomUtils.getContentByLanguage(obj?.body, obj?.bodyEn),
    };
  },
  getFilteredOptionsByName: <T extends {name?: string; nameEn?: string}>(
    list: T[],
    query: string
  ): T[] => {
    return (
      list &&
      list.filter((option) => {
        return (
          !option ||
          (option &&
            (option.name
              ?.toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1 ||
              option.nameEn
                ?.toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1))
        );
      })
    );
  },
};
