import React from 'react';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import {Close} from '@mui/icons-material';
import {TextLanguage} from './TextLanguage';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../redux/reducers/ContentLanguage';
import {DialogLoading} from '../components/loading-indicator/DialogLoading';
import {SecondaryLightHover} from '../../../App';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2.5),
      top: theme.spacing(2),
      width: 35,
      height: 35,
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  contentLanguage?: boolean;
  onChangeLanguage?: (language: ContentLanguage) => void;
  selectedLanguage?: ContentLanguage;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    contentLanguage,
    onChangeLanguage,
    selectedLanguage,
    ...other
  } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      {contentLanguage && (
        <TextLanguage
          onChangeLanguage={onChangeLanguage}
          selectedLanguage={selectedLanguage}
        />
      )}
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 20,
            top: 16,
            width: 35,
            height: 35,
            color: 'secondary.main',
            backgroundColor: SecondaryLightHover,
          }}
          // className={classes.closeButton}
          onClick={onClose}
          size="large">
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export interface Props {
  children: React.ReactNode;
  title: string;
  open: boolean;
  loading?: boolean;
  onCloseDialog: () => void;
  width?: string | number;
  minWidth?: string | number;
  minHeight?: string;
  contentLanguage?: boolean;
}

export const DialogPrimary = ({
  open,
  children,
  title,
  loading,
  onCloseDialog,
  width,
  minWidth,
  minHeight,
  contentLanguage = false,
}: Props): JSX.Element => {
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const dispatch = useAppDispatch();

  //TODO: zbytocne setovanie  - vymazat?
  // useEffect(() => {
  //   if (!open) {
  //     dispatch(setSelectedLanguage(ContentLanguage.CZ));
  //   }
  // }, [open]);

  return (
    <Box>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 25,
            minWidth: minWidth ? minWidth : 450,
            minHeight: minHeight ? minHeight : 300,
            zIndex: 10,
            width: width,
          },
        }}
        onClose={onCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogLoading showLoading={loading} />
        <DialogTitle
          id="customized-dialog-title"
          onClose={onCloseDialog}
          contentLanguage={contentLanguage}
          onChangeLanguage={(language) =>
            contentLanguage && dispatch(setSelectedLanguage(language))
          }
          selectedLanguage={selectedLanguage}>
          {title}
        </DialogTitle>
        <Divider variant={'middle'} />
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Box>
  );
};
