import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import mapReducer from './redux/reducers/MapSlice';
import drawerReducer from './redux/reducers/DrawerSlice';
import loadingReducer from './redux/reducers/LoadingSlice';
import poiReducer from './redux/reducers/data-reducers/PoiSlice';
import routeReducer from './redux/reducers/data-reducers/RouteSlice';
import poiSelectionReducer from './redux/reducers/PoiListSelectionSlice';
import groupsReducer from './redux/reducers/data-reducers/GroupsSlice';
import object3dListReducer from './redux/reducers/data-reducers/Object3dSlice';
import newsReducer from './redux/reducers/data-reducers/NewsSlice';
import poiCategoriesReducer from './redux/reducers/data-reducers/PoiCategoriesSlice';
import routeSelectionReducer from './redux/reducers/RouteListSelectionSlice';
import layersReducer from './redux/reducers/data-reducers/LayersSlice';
import poi360Reducer from './redux/reducers/data-reducers/Poi360Slice';
import poi360SelectionReducer from './redux/reducers/Poi360SelectionSlice';
import contentLanguageReducer from './redux/reducers/ContentLanguage';
import galleryPhotoReducer from './redux/reducers/data-reducers/GalleryPhotoSlice';
import categoryIconsReducer from './redux/reducers/data-reducers/CategoryIconSlice';
import userReducer from './redux/reducers/data-reducers/UserSlice';
import simpleObjectListsReducer from './redux/reducers/data-reducers/SimpleObjectListsSlice';
import appsReducer from './redux/reducers/data-reducers/AppsSlice';
import errorReducer from './redux/reducers/ErrorSlice';
import foodMenuReducer from './redux/reducers/data-reducers/FoodMenuSlice';
import imagePreviewReducer from './redux/reducers/ImagePreview';
import rolesReducer from './redux/reducers/data-reducers/RolesSlice';
import wishlistReducer from './redux/reducers/data-reducers/WishlistSlice';
import notificationReducer from './redux/reducers/data-reducers/NotificationSlice';

export const store = configureStore({
  reducer: {
    map: mapReducer,
    drawer: drawerReducer,
    loading: loadingReducer,
    poiList: poiReducer,
    routeList: routeReducer,
    poiCategories: poiCategoriesReducer,
    newsList: newsReducer,
    groupsList: groupsReducer,
    poiSelection: poiSelectionReducer,
    routeSelection: routeSelectionReducer,
    layers: layersReducer,
    poi360: poi360Reducer,
    poi360Selection: poi360SelectionReducer,
    object3dList: object3dListReducer,
    galleryPhoto: galleryPhotoReducer,
    contentLanguage: contentLanguageReducer,
    categoryIcons: categoryIconsReducer,
    users: userReducer,
    simpleObjectLists: simpleObjectListsReducer,
    error: errorReducer,
    imagePreview: imagePreviewReducer,
    apps: appsReducer,
    foodMenu: foodMenuReducer,
    roles: rolesReducer,
    wishlist: wishlistReducer,
    notification: notificationReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
