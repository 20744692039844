import React from 'react';
import {IconButton} from '@mui/material';
import {CellProps, Row} from 'react-table';
import {Delete, Edit, RemoveRedEyeOutlined} from '@mui/icons-material';
import moment from 'moment/moment';

export const isNotificationSent = (date?: Date): boolean => {
  if (moment(date).isValid()) {
    return moment(date).isBefore(moment());
  } else {
    return false;
  }
};

export interface NotificationActionCellProps<
  T extends Record<string, unknown>
> {
  cell: CellProps<T>;
  onEditClick?: (id: string) => void;
  onPreviewClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}
export const NotificationActionCell = <T extends Record<string, unknown>>({
  cell,
  onEditClick,
  onDeleteClick,
  onPreviewClick,
}: NotificationActionCellProps<T>): JSX.Element => {
  const isSent = (row: Row<T>): boolean | undefined => {
    const val = row.cells?.find(
      (tableCell) => tableCell.column.id === 'send'
    )?.value;
    return val === 'Sent' || val === 'Odesláno';
  };

  const sent = isSent(cell.row);

  return (
    <>
      {(!sent && onPreviewClick) ||
        (onPreviewClick && (
          <IconButton
            edge="end"
            aria-label="edit"
            size="small"
            onClick={() => onPreviewClick(cell.value)}>
            <RemoveRedEyeOutlined fontSize="small" />
          </IconButton>
        ))}
      {(sent && onEditClick) ||
        (onEditClick && (
          <IconButton
            edge="end"
            aria-label="edit"
            size="small"
            onClick={() => onEditClick(cell.value)}>
            <Edit fontSize="small" />
          </IconButton>
        ))}
      {!sent && onDeleteClick && (
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={() => onDeleteClick && onDeleteClick(cell.value)}>
          <Delete fontSize="small" />
        </IconButton>
      )}
    </>
  );
};
