import React from 'react';
import {Box, Typography} from '@mui/material';
import {TFunction, useTranslation} from 'react-i18next';
import {DialogStyles} from '../../../StylesDialog';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {SendNotificationControls} from './SendNotificationControls';
import {ButtonAdd} from '../../../../components/buttons/ButtonAdd';
import Divider from '@mui/material/Divider';
import {NewsForm} from '../../DialogNews';
import {NewsNotificationState} from '../../../../../../redux/reducers/data-reducers/NewsSlice';
import moment from 'moment';
import {DefaultDateTimeFormatFE} from '../../../../utils/Utils';

const getNotificationActionFromState = (
  newsState?: NewsNotificationState,
  t?: TFunction,
  date?: string
): NewsNotificationButtonProps | undefined => {
  if (!t) {
    return undefined;
  }
  switch (newsState) {
    case 'withoutNotification':
      return {
        description: t(
          'news.Notification has not been sent if you wish to send notification to this news press this button'
        ),
        newNotification: true,
        buttonText: t('news.Send notification'),
      };
    case 'scheduledNotification':
      return {
        description: t('news.Sending of notification is scheduled to', {
          date: date,
        }),
        newNotification: false,
        buttonText: t('news.Edit notification'),
      };
    case 'sentNotification':
      return {
        description: t('news.Notification sent at', {date: date}),
        newNotification: true,
        buttonText: t('news.Send again'),
      };
    default: {
      return undefined;
    }
  }
};

export interface NewsNotificationButtonProps {
  description?: string;
  buttonText?: string;
  newNotification?: boolean;
  onNotificationActionButtonClick?: (newNotification: boolean) => void;
}

export const NewsNotificationButton = ({
  description,
  buttonText,
  newNotification,
  onNotificationActionButtonClick,
}: NewsNotificationButtonProps): JSX.Element => {
  return (
    <Box
      sx={{
        ...DialogStyles.rowContainer,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Typography
        sx={{
          mx: 1,
          // width: '78%',
          fontStyle: 'italic',
          color: 'gray',
          fontSize: 13.8,
          mt: -1,
        }}>
        {description}
      </Typography>
      <ButtonAdd
        color={'#5d00c9'}
        withoutIcon
        // icon={<NotificationsNone />}
        name={buttonText || ''}
        sx={{mr: 1, mt: -1}}
        onClick={() =>
          onNotificationActionButtonClick &&
          onNotificationActionButtonClick(!!newNotification)
        }
      />
    </Box>
  );
};

export interface NewsNotificationControlsProps
  extends Partial<UseFormReturn<NewsForm>> {
  newsNotificationState?: NewsNotificationState;
  onNotificationActionButtonClick?: (newNotification: boolean) => void;
  notificationSendDate?: string;
  addNew?: boolean;
}

export const NewsNotificationControls = ({
  newsNotificationState,
  control,
  watch,
  addNew,
  setValue,
  onNotificationActionButtonClick,
  notificationSendDate,
}: NewsNotificationControlsProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <>
      {newsNotificationState === 'new' ? (
        <SendNotificationControls
          control={control}
          watch={watch}
          addNew={addNew}
          setValue={setValue}
        />
      ) : (
        <>
          <NewsNotificationButton
            {...getNotificationActionFromState(
              newsNotificationState,
              t,
              moment(notificationSendDate).format(DefaultDateTimeFormatFE)
            )}
            onNotificationActionButtonClick={onNotificationActionButtonClick}
          />
          <Divider sx={{borderBottomWidth: 3, mt: 1}} />
        </>
      )}
    </>
  );
};
