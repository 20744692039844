import {
  Cartesian3,
  Cartographic,
  Cesium3DTileset,
  Matrix4,
  Viewer as CesiumViewer,
} from 'cesium';
import {useCallback, useEffect, useRef} from 'react';
import {setMapLoading} from '../../../../redux/reducers/LoadingSlice';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {selectTileset3dUrlList} from '../../../../redux/reducers/data-reducers/Object3dSlice';
import {CampusCoords} from './useMap';

export const useMapType = (
  viewer: CesiumViewer | null,
  map3D: boolean,
  flyToPoint: (
    position: number[],
    show3dObjects: boolean,
    height: number
  ) => void
): void => {
  const dispatch = useAppDispatch();
  const tilesetRef = useRef<Cesium3DTileset[] | null>();
  const tileset3dUrlList = useAppSelector(selectTileset3dUrlList);

  const getTileset = useCallback(async (): Promise<Cesium3DTileset[]> => {
    return Promise.all(
      tileset3dUrlList.map(async (baseObject3d) => {
        const cesiumTileset = viewer?.scene.primitives.add(
          new Cesium3DTileset({
            url: baseObject3d,
            maximumScreenSpaceError: 4,
            maximumMemoryUsage: 256,
            dynamicScreenSpaceError: true,
            skipLevelOfDetail: true,
            preferLeaves: true,
          })
        );
        await cesiumTileset.readyPromise;
        const heightOffset = -325.0;
        const boundingSphere = cesiumTileset.boundingSphere;
        const cartographic = Cartographic.fromCartesian(boundingSphere.center);
        const surface = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          0.0
        );
        const offset = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          heightOffset
        );
        const translation = Cartesian3.subtract(
          offset,
          surface,
          new Cartesian3()
        );
        cesiumTileset.modelMatrix = Matrix4.fromTranslation(translation);
        return cesiumTileset;
      })
    );
  }, [tileset3dUrlList, viewer?.scene.primitives]);

  useEffect(() => {
    if (tileset3dUrlList && viewer && map3D) {
      dispatch(setMapLoading(true));
      flyToPoint(
        [CampusCoords.longitude - 0.003, CampusCoords.latitude - 0.004],
        map3D,
        1000
      );
      getTileset()
        .then((newTileset) => {
          tilesetRef.current = newTileset;
        })
        .finally(() => dispatch(setMapLoading(false)));
    } else if (viewer && !map3D) {
      if (tilesetRef.current) {
        tilesetRef.current.forEach((tile) => {
          viewer.scene.primitives.remove(tile);
        });
      }
      viewer.scene.requestRender();
      flyToPoint(
        [CampusCoords.longitude - 0.003, CampusCoords.latitude],
        map3D,
        2000
      );
    }
  }, [tileset3dUrlList, dispatch, flyToPoint, getTileset, map3D, viewer]);
};
