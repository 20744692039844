import {Tooltip as MuiTooltip} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {CSSProperties} from 'react';
import {CellProps} from 'react-table';

const useStyles = makeStyles({
  truncated: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const TooltipCellRenderer = ({
  cell: {value},
  column: {align = 'left'},
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CellProps<any>): JSX.Element => <TooltipCell text={value} align={align} />;

interface TooltipProps {
  text: string;
  tooltip?: string;
  align: string;
}

export const TooltipCell = ({
  text,
  tooltip = text,
  align,
}: TooltipProps): JSX.Element => {
  const classes = useStyles({});
  return (
    <MuiTooltip
      title={tooltip}
      className={classes.truncated}
      style={{textAlign: align} as CSSProperties}>
      <span>{text}</span>
    </MuiTooltip>
  );
};
