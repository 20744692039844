import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {InputCustomEvent} from '../../../../../hooks/useForm';
import {ButtonUploadImage} from '../../../components/buttons/ButtonUploadImage';
import {useAppDispatch} from '../../../../../hooks/hooks';
import {useTranslation} from 'react-i18next';
import {deleteGalleryPhoto} from '../../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {LinearProgressWithLabel} from '../../../components/loading-indicator/LinearProgress';
import {UploadFile} from '../../../../../hooks/useUploadPhoto';
import {Delete} from '@mui/icons-material';
import {
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {ObjMapper} from '@skczu/czu-frontend-library';

export interface GalleryPhotoContainerProps {
  galleryPhotoList?: UploadFile[];
  onRemove?: (galleryPhoto: DamImage) => void;
  onUploadFiles: (galleryPhotos: DamFileWithData[]) => void;
  border?: boolean;
}
export const GalleryPhotoContainer = ({
  galleryPhotoList,
  onRemove,
  onUploadFiles,
  border = true,
}: GalleryPhotoContainerProps): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const useStyles = makeStyles((theme) => ({
    container: {
      width: '50%',
      marginRight: 20,
    },
    listContainer: {
      marginTop: 20,
      display: 'flex',
      minHeight: 115,
      border: border ? `2px solid ${theme.palette.secondary.light}` : undefined,
      width: '100%',
      borderRadius: 10,
      boxShadow: theme.shadows[2],
      flexDirection: 'column',
    },
    routeContainer: {
      marginTop: 20,
      justifyContent: 'space-between',
      width: '70%',
      wordWrap: 'break-word',
      display: 'inline-block',
    },
    fileRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  }));
  const classes = useStyles();

  const handleRemovePhoto = (galleryPhoto: DamImage) => {
    galleryPhoto.id && dispatch(deleteGalleryPhoto(galleryPhoto.id));
    onRemove && onRemove(galleryPhoto);
  };

  const handleUploadFile = (event: InputCustomEvent) => {
    if (event) {
      const files = event as DamFileWithData[];
      if (files) {
        const photos: DamFileWithData[] = [];
        files.forEach((file) => {
          photos.push(file);
        });
        onUploadFiles && onUploadFiles(photos);
      }
    }
  };

  return (
    <Box m={1} className={classes.container}>
      <ButtonUploadImage
        multiple={true}
        name={'photos'}
        boldLabel
        label={t(`objectGallery.Upload gallery image`)}
        onChange={handleUploadFile}
      />
      <Box className={classes.listContainer}>
        <Box m={1}>
          {galleryPhotoList?.map(
            (photo, index) =>
              photo.galleryPhoto && (
                <Box
                  key={
                    ObjMapper.geNameFromDam(photo.galleryPhoto) + '-' + index
                  }
                  className={classes.fileRow}>
                  <Box m={1} className={classes.routeContainer}>
                    <Typography variant={'subtitle2'}>
                      {ObjMapper.geNameFromDam(photo.galleryPhoto)}
                    </Typography>
                  </Box>
                  {(photo.state === 'upload' || !photo.galleryPhoto?.id) && (
                    <LinearProgressWithLabel value={photo.progress} />
                  )}
                  {photo.state !== 'upload' && photo.galleryPhoto?.id && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        photo.galleryPhoto &&
                        handleRemovePhoto(photo.galleryPhoto)
                      }>
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )
          )}
        </Box>
      </Box>
    </Box>
  );
};
