import React, {ChangeEvent} from 'react';
import {Box, IconButton, Popover, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Add} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {readFileContent} from '../../utils/Utils';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 5,
    marginLeft: 5,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(0.6),
    borderRadius: 10,
  },
}));

export interface Props {
  onChangeValue?: (event: DamFileWithData) => void;
}

export const ButtonCategoryIconUpdate = ({
  onChangeValue,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      readFileContent(file).then((result) => {
        onChangeValue && onChangeValue(result);
      });
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={classes.button}>
      <label htmlFor={'upload-category-icon'}>
        <input
          accept="image/*"
          id={'upload-category-icon'}
          type="file"
          hidden
          onChange={handleUploadCapture}
        />
        <IconButton
          edge="end"
          size="small"
          component="span"
          color={'inherit'}
          aria-owns={open ? 'add-category-icon' : undefined}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          aria-haspopup="true">
          <Add fontSize="large" />
        </IconButton>
        <Popover
          id="add-category-icon"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Typography>
            {t('category.Add new icon to the poi category icon list')}
          </Typography>
        </Popover>
      </label>
    </Box>
  );
};
