import React from 'react';
import {ContentLanguage} from '../../../../redux/reducers/ContentLanguage';
import {Controller, Path, PathValue} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {Control, UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {TextEditor} from './TextEditor';
import {InputLabelProps} from '../input-label/InputLabel';

export interface TextEditorObjectBodyProps<
  T extends FieldValues & {name?: string; nameEn?: string}
> extends InputLabelProps {
  controlAttr?: string;
  hideLabel?: boolean;
  control?: Control<T>;
  selectedLanguage?: ContentLanguage;
}
export const TextEditorObjectBody = <
  T extends FieldValues & {name?: string; nameEn?: string}
>({
  controlAttr,
  control,
  hideLabel,
  required,
  selectedLanguage,
  boldLabel,
}: TextEditorObjectBodyProps<T>): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Box>
      {selectedLanguage === ContentLanguage.CZ && (
        <Controller
          name={(controlAttr ?? 'body') as Path<T>}
          control={control}
          rules={{required: true}}
          defaultValue={'' as UnpackNestedValue<PathValue<T, Path<T>>>}
          render={({field, fieldState: {error}}) => (
            <TextEditor
              {...field}
              required={required}
              boldLabel={boldLabel}
              label={hideLabel ? undefined : t(`allObjects.Body`)}
              errorType={error?.type}
            />
          )}
        />
      )}
      {selectedLanguage === ContentLanguage.EN && (
        <Controller
          name={(controlAttr ? controlAttr + 'En' : 'bodyEn') as Path<T>}
          control={control}
          defaultValue={'' as UnpackNestedValue<PathValue<T, Path<T>>>}
          render={({field, fieldState: {error}}) => (
            <TextEditor
              {...field}
              boldLabel={boldLabel}
              label={hideLabel ? undefined : t(`allObjects.BodyEn`)}
              errorType={error?.type}
            />
          )}
        />
      )}
    </Box>
  );
};
