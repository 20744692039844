import React, {forwardRef, useEffect} from 'react';
import {Box} from '@mui/material';
import {SelectMultiplePrimary} from '../SelectMultiplePrimary';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {MultipleSelect} from './SelectCategories';
import {useTranslation} from 'react-i18next';
import {
  getSimpleRouteList,
  selectSimpleRouteList,
} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';

export const SelectRoutes = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      onBlur,
      disabled,
      errorType,
      selectAll = true,
      selectAny = true,
    }: MultipleSelect,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleRouteList = useAppSelector(selectSimpleRouteList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!simpleRouteList || simpleRouteList.length === 0) {
        dispatch(getSimpleRouteList());
      }
    }, [dispatch, simpleRouteList]);

    return (
      <Box>
        <SelectMultiplePrimary
          id={id}
          name={name}
          ref={ref}
          selectedValue={
            t('objectInputs.Routes') +
            (value
              ? ` (${value.length === 0 ? t('allObjects.None') : value.length})`
              : '')
          }
          disabled={disabled}
          label={label}
          className={className}
          value={value}
          errorType={errorType}
          onBlur={onBlur}
          onChange={onChange}
          list={simpleRouteList}
          selectAll={selectAll}
          selectAny={selectAny}></SelectMultiplePrimary>
      </Box>
    );
  }
);
SelectRoutes.displayName = 'SelectRoutes';

export default SelectRoutes;
