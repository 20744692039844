import React from 'react';
import {Box} from '@mui/material';
import {StylesDialog} from '../../StylesDialog';
import {useTranslation} from 'react-i18next';
import {ContentLanguage} from '../../../../../redux/reducers/ContentLanguage';
import {SelectLayers} from '../../../components/selects/select-multiple/SelectLayers';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {RouteForm} from '../DialogRoute';
import {Controller} from 'react-hook-form';
import {TextInputObjectName} from '../../../components/text-inputs/TextInputObjectName';
import {TextEditorObjectBody} from '../../../components/text-edior/TextEditorObjectBody';

export interface Props extends Partial<UseFormReturn<RouteForm>> {
  selectedLanguage?: ContentLanguage;
}

export const TabDetailRoute = ({
  control,
  selectedLanguage,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();

  return (
    <Box>
      <Box>
        <TextInputObjectName
          selectedLanguage={selectedLanguage}
          control={control}
        />
        <Box marginTop={2}>
          <Controller
            name="layers"
            control={control}
            defaultValue={[]}
            render={({field, fieldState: {error}}) => (
              <SelectLayers
                {...field}
                label={t(`poi.Layers`)}
                errorType={error?.type}
              />
            )}
          />
        </Box>
      </Box>
      <Box className={dialogClasses.textEditor}>
        <TextEditorObjectBody
          control={control}
          boldLabel
          selectedLanguage={selectedLanguage}
        />
      </Box>
    </Box>
  );
};
