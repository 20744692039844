import React, {FormEventHandler} from 'react';
import {Box} from '@mui/material';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import {Controller} from 'react-hook-form';
import TextInputPrimary from '../../components/text-inputs/TextInputPrimary';
import SwitchPrimary from '../../components/switches/SwitchPrimary';
import Divider from '@mui/material/Divider';
import {TextEditorObjectBody} from '../../components/text-edior/TextEditorObjectBody';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {useAppSelector} from '../../../../hooks/hooks';
import {contentLanguageSelector} from '../../../../redux/reducers/ContentLanguage';
import {useTranslation} from 'react-i18next';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {NotificationFormType} from './DialogNotification';
import DateTimeInput from '../../components/date/DateTimeInput';
import SelectUsers from '../../components/selects/select-multiple/SelectUsers';
import moment from 'moment';
import {DefaultDateTimeFormatBE} from '../../utils/Utils';

export interface NotificationFormProps
  extends Partial<UseFormReturn<NotificationFormType>> {
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
  addNew: boolean;
  fromParentObj?: boolean;
}

export const NotificationForm = ({
  onSubmit,
  control,
  watch,
  addNew,
  fromParentObj,
  setValue,
}: NotificationFormProps): JSX.Element => {
  const {t} = useTranslation();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="row">
        <Box marginLeft={4}>
          <Box marginTop={2}>
            <TextInputObjectName
              selectedLanguage={selectedLanguage}
              control={control}
              label={t(`notification.Title`)}
              labelEn={t(`notification.Title`)}
              boldLabel
              required
            />
            <Box display={'flex'} marginTop={3} marginBottom={1}>
              <Box width={'50%'}>
                <Controller
                  name="uri"
                  control={control}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputPrimary
                      {...field}
                      style={{width: '85%'}}
                      disabled={fromParentObj}
                      boldLabel
                      label={t(
                        `notification.Dynamic link of object (optional)`
                      )}
                      errorType={error?.type}
                    />
                  )}
                />
                <Box marginTop={2}>
                  <Controller
                    name="users"
                    control={control}
                    defaultValue={[]}
                    rules={{required: watch && watch('confirmationRequired')}}
                    render={({field, fieldState: {error}}) => (
                      <SelectUsers
                        {...field}
                        selectAll
                        publicNotif
                        label={t(`notification.users`)}
                        errorType={error?.type}
                      />
                    )}
                  />
                </Box>
                <Box marginTop={3}>
                  <Controller
                    name="visibleInApp"
                    control={control}
                    defaultValue={true}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        label={t(
                          `notification.Save for user in notification app`
                        )}
                        boldLabel
                        value={
                          watch && watch('confirmationRequired')
                            ? watch('confirmationRequired')
                            : field.value
                        }
                        disabled={watch && watch('confirmationRequired')}
                        row
                        labelRowWidth={'70%'}
                        inputRowWidth={'30%'}
                        errorType={error?.type}
                      />
                    )}
                  />
                </Box>
                <Box marginTop={1}>
                  <Controller
                    name="confirmationRequired"
                    control={control}
                    defaultValue={false}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        row
                        boldLabel
                        labelRowWidth={'70%'}
                        inputRowWidth={'30%'}
                        label={t(`notification.Request read confirmation`)}
                        errorType={error?.type}
                        onChange={(event) => {
                          if (event.target.value) {
                            setValue && setValue('visibleInApp', true);
                            setValue && setValue('showMessage', true);
                          }
                          field.onChange(event);
                        }}
                      />
                    )}
                  />
                </Box>
                <Box marginTop={1}>
                  <Controller
                    name="showMessage"
                    control={control}
                    defaultValue={false}
                    rules={{required: watch && watch('confirmationRequired')}}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        row
                        boldLabel
                        value={
                          watch && watch('confirmationRequired')
                            ? watch('confirmationRequired')
                            : field.value
                        }
                        disabled={watch && watch('confirmationRequired')}
                        labelRowWidth={'70%'}
                        inputRowWidth={'30%'}
                        label={t(`notification.Show message`)}
                        errorType={error?.type}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                width={'47%'}
                marginLeft={'3%'}
                flexDirection={'column'}>
                <Box marginTop={3}>
                  <Controller
                    name="sendNow"
                    control={control}
                    defaultValue={false}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        row
                        boldLabel
                        labelRowWidth={'30%'}
                        inputRowWidth={'65%'}
                        label={t(`notification.Send now`)}
                        errorType={error?.type}
                      />
                    )}
                  />
                  <Box marginTop={1}>
                    <Controller
                      name="sendDate"
                      control={control}
                      defaultValue={''}
                      render={({field, fieldState: {error}}) => (
                        <DateTimeInput
                          {...field}
                          boldLabel
                          minutesStep={5}
                          disabled={watch && watch('sendNow')}
                          value={watch && watch('sendNow') ? '' : field.value}
                          label={t(`notification.Scheduled sending date`)}
                          min={moment().format(DefaultDateTimeFormatBE)}
                          errorType={error?.type}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {watch &&
            // (watch('showMessage') || watch('confirmationRequired')) ? (
            watch('showMessage') ? (
              <>
                <Divider light={true} />
                <Box marginTop={3}>
                  <TextEditorObjectBody
                    boldLabel
                    required
                    control={control}
                    selectedLanguage={selectedLanguage}
                  />
                </Box>
              </>
            ) : null}
          </Box>
          <Box marginTop={10}>
            <ButtonAdd
              name={
                addNew
                  ? t('notification.Add notification')
                  : t('notification.Save changes')
              }
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};
