import React, {useEffect, useRef} from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@mui/material';
import ListItem from '@mui/material/ListItem';
import {
  Delete,
  Edit,
  QrCode2,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface ListItemRow {
  id: string;
  itemRowName: string;
  activeItem: boolean;
  visible?: boolean;
  selectedItem?: boolean;
  icon?: React.ReactNode;
}

export interface Props {
  listItems: ListItemRow[];
  handleEditClick?: (id: string) => void;
  handleDeleteClick?: (id: string) => void;
  handleItemClick?: (id: string) => void;
  handleQrCodeClick?: (id: string, objName: string) => void;
  handleVisibleClick?: (id: string, visible: boolean) => void;
  loadingList?: boolean;
  hasMore: boolean;
  handleLoadMore?: () => void;
  scrollTo?: string;
}

export const CustomListItems = ({
  listItems,
  handleEditClick,
  handleDeleteClick,
  handleItemClick,
  handleQrCodeClick,
  loadingList,
  handleLoadMore,
  handleVisibleClick,
  hasMore,
  scrollTo,
}: Props): JSX.Element => {
  const itemsRef = useRef<Record<string, HTMLDivElement | null>>({});

  const useStyles = makeStyles((theme) => ({
    loader: {
      color: theme.palette.grey[500],
    },
    list: {
      maxHeight: '70vh',
      minHeight: '15vh',
      overflow: 'auto',
      marginBottom: 15,
    },
    listItem: {
      backgroundColor: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    listItemSelected: {
      backgroundColor: theme.palette.primary.light,
    },
    listItemActive: {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (
      scrollTo &&
      itemsRef &&
      itemsRef.current &&
      itemsRef.current[scrollTo]
    ) {
      const itemRef = itemsRef.current[scrollTo];
      itemRef?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [scrollTo]);

  return (
    <Box>
      <InfiniteScroll
        className={classes.list}
        dataLength={listItems.length}
        next={() => handleLoadMore && handleLoadMore()}
        hasMore={hasMore}
        loader={
          loadingList !== undefined &&
          !loadingList && (
            <Box marginTop={1} textAlign={'center'}>
              <CircularProgress className={classes.loader} />
            </Box>
          )
        }
        height={'100%'}
        endMessage={
          <p style={{textAlign: 'center'}}>{/*<b>You have seen it all</b>*/}</p>
        }>
        {listItems.map((listItem) => (
          <div
            key={listItem.id}
            ref={(el) => listItem.id && (itemsRef.current[listItem.id] = el)}>
            <ListItem
              className={
                listItem.activeItem
                  ? classes.listItemActive
                  : listItem.selectedItem
                  ? classes.listItemSelected
                  : classes.listItem
              }
              key={listItem.id}
              onClick={() => handleItemClick && handleItemClick(listItem.id)}
              ContainerComponent="div">
              {listItem.icon && <ListItemIcon>{listItem.icon}</ListItemIcon>}
              <ListItemText
                primary={listItem.itemRowName}
                style={{
                  maxWidth: listItem.icon
                    ? handleVisibleClick
                      ? '55%'
                      : '65%'
                    : '80%',
                }}
              />
              <ListItemSecondaryAction>
                {handleVisibleClick && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() =>
                      handleVisibleClick(listItem.id, !listItem.visible)
                    }>
                    {listItem.visible ? (
                      <VisibilityOutlined fontSize="small" />
                    ) : (
                      <VisibilityOffOutlined fontSize="small" />
                    )}
                  </IconButton>
                )}
                {handleQrCodeClick && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() =>
                      handleQrCodeClick(listItem.id, listItem.itemRowName)
                    }>
                    <QrCode2 fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  aria-label="edit"
                  size="small"
                  onClick={() =>
                    handleEditClick && handleEditClick(listItem.id)
                  }>
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  onClick={() =>
                    handleDeleteClick && handleDeleteClick(listItem.id)
                  }>
                  <Delete fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
        ))}
        {loadingList && (
          <Box marginTop={1} textAlign={'center'}>
            <CircularProgress className={classes.loader} />
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};
