import React from 'react';
import {IconButton} from '@mui/material';
import {CellProps} from 'react-table';
import {Delete, Edit} from '@mui/icons-material';

export interface NewsActionCellProps<T extends Record<string, unknown>> {
  cell: CellProps<T>;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}
export const NewsActionCell = <T extends Record<string, unknown>>({
  cell,
  onEditClick,
  onDeleteClick,
}: NewsActionCellProps<T>): JSX.Element => {
  return (
    <>
      {onEditClick && (
        <IconButton
          edge="end"
          aria-label="edit"
          size="small"
          onClick={() => onEditClick(cell.value)}>
          <Edit fontSize="small" />
        </IconButton>
      )}
      {onDeleteClick && (
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={() => onDeleteClick && onDeleteClick(cell.value)}>
          <Delete fontSize="small" />
        </IconButton>
      )}
    </>
  );
};
