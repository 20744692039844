import * as React from 'react';
import {Route, Redirect, RouteComponentProps} from 'react-router-dom';
import type {RouteProps} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak-fork/web';
import {useEffect, useState} from 'react';
import {MapLoadingIndicator} from '@skczu/czu-react-components';

interface PrivateRouteParams extends RouteProps {
  component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | React.ComponentType<RouteComponentProps<any>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteParams): JSX.Element => {
  const {keycloak} = useKeycloak();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keycloak.loadUserInfo().then((value: any) => {
      if (value.roles.some((role: string) => role === 'admin')) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
        keycloak.logout();
      }
    });
  }, [keycloak]);

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          authorized ? (
            <Component {...props} />
          ) : (
            <MapLoadingIndicator showLoading={authorized} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};
