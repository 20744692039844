import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../store';

interface ErrorState {
  showError: boolean;
  errorMessage: string;
}

const initialState: ErrorState = {
  showError: false,
  errorMessage: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setShowErrorMessage: (state, {payload}: PayloadAction<string>) => {
      state.errorMessage = payload;
      state.showError = true;
    },
    setShowError: (state, {payload}: PayloadAction<boolean>) => {
      state.showError = payload;
    },
    closeError: (state) => {
      state.showError = false;
      state.errorMessage = '';
    },
  },
});

export const showErrorMessage =
  (massage?: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setShowErrorMessage(
        massage ? massage : 'Objevila se chyba. Prosím, zkuste to později.'
      )
    );
    setTimeout(() => {
      dispatch(closeError());
    }, 5000);
  };

export const {setShowErrorMessage, setShowError, closeError} =
  errorSlice.actions;

export const errorSelector = (state: RootState): typeof state.error =>
  state.error;

export default errorSlice.reducer;
