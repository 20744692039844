import {CellProps, Column, FilterProps} from 'react-table';
import React from 'react';
import {TFunction} from 'react-i18next';
import {TextFilter} from '../../../../shared/table-fiter-inputs/TextFilter';
import {AdminUserObj, UsersData} from './TableUsers';
import {UsersActionCell} from './UsersActionCell';
import {SelectColumnFilter} from '../../../../shared/table-fiter-inputs/SelectColumnFilter';
import {DateFilter} from '../../../../shared/table-fiter-inputs/DateFilter';
import {AdminNewsObj, TableDateCell} from '../../news/table/TableNews';
import {SelectRolesFilter} from '../../../../shared/table-filter-selects/SelectRolesFilter';

export interface TableUsersColumnsProps {
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  t: TFunction;
}

export const getTableUsersColumns = ({
  onEditClick,
  onDeleteClick,
  t,
}: TableUsersColumnsProps): Column<UsersData>[] => {
  return [
    {
      id: 'users-table',
      Header: '',
      columns: [
        {
          Header: t(`objectInputs.First name`) + '',
          accessor: 'firstName',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminUserObj>) =>
            `${value} Names`,
        },
        {
          Header: t(`objectInputs.Last name`) + '',
          accessor: 'lastName',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminUserObj>) =>
            `${value} Last names`,
        },
        {
          Header: t(`objectInputs.Email`) + '',
          accessor: 'email',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminUserObj>) =>
            `${value} email`,
        },
        {
          Header: t(`objectInputs.Status`) + '',
          accessor: 'enabled',
          filter: 'tValue',
          // eslint-disable-next-line react/display-name
          Filter: (props: FilterProps<AdminUserObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: t('user.All')},
                {value: 'user.Active', name: t('user.Active')},
                {value: 'user.Inactive', name: t('user.Inactive')},
              ]}
            />
          ),
        },
        {
          Header: t(`objectInputs.Roles`) + '',
          accessor: 'roleKey',
          filter: 'some',
          Filter: SelectRolesFilter,
          disableSortBy: true,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Unique Names`,
        },
        {
          Header: t(`news.Last login`) + '',
          accessor: 'lastLogin',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          disableSortBy: true,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`objectInputs.Sign in type`) + '',
          accessor: 'signInType',
          filter: 'tValue',
          // eslint-disable-next-line react/display-name
          Filter: (props: FilterProps<AdminUserObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {value: 'ČZU', name: t('ČZU')},
                {value: 'user.External', name: t('user.External')},
              ]}
            />
          ),
        },
        // {
        //   Header: t(`objectInputs.Groups`) + '',
        //   accessor: 'groups',
        //   disableSortBy: true,
        //   disableFilters: true,
        // },
        {
          Header: t(`objectInputs.Action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          // eslint-disable-next-line react/display-name
          Cell: (cell: CellProps<AdminUserObj>) => (
            <UsersActionCell
              cell={cell}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ),
        },
      ],
    },
  ];
};
