import React, {useCallback} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak-fork/web';
import i18n from 'i18next';

export const SignIn = (): JSX.Element | null => {
  const location = useLocation<{[key: string]: unknown}>();
  const currentLocationState = location.state || {
    from: {pathname: '/map'},
  };

  const {keycloak} = useKeycloak();

  const login = useCallback(() => {
    keycloak?.login({locale: i18n.language});
  }, [keycloak]);

  if (keycloak?.authenticated)
    return <Redirect to={currentLocationState?.from as string} />;

  login();
  return null;
};
