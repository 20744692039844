import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  GenericState,
  getObj,
} from '@skczu/czu-frontend-library';
import {
  AdminUserResponse,
  Configuration,
  Order,
  Status,
  UserApi,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {getToken} from '../../../keycloak';
import {BaseKeycloakUser} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated/api';
import {
  KeycloakUser,
  UserApi as KeycloakUserApi,
} from '@skczu/czu-frontend-library/build/apis/keycloak-user-service-api/generated';
import {getSimpleUserList} from './SimpleObjectListsSlice';

export interface BaseList {
  totalCount?: number;
  list: KeycloakUser[];
}

export interface BaseUserListRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  offset?: number;
  limit?: number;
  active?: boolean;
  orderBy?: string;
  betweenLoginDate?: string;
  sortOrder?: Order;
  isCzuUser?: boolean;
  roleKey?: string;
}

export interface KeycloakState
  extends GenericState<AdminUserResponse, KeycloakUser> {
  adminList: BaseList;
}

const initialState: KeycloakState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {
    list: [],
  },
};

const usersSlice = createGenericSlice({
  name: 'users',
  initialState,
})({
  setAdminList: (state: KeycloakState, {payload}: PayloadAction<BaseList>) => {
    state.adminList = payload;
  },
});

export const getBaseUserList =
  ({
    lastName,
    firstName,
    email,
    offset,
    limit,
    betweenLoginDate,
    active,
    orderBy,
    sortOrder,
    isCzuUser,
    roleKey,
  }: BaseUserListRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));

      const response = await new KeycloakUserApi(
        new Configuration({accessToken: await getToken()}),
        config.keycloakDataRestUrl
      ).userAdminUsersGet(
        lastName,
        firstName,
        email,
        betweenLoginDate,
        offset,
        limit,
        active,
        orderBy,
        sortOrder,
        isCzuUser,
        roleKey
      );
      if (response?.data) {
        response.data.users &&
          dispatch(
            usersActions.setAdminList({
              list: response.data.users
                ? (response.data.users as KeycloakUser[])
                : [],
              totalCount: response.data.totalcount,
            })
          );
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const getUser =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<KeycloakUser>(
        id,
        async () =>
          new UserApi(
            new Configuration({accessToken: await getToken()}),
            config.userDataRestUrl
          ).adminUserUserIdGet(id),
        (loading) => dispatch(usersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(usersActions.setObj(obj));
        }
      )
    );
  };

export const changeUsersStatus =
  (status: Status, userIds: string[], onChangeStatus: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));
      if (status === Status.Active) {
        await new UserApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminUsersActivatePost(userIds);
      } else if (status === Status.Inactive) {
        await new UserApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminUsersActivateDelete(userIds);
      }
      onChangeStatus();
      dispatch(usersActions.setOpenObjDialog(false));
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const addRolesToUsers =
  (
    rolesToAdd: string[],
    userIds: string[],
    onChangeRoles: () => void,
    rolesToRemove?: string[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));
      rolesToRemove &&
        (await new UserApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminUsersRolesDelete({
          roles: rolesToRemove,
          users: userIds,
        }));
      rolesToAdd &&
        (await new UserApi(
          new Configuration({accessToken: await getToken()}),
          config.userDataRestUrl
        ).adminUsersRolesPost({roles: rolesToAdd, users: userIds}));
      dispatch(usersActions.setOpenObjDialog(false));
      onChangeRoles();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const deleteUsers =
  (userIds: string[], onDeleteUser: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));
      await new UserApi(
        new Configuration({accessToken: await getToken()}),
        config.userDataRestUrl
      ).adminUsersDelete(userIds);
      onDeleteUser();

      dispatch(getSimpleUserList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const createUser =
  (user: BaseKeycloakUser, onCreate: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));
      await new UserApi(
        new Configuration({accessToken: await getToken()}),
        config.userDataRestUrl
      ).adminUserPost(user);
      dispatch(usersActions.setObj(null));
      dispatch(usersActions.setOpenObjDialog(false));
      onCreate && onCreate();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const updateUser =
  (userId: string, user: BaseKeycloakUser, onUpdate: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.setObjLoadingList(true));
      await new UserApi(
        new Configuration({accessToken: await getToken()}),
        config.userDataRestUrl
      ).adminUserUserIdPut(userId, user);
      dispatch(usersActions.setObj(null));
      dispatch(usersActions.setOpenObjDialog(false));
      onUpdate && onUpdate();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(usersActions.setObjLoadingList(false));
    }
  };

export const usersActions = usersSlice.actions;

export const usersSelector = (state: RootState): typeof state.users =>
  state.users;

export default usersSlice.reducer;
