import React from 'react';
import Divider from '@mui/material/Divider';
import {Box} from '@mui/material';
import {Controller} from 'react-hook-form';
import DateTimeInput from '../../../../components/date/DateTimeInput';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import SwitchPrimary from '../../../../components/switches/SwitchPrimary';
import {useTranslation} from 'react-i18next';
import {DialogStyles} from '../../../StylesDialog';
import {NewsForm} from '../../DialogNews';
import moment from 'moment/moment';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  isBeforeVisible,
  roundTimeMinutes,
} from '../../../../utils/Utils';

const addMinuteFromNow = (date?: string, format?: string) =>
  moment(date).add(1, 'minutes').format(format);

export interface VisibleInNewsAppControlsProps
  extends Partial<UseFormReturn<NewsForm>> {
  addNew?: boolean;
  newsVisibleFromDate?: string;
  newsVisibleToDate?: string;
}
export const VisibleInNewsAppControls = ({
  control,
  setValue,
  watch,
  addNew,
  newsVisibleFromDate,
  newsVisibleToDate,
}: VisibleInNewsAppControlsProps): JSX.Element => {
  const {t} = useTranslation();

  const newsAlreadyVisible = isBeforeVisible(
    moment(newsVisibleFromDate).format(DefaultDateTimeFormatFE),
    addMinuteFromNow(undefined, DefaultDateTimeFormatFE)
  );
  const afterVisibleTo = !addNew
    ? newsVisibleToDate
      ? isBeforeVisible(
          moment(newsVisibleToDate).format(DefaultDateTimeFormatFE),
          moment().format(DefaultDateTimeFormatFE)
        )
      : false
    : false;
  return (
    <>
      <Divider />
      <Box sx={{mx: 3}}>
        <Box sx={{width: '50%', mt: 2}}>
          <Controller
            name="visibleFromNow"
            control={control}
            defaultValue={addNew}
            render={({field, fieldState: {error}}) => (
              <SwitchPrimary
                {...field}
                row
                disabled={
                  !addNew ? !afterVisibleTo && newsAlreadyVisible : false
                }
                label={t(`news.Visible from now`)}
                errorType={error?.type}
                boldLabel
                onChange={(event) => {
                  if (setValue && watch && !watch('visibleFromNow')) {
                    setValue('visibleFrom', undefined);
                    setValue('notification.sendDate', undefined);
                    setValue('notification.sendNow', true);
                  } else if (setValue && watch && watch('visibleFromNow')) {
                    watch('notification.sendNow') &&
                      setValue('notification.sendNow', false);
                    setValue(
                      'visibleFrom',
                      moment().format(DefaultDateTimeFormatFE)
                    );
                    setValue(
                      'notification.sendDate',
                      roundTimeMinutes(
                        moment(),
                        moment.duration(5, 'minutes')
                      ).format(DefaultDateTimeFormatFE)
                    );
                  }
                  field.onChange(event);
                }}
              />
            )}
          />
        </Box>
        <Box sx={DialogStyles.rowContainer}>
          <Box sx={{width: '50%'}}>
            <Controller
              name="visibleFrom"
              control={control}
              defaultValue={''}
              render={({field, fieldState: {error}}) => (
                <DateTimeInput
                  {...field}
                  disabled={
                    !addNew
                      ? watch && watch('visibleFromNow')
                        ? true
                        : !afterVisibleTo && newsAlreadyVisible
                      : watch && watch('visibleFromNow')
                  }
                  value={watch && watch('visibleFromNow') ? '' : field.value}
                  onChange={(visibleFromDate) => {
                    if (setValue) {
                      setValue(
                        'notification.sendDate',
                        roundTimeMinutes(
                          moment(visibleFromDate, DefaultDateTimeFormatFE),
                          moment.duration(5, 'minutes')
                        ).format(DefaultDateTimeFormatFE)
                      );
                      watch &&
                        watch('notification.sendNow') &&
                        setValue('notification.sendNow', false);
                      isBeforeVisible(
                        watch && watch('visibleTo'),
                        visibleFromDate
                      ) && setValue('visibleTo', '');
                    }
                    field.onChange(visibleFromDate);
                  }}
                  label={t(`news.Visible from`)}
                  errorType={error?.type}
                  boldLabel
                  min={moment().format(DefaultDateTimeFormatBE)}
                />
              )}
            />
          </Box>
          <Box sx={{width: '50%', mb: 2}}>
            <Controller
              name="visibleTo"
              control={control}
              defaultValue={''}
              render={({field, fieldState: {error}}) => (
                <DateTimeInput
                  {...field}
                  label={t(`news.Visible to`)}
                  errorType={error?.type}
                  boldLabel
                  min={
                    watch && watch('visibleFromNow')
                      ? addMinuteFromNow(undefined, DefaultDateTimeFormatBE)
                      : watch && watch('visibleFrom')
                      ? newsAlreadyVisible
                        ? addMinuteFromNow(undefined, DefaultDateTimeFormatBE)
                        : moment(
                            watch && (watch('visibleFrom') as string),
                            DefaultDateTimeFormatFE
                          )
                            .add(1, 'minutes')
                            .format(DefaultDateTimeFormatBE)
                      : addMinuteFromNow(undefined, DefaultDateTimeFormatBE)
                  }
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
