import React, {forwardRef} from 'react';
import {CustomSelectInput} from '../custom-inputs/InputBase';
import {FormModel} from '../../../../hooks/useForm';
import {InputStyles} from '../../styles/StylesInput';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {InputLabel} from '../input-label/InputLabel';
import {CustomSelect} from '../custom-inputs/SelectBase';

export interface SelectPrimaryProps extends FormModel {
  children: React.ReactNode;
  value: string;
  row?: boolean;
}

const SelectPrimary = forwardRef(
  (
    {
      id,
      name,
      children,
      label,
      disabled,
      value,
      className,
      onChange,
      onBlur,
      errorType,
      row,
    }: SelectPrimaryProps,
    ref
  ): JSX.Element => {
    const classes = InputStyles();

    return (
      <InputLabel label={label} row={row} disabled={disabled} boldLabel={true}>
        <CustomSelect
          id={id}
          name={name}
          value={value}
          ref={ref}
          onBlur={onBlur}
          className={className + ' ' + (errorType ? classes.error : ' ')}
          onChange={onChange}
          disabled={disabled}
          SelectDisplayProps={{
            style: {
              height: 42,
              paddingLeft: '32px',
              width: 175,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
            },
          }}
          MenuProps={{
            sx: {
              '& .MuiMenu-paper': {
                maxHeight: '50%',
              },
            },
            disableAutoFocusItem: true,
            MenuListProps: {
              disableListWrap: true,
            },
          }}
          input={<CustomSelectInput />}>
          {/*<SearchInput*/}
          {/*  name={name + '_search'}*/}
          {/*  placeholder={searchFieldPlaceholder || t('leftMenu.search')}*/}
          {/*  onChangeValue={handleSearchChange}*/}
          {/*/>*/}
          {children}
        </CustomSelect>

        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </InputLabel>
    );
  }
);

SelectPrimary.displayName = 'SelectPrimary';

export default SelectPrimary;
