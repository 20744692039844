import React from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../shared/components/buttons/ButtonAdd';
import {DialogObject} from '../../../../../../shared/dialogs/dialog-object/DialogObject';
import {
  CustomListItems,
  ListItemRow,
} from '../../../../../../shared/components/lists/CustomListItems';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks/hooks';
import {
  object3dSelector,
  object3dActions,
  getBaseObject3dList,
  getObject3d,
  updateObject3dAndImages3d,
  createObject3dAndImages3d,
  deleteObject3d,
} from '../../../../../../../redux/reducers/data-reducers/Object3dSlice';
import {StylesMenu} from '../../StylesMenu';
import {SearchInput} from '../../../../../../shared/components/search/SearchInput';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../../../shared/dialogs/dialog-info/DialogInfo';
import {CustomUtils} from '../../../../../../shared/utils/CustomUtils';
import {useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {useInfoDialog} from '../../../../../../../hooks/useInfoDialog';

export const ListOfObjectsTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const dispatch = useAppDispatch();
  const {
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingList,
    loadingDetail,
    hasMore,
  } = useAppSelector(object3dSelector);
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deleteObject3d(id)),
    });
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    object3dActions,
    dispatch,
    baseObjSearch,
    getBaseObject3dList
  );

  const handleEditClick = (id: string) => {
    dispatch(object3dActions.setEditObjDetail());
    dispatch(getObject3d(id));
  };

  return (
    <Box>
      <HeaderMenuItem name={t('leftMenu.List of 3D areas')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          name={'object3d-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <CustomListItems
        listItems={
          baseObjList
            ? baseObjList.map((object3d) => {
                return {
                  id: object3d.id,
                  itemRowName: CustomUtils.getContentByLanguage(
                    object3d.name,
                    object3d.nameEn
                  ),
                  activeItem: false,
                } as ListItemRow;
              })
            : []
        }
        handleEditClick={handleEditClick}
        handleDeleteClick={showInfoDialog}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
      <Box>
        <ButtonAdd
          name={t('leftMenu.Add 3D area')}
          onClick={() => {
            dispatch(object3dActions.setObj(null));
            dispatch(object3dActions.setAddNewObjDetail());
          }}
        />
      </Box>
      {openObjDialog && (
        <DialogObject
          addNew={addNewObj}
          object3d={obj}
          loading={loadingDetail}
          openDialog={openObjDialog}
          onCloseDialog={() => {
            dispatch(object3dActions.setObj(null));
            dispatch(object3dActions.setOpenObjDialog(false));
          }}
          onAddObject3d={(object3d, image3ds) => {
            dispatch(createObject3dAndImages3d(object3d, image3ds));
          }}
          onUpdateObject3d={(object3d, image3ds) => {
            dispatch(updateObject3dAndImages3d(object3d, image3ds));
          }}
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.area')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.area'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
    </Box>
  );
};
