import {Table, TableCell} from '@mui/material';
import {styled} from '@mui/material/styles';

export const CustomTableCell = styled(
  TableCell,
  {}
)(({theme}) => ({
  '& .MuiTableCell-head': {
    fontSize: 17,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
  },
  '& .MuiTableCell-body': {
    border: 0,
  },
}));

export const CustomTable = styled(
  Table,
  {}
)(({theme}) => ({
  '& .MuiTable-root': {
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
  },
}));
