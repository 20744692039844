import {AppThunk, RootState} from '../../../store';
import {
  clearObjectPreview,
  setActiveObjectPreview,
  setPoiScrollIndex,
} from '../DrawerSlice';
import {setGalleryPhotoList} from './GalleryPhotoSlice';
import {CustomUtils} from '../../../components/shared/utils/CustomUtils';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getObj,
  getBaseObjList,
  updateObj,
  PoiWithFoodMenu,
} from '@skczu/czu-frontend-library';
import {addToPoiSelectionList} from '../PoiListSelectionSlice';
import {getSimplePoiList} from './SimpleObjectListsSlice';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  CreateOrUpdatePoi,
  Poi,
  PoiApi,
  PoiRef,
  PoiFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  BaseRestaurant,
  FoodPointApi,
} from '@skczu/czu-frontend-library/build/apis/czu-relay-api/generated';
import {setTmpMapRender} from '../MapSlice';
import {
  foodMenuActions,
  getRestaurantDailyMenu,
  getRestaurantMenu,
} from './FoodMenuSlice';
import moment from 'moment/moment';
import {getToken} from '../../../keycloak';
import {batch} from 'react-redux';

export interface PoiState extends GenericState<PoiRef, PoiWithFoodMenu> {
  foodPoints?: BaseRestaurant[];
}

const initialState: PoiState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const poiSlice = createGenericSlice({
  name: 'poi',
  initialState,
})({
  setFoodPoints: (
    state: PoiState,
    {payload}: PayloadAction<BaseRestaurant[]>
  ) => {
    state.foodPoints = payload;
  },
});

export const getBasePoiList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<PoiRef, PoiFilterResponse>(
        getState().poiList.baseObjSearch,
        getState().poiList.baseObjList,
        async () =>
          new PoiApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiFilterGet(
            keyword,
            getState().poiList.baseObjSearch.limit,
            getState().poiList.baseObjSearch.offset
          ),
        (loading) => dispatch(poiActions.setObjLoadingList(loading)),
        () => dispatch(showErrorMessage()),
        (offset) => dispatch(poiActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(poiActions.setBaseObjList(newDataList ? newDataList : []));
          } else {
            dispatch(poiActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(poiActions.hasMore(hasMore))
      )
    );
  };

export const getPoi =
  (id: string, setNewPoi = true, addToSelection?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Poi>(
        id,
        async () =>
          new PoiApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiIdGet(id),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          setNewPoi && dispatch(poiActions.setObj(obj));
          if (addToSelection) {
            dispatch(
              addToPoiSelectionList(CustomUtils.convertPoiToBasePoi(obj))
            );
            dispatch(
              poiActions.addToBaseObjList([
                CustomUtils.convertPoiToBasePoi(obj),
              ])
            );
          } else {
            dispatch(
              setGalleryPhotoList(
                obj.photos
                  ? obj.photos?.map((photo) => {
                      return {galleryPhoto: photo, progress: 0, state: 'new'};
                    })
                  : []
              )
            );
          }
        }
      )
    );
  };

export const getPoiForMobilePreview =
  (id: string, scrollTo: (id: string) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(clearObjectPreview());
    batch(() => {
      dispatch(foodMenuActions.setRestaurantMenus());
      dispatch(foodMenuActions.setRestaurantWeekMenu());
      dispatch(foodMenuActions.setRestaurantDailyMenu());
      dispatch(foodMenuActions.setRestaurantDetail());
    });
    dispatch(
      getObj<Poi>(
        id,
        (poiId) =>
          new PoiApi(undefined, config.cmsRestUrl).poiIdGet(poiId as string),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(poiActions.setObjFailed({message: fail})),
        (obj) => {
          const setPoiToList = (poi: Poi) => {
            if (
              !getState().poiList.baseObjList.some(
                (basePoi) => basePoi.id === id
              )
            ) {
              !getState().poiList.baseObjList &&
                dispatch(
                  getBasePoiList(
                    true,
                    getState().poiList.baseObjSearch.searchQuery
                      ? getState().poiList.baseObjSearch.searchQuery
                      : undefined
                  )
                );
              dispatch(
                poiActions.addToBaseObjList([
                  CustomUtils.convertPoiToBasePoi(poi),
                ])
              );
            }
            scrollTo(id);
          };

          if (obj.foodPointId) {
            dispatch(
              getRestaurantMenu(obj.foodPointId, (food) => {
                dispatch(
                  setActiveObjectPreview({
                    objectPreview: {
                      objectMobileScene: 'poi',
                      objectPreviewData: {
                        ...obj,
                        foodMenuData: {
                          restaurantMenu: food ? food : undefined,
                          restaurantDetail: obj.restaurant
                            ? obj.restaurant
                            : undefined,
                          selectedWeekDates: {
                            fromDay: moment()
                              .startOf('week')
                              .format('YYYY-MM-DD'),
                            toDay: moment().endOf('week').format('YYYY-MM-DD'),
                          },
                        },
                      },
                    },
                    objectPreviewView: {
                      openObjectPreview: true,
                    },
                  })
                );
                obj?.foodPointId &&
                  dispatch(
                    getRestaurantDailyMenu(
                      obj.foodPointId,
                      moment().format('YYYY-MM-DD'),
                      true,
                      true
                    )
                  );
                setPoiToList(obj);
              })
            );
          } else {
            dispatch(
              setActiveObjectPreview({
                objectPreview: {
                  objectMobileScene: 'poi',
                  objectPreviewData: obj,
                },
                objectPreviewView: {
                  openObjectPreview: true,
                },
              })
            );
            setPoiToList(obj);
          }
        }
      )
    );
  };

export const deletePoi =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new PoiApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiIdDelete(id),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(poiActions.setObj(null));
          dispatch(poiActions.removeFromBaseObjList(id));
          dispatch(getSimplePoiList());
          dispatch(clearObjectPreview());
          dispatch(setTmpMapRender());
        }
      )
    );
  };

export const createPoi =
  (poi: CreateOrUpdatePoi): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CreateOrUpdatePoi>(
        poi,
        async (obj) =>
          new PoiApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiPost(obj),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (id) => {
          dispatch(poiActions.setObj(null));
          dispatch(
            poiActions.addToBaseObjListAsFirst({...poi, id: id as string})
          );
          dispatch(getSimplePoiList());
          dispatch(poiActions.setOpenObjDialog(false));
          dispatch(
            getPoiForMobilePreview(id as string, (poiObjId) =>
              dispatch(setPoiScrollIndex(poiObjId))
            )
          );
          dispatch(setTmpMapRender());
        }
      )
    );
  };

export const updatePoi =
  (poi: CreateOrUpdatePoi): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      updateObj<CreateOrUpdatePoi>(
        poi,
        async (obj) =>
          new PoiApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiPut(obj),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        (message) =>
          message.includes('The Ar image quality score is less')
            ? dispatch(
                showErrorMessage(
                  'The Ar image quality score is less than the minimum allowed score.'
                )
              )
            : dispatch(showErrorMessage()),

        (obj) => {
          dispatch(poiActions.setObj(null));
          dispatch(poiActions.updateBaseObjInList(obj));
          dispatch(getSimplePoiList());
          dispatch(poiActions.setOpenObjDialog(false));
          const drawerState = getState().drawer;
          obj.id &&
            drawerState.objectPreviewView.openObjectPreview &&
            drawerState.objectPreview.objectPreviewData &&
            dispatch(
              getPoiForMobilePreview(obj.id, (poiObjId) =>
                dispatch(setPoiScrollIndex(poiObjId))
              )
            );
          dispatch(setTmpMapRender());
        }
      )
    );
  };

export const getFoodPoints = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(poiActions.setObjLoadingList(true));
    const foodPoints = getState().poiList.foodPoints;
    if (!foodPoints) {
      const response = await new FoodPointApi(
        new Configuration({accessToken: await getToken()}),
        config.czuRelayRestUrl
      ).restaurantsGet();
      if (response?.data?.restaurants) {
        dispatch(poiActions.setFoodPoints(response.data.restaurants));
      }
    }
  } catch (error) {
    dispatch(showErrorMessage());
    // console.log(error);
  } finally {
    dispatch(poiActions.setObjLoadingList(false));
  }
};

export const changeVisibleOnMap =
  (poiId: string, visible: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(poiActions.setObjLoadingList(true));
      const response = await new PoiApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poiIdVisiblePut(poiId, visible);
      if (response?.data?.success) {
        const poi = getState().poiList?.baseObjList?.find(
          (basePoi) => basePoi.id === poiId
        );
        const editedPoi = Object.assign({}, poi);
        if (editedPoi) {
          editedPoi.showOnMap = visible;
          dispatch(poiActions.updateBaseObjInList(editedPoi));
          dispatch(setTmpMapRender());
        }
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(poiActions.setObjLoadingList(false));
    }
  };

export const poiActions = poiSlice.actions;
export const poiSelector = (state: RootState): typeof state.poiList =>
  state.poiList;

export default poiSlice.reducer;
