import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {selectSelectedPoi} from '../../../../../redux/reducers/PoiListSelectionSlice';
import {selectSelectedRoute} from '../../../../../redux/reducers/RouteListSelectionSlice';
import {setObjectPreviewView} from '../../../../../redux/reducers/DrawerSlice';
import {ButtonBack} from '@skczu/czu-react-components';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  divider: {
    height: 3,
  },
}));

export interface Props {
  name: string;
  onBackClick?: () => void;
}

export const HeaderMenuItem = ({name, onBackClick}: Props): JSX.Element => {
  const classes = useStyles();
  const {url} = useRouteMatch();
  const history = useHistory();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const routeSelection = useAppSelector(selectSelectedRoute);
  const dispatch = useAppDispatch();

  const handleNavigation = useCallback(
    (newPath: string) => history.replace(`${newPath}`),
    [history]
  );

  return (
    <Box>
      <Box className={classes.toolbar}>
        <Box width={'100%'}>
          <h2>{name}</h2>
        </Box>
        <Box>
          {!routeSelection.routeSelection && !poiSelection.poiSelection && (
            <ButtonBack
              onClick={() => {
                if (onBackClick) {
                  onBackClick();
                }
                dispatch(
                  setObjectPreviewView({
                    openObjectPreview: false,
                  })
                );
                handleNavigation(url.substring(0, url.lastIndexOf('/')));
              }}
            />
          )}
        </Box>
      </Box>

      <Divider className={classes.divider} />
    </Box>
  );
};
