import {ListItemText, MenuItem, Switch} from '@mui/material';
import React from 'react';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
const styles = {
  selectAll: {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderTop: `1px solid`,
    borderColor: 'secondary.light',
  },
  selectAllText: {
    fontWeight: 500,
  },
};
export interface SelectAllMenuItemProps {
  checked: boolean;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: () => void;
  key: string;
  value?: string;
  text: string;
  sx?: SxProps<Theme>;
}
export const SelectHeaderMenuItem = ({
  checked,
  key,
  value,
  text,
  onChange,
  disabled,
  sx,
  multiple,
}: SelectAllMenuItemProps): JSX.Element => {
  return (
    <MenuItem
      key={key}
      value={value}
      onChange={onChange}
      sx={{
        ...sx,
        ...styles.selectAll,
      }}>
      {multiple ? (
        <Switch checked={checked} disabled={disabled} color={'primary'} />
      ) : null}
      <ListItemText
        sx={{'& .MuiListItemText-primary': styles.selectAllText}}
        primary={text}
      />
    </MenuItem>
  );
};
