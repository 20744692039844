import React from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {
  Box,
  Button,
  DialogActions,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {SelectRoles} from '../../components/selects/select-multiple/SelectRoles';
import {SelectEvents} from '../../components/selects/select-multiple/SelectEvents';
import SelectPrimary from '../../components/selects/SelectPrimary';
import {InputModelValueType} from '../../../../hooks/useForm';

export type DialogItemButtonTypes = 'role' | 'group' | 'enabled' | 'delete';
export interface DialogItemType<T> {
  openDialog: boolean;
  id?: string;
  type?: DialogItemButtonTypes;
  value?: InputModelValueType;
  selectedRows?: Array<T>;
}

export const getTitleFromType = (
  type: DialogItemButtonTypes | undefined
): string => {
  switch (type) {
    case 'enabled':
      return 'Change status';
    case 'role':
      return 'Add roles';
    case 'group':
      return 'Add to group';
    case 'delete':
      return 'Delete';
    default:
      return '';
  }
};

export interface Props<T> {
  id?: string;
  title: string;
  text: string;
  openDialog: boolean;
  type?: DialogItemButtonTypes;
  onCloseDialog: () => void;
  value?: InputModelValueType;
  selectedRows?: Array<T>;
  onChangeValue?: (event: string[], selectedRows: Array<T>) => void;
  onAgree?: (event: InputModelValueType, selectedRows: Array<T>) => void;
}
export function DialogItemSelect<T>({
  id,
  openDialog,
  title,
  type,
  text,
  onCloseDialog,
  value,
  onChangeValue,
  selectedRows,
  onAgree,
}: Props<T>): JSX.Element {
  const {t} = useTranslation();

  return (
    <Box>
      <DialogPrimary
        title={title}
        open={openDialog}
        onCloseDialog={onCloseDialog}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}>
          {type === 'enabled' && (
            <>
              <Box m={1}>
                <Typography>{text}</Typography>
              </Box>
              <SelectPrimary
                name={id + '_id'}
                label={t('user.Status')}
                value={(value as string) || ''}
                onChange={(e) =>
                  selectedRows &&
                  onChangeValue &&
                  onChangeValue(e.target.value, selectedRows)
                }>
                <MenuItem key={'Active'} value={t('user.Active')}>
                  <ListItemText primary={t('user.Active')} />
                </MenuItem>
                <MenuItem key={'Inactive'} value={t('user.Inactive')}>
                  <ListItemText primary={t('user.Inactive')} />
                </MenuItem>
              </SelectPrimary>
            </>
          )}
          {type === 'role' && (
            <>
              <Box m={1}>
                <Typography>{text}</Typography>
              </Box>
              <SelectRoles
                name={id + '_id'}
                label={t('user.Role')}
                value={(value as string[]) || []}
                onChange={(e) =>
                  selectedRows &&
                  onChangeValue &&
                  onChangeValue(e, selectedRows)
                }
              />
            </>
          )}
          {type === 'group' && (
            <>
              <Box m={1}>
                <Typography>{text}</Typography>
              </Box>
              <SelectEvents
                name={id + '_id'}
                label={t('user.Group')}
                value={(value as string[]) || []}
                onChange={(e) =>
                  selectedRows &&
                  onChangeValue &&
                  onChangeValue(e, selectedRows)
                }
              />
            </>
          )}
          {type === 'delete' && (
            <>
              <Box m={1}>
                <Typography>{text}</Typography>
              </Box>
            </>
          )}
        </Box>
        <Box sx={{position: 'absolute', bottom: 10, right: 10}}>
          <DialogActions>
            <Button
              onClick={() =>
                onAgree && selectedRows && value && onAgree(value, selectedRows)
              }>
              {t('allObjects.Yes')}
            </Button>
            <Button autoFocus onClick={onCloseDialog}>
              {t('allObjects.Cancel')}
            </Button>
          </DialogActions>
        </Box>
      </DialogPrimary>
    </Box>
  );
}
