import {InputBase} from '@mui/material';

import {styled} from '@mui/material/styles';
import {SecondaryLightHover} from '../../../../App';

export const CustomSelectInput = styled(
  InputBase,
  {}
)(({theme}) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.secondary.light,
  boxShadow: theme.shadows[2],
}));

export const CustomInput = styled(
  InputBase,
  {}
)(({theme}) => ({
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    fontSize: 18,
    padding: '10px 12px',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.shadows[2],
    '&:hover': {
      background: SecondaryLightHover,
    },
  },
}));
