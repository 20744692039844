import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {DialogNews} from '../../../../shared/dialogs/dialog-news/DialogNews';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  createNews,
  deleteMultipleNews,
  deleteNews,
  getBaseNewsList,
  getNews,
  getNewsForMobilePreview,
  newsActions,
  newsSelector,
  updateNews,
} from '../../../../../redux/reducers/data-reducers/NewsSlice';
import {
  poiSelectionSelector,
  setPoiSelectionList,
} from '../../../../../redux/reducers/PoiListSelectionSlice';
import {
  routeSelectionSelector,
  setRouteSelectionList,
} from '../../../../../redux/reducers/RouteListSelectionSlice';
import {StylesAdminTab} from '../../StylesAdminTab';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../shared/dialogs/dialog-info/DialogInfo';
import {FilterParams, Table} from '../../../../shared/table';
import {Cell, CellProps, Row, TableInstance} from 'react-table';
import moment from 'moment';
import {
  DefaultDateFormat,
  DefaultDateTimeFormatFE,
  ValueLocale,
} from '../../../../shared/utils/Utils';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {setGalleryPhotoList} from '../../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {showImagePreview} from '../../../../../redux/reducers/ImagePreview';
import {
  findFilterById,
  getDateRangeFilterString,
  getSortDirection,
  useLocalStorage,
} from '../../../../shared/table/utils';
import {useInfoDialog} from '../../../../../hooks/useInfoDialog';
import {getTableNewsColumns} from './TableNewsColumns';
import {
  CreateOrUpdateNews,
  NewsFilterRequest,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {SortDirection} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {
  DialogItemButtonTypes,
  DialogItemSelect,
  DialogItemType,
  getTitleFromType,
} from '../../../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {InputModelValueType} from '../../../../../hooks/useForm';

export type AdminNewsObj = {
  name: string;
  miniBody: string;
  action: string;
  pois: string;
  routes: string;
  // groups: string;
  visibleInApp?: boolean;
  lastUpdateDate?: Date | null;
  visibleFrom?: Date | null;
  visibleTo?: Date | null;
  lastUpdateFrom?: Date | null;
  lastUpdateTo?: Date | null;
};

export type NewsData = AdminNewsObj & {
  subRows?: NewsData[];
};

export const TableDateCell = ({
  cell: {value},
}: CellProps<AdminNewsObj>): JSX.Element => {
  return (
    <span>{value ? moment(value).format(DefaultDateTimeFormatFE) : '-'}</span>
  );
};

export const TableNews = (): JSX.Element => {
  const {t} = useTranslation();
  const tabClasses = StylesAdminTab();
  const dispatch = useAppDispatch();
  const {adminList, obj, openObjDialog, addNewObj, loadingDetail, loadingList} =
    useAppSelector(newsSelector);
  const {openDialogAfterPoiSelection} = useAppSelector(poiSelectionSelector);
  const {openDialogAfterRouteSelection} = useAppSelector(
    routeSelectionSelector
  );
  const [multiSelect, setMultiSelect] = React.useState<
    DialogItemType<AdminNewsObj>
  >({
    openDialog: false,
  });
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) =>
        id &&
        dispatch(
          deleteNews(id, () => {
            dispatch(getBaseNewsList(createRequest(initialState)));
          })
        ),
    });

  const [tablePageIndex, setTablePageIndex] = useState<number>(0);

  const [initialState, setInitialState] = useLocalStorage(
    'tableState:tableNews',
    {
      sortBy: [
        {
          // id: 'lastUpdateDate',
          desc: true,
        },
      ],
      pageSize: 10,
      pageIndex: 0,
    }
  );

  const setState = useCallback(
    (value: FilterParams<NewsData>) => {
      setInitialState(value);
    },
    [setInitialState]
  );

  useEffect(() => {
    openDialogAfterPoiSelection &&
      dispatch(newsActions.setOpenObjDialog(openDialogAfterPoiSelection));
  }, [dispatch, openDialogAfterPoiSelection]);

  useEffect(() => {
    openDialogAfterRouteSelection &&
      dispatch(newsActions.setOpenObjDialog(openDialogAfterRouteSelection));
  }, [dispatch, openDialogAfterRouteSelection]);

  const handleEditClick = useCallback(
    (id: string) => {
      dispatch(newsActions.setEditObjDetail());
      dispatch(getNews(id));
    },
    [dispatch]
  );

  const handleItemClick = useCallback(
    (row: Row<NewsData>, cell: Cell<NewsData>) => {
      const actionColumn = row.cells.find(
        (rowCell) => rowCell.column.id === 'action'
      );
      cell.column.id !== 'action' &&
        actionColumn?.value &&
        dispatch(getNewsForMobilePreview(actionColumn.value));
    },
    [dispatch]
  );

  const createRequest = useCallback(
    (params: FilterParams<AdminNewsObj>): NewsFilterRequest => {
      const filteredName = findFilterById(params.filters, 'name')?.value;
      const filteredPois = findFilterById(params.filters, 'pois')?.value;
      const filteredRoutes = findFilterById(params.filters, 'routes')?.value;
      const filteredVisibleInApp = findFilterById(
        params.filters,
        'visibleInApp'
      )?.value;
      const filteredLastUpdateDate = findFilterById(
        params.filters,
        'lastUpdateDate'
      )?.value;
      const filteredVisibleFrom = findFilterById(
        params.filters,
        'visibleFrom'
      )?.value;
      const filteredVisibleTo = findFilterById(
        params.filters,
        'visibleTo'
      )?.value;

      const poiIds = filteredPois?.map((obj: ValueLocale) => obj.id);
      const routeIds = filteredRoutes?.map((obj: ValueLocale) => obj.id);
      const visibleInApp = filteredVisibleInApp
        ? filteredVisibleInApp === 'news.Visible'
        : undefined;

      const name = filteredName ? filteredName : undefined;
      const sortBy: {[key: string]: SortDirection} = getSortDirection(
        params?.sortBy
      );
      const orderBy = params?.sortBy ? sortBy : undefined;
      const betweenUpdatedDates = filteredLastUpdateDate
        ? getDateRangeFilterString(
            filteredLastUpdateDate[0],
            filteredLastUpdateDate[1],
            true
          )
        : undefined;
      const betweenVisibleFromDates = filteredVisibleFrom
        ? getDateRangeFilterString(
            filteredVisibleFrom[0],
            filteredVisibleFrom[1],
            true
          )
        : undefined;
      const betweenVisibleToDates = filteredVisibleTo
        ? getDateRangeFilterString(
            filteredVisibleTo[0],
            filteredVisibleTo[1],
            true
          )
        : undefined;
      return {
        orderBy,
        name,
        poiIds,
        routeIds,
        visibleInApp,
        // eventIds,
        betweenUpdatedDates,
        betweenVisibleToDates,
        betweenVisibleFromDates,
        limit: params.pageSize,
        offset: params.pageIndex * params.pageSize,
      };
    },
    []
  );

  const newsList = useMemo(() => {
    t('');
    return adminList.list.map((dataObj) => {
      return {
        action: dataObj.id,
        name: CustomUtils.getContentByLanguage(dataObj.name, dataObj.nameEn),
        miniBody: CustomUtils.getContentByLanguage(
          dataObj.miniBody,
          dataObj.miniBodyEn
        ),
        visibleFrom: dataObj.visibleFrom
          ? moment(new Date(dataObj.visibleFrom), DefaultDateFormat).toDate()
          : null,
        visibleTo: dataObj.visibleTo
          ? moment(new Date(dataObj.visibleTo), DefaultDateFormat).toDate()
          : null,
        lastUpdateDate: dataObj.lastUpdateDate
          ? moment(new Date(dataObj.lastUpdateDate), DefaultDateFormat).toDate()
          : null,
        groups: dataObj.events
          ?.map((group) =>
            CustomUtils.getContentByLanguage(group.name, group.nameEn)
          )
          .toString(),
        pois: dataObj.pois
          ?.map((poi) => CustomUtils.getContentByLanguage(poi.name, poi.nameEn))
          .toString(),
        routes: dataObj.routes
          ?.map((route) =>
            CustomUtils.getContentByLanguage(route.name, route.nameEn)
          )
          .toString(),
        visibleInApp: dataObj.visibleInApp
          ? t(`allObjects.Yes`)
          : t(`allObjects.No`),
      } as AdminNewsObj;
    });
  }, [adminList.list, t]);

  const getNewsData = useCallback(
    (params: FilterParams<AdminNewsObj>) => {
      if (!loadingList) {
        setState(params);
        dispatch(getBaseNewsList(createRequest(params)));
      }
    },
    [createRequest, dispatch, loadingList, setState]
  );

  const handleUpdateNews = (updatedNews: CreateOrUpdateNews) => {
    dispatch(setPoiSelectionList([]));
    dispatch(setRouteSelectionList([]));
    dispatch(
      updateNews(updatedNews, () => {
        dispatch(getBaseNewsList(createRequest(initialState)));
      })
    );
  };
  const handleAddNews = (newNews: CreateOrUpdateNews) => {
    dispatch(setPoiSelectionList([]));
    dispatch(setRouteSelectionList([]));
    dispatch(
      createNews(newNews, () => {
        dispatch(getBaseNewsList(createRequest(initialState)));
      })
    );
  };

  const handleCloseDialog = () => {
    dispatch(newsActions.setObj(null));
    dispatch(newsActions.setOpenObjDialog(false));
    dispatch(setPoiSelectionList([]));
    dispatch(setRouteSelectionList([]));
  };

  const columns = useMemo(() => {
    return getTableNewsColumns({
      onEditClick: handleEditClick,
      onDeleteClick: showInfoDialog,
      t,
    });
  }, [handleEditClick, showInfoDialog, t]);

  const onAgreeMultiSelect = useCallback(
    (
      type: DialogItemButtonTypes,
      value: InputModelValueType,
      selectedRows: Array<AdminNewsObj>
    ) => {
      switch (type) {
        case 'delete':
          dispatch(
            deleteMultipleNews(
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseNewsList(createRequest(initialState)))
            )
          );
          break;
      }
      setMultiSelect({
        openDialog: false,
        id: undefined,
      });
    },
    [createRequest, dispatch, initialState]
  );

  const handleMultiSelect = useCallback(
    (
        instance: TableInstance<AdminNewsObj>,
        type: DialogItemType<AdminNewsObj>
      ) =>
      () => {
        setMultiSelect({
          ...type,
          selectedRows: instance.selectedFlatRows.map((v) => v.original),
        });
      },
    []
  );

  const renderTable = useMemo(() => {
    return (
      <Table<NewsData>
        t={t}
        name={'newsTable'}
        multiselectButtonTypes={['delete']}
        totalCount={adminList.totalCount}
        rowSelection
        columns={columns}
        autoResetPage={false}
        tablePageIndex={tablePageIndex}
        pageCount={
          adminList?.totalCount ? Math.ceil(adminList.totalCount / 10) : 0
        }
        onChangePage={(page) => setTablePageIndex(page)}
        tableState={initialState}
        setTableState={(val) => setState(val)}
        data={newsList}
        onFetchData={getNewsData}
        setMultiSelect={handleMultiSelect}
        onClick={handleItemClick}
        manualFilters
        manualSortBy
        manualPagination
        loadingList={loadingList}
      />
    );
  }, [
    t,
    adminList.totalCount,
    columns,
    tablePageIndex,
    initialState,
    newsList,
    getNewsData,
    handleMultiSelect,
    handleItemClick,
    loadingList,
    setState,
  ]);

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('news.Add news')}
          onClick={() => {
            dispatch(newsActions.setObj(null));
            dispatch(newsActions.setAddNewObjDetail());
            dispatch(setGalleryPhotoList([]));
          }}
        />
      </Box>
      {renderTable}
      {openObjDialog && (
        <DialogNews
          addNew={addNewObj}
          news={obj}
          loading={loadingDetail}
          openDialog={openObjDialog}
          onCloseDialog={handleCloseDialog}
          onAddNews={handleAddNews}
          onUpdateNews={handleUpdateNews}
          onObjectSelection={(tmpNews) => dispatch(newsActions.setObj(tmpNews))}
          imagePreview={(selectedImage, images) =>
            dispatch(showImagePreview({selectedImage, images}))
          }
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.news')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.news'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogItemSelect
        title={t(`user.${getTitleFromType(multiSelect.type)}`)}
        text={
          t(`user.${getTitleFromType(multiSelect.type)}`) +
          ', ' +
          t('news.News') +
          ': ' +
          (multiSelect?.selectedRows?.length || '0')
        }
        id={multiSelect.id}
        value={multiSelect.value}
        openDialog={multiSelect.openDialog}
        type={multiSelect.type}
        selectedRows={multiSelect.selectedRows}
        onCloseDialog={() =>
          setMultiSelect({
            openDialog: false,
            id: undefined,
          })
        }
        onChangeValue={(event, selectedRows) => {
          setMultiSelect((select) => {
            return {...select, value: event, selectedRows};
          });
        }}
        onAgree={(value, selectedRows) =>
          multiSelect.type &&
          onAgreeMultiSelect(multiSelect.type, value, selectedRows)
        }
      />
    </Box>
  );
};
