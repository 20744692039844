import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import i18n from 'i18next';
import moment from 'moment/moment';
import {
  Allergen,
  BaseRestaurant,
  Food,
  FoodPointApi,
  FoodResponse,
  Restaurant,
} from '@skczu/czu-frontend-library/build/apis/czu-relay-api/generated';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';
import {updateActiveObjectPreviewData} from '../DrawerSlice';
import {PoiWithFoodMenu} from '@skczu/czu-frontend-library';

export type PaymentType = 'Credit card' | 'Cash' | 'Meal voucher' | 'CZU card';

export const getPaymentType = (restaurant: BaseRestaurant): PaymentType[] => {
  const paymentTypes: PaymentType[] = [];
  restaurant.paymentByCash && paymentTypes.push('Cash');
  restaurant.paymentByCreditCard && paymentTypes.push('Credit card');
  restaurant.paymentByCZUCard && paymentTypes.push('CZU card');
  restaurant.paymentByMealVoucher && paymentTypes.push('Meal voucher');
  return paymentTypes;
};

export type RestaurantFood = {[key: string]: Array<Food>};
export type RestaurantWeekMenu = {[key: string]: FoodResponse};
export type SelectedWeekDates = {fromDay: string; toDay: string};

export interface FoodMenuState {
  restaurantMenu?: RestaurantFood;
  restaurantWeekMenu?: RestaurantWeekMenu;
  selectedWeekDates?: SelectedWeekDates;
  restaurantDailyMenu?: RestaurantFood;
  restaurantDetail?: Restaurant;
  allergens?: Allergen[];
  objLoading: boolean;
}

const initialState: FoodMenuState = {
  objLoading: false,
};

export const foodMenuSlice = createSlice({
  name: 'foodMenu',
  initialState,
  reducers: {
    setAllergens: (state, {payload}: PayloadAction<Allergen[]>) => {
      state.allergens = payload;
    },
    setObjLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.objLoading = payload;
    },
    setRestaurantDetail: (
      state,
      {payload}: PayloadAction<Restaurant | undefined>
    ) => {
      state.restaurantDetail = payload;
    },
    setSelectedWeekDates: (
      state,
      {payload}: PayloadAction<SelectedWeekDates>
    ) => {
      state.selectedWeekDates = payload;
    },
    setRestaurantMenus: (
      state,
      {payload}: PayloadAction<RestaurantFood | undefined>
    ) => {
      state.restaurantMenu = payload;
    },
    setRestaurantWeekMenu: (
      state,
      {payload}: PayloadAction<RestaurantWeekMenu | undefined>
    ) => {
      state.restaurantWeekMenu = payload;
    },
    setRestaurantDailyMenu: (
      state,
      {payload}: PayloadAction<RestaurantFood | undefined>
    ) => {
      state.restaurantDailyMenu = payload;
    },
  },
});

export const getRestaurantMenu =
  (
    restaurantId: string,
    onGetRestaurantMenu?: (food: RestaurantFood | undefined) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(foodMenuActions.setObjLoading(true));
      const response = await new FoodPointApi(
        undefined,
        config?.czuRelayRestUrl
      ).restaurantsIdMenuGet(restaurantId);
      if (response.data.FoodCz) {
        dispatch(
          foodMenuActions.setRestaurantMenus(
            i18n.language === 'en'
              ? response.data.FoodEn?.Foods
              : response.data.FoodCz?.Foods
          )
        );
      }
      onGetRestaurantMenu &&
        onGetRestaurantMenu(
          i18n.language === 'en'
            ? response.data.FoodEn?.Foods
            : response.data.FoodCz?.Foods
        );
    } catch (error) {
      // dispatch(foodMenuActions.setAccountState('serviceError'));
      // console.log(JSON.stringify(error));
      // console.log('getRestaurantMenu---------------------------');
    } finally {
      // dispatch(foodMenuActions.setObjLoading(false));
    }
  };

export const getRestaurantDailyMenu =
  (
    restaurantId: string,
    day: string,
    newWeek?: boolean,
    updateDrawerPreview?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const weekMenu = getState().foodMenu.restaurantWeekMenu;
    const selectedWeekDates = getState().foodMenu.selectedWeekDates;
    const objLoading = getState().foodMenu.objLoading;
    const startDate = moment(day).startOf('isoWeek');
    const endDate = moment(day).endOf('isoWeek');

    const setDailyMenu = (menu?: RestaurantWeekMenu) => {
      const poiData = getState().drawer.objectPreview.objectPreviewData;
      if (menu) {
        let foodResponse: FoodResponse | undefined;
        Object.keys(menu).forEach((key) => {
          if (key === day) {
            foodResponse = menu[key];
          }
        });
        const dailyMenu =
          i18n.language === 'en'
            ? foodResponse?.FoodEn?.Foods
            : foodResponse?.FoodCz?.Foods;
        dispatch(foodMenuActions.setRestaurantDailyMenu(dailyMenu));

        const weekDates = {
          fromDay: moment(startDate).format('YYYY-MM-DD'),
          toDay: moment(endDate).format('YYYY-MM-DD'),
        };
        dispatch(foodMenuActions.setSelectedWeekDates(weekDates));

        updateDrawerPreview &&
          dispatch(
            updateActiveObjectPreviewData({
              ...poiData,
              foodMenuData: {
                ...(poiData as PoiWithFoodMenu).foodMenuData,
                restaurantDailyMenu: dailyMenu ? dailyMenu : undefined,
                selectedWeekDates: weekDates,
              },
            })
          );
      }
    };

    if (
      !selectedWeekDates ||
      !weekMenu ||
      (selectedWeekDates &&
        selectedWeekDates.fromDay !== moment(startDate).format('YYYY-MM-DD') &&
        selectedWeekDates.toDay !== moment(endDate).format('YYYY-MM-DD'))
    ) {
      !objLoading &&
        dispatch(
          getRestaurantWeekMenu(
            restaurantId,
            moment(new Date()).format('YYYY-MM-DD'),
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            (newWeekMenu) => {
              !weekMenu &&
                dispatch(
                  getRestaurantDailyMenu(restaurantId, day, updateDrawerPreview)
                );
              setDailyMenu(newWeekMenu);
            },
            updateDrawerPreview
          )
        );
    }
    setDailyMenu(weekMenu);
  };

export const getRestaurantWeekMenu =
  (
    restaurantId: string,
    day: string,
    from: string,
    to: string,
    onGetRestaurantWeekMenu: (weekMenu?: RestaurantWeekMenu) => void,
    updateDrawerPreview?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(foodMenuActions.setObjLoading(true));
      const response = await new FoodPointApi(
        undefined,
        config?.czuRelayRestUrl
      ).restaurantsIdDailyMenuGet(restaurantId, from, to);
      if (response.data) {
        if (response.data) {
          onGetRestaurantWeekMenu && onGetRestaurantWeekMenu(response.data);
          dispatch(foodMenuActions.setRestaurantWeekMenu(response.data));
          const poiData = getState().drawer.objectPreview.objectPreviewData;
          updateDrawerPreview &&
            dispatch(
              updateActiveObjectPreviewData({
                ...poiData,
                foodMenuData: {
                  ...(poiData as PoiWithFoodMenu).foodMenuData,
                  restaurantWeekMenu: response.data,
                },
              })
            );
        }
      }
    } catch (error) {
      // dispatch(foodMenuActions.setAccountState('serviceError'));
      // console.log(JSON.stringify(error));
      // console.log('getRestaurantDailyMenu---------------------------');
    } finally {
      dispatch(foodMenuActions.setObjLoading(false));
    }
  };

export const getAllergens = (): AppThunk => async (dispatch, getState) => {
  try {
    if (!getState().foodMenu.allergens) {
      dispatch(foodMenuActions.setObjLoading(true));
      const response = await new FoodPointApi(
        undefined,
        config?.czuRelayRestUrl
      ).allergensGet();
      if (response.data.allergens) {
        dispatch(foodMenuActions.setAllergens(response.data.allergens));
      }
    }
  } catch (error) {
    // dispatch(foodMenuActions.setAccountState('serviceError'));
    // console.log(JSON.stringify(error));
    // console.log('getAllergens---------------------------');
  } finally {
    dispatch(foodMenuActions.setObjLoading(false));
  }
};

export const getRestaurantData =
  (restaurantId: string): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(foodMenuActions.setObjLoading(true));
      const response = await new FoodPointApi(
        undefined,
        config?.czuRelayRestUrl
      ).restaurantsIdGet(restaurantId);
      if (response.data) {
        dispatch(foodMenuActions.setRestaurantDetail(response.data));
      }
    } catch (error) {
      // dispatch(foodMenuActions.setAccountState('serviceError'));
      // console.log(JSON.stringify(error));
      // console.log('getRestaurantData---------------------------');
    } finally {
      // dispatch(foodMenuActions.setObjLoading(false));
    }
  };

export const foodMenuActions = foodMenuSlice.actions;

export const foodMenuSelector = (state: RootState): typeof state.foodMenu =>
  state.foodMenu;

export default foodMenuSlice.reducer;
