import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {DialogPrimary} from '../DialogPrimary';
import {
  ContentLanguage,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../../hooks/hooks';
import {TextEditorObjectBody} from '../../components/text-edior/TextEditorObjectBody';
import {StylesDialog} from '../StylesDialog';
import {MobileScreenChangesPreview} from '@skczu/czu-react-components';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {Wishlist} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';

export type WishlistForm = Omit<Wishlist, 'id' | 'visible' | 'editedDate'>;
export interface DialogChangesProps {
  openDialog: boolean;
  wishlist?: Wishlist;
  onCloseDialog: () => void;
  selectedLanguage?: ContentLanguage;
  onUpdateChanges?: (wishlist: Wishlist) => void;
}

export const DialogChanges = ({
  openDialog,
  wishlist,
  onCloseDialog,
  selectedLanguage,
  onUpdateChanges,
}: DialogChangesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();
  const {control, watch, reset, handleSubmit, setValue} =
    useForm<WishlistForm>();

  useEffect(() => {
    if (wishlist) {
      setValue('text', wishlist?.text);
      setValue('textEn', wishlist?.textEn);
    }
  }, [wishlist, setValue]);

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const onValidSubmit = (data: UnpackNestedValue<WishlistForm>) => {
    onUpdateChanges && onUpdateChanges(data);
  };

  return (
    <DialogPrimary
      title={t('changes.Upcoming changes')}
      open={openDialog}
      minWidth={'1280px'}
      onCloseDialog={handleCloseDialog}
      contentLanguage={true}>
      <Box display="flex" flexDirection="row">
        <Box marginTop={3} className={dialogClasses.mobileScreenContainer}>
          <MobileScreenChangesPreview
            bodyTextHtml={
              (selectedLanguage === ContentLanguage.EN
                ? watch('textEn')
                : watch('text')) as string
            }
            newsName={t('changes.Upcoming changes')}
          />
        </Box>
        <Box marginLeft={3} className={dialogClasses.textEditor}>
          <form onSubmit={handleSubmit(onValidSubmit)}>
            <TextEditorObjectBody
              controlAttr={'text'}
              control={control}
              boldLabel
              selectedLanguage={selectedLanguage}
            />
            <Box marginTop={10} display={'flex'} justifyContent={'center'}>
              <ButtonAdd name={t('changes.Save changes')} id="save-draft" />
            </Box>
          </form>
        </Box>
      </Box>
    </DialogPrimary>
  );
};
