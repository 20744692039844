import React, {PropsWithChildren} from 'react';
import {Box} from '@mui/material';
import {TFunction} from 'react-i18next';
import {CopyToClipboardButton} from './CopyToClipboardButton';
import {
  getMobileObjDetailUrl,
  MobileObjDetailUrlTypes,
} from './MobileObjDetailUrlType';

export interface MobileObjectDetailButtonsProps {
  id?: string;
  objType?: MobileObjDetailUrlTypes;
  t: TFunction;
}

export const MobileObjectDetailButtons = ({
  t,
  id,
  objType,
  children,
}: PropsWithChildren<MobileObjectDetailButtonsProps>): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" alignItems={'center'}>
      {id ? (
        <CopyToClipboardButton
          name={t('allObjects.Copy link of object')}
          text={getMobileObjDetailUrl(objType, id)}
          toolbarTitle={t('allObjects.Link copied to clipboard') || ''}
        />
      ) : null}
      <Box marginTop={2}>{children}</Box>
    </Box>
  );
};
