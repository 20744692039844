import React from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputBase,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Search} from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[2],
    position: 'relative',
    borderRadius: 10,
    width: '100%',
    textAlign: 'left',
  },
  searchIcon: {
    position: 'absolute',
    right: 10,
    top: 2,
  },
  inputInput: {
    paddingLeft: 10,
  },
  filterSearch: {
    width: '95%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 5,
  },
}));

export interface SearchInputProps {
  placeholder?: string;
  name: string;
  value?: string;
  autoFocus?: boolean;
  onChangeValue?: (value: string) => void;
  onInputClick?: () => void;
  onSearchClick?: () => void;
}

export const SearchInput = ({
  placeholder,
  value,
  name,
  autoFocus,
  onChangeValue,
  onInputClick,
  onSearchClick,
}: SearchInputProps): JSX.Element => {
  const classes = useStyles();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.name) {
      onChangeValue && onChangeValue(e.target.value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick && onSearchClick();
    }
  };

  return (
    <ClickAwayListener onClickAway={() => null}>
      <ListItem>
        <Box className={classes.filterSearch}>
          <Box className={classes.search}>
            <InputBase
              autoFocus={autoFocus}
              onClick={onInputClick}
              placeholder={placeholder}
              sx={{
                '& .MuiInputBase-input': {
                  paddingLeft: '10px',
                },
              }}
              inputProps={{'aria-label': 'search'}}
              name={name}
              value={value}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              onKeyDown={(e) => {
                // Prevent MUI-Autoselect while typing
                e.stopPropagation();
              }}
            />
            <Box className={classes.searchIcon}>
              <IconButton
                edge="end"
                aria-label="edit"
                size="small"
                onClick={() => onSearchClick && onSearchClick()}>
                <Search />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
};
