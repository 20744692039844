import React, {useCallback, useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useAppDispatch} from '../../../../hooks/hooks';
import {useTranslation} from 'react-i18next';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {NotificationForm} from './NotificationForm';
import {SaveOrEditNotificationRequest} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated/api';
import moment from 'moment/moment';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  isBeforeVisible,
  roundTimeMinutes,
} from '../../utils/Utils';

export type NotificationFormType = Omit<
  SaveOrEditNotificationRequest,
  'id' | 'title' | 'titleEn'
> & {name?: string; nameEn?: string};

export interface Props {
  addNew?: boolean;
  obj?: SaveOrEditNotificationRequest | null;
  openDialog: boolean;
  loading: boolean;
  fromParentObj?: boolean;
  onCloseDialog: () => void;
  onAdd?: (notification: SaveOrEditNotificationRequest) => void;
  onUpdate?: (notification: SaveOrEditNotificationRequest) => void;
}

export const DialogNotification = ({
  addNew = true,
  obj,
  loading,
  openDialog,
  onCloseDialog,
  fromParentObj,
  onAdd,
  onUpdate,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {control, handleSubmit, reset, setValue, watch} =
    useForm<NotificationFormType>();

  const clearInputs = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (obj && openDialog) {
      setValue('name', obj?.title);
      setValue('nameEn', obj?.titleEn);
      setValue('body', obj?.body);
      setValue('bodyEn', obj?.bodyEn);
      setValue('uri', obj?.uri);
      setValue('visibleInApp', obj?.visibleInApp);
      setValue('showMessage', obj?.showMessage);
      setValue('confirmationRequired', obj?.confirmationRequired);
      setValue(
        'sendDate',
        obj.sendDate
          ? moment.utc(obj.sendDate).local().format(DefaultDateTimeFormatFE)
          : undefined
      );
      setValue('users', obj?.users);
    }
    if (openDialog && !obj) {
      clearInputs();
    }
  }, [clearInputs, dispatch, obj, openDialog, setValue]);

  const onErrorSubmit: SubmitErrorHandler<NotificationFormType> = (data) => {
    console.log('error');
    console.log(data);
  };

  const onValidSubmit: SubmitHandler<NotificationFormType> = (data) => {
    const {name, nameEn, body, bodyEn, users, ...dataObj} = data;
    const newNotification = {
      ...dataObj,
      title: name,
      titleEn: nameEn,
      users: users && users?.length > 0 ? users : undefined,
      body: data.showMessage ? (body ? body : undefined) : undefined,
      bodyEn: data.showMessage ? (bodyEn ? bodyEn : undefined) : undefined,
      sendDate:
        dataObj?.sendDate && dataObj.sendDate !== 'Invalid date'
          ? isBeforeVisible(
              dataObj.sendDate,
              moment().add(1, 'minutes').format(DefaultDateTimeFormatFE)
            )
            ? roundTimeMinutes(
                moment().add(1, 'minutes'),
                moment.duration(5, 'minutes')
              )
                .utc()
                .format(DefaultDateTimeFormatBE)
            : moment(dataObj.sendDate, DefaultDateTimeFormatFE)
                .utc()
                .format(DefaultDateTimeFormatBE)
          : undefined,
    };
    if (addNew) {
      onAdd && onAdd(newNotification);
    } else {
      onUpdate && onUpdate({...newNotification, id: obj?.id});
    }
  };

  const handleCloseDialog = () => {
    onCloseDialog();
    reset();
  };

  return (
    <Box>
      <DialogPrimary
        title={
          addNew
            ? t('notification.Add notification')
            : t('notification.Edit notification')
        }
        open={openDialog}
        loading={loading}
        minWidth={'900px'}
        contentLanguage={true}
        onCloseDialog={handleCloseDialog}>
        <NotificationForm
          addNew={addNew}
          watch={watch}
          control={control}
          setValue={setValue}
          fromParentObj={fromParentObj}
          onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}
        />
      </DialogPrimary>
    </Box>
  );
};
