import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getBaseObjList,
  getObj,
  InfiniteSearch,
  updateObj,
} from '@skczu/czu-frontend-library';
import {setLoading} from '../LoadingSlice';
import {getSimpleCategoryList} from './SimpleObjectListsSlice';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  CreateOrUpdateCategory,
  PoiCategory,
  PoiCategoryApi,
  PoiCategoryFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {setFilterCategoryId} from '../MapSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {getToken} from '../../../keycloak';

export interface PoiCategoriesState
  extends GenericState<PoiCategory, PoiCategory> {
  selectedEventsCategories: PoiCategory[];
}

const initialState: PoiCategoriesState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  filterDataList: [],
  selectedEventsCategories: [],
  filterObjSearch: {
    limit: 20,
    offset: 0,
  },
};

const poiCategoriesSlice = createGenericSlice({
  name: 'poiCategories',
  initialState,
})({
  setEventCategories: (state, {payload}: PayloadAction<PoiCategory[]>) => {
    state.selectedEventsCategories = payload;
  },
});

export const getBasePoiCategoryList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<PoiCategory, PoiCategoryFilterResponse>(
        getState().poiCategories.baseObjSearch,
        getState().poiCategories.baseObjList,
        async () =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryFilterGet(keyword, 9999, 0),
        (loading) => dispatch(poiCategoryActions.setObjLoadingList(loading)),
        () => dispatch(showErrorMessage()),
        (offset) => dispatch(poiCategoryActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(
              poiCategoryActions.setBaseObjList(newDataList ? newDataList : [])
            );
          } else {
            dispatch(poiCategoryActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(poiCategoryActions.hasMore(hasMore))
      )
    );
  };

export const getFilterPoiCategoryList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<PoiCategory, PoiCategoryFilterResponse>(
        getState().poiCategories.filterObjSearch as InfiniteSearch,
        getState().poiCategories.filterDataList as PoiCategory[],
        async () =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryFilterGet(
            newList
              ? (getState().poiCategories.filterObjSearch as InfiniteSearch)
                  .searchQuery
              : keyword,
            9999,
            0
          ),
        (loading) => dispatch(setLoading(loading)),
        () => dispatch(showErrorMessage()),
        () => void 0,
        (newDataList) => {
          const selectedCategoryIDs =
            getState().map.mapFilter.selectedCategoryId;
          const categoryId = newDataList?.some(
            (cat) => selectedCategoryIDs === cat.id
          );
          !categoryId && dispatch(setFilterCategoryId(''));
          dispatch(poiCategoryActions.setFilterDataList(newDataList));
        },
        keyword,
        () => true,
        newList
      )
    );
  };

export const getPoiCategory =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<PoiCategory>(
        id,
        async () =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryIdGet(id),
        (loading) => dispatch(poiCategoryActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(poiCategoryActions.setObj(obj));
        }
      )
    );
  };

export const deletePoiCategory =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryIdDelete(id),
        (loading) => dispatch(poiCategoryActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(poiCategoryActions.setObj(null));
          dispatch(poiCategoryActions.removeFromBaseObjList(id));
          dispatch(getSimpleCategoryList());
          dispatch(getFilterPoiCategoryList(true));
        }
      )
    );
  };

export const createPoiCategory =
  (category: CreateOrUpdateCategory): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      createObj<CreateOrUpdateCategory>(
        category,
        async (obj) =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryPost(obj),
        (loading) => dispatch(poiCategoryActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (id) => {
          const categoryIcon = getState().categoryIcons.objList.find(
            (icon) => icon.id === category.icon
          );
          dispatch(poiCategoryActions.setObj(null));
          categoryIcon &&
            dispatch(
              poiCategoryActions.addToBaseObjListAsFirst({
                ...category,
                id: id,
                icon: categoryIcon,
              } as CreateOrUpdateCategory)
            );
          dispatch(getSimpleCategoryList());
          dispatch(getFilterPoiCategoryList(true));
          dispatch(poiCategoryActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const updatePoiCategory =
  (category: CreateOrUpdateCategory): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      updateObj<CreateOrUpdateCategory>(
        category,
        async (obj) =>
          new PoiCategoryApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).poiCategoryPut(obj),
        (loading) => dispatch(poiCategoryActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          const categoryIcon = getState().categoryIcons.objList.find(
            (icon) => icon.id === category.icon
          );
          dispatch(poiCategoryActions.setObj(null));
          categoryIcon &&
            dispatch(
              poiCategoryActions.updateBaseObjInList({
                ...obj,
                icon: categoryIcon,
              } as CreateOrUpdateCategory)
            );
          dispatch(getSimpleCategoryList());
          dispatch(getFilterPoiCategoryList(true));
          dispatch(poiCategoryActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const getEventCategoriesList =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const selectedEventIds = getState().map.mapFilter.selectedGroups.map(
        (selectedEvent) => selectedEvent.id as string
      );
      const categoriesList = await new PoiCategoryApi(
        undefined,
        config?.cmsRestUrl
      ).eventCategoryGet(selectedEventIds, selectedEventIds.length === 0);
      const selectedCategoryId = getState().map.mapFilter.selectedCategoryId;
      categoriesList?.data.categories &&
        dispatch(
          poiCategoryActions.setEventCategories(categoriesList.data.categories)
        );
      if (selectedCategoryId) {
        const foundCategory = categoriesList.data.categories?.find(
          (cat) => cat.id === selectedCategoryId
        );
        !foundCategory && dispatch(setFilterCategoryId(''));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

export const poiCategoryActions = poiCategoriesSlice.actions;

export const selectPoiCategories = (state: RootState) => {
  const {
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingDetail,
    loadingList,
    hasMore,
  } = state.poiCategories;
  return {
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingDetail,
    loadingList,
    hasMore,
  };
};

export const selectSelectedEventsCategories = (
  state: RootState
): typeof state.poiCategories.selectedEventsCategories =>
  state.poiCategories.selectedEventsCategories;

export const selectPoiCategoryList = (
  state: RootState
): typeof state.poiCategories.baseObjList => state.poiCategories.baseObjList;

export default poiCategoriesSlice.reducer;
