import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ScreenTab, ScreenTabs} from '../ScreenTabs';
import {TableNotifications} from './table/TableNotifications';

export const TabsNotifications = (): JSX.Element => {
  const {t} = useTranslation();
  const tabs = useMemo(
    (): ScreenTab[] => [
      {
        key: 'notifications',
        tab: <TableNotifications />,
        name: t('notification.Notifications'),
      },
    ],
    [t]
  );

  return <ScreenTabs tabs={tabs} />;
};
