import Keycloak from 'keycloak-js';
import config from './config';

const keycloak = new Keycloak({
  url: config.keycloak.authUrl,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
});

export const getToken = async (
  minValidity = 10
): Promise<string | undefined> => {
  const refreshed = await keycloak.updateToken(minValidity);

  if (refreshed) {
    return keycloak.token;
  } else {
    return keycloak.token;
  }
};
export default keycloak;
