import {TableSortLabel, TextField, Tooltip} from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import cx from 'classnames';
import React, {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Cell,
  CellProps,
  ColumnInstance,
  FilterProps,
  Filters,
  HeaderGroup,
  HeaderProps,
  Hooks,
  IdType,
  Meta,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  // useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import {camelToWords} from './utils';
import {FilterChipBar} from './FilterChipBar';
import {fuzzyTextFilter, numericTextFilter, dateBetweenFilter} from './filters';
import {ResizeHandle} from './ResizeHandle';
import {TablePagination} from './TablePagination';
import {
  HeaderCheckbox,
  RowCheckbox,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableLabel,
  TableRow,
  TableStyles,
  TableTable,
} from './TableStyles';
import {TooltipCellRenderer} from './TooltipCell';
import {TableButtonContainer} from '../../scenes/admin-menu-scene/TableButtonContainer';
import {
  DialogItemButtonTypes,
  DialogItemType,
} from '../dialogs/dialog-item-select/DialogItemSelect';
import {TableToolbar} from './TableToolbar';
import {DialogLoading} from '../components/loading-indicator/DialogLoading';
import {TFunction} from 'react-i18next';

export interface TableProperties<T extends Record<string, unknown>>
  extends TableOptions<T> {
  name: string;
  t?: TFunction;
  multiselectButtonTypes?: DialogItemButtonTypes[];
  rowSelection?: boolean;
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>, cell: Cell<T>) => void;
  onFetchData?: (params: FilterParams<T>) => void;
  setMultiSelect?: (
    instance: TableInstance<T>,
    type: DialogItemType<T>
  ) => MouseEventHandler;
  totalCount?: number;
  tablePageIndex?: number;
  onChangePage?: (page: number) => void;
  loadingList?: boolean;
  tableState?: FilterParams<T>;
  setTableState?: (state: FilterParams<T>) => void;
}

export interface FilterParams<T extends Record<string, unknown>> {
  pageIndex: number;
  pageSize: number;
  sortBy: Array<SortingRule<T>>;
  filters: Filters<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultHeader: React.FC<HeaderProps<any>> = ({column}: any) => (
  <>{column.id.startsWith('_') ? null : camelToWords(column.id)}</>
);

// yes this is recursive, but the depth never exceeds three so it seems safe enough
const findFirstColumn = <T extends Record<string, unknown>>(
  columns: Array<ColumnInstance<T>>
): ColumnInstance<T> =>
  columns[0].columns ? findFirstColumn(columns[0].columns) : columns[0];

function DefaultColumnFilter<T extends Record<string, unknown>>({
  columns,
  column,
}: FilterProps<T>) {
  const {id, filterValue, setFilter, render} = column;
  const [value, setValue] = React.useState(filterValue || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || '');
  }, [filterValue]);

  const isFirstColumn = findFirstColumn(columns) === column;
  return (
    <TextField
      name={id}
      label={render('Header')}
      InputLabelProps={{htmlFor: id}}
      value={value}
      autoFocus={isFirstColumn}
      variant="standard"
      onChange={handleChange}
      onBlur={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,unused-imports/no-unused-vars
const getStyles = (props: any, disableResizing = false, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectionHook = (tableHooks: Hooks<any>) => {
  tableHooks.allColumns.push((columns) => [
    // Let's make a column for selection
    {
      id: '_selector',
      disableResizing: true,
      disableGroupBy: true,
      minWidth: 45,
      width: 45,
      maxWidth: 45,
      Aggregated: undefined,
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,react/display-name
      Header: ({getToggleAllRowsSelectedProps}: HeaderProps<any>) => (
        <HeaderCheckbox
          {...getToggleAllRowsSelectedProps()}
          color={'primary'}
        />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,react/display-name
      Cell: ({row}: CellProps<any>) => (
        <RowCheckbox {...row.getToggleRowSelectedProps()} color={'primary'} />
      ),
    },
    ...columns,
  ]);
  tableHooks.useInstanceBeforeDimensions.push(({headerGroups}) => {
    // fix the parent group of the selection button to not be resizable
    const selectionGroupHeader = headerGroups[0].headers[0];
    selectionGroupHeader.canResize = false;
  });
};

const headerProps = <T extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
  {column}: Meta<T, {column: HeaderGroup<T>}>
) => getStyles(props, column && column.disableResizing, column && column.align);

const cellProps = <T extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
  {cell}: Meta<T, {cell: Cell<T>}>
) =>
  getStyles(
    props,
    cell.column && cell.column.disableResizing,
    cell.column && cell.column.align
  );

const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: TooltipCellRenderer,
  Header: DefaultHeader,
  // When using the useFlexLayout:
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 150, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
};

const hooks = [
  useColumnOrder,
  useFilters,
  // useGroupBy,
  useSortBy,
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  // selectionHook,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterTypes: any = {
  fuzzyText: fuzzyTextFilter,
  numeric: numericTextFilter,
  dateRange: dateBetweenFilter,
};

export function Table<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>
): ReactElement {
  const {
    t,
    name,
    rowSelection,
    columns,
    onAdd,
    onDelete,
    onEdit,
    onClick,
    setMultiSelect,
    onFetchData,
    loadingList,
    tablePageIndex,
    onChangePage,
    tableState,
    setTableState,
    totalCount,
    multiselectButtonTypes,
  } = props;
  // const classes = useStyles();

  const plugins = rowSelection ? [selectionHook, ...hooks] : hooks;

  const instance = useTable<T>(
    {
      ...props,
      columns,
      filterTypes,
      defaultColumn,
      initialState: tableState,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex:
              tablePageIndex || tablePageIndex === 0
                ? tablePageIndex
                : state.pageIndex,
          }),
          [state]
        );
      },
    },
    ...plugins
  );

  const fetchTableData = useCallback(
    (filter: FilterParams<T>) => {
      onFetchData && onFetchData(filter);
    },
    [onFetchData]
  );

  useEffect(() => {
    fetchTableData &&
      fetchTableData({
        pageIndex: instance.state.pageIndex,
        pageSize: instance.state.pageSize,
        sortBy: instance.state.sortBy,
        filters: instance.state.filters,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    instance.state.pageIndex,
    instance.state.pageSize,
    instance.state.sortBy,
  ]);
  const [filteredParams, setFilteredParams] = useState<Filters<T>>();

  // useEffect(() => {
  //   instance.state.filters.length > 0 && instance.gotoPage(0);
  //   instance.state.filters.length > 0 && onChangePage && onChangePage(0);
  //   fetchTableData &&
  //     fetchTableData({
  //       pageIndex:
  //         instance.state.filters.length > 0 ? 0 : instance.state.pageIndex,
  //       pageSize: instance.state.pageSize,
  //       sortBy: instance.state.sortBy,
  //       filters: instance.state.filters,
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [instance.state.filters]);

  const {getTableProps, headerGroups, getTableBodyProps, page, prepareRow} =
    instance;

  const tableFilterHandler = () => {
    onChangePage && onChangePage(0);
    setTableState
      ? setTableState({
          pageIndex: 0,
          pageSize: instance.state.pageSize,
          sortBy: instance.state.sortBy,
          filters: instance.state.filters,
        })
      : setFilteredParams(instance.state.filters);
    fetchTableData &&
      fetchTableData({
        pageIndex: 0,
        pageSize: instance.state.pageSize,
        sortBy: instance.state.sortBy,
        filters: instance.state.filters,
      });
  };

  const removeFilterHandler = (id: IdType<T>) => {
    const filters = instance.state.filters.filter((filter) => filter.id !== id);
    setTableState
      ? setTableState({
          pageIndex: instance.state.pageIndex,
          pageSize: instance.state.pageSize,
          sortBy: instance.state.sortBy,
          filters: filters,
        })
      : setFilteredParams(filters);
    fetchTableData &&
      fetchTableData({
        pageIndex: instance.state.pageIndex,
        pageSize: instance.state.pageSize,
        sortBy: instance.state.sortBy,
        filters: filters,
      });
  };

  const cellClickHandler = (cell: Cell<T>) => () => {
    onClick &&
      !cell.column.isGrouped &&
      !cell.row.isGrouped &&
      cell.column.id !== '_selector' &&
      onClick(cell.row, cell);
  };

  const {...tableProps} = getTableProps();
  return (
    <>
      <TableButtonContainer
        multiselectButtonTypes={multiselectButtonTypes}
        instance={instance}
        setMultiSelect={setMultiSelect}
      />
      <DialogLoading showLoading={loadingList} />
      <TableToolbar
        t={t}
        instance={instance}
        onFilterData={tableFilterHandler}
        {...{onAdd, onDelete, onEdit}}
      />
      <FilterChipBar<T>
        instance={instance}
        filters={tableState ? tableState.filters : filteredParams}
        onRemoveFilter={removeFilterHandler}
      />
      <TableTable {...tableProps}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const {key: headerGroupKey, ...getHeaderGroupProps} =
              headerGroup.getHeaderGroupProps();
            return (
              <TableHeadRow key={headerGroupKey} {...getHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const style = {
                    textAlign: column.align ? column.align : 'left ',
                  } as CSSProperties;
                  const {key: headerKey, ...getHeaderProps} =
                    column.getHeaderProps(headerProps);
                  const {title: sortTitle = '', ...columnSortByProps} =
                    column.getSortByToggleProps();

                  return (
                    <TableHeadCell key={headerKey} {...getHeaderProps}>
                      {column.canGroupBy && (
                        <Tooltip title={sortTitle}>
                          <TableSortLabel
                            active
                            direction={column.isGrouped ? 'desc' : 'asc'}
                            IconComponent={KeyboardArrowRight}
                            sx={{...TableStyles.headerIcon}}
                          />
                        </Tooltip>
                      )}
                      {column.canSort ? (
                        <Tooltip title={sortTitle}>
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                            {...columnSortByProps}
                            sx={{...TableStyles.tableSortLabel}}
                            style={style}>
                            {column.render('Header')}
                            {/*</TableSortLabel>*/}
                            {/*{...columnSortByProps}*/}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        <TableLabel style={style}>
                          {column.render('Header')}
                        </TableLabel>
                      )}
                      {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                      {column.canResize && <ResizeHandle column={column} />}
                    </TableHeadCell>
                  );
                })}
              </TableHeadRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const {key: rowKey, ...getRowProps} = row.getRowProps();
            return (
              <TableRow
                key={rowKey}
                {...getRowProps}
                className={cx({
                  rowSelected: row.isSelected,
                  clickable: onClick,
                })}>
                {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  row.cells.map((cell: any) => {
                    const {key: cellKey, ...getCellProps} =
                      cell.getCellProps(cellProps);
                    return (
                      <TableCell
                        key={cellKey}
                        {...getCellProps}
                        onClick={cellClickHandler(cell)}>
                        {cell.isGrouped ? (
                          <>
                            <TableSortLabel
                              sx={{
                                '& .MuiTableSortLabel-iconDirectionAsc': {
                                  ...TableStyles.iconDirectionAsc,
                                },
                                '& .MuiTableSortLabel-iconDirectionDesc': {
                                  ...TableStyles.iconDirectionDesc,
                                },
                                ...TableStyles.cellIcon,
                              }}
                              active
                              direction={row.isExpanded ? 'desc' : 'asc'}
                              IconComponent={KeyboardArrowUp}
                              {...row.getToggleRowExpandedProps()}
                            />
                            {cell.render('Cell', {editable: false})} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell')
                        )}
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            );
          })}
        </TableBody>
      </TableTable>
      <TablePagination<T>
        instance={instance}
        totalCount={totalCount}
        onChangePage={onChangePage}
      />
    </>
  );
}
