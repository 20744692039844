import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {
  findFilterById,
  getDateRangeFilterString,
  useLocalStorage,
} from '../../../../shared/table/utils';
import {FilterParams, Table} from '../../../../shared/table';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {StylesAdminTab} from '../../StylesAdminTab';
import {DialogNotification} from '../../../../shared/dialogs/dialog-notification/DialogNotification';
import {
  createNewsNotification,
  createNotification,
  deleteMultipleNotifications,
  deleteNotifications,
  getBaseNotificationList,
  getNotification,
  getNotificationForMobilePreview,
  notificationActions,
  notificationSelector,
  updateNotification,
} from '../../../../../redux/reducers/data-reducers/NotificationSlice';
import {batch} from 'react-redux';
import moment from 'moment/moment';
import {htmlToPlainText} from '../../../../shared/utils/Utils';
import {useInfoDialog} from '../../../../../hooks/useInfoDialog';
import {DialogInfo} from '../../../../shared/dialogs/dialog-info/DialogInfo';
import {getTableNotificationColumns} from './TableNotificationsColumns';
import {NotificationFilterRequest} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated/api';
import {useNotificationNewsSelection} from './useNotificationNewsSelection';
import {NotificationActionCell} from './NotificationActionCell';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {
  DialogItemButtonTypes,
  DialogItemSelect,
  DialogItemType,
  getTitleFromType,
} from '../../../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {Cell, Row, TableInstance} from 'react-table';
import {InputModelValueType} from '../../../../../hooks/useForm';

export enum SortDirection {
  Desc = 'DESC',
  Asc = 'ASC',
}

export type AdminNotificationObj = {
  title?: string;
  uri?: string;
  visibleInApp?: string;
  confirmationRequired?: string;
  sendDate?: Date | null;
  scheduledSendDate?: Date | null;
  send?: string;
  miniBody?: string;
  action: string;
};

export type NotificationObj = AdminNotificationObj & {
  subRows?: AdminNotificationObj[];
};

export const TableNotifications = (): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const tabClasses = StylesAdminTab();
  const {obj, adminList, openObjDialog, addNewObj, loadingList, loadingDetail} =
    useAppSelector(notificationSelector);
  const [multiSelect, setMultiSelect] = React.useState<
    DialogItemType<AdminNotificationObj>
  >({
    openDialog: false,
  });

  const [tablePageIndex, setTablePageIndex] = useState<number>(0);

  const {newsNotificationSelection, handleGoBack, selectedNewsId} =
    useNotificationNewsSelection();

  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) =>
        id &&
        dispatch(
          deleteNotifications(Number(id), () => {
            dispatch(getBaseNotificationList(createRequest(initialState)));
          })
        ),
    });

  const [initialState, setInitialState] = useLocalStorage(
    'tableState:tableNotifications',
    {
      pageSize: 10,
      pageIndex: 0,
    }
  );

  const setState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      setInitialState(value);
    },
    [setInitialState]
  );

  const handleEditClick = useCallback(
    (id: number | string) => {
      dispatch(notificationActions.setEditObjDetail());
      dispatch(getNotification(id as number));
    },
    [dispatch]
  );

  const handlePreviewClick = useCallback(
    (id: number | string) => {
      id && dispatch(getNotificationForMobilePreview(id as number));
    },
    [dispatch]
  );

  const handleDeleteClick = useCallback(
    (id: number | string) => {
      showInfoDialog(id as string);
    },
    [showInfoDialog]
  );

  const createRequest = useCallback(
    (params: FilterParams<AdminNotificationObj>): NotificationFilterRequest => {
      const filteredName = findFilterById(params.filters, 'title')?.value;
      const filteredUriVal = findFilterById(params.filters, 'uri')?.value;
      const filteredSendDate = findFilterById(
        params.filters,
        'sendDate'
      )?.value;
      const filteredScheduledSendDate = findFilterById(
        params.filters,
        'scheduledSendDate'
      )?.value;
      const filteredVisibleInApp = findFilterById(
        params.filters,
        'visibleInApp'
      )?.value;
      const filteredReadRequest = findFilterById(
        params.filters,
        'confirmationRequired'
      )?.value;
      const filteredStatus = findFilterById(params.filters, 'send')?.value;

      const name = filteredName ? filteredName : undefined;
      const uri = filteredUriVal ? filteredUriVal : undefined;

      const betweenSendDates = filteredSendDate
        ? getDateRangeFilterString(
            filteredSendDate[0],
            filteredSendDate[1],
            true
          )
        : undefined;
      const betweenScheduledSendDates = filteredScheduledSendDate
        ? getDateRangeFilterString(
            filteredScheduledSendDate[0],
            filteredScheduledSendDate[1],
            true
          )
        : undefined;

      const visibleInApp = filteredVisibleInApp
        ? filteredVisibleInApp === 'notification.Visible'
        : undefined;
      const readRequest = filteredReadRequest
        ? filteredReadRequest === 'allObjects.Yes'
        : undefined;
      const send = filteredStatus
        ? filteredStatus === 'notification.Sent'
        : undefined;
      const sortBy =
        params?.sortBy && params.sortBy[0] && params.sortBy[0].id
          ? params.sortBy[0].id === 'scheduledSendDate'
            ? 'notificationScheduler.sendDate'
            : params.sortBy[0].id
          : undefined;
      const sortDirection =
        params?.sortBy && params.sortBy[0]
          ? params?.sortBy[0].desc
            ? SortDirection.Desc
            : SortDirection.Asc
          : undefined;
      return {
        name,
        uri,
        betweenSendDates,
        betweenScheduledSendDates,
        visibleInApp,
        send,
        sortBy,
        sortDirection,
        readRequest,
        limit: params.pageSize,
        offset: params.pageIndex * params.pageSize,
      };
    },
    []
  );

  const fetchData = useCallback(
    (params: FilterParams<AdminNotificationObj>) => {
      if (!loadingList) {
        setState(params);
        dispatch(getBaseNotificationList(createRequest(params)));
      }
    },
    [createRequest, dispatch, loadingList, setState]
  );

  const tableData = useMemo(() => {
    t('');
    return adminList.list.map((dataObj) => {
      const miniBody = CustomUtils.getContentByLanguage(
        dataObj.body,
        dataObj.bodyEn
      );
      return {
        ...dataObj,
        miniBody: miniBody ? htmlToPlainText(miniBody).substring(0, 99) : '-',
        title: CustomUtils.getContentByLanguage(dataObj.title, dataObj.titleEn),
        sendDate: dataObj.sendDate
          ? moment.utc(dataObj.sendDate).local().toDate()
          : null,
        scheduledSendDate: dataObj.scheduledSendDate
          ? moment.utc(dataObj.scheduledSendDate).local().toDate()
          : null,
        confirmationRequired: dataObj.confirmationRequired
          ? t(`allObjects.Yes`)
          : t(`allObjects.No`),
        visibleInApp: dataObj.visibleInApp
          ? t(`allObjects.Yes`)
          : t(`allObjects.No`),
        send: dataObj?.sent
          ? t(`notification.Sent`)
          : t(`notification.Not sent`),
        action: String(dataObj?.id),
      };
    });
  }, [adminList.list, t]);

  const handleMultiSelect = useCallback(
    (
        instance: TableInstance<AdminNotificationObj>,
        type: DialogItemType<AdminNotificationObj>
      ) =>
      () => {
        setMultiSelect({
          ...type,
          selectedRows: instance.selectedFlatRows.map((v) => v.original),
        });
      },
    []
  );

  const onAgreeMultiSelect = useCallback(
    (
      type: DialogItemButtonTypes,
      value: InputModelValueType,
      selectedRows: Array<AdminNotificationObj>
    ) => {
      switch (type) {
        case 'delete':
          dispatch(
            deleteMultipleNotifications(
              selectedRows.map((row) => Number(row.action)) as number[],
              () =>
                dispatch(getBaseNotificationList(createRequest(initialState)))
            )
          );
          break;
      }
      setMultiSelect({
        openDialog: false,
        id: undefined,
      });
    },
    [createRequest, dispatch, initialState]
  );

  const handleItemClick = useCallback(
    (row: Row<NotificationObj>, cell: Cell<NotificationObj>) => {
      const actionColumn = row.cells.find((cel) => cel.column.id === 'action');
      cell.column.id !== 'action' &&
        actionColumn?.value &&
        dispatch(getNotificationForMobilePreview(actionColumn.value));
    },
    [dispatch]
  );

  const renderTable = useMemo(() => {
    return (
      <Table<NotificationObj>
        t={t}
        name={'tableNotifications'}
        totalCount={adminList.totalCount}
        rowSelection
        setMultiSelect={handleMultiSelect}
        multiselectButtonTypes={['delete']}
        columns={getTableNotificationColumns({
          t,
          actionCell: (cell) => (
            <NotificationActionCell
              cell={cell}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              onPreviewClick={handlePreviewClick}
            />
          ),
        })}
        autoResetPage={false}
        tablePageIndex={tablePageIndex}
        pageCount={
          adminList?.totalCount ? Math.ceil(adminList.totalCount / 10) : 0
        }
        onChangePage={(page) => setTablePageIndex(page)}
        tableState={initialState}
        setTableState={setState}
        data={tableData}
        onClick={handleItemClick}
        onFetchData={fetchData}
        loadingList={loadingList}
        manualFilters
        manualSortBy
        manualPagination
      />
    );
  }, [
    t,
    adminList.totalCount,
    tablePageIndex,
    initialState,
    tableData,
    handleMultiSelect,
    handleItemClick,
    fetchData,
    loadingList,
    handleEditClick,
    handleDeleteClick,
    handlePreviewClick,
    setState,
  ]);

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('notification.Add notification')}
          onClick={() => {
            dispatch(notificationActions.setObj(null));
            dispatch(notificationActions.setAddNewObjDetail());
          }}
        />
      </Box>
      {renderTable}
      <DialogNotification
        openDialog={openObjDialog}
        obj={obj}
        fromParentObj={newsNotificationSelection}
        loading={loadingDetail}
        addNew={addNewObj}
        onCloseDialog={() => {
          batch(() => {
            dispatch(notificationActions.setObj(null));
            dispatch(notificationActions.setOpenObjDialog(false));
            newsNotificationSelection && handleGoBack(true);
          });
        }}
        onAdd={(notification) =>
          newsNotificationSelection
            ? selectedNewsId &&
              dispatch(
                createNewsNotification(selectedNewsId, notification, () => {
                  handleGoBack(true);
                  dispatch(
                    getBaseNotificationList(createRequest(initialState))
                  );
                })
              )
            : dispatch(
                createNotification(notification, () => {
                  dispatch(
                    getBaseNotificationList(createRequest(initialState))
                  );
                })
              )
        }
        onUpdate={(notification) =>
          obj?.id &&
          dispatch(
            updateNotification(obj.id, notification, () => {
              newsNotificationSelection && handleGoBack(true);
              dispatch(getBaseNotificationList(createRequest(initialState)));
            })
          )
        }
      />
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.notification')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.notification'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogItemSelect
        title={t(`user.${getTitleFromType(multiSelect.type)}`)}
        text={
          t(`user.${getTitleFromType(multiSelect.type)}`) +
          ', ' +
          t('notification.notification') +
          ': ' +
          (multiSelect?.selectedRows?.length || '0')
        }
        id={multiSelect.id}
        value={multiSelect.value}
        openDialog={multiSelect.openDialog}
        type={multiSelect.type}
        selectedRows={multiSelect.selectedRows}
        onCloseDialog={() =>
          setMultiSelect({
            openDialog: false,
            id: undefined,
          })
        }
        onChangeValue={(event, selectedRows) => {
          setMultiSelect((select) => {
            return {...select, value: event, selectedRows};
          });
        }}
        onAgree={(value, selectedRows) =>
          multiSelect.type &&
          onAgreeMultiSelect(multiSelect.type, value, selectedRows)
        }
      />
    </Box>
  );
};
