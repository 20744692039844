import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Language} from '@mui/icons-material';
import {ContentLanguage} from '../../../redux/reducers/ContentLanguage';

export interface Props {
  onChangeLanguage?: (language: ContentLanguage) => void;
  selectedLanguage?: ContentLanguage;
  blackBackground?: boolean;
}

export const TextLanguage = ({
  onChangeLanguage,
  selectedLanguage,
  blackBackground,
}: Props): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    buttonContainer: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: 14,
    },
    button: {
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: 20,
      marginRight: 4,
      marginLeft: 4,
      color: blackBackground ? '#b3b3b3' : theme.palette.secondary.light,
    },
    languageActive: {
      color: blackBackground
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    icon: {
      color: blackBackground
        ? theme.palette.secondary.main
        : theme.palette.secondary.contrastText,
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.buttonContainer}>
      <Language className={classes.icon} />
      <Box
        className={
          classes.button +
          ' ' +
          (selectedLanguage === ContentLanguage.CZ
            ? classes.languageActive
            : ' ')
        }
        onClick={() =>
          onChangeLanguage && onChangeLanguage(ContentLanguage.CZ)
        }>
        CZ
      </Box>
      <Box
        className={
          classes.button +
          ' ' +
          (selectedLanguage === ContentLanguage.EN
            ? classes.languageActive
            : ' ')
        }
        onClick={() =>
          onChangeLanguage && onChangeLanguage(ContentLanguage.EN)
        }>
        EN
      </Box>
    </Box>
  );
};
