import {useCallback, useEffect, useState} from 'react';
import {
  Cartesian3,
  CustomDataSource,
  Rectangle,
  Viewer as CesiumViewer,
} from 'cesium';
import {Coordinates} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Route} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GraphHopperRouting from 'graphhopper-js-api-client/src/GraphHopperRouting';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GHInput from 'graphhopper-js-api-client/src/GHInput';
import config from '../../../../config';
import {MapUtils} from '@skczu/czu-frontend-library';
import {
  selectShowMap3dObjects,
  setMarkersList,
} from '../../../../redux/reducers/MapSlice';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  addToPoiSelection,
  selectSelectedPoi,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {selectObjectPreview} from '../../../../redux/reducers/DrawerSlice';
import {Poi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {selectSelectedRoute} from '../../../../redux/reducers/RouteListSelectionSlice';
import {useHistory} from 'react-router-dom';
import {
  FlyToPointEvent,
  useMapEntitiesController,
} from './useMapEntitiesController';

interface MapRoutePoiPreview {
  handleSelectPoi: (id: string) => void;
  flyToPoint: FlyToPointEvent;
}

export const useMapRoutePoiPreview = (
  viewer: CesiumViewer | null
): MapRoutePoiPreview => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {objectPreviewView, objectPreview} =
    useAppSelector(selectObjectPreview);
  const show3dObjects = useAppSelector(selectShowMap3dObjects);
  const poiSelection = useAppSelector(selectSelectedPoi);
  const routeSelection = useAppSelector(selectSelectedRoute);
  const {
    removeSelectedPoi,
    removeRoute,
    highlightSelectPoi,
    addNewRoute,
    flyToPoint,
  } = useMapEntitiesController(viewer, show3dObjects);

  const navigateTo = useCallback((path) => history.push(path), [history]);
  const [routeEntities, setRouteEntities] = useState<CustomDataSource | null>(
    null
  );

  const getRouteReq = useCallback(
    (points: Coordinates[], route: Route) => {
      if (points && points.length > 0) {
        const ghRouting = new GraphHopperRouting({
          vehicle: 'foot',
          locale: 'cs',
          geometries: 'geometries',
          elevation: false,
          host: config.navigationServiceUrl,
          'ch.disable': true,
        });
        points.forEach((point) => {
          ghRouting.addPoint(new GHInput(point.latitude, point.longitude));
        });
        ghRouting
          .doRequest()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            const bbox = response.paths[0].bbox;
            bbox &&
              viewer?.camera.flyTo({
                destination: Rectangle.fromDegrees(
                  bbox[0] - 0.0022,
                  bbox[1] - 0.0003,
                  bbox[2] + 0.0011,
                  bbox[3] + 0.0003
                ),
              });
            if (response.paths[0].points.coordinates && viewer) {
              const coordinates = response.paths[0].points.coordinates;
              const polylineToDisplay =
                MapUtils.flatenArrayOfArray(coordinates);
              const polylinePoints = Cartesian3.fromDegreesArray(
                polylineToDisplay as number[]
              );
              polylinePoints &&
                addNewRoute(polylinePoints, (routeDataSource) =>
                  setRouteEntities(routeDataSource)
                );
              route?.pois &&
                dispatch(
                  setMarkersList(MapUtils.createMarkersFromRoute(route))
                );

              viewer?.scene.requestRender();
            }
          })
          .catch((ex: Error) => {
            // console.log('ghRouting');
            // console.log(ex);
          })
          .finally(() => {
            // console.log('route set');
          });
      }
    },
    [addNewRoute, dispatch, viewer]
  );

  useEffect(() => {
    if (
      (objectPreviewView.openObjectPreview &&
        poiSelection.poiSelectionFrom === 'route' &&
        objectPreview.objectMobileScene === 'route') ||
      poiSelection.poiSelectionFrom === 'route'
    ) {
      const coordsList: Coordinates[] = [];
      poiSelection.poiList?.forEach((poi) => {
        poi.coordinates && coordsList.push(poi.coordinates);
      });
      if (coordsList.length === 1) {
        viewer &&
          viewer.camera.flyTo({
            destination: Cartesian3.fromDegrees(
              Number(coordsList[0].longitude),
              Number(coordsList[0].latitude),
              1000.0
            ),
          });
        removeRoute();
      } else {
        coordsList && coordsList.length > 0
          ? getRouteReq(coordsList, objectPreview.objectPreviewData as Route)
          : removeRoute();
      }
    } else if (
      (!objectPreviewView.openObjectPreview &&
        !poiSelection.poiSelectionFrom &&
        !objectPreview.objectPreviewData) ||
      (objectPreviewView.openObjectPreview &&
        objectPreview.objectMobileScene !== 'route')
    ) {
      removeRoute();
    }
  }, [
    getRouteReq,
    objectPreview.objectMobileScene,
    objectPreview.objectPreviewData,
    objectPreviewView.openObjectPreview,
    poiSelection.poiList,
    poiSelection.poiSelectionFrom,
    removeRoute,
    viewer,
  ]);

  const handleSelectPoi = useCallback(
    (id: string) => {
      if (!routeSelection.routeSelection) {
        if (poiSelection.poiSelection) {
          if (
            objectPreviewView.openObjectPreview &&
            objectPreview.objectMobileScene === 'route'
          ) {
            removeRoute();
          } else {
            dispatch(
              addToPoiSelection(id, poiSelection.poiSelectionFrom !== 'poi360')
            );
          }
        } else {
          navigateTo(`/map/poi/poiList/${id}`);
        }
      }
    },
    [
      dispatch,
      navigateTo,
      objectPreview.objectMobileScene,
      objectPreviewView.openObjectPreview,
      poiSelection.poiSelection,
      poiSelection.poiSelectionFrom,
      removeRoute,
      routeSelection.routeSelection,
    ]
  );

  useEffect(() => {
    if (viewer && routeEntities) {
      const source = viewer.dataSources.getByName('route');
      source[0] && viewer.dataSources.remove(source[0]);
      viewer.dataSources
        .add(routeEntities)
        .then(() => viewer.scene.requestRender());
    }
  }, [routeEntities, viewer]);

  useEffect(() => {
    if (
      objectPreviewView.openObjectPreview &&
      viewer &&
      objectPreview.objectMobileScene === 'poi' &&
      (objectPreview.objectPreviewData as Poi).coordinates?.longitude &&
      (objectPreview.objectPreviewData as Poi).coordinates?.latitude
    ) {
      const lon = Number(
        (objectPreview.objectPreviewData as Poi).coordinates?.longitude
      );
      const lat = Number(
        (objectPreview.objectPreviewData as Poi).coordinates?.latitude
      );
      lon && lat && highlightSelectPoi(Number(lon), Number(lat));
    } else {
      removeSelectedPoi();
    }
  }, [
    highlightSelectPoi,
    objectPreviewView.openObjectPreview,
    objectPreview.objectMobileScene,
    objectPreview.objectPreviewData,
    removeSelectedPoi,
    viewer,
  ]);

  return {
    handleSelectPoi,
    flyToPoint,
  };
};
