import React, {useCallback, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {SignIn} from './components/scenes/sing-in-scene/SingIn';
import {useAppDispatch, useAppSelector} from './hooks/hooks';
import {loadingSelector} from './redux/reducers/LoadingSlice';
import './localization/i18n';
import {useKeycloak} from '@react-keycloak-fork/web';
import {PrivateRoute} from './route/PrivateRoute';
import {MapPage} from './components/scenes/map-scene/MapPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {errorSelector, closeError} from './redux/reducers/ErrorSlice';
import {ErrorNotification} from './components/shared/components/error-notification/ErrorNotification';
import {MapLoadingIndicator, AppVersion} from '@skczu/czu-react-components';
import config from './config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationCS from 'moment/locale/cs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizationEN from 'moment/locale/en-gb';
import moment from 'moment/moment';
import 'react-quill/dist/quill.snow.css';
import {
  createTheme,
  responsiveFontSizes,
  Theme,
  ThemeProvider,
} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {appLanguageSelector} from './redux/reducers/DrawerSlice';
moment.locale();

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
export const SecondaryLightHover = '#cccccc';
export let theme = createTheme({
  palette: {
    primary: {
      light: '#dafdcc',
      main: '#73be46',
      dark: '#303030',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#e0e0e0',
      main: '#ffffff',
      dark: '#303030',
      contrastText: '#000',
    },
  },
});
theme = responsiveFontSizes(theme);

export const App = (): JSX.Element => {
  const {isLoading, loadingCounter} = useAppSelector(loadingSelector);
  const {initialized, keycloak} = useKeycloak();
  const {showError, errorMessage} = useAppSelector(errorSelector);
  const appLanguage = useAppSelector(appLanguageSelector);
  const dispatch = useAppDispatch();

  const handleTokenExpiration = useCallback(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }
    return () => {
      if (keycloak) {
        keycloak.onTokenExpired = () => {
          return;
        };
      }
    };
  }, [initialized, keycloak]);

  useEffect(() => {
    handleTokenExpiration();
  }, [handleTokenExpiration, initialized, keycloak]);

  useEffect(() => {
    appLanguage === 'cs'
      ? moment.locale('cs', localizationCS)
      : moment.locale('en-gb', localizationEN);
  }, [appLanguage]);

  return initialized ? (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={appLanguage === 'cs' ? 'cs' : 'en-gb'}>
        {/*adapterLocale={'cs'}>*/}
        <MapLoadingIndicator showLoading={isLoading || loadingCounter > 0} />
        <ErrorNotification
          showError={showError}
          errorMessage={errorMessage}
          onClose={() => dispatch(closeError())}
        />
        <Router>
          <Switch>
            <Route exact path="/login">
              <SignIn />
            </Route>
            <PrivateRoute
              path={[
                '/map',
                '/users',
                '/news',
                '/settings',
                '/events',
                '/notifications',
              ]}
              component={MapPage}
            />
          </Switch>
        </Router>
        {config?.environmentName !== 'Prod' && (
          <AppVersion
            appVersion={config?.appVersion}
            envName={config?.environmentName}
          />
        )}
      </LocalizationProvider>
    </ThemeProvider>
  ) : (
    <div>Loading...</div>
  );
};

export default App;
