import React from 'react';
import {UploadFile} from './useUploadPhoto';
import {CreateVideoResponse} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {Video} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import config from '../config';
import {
  Configuration,
  VideoApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {getToken} from '../keycloak';

export interface UploadVideoHook {
  videoUploadProgress: UploadFile;
  addGalleryVideo: (video: Video) => void;
}
export const useUploadVideo = (
  onVideoSet: (newVideo: CreateVideoResponse) => void
): UploadVideoHook => {
  const [videoUploadProgress, setVideoUploadProgress] =
    React.useState<UploadFile>({progress: 0, state: 'new'});

  const onUploadProgress = (data: ProgressEvent) => {
    const percentCompleted = Math.round((data.loaded * 100) / data.total) - 1;
    setVideoUploadProgress({
      progress: percentCompleted,
      state: 'upload',
    });
  };

  const addGalleryVideo = async (video: Video) => {
    try {
      const newVideo = await new VideoApi(
        new Configuration({accessToken: await getToken(9999999)}),
        config.cmsRestUrl
      ).videoPost(
        {...video, objectType: 'Video'},
        {
          onUploadProgress: onUploadProgress,
        }
      );
      if (newVideo?.data) {
        onVideoSet(newVideo.data);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setVideoUploadProgress({
        progress: videoUploadProgress.progress,
        state: 'new',
      });
    }
  };

  return {videoUploadProgress, addGalleryVideo};
};
