import React, {forwardRef, useEffect} from 'react';
import {Box} from '@mui/material';
import {SelectMultiplePrimary} from '../SelectMultiplePrimary';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {MultipleSelect} from './SelectCategories';
import {useTranslation} from 'react-i18next';
import {
  getSimpleLayerList,
  selectSimpleLayerList,
} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';

export const SelectLayers = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      disabled,
      onBlur,
      errorType,
      selectAll = true,
      selectAny = true,
    }: MultipleSelect,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleLayerList = useAppSelector(selectSimpleLayerList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getSimpleLayerList());
    }, [dispatch]);

    return (
      <Box>
        <SelectMultiplePrimary
          boldLabel
          id={id}
          name={name}
          ref={ref}
          selectedValue={
            t('objectInputs.Layers') +
            (value
              ? ` (${value.length === 0 ? t('allObjects.None') : value.length})`
              : '')
          }
          disabled={disabled}
          label={label}
          className={className}
          value={value}
          errorType={errorType}
          onBlur={onBlur}
          onChange={onChange}
          list={simpleLayerList}
          selectAll={selectAll}
          selectAny={selectAny}
        />
      </Box>
    );
  }
);
SelectLayers.displayName = 'SelectLayers';

export default SelectLayers;
