import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './store';
import {ReactKeycloakProvider} from '@react-keycloak-fork/web';
import keycloak from './keycloak';
import config from './config';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {BrowserView, MobileView} from 'react-device-detect';

ReactDOM.render(
  <React.StrictMode>
    <BrowserView>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          checkLoginIframe: false,
          redirectUri: config.adminMapUrl,
        }}>
        <Provider store={store}>
          <App />
        </Provider>
      </ReactKeycloakProvider>
    </BrowserView>
    <MobileView>
      <Router>
        <Switch>
          <Route
            component={() => {
              window.location.href =
                `https://${config.environmentName !== 'Prod' ? 'my.' : ''}` +
                config.environmentUrl +
                '/';
              return null;
            }}
          />
        </Switch>
      </Router>
    </MobileView>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
