import React from 'react';
import {Button, ClickAwayListener, Tooltip} from '@mui/material';
import {ContentCopy} from '@mui/icons-material';
import {ButtonPrimaryStyle} from '../buttons/ButtonPrimary';

export interface CopyToClipboardButtonProps {
  text: string;
  toolbarTitle: string;
  name: string;
}

export const CopyToClipboardButton = ({
  text,
  toolbarTitle,
  name,
}: CopyToClipboardButtonProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), 3500);
    navigator.clipboard.writeText(text);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            style: {marginTop: -16, marginLeft: 50},
          }}
          onClose={handleTooltipClose}
          open={open}
          placement={'bottom-start'}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={toolbarTitle}>
          <Button
            variant={'contained'}
            startIcon={<ContentCopy />}
            // classes={{iconSizeMedium: classes.myIconSizeMedium}}
            onClick={handleTooltipOpen}
            sx={{
              margin: 0,
              padding: '2px 16px 2px 16px',
              fontSize: 'smaller',
              textTransform: 'none',
              '& .MuiButton-iconSizeMedium': {
                '& > *:first-child': {
                  fontSize: 'small',
                },
              },
              ...ButtonPrimaryStyle,
            }}>
            {name}
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
