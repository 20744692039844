import {Filters, FilterValue, SortingRule} from 'react-table';
import {SortDirection} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import moment from 'moment/moment';
import {DefaultDateTimeFormatFE} from '../../utils/Utils';

export const findFilterById = <D extends {id: string}>(
  filters: Filters<D>,
  filterId: string
): {id: string; value: FilterValue} | undefined => {
  return filters?.find((param) => param.id === filterId);
};

export const getSortDirection = <D>(
  sortBy: Array<SortingRule<D>>
): {[key: string]: SortDirection} => {
  return sortBy?.reduce(
    (o, key) =>
      Object.assign(o, {
        [key.id]: key.desc ? SortDirection.Desc : SortDirection.Asc,
      }),
    {}
  );
};

export const getDateRangeFilterString = (
  from?: string,
  to?: string,
  asUtc?: boolean
): string => {
  const dateFormatted = (date: string): string => {
    return asUtc
      ? moment(date, DefaultDateTimeFormatFE)
          .utc()
          .format(DefaultDateTimeFormatFE)
      : moment(date, DefaultDateTimeFormatFE).format(DefaultDateTimeFormatFE);
  };
  return `${from ? dateFormatted(from) : ''};${to ? dateFormatted(to) : ''}`;
};
