import React, {useCallback, useEffect, useMemo, useState} from 'react';
// import Drawer from '@mui/material/Drawer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/icons-material/Menu';
import {RoutesPoiTab} from '../menu-items/poi/RoutesPoiTab';
import {
  useHistory,
  useRouteMatch,
  Route as RouteDom,
  Switch,
} from 'react-router-dom';
import {RoutesRouteTab} from '../menu-items/routes/RoutesRouteTab';
import {Box, Button, ListItem, ListItemButton} from '@mui/material';
import {RoutesObjectTab} from '../menu-items/objects/RoutesObjectTab';
import {RoutesLayerTab} from '../menu-items/layers/RoutesLayerTab';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  selectDrawer,
  setOpenDrawer,
  setOpenDrawerMenu,
  clearObjectPreview,
  setLanguage,
} from '../../../../../redux/reducers/DrawerSlice';
import {setPoiSelection} from '../../../../../redux/reducers/PoiListSelectionSlice';
import {useTranslation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak-fork/web';
import {MenuAdmin} from '../../../admin-menu-scene/MenuAdmin';
import {
  FiberNew,
  FormatShapes,
  Layers,
  Place,
  SwapHoriz,
  Group as GroupIcon,
  Event,
  Settings,
  NotificationsNone,
} from '@mui/icons-material';
import {showImagePreview} from '../../../../../redux/reducers/ImagePreview';
import {
  AboutApp,
  AppLanguage,
  MobileScreenPreview,
  DrawerMenu,
  TooltipIconButton,
} from '@skczu/czu-react-components';
import {
  ImagePreviewImage,
  Language,
  PoiWithFoodMenu,
} from '@skczu/czu-frontend-library';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import i18n from 'i18next';
import config from '../../../../../config';
import {
  foodMenuSelector,
  getAllergens,
  getRestaurantDailyMenu,
} from '../../../../../redux/reducers/data-reducers/FoodMenuSlice';
import moment from 'moment/moment';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

export const DrawerMenuWidth = 350;
export const DrawerWidth = 164;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  width: DrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface MenuItem {
  name: string;
  icon: JSX.Element;
}

export const MenuItemsArr: MenuItem[] = [
  {name: 'menu', icon: <Menu />},
  {name: 'poi', icon: <Place />},
  {name: 'objects', icon: <FormatShapes />},
  {name: 'routes', icon: <SwapHoriz />},
  {name: 'layers', icon: <Layers />},
  {name: 'events', icon: <Event />},
  {name: 'news', icon: <FiberNew />},
  {name: 'notifications', icon: <NotificationsNone />},
  {name: 'users', icon: <GroupIcon />},
  {name: 'settings', icon: <Settings />},
];

export const LeftDrawer = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const [selectedItem, setSelectedItem] = useState<string | ''>('');
  const dispatch = useAppDispatch();
  const {objectPreview, objectPreviewView, openDrawer, openDrawerMenu} =
    useAppSelector(selectDrawer);
  const {keycloak} = useKeycloak();
  const {allergens} = useAppSelector(foodMenuSelector);
  const appTheme = useTheme();
  const styles = {
    root: {display: 'flex'},
    drawer: {
      width: DrawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
    },
    aboutApp: {
      alignSelf: 'flex-end',
    },
    drawerFoot: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      width: '100%',
    },
    loginContainer: {
      position: 'absolute',
      bottom: 2,
      alignSelf: 'flex-end',
    },
    drawerDisabled: {
      background: appTheme.palette.grey.A200,
    },
    drawerDisabledColor: {
      color: appTheme.palette.grey.A100,
    },
    drawerEnabledColor: {
      color: appTheme.palette.secondary.main,
      marginLeft: '-12px',
    },
    drawerEnabled: {
      background: appTheme.palette.primary.dark,
    },
    drawerOpen: {
      background: appTheme.palette.primary.dark,
    },
    itemText: {
      backgroundColor: appTheme.palette.secondary.light + '!important',
    },
    objectPreviewOpen: {
      position: 'absolute',
      left: DrawerMenuWidth + DrawerWidth,
      top: '12.5%',
      transition: appTheme.transitions.create('width', {
        easing: appTheme.transitions.easing.sharp,
        duration: appTheme.transitions.duration.enteringScreen,
      }),
      backgroundColor: appTheme.palette.secondary.main,
      zIndex: 10,
    },
    objectPreviewClose: {
      transition: appTheme.transitions.create('width', {
        easing: appTheme.transitions.easing.sharp,
        duration: appTheme.transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    menuAdmin: {
      width: '100%',
    },
  };
  const handleNavigation = useCallback(
    (newPath: string) => history.replace(`${newPath}`),
    [history]
  );
  const isAdminPath = (urlPath: string): boolean =>
    urlPath === 'news' ||
    urlPath === 'users' ||
    urlPath === 'settings' ||
    urlPath === 'notifications' ||
    urlPath === 'events';

  const handleMapNavigation = useCallback(
    (item) => {
      const urlPath = url.substring(1);
      if (isAdminPath(urlPath)) {
        history.push(`map/${item}`);
      } else {
        history.push(`${url}/${item}`);
      }
    },
    [history, url]
  );

  const handleRootNavigation = useCallback(
    (item) => history.push(`/${item}`),
    [history]
  );

  useEffect(() => {
    if (history.location.pathname) {
      const routeName = history.location.pathname.toLowerCase().split('/');
      let lastRouteName = routeName[routeName.length - 1];
      if (
        lastRouteName !== 'map' &&
        MenuItemsArr.some((item) => item.name === lastRouteName)
      ) {
        setSelectedItem(lastRouteName);
        dispatch(setOpenDrawer(true));
        dispatch(setOpenDrawerMenu(true));
      } else if (
        routeName[routeName.length - 2] !== 'map' &&
        MenuItemsArr.some(
          (item) => item.name === routeName[routeName.length - 2]
        )
      ) {
        setSelectedItem(routeName[routeName.length - 2]);
        dispatch(setOpenDrawer(true));
        dispatch(setOpenDrawerMenu(true));
      } else {
        lastRouteName = routeName[routeName.length - 3];
        if (
          lastRouteName !== 'map' &&
          MenuItemsArr.some((item) => item.name === lastRouteName)
        ) {
          setSelectedItem(lastRouteName);
          dispatch(setOpenDrawer(true));
          dispatch(setOpenDrawerMenu(true));
        }
      }
      if (!lastRouteName) {
        setSelectedItem('');
        dispatch(setOpenDrawer(false));
        dispatch(setOpenDrawerMenu(false));
      }
    }
  }, [dispatch, history.location.pathname]);

  const handleDrawerOpen = (item: MenuItem) => {
    if (item.name === selectedItem) {
      handleDrawerClose();
    } else {
      dispatch(setOpenDrawerMenu(item.name !== 'menu'));
      dispatch(setOpenDrawer(true));
      setSelectedItem(item.name);
      isAdminPath(item.name)
        ? handleRootNavigation(item.name)
        : item.name !== 'menu' && handleMapNavigation(item.name);
    }
    dispatch(setPoiSelection({poiSelection: false}));
    dispatch(clearObjectPreview());
  };

  const handleDrawerClose = () => {
    dispatch(setOpenDrawer(false));
    setSelectedItem('');
    dispatch(setOpenDrawerMenu(false));
  };

  const handleMobilePreviewClickBack = useCallback(() => {
    handleNavigation(
      history.location.pathname.substring(
        0,
        history.location.pathname.lastIndexOf('/')
      )
    );
    dispatch(clearObjectPreview());
    dispatch(setPoiSelection({poiSelection: false}));
  }, [dispatch, handleNavigation, history.location.pathname]);

  const selectImagePreview = useCallback(
    (selectedImage: ImagePreviewImage, images: ImagePreviewImage[]) => {
      dispatch(showImagePreview({selectedImage, images}));
    },
    [dispatch]
  );

  const handleChangeLanguage = async (language: Language) => {
    dispatch(setLanguage(language));
    await i18n.changeLanguage(language);
  };

  useEffect(() => {
    dispatch(getAllergens());
  }, [dispatch]);

  const onDailyMenuDateChanged = useCallback(
    (date: string, newWeek: boolean) => {
      const foodPointId = (objectPreview.objectPreviewData as PoiWithFoodMenu)
        .foodPointId;
      foodPointId &&
        dispatch(
          getRestaurantDailyMenu(
            foodPointId,
            moment(date).format('YYYY-MM-DD'),
            newWeek,
            true
          )
        );
    },
    [dispatch, objectPreview.objectPreviewData]
  );

  const renderMobileScreenPreview = useMemo(() => {
    return (
      <Box
        sx={
          objectPreviewView.openObjectPreview
            ? styles.objectPreviewOpen
            : styles.objectPreviewClose
        }>
        {objectPreviewView.openObjectPreview &&
          objectPreview.objectPreviewData && (
            <MobileScreenPreview
              t={t}
              objectMobileScene={objectPreview.objectMobileScene}
              getObjByLanguage={CustomUtils.getObjByLanguage}
              objectPreviewData={CustomUtils.getObjByLanguage(
                objectPreview.objectPreviewData
              )}
              allergens={allergens}
              onClickBack={handleMobilePreviewClickBack}
              imagePreview={selectImagePreview}
              onDailyMenuDateChanged={onDailyMenuDateChanged}
            />
          )}
      </Box>
    );
  }, [
    objectPreviewView.openObjectPreview,
    styles.objectPreviewOpen,
    styles.objectPreviewClose,
    objectPreview.objectPreviewData,
    objectPreview.objectMobileScene,
    t,
    allergens,
    handleMobilePreviewClickBack,
    selectImagePreview,
    onDailyMenuDateChanged,
  ]);

  return (
    <Box sx={styles.root}>
      <Drawer
        open={openDrawer}
        variant="permanent"
        PaperProps={{
          sx: styles.drawerOpen,
        }}>
        <List>
          {MenuItemsArr.map((item, index) => (
            <ListItem
              key={item.name + '_' + index}
              disablePadding
              sx={{display: 'block'}}>
              <ListItemButton
                key={item.name}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? 'initial' : 'center',
                  px: 2.5,
                }}
                selected={selectedItem === item.name}
                // classes={{selected: styles.itemText}}
                onClick={() => handleDrawerOpen(item)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 1 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <TooltipIconButton
                    instance={item}
                    icon={item.icon}
                    label={t(`leftMenu.${item.name}`)}
                  />
                </ListItemIcon>

                <ListItemText
                  // sx={styles.drawerEnabledColor}
                  sx={{
                    opacity: openDrawer ? 1 : 0,
                    color: 'secondary.main',
                  }}
                  primary={t(`leftMenu.${item.name}`)}
                />
              </ListItemButton>
              <Divider />
            </ListItem>
          ))}
        </List>
        <Box sx={styles.drawerFoot}>
          <Box sx={styles.aboutApp}>
            <AppLanguage
              shown={openDrawer}
              onChange={handleChangeLanguage}
              language={i18n.language as Language}
            />
          </Box>
          {openDrawer && (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                keycloak?.logout();
              }}>
              {t('leftMenu.Log out')}
            </Button>
          )}
          <AboutApp
            label={t('allObjects.About app')}
            appName={'My ČZU'}
            open={openDrawer}
            appVersion={config?.appVersion}
            envName={
              config?.environmentName !== 'Prod' ? config?.environmentName : ''
            }
            appLogo={'/assets/logo/CZULogoPNG.png'}
            appDialogLogo={'/assets/logo/CZULogoColorPNG.png'}
          />
        </Box>
      </Drawer>
      <Switch>
        <RouteDom
          path={['/users', '/news', '/settings', '/events', '/notifications']}>
          <Box sx={styles.menuAdmin}>
            <MenuAdmin openDrawer={openDrawer} />
          </Box>
        </RouteDom>
        <DrawerMenu
          open={openDrawerMenu}
          drawerWidth={DrawerWidth}
          drawerMenuWidth={DrawerMenuWidth}>
          {openDrawerMenu && (
            <>
              <RouteDom path={`${path}/poi`}>
                <RoutesPoiTab />
              </RouteDom>
              <RouteDom path={`${path}/routes`}>
                <RoutesRouteTab />
              </RouteDom>
              <RouteDom path={`${path}/objects`}>
                <RoutesObjectTab />
              </RouteDom>
              <RouteDom path={`${path}/layers`}>
                <RoutesLayerTab />
              </RouteDom>
            </>
          )}
        </DrawerMenu>
      </Switch>
      <RouteDom
        path={[
          `/map`,
          `${path}/menu`,
          `${path}/poi`,
          `${path}/routes`,
          `${path}/objects`,
          `${path}/layers`,
        ]}>
        {renderMobileScreenPreview}
      </RouteDom>
    </Box>
  );
};
