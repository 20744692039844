import React from 'react';
import {ContentLanguage} from '../../../../redux/reducers/ContentLanguage';
import {Controller, Path, PathValue} from 'react-hook-form';
import {FieldPath, FieldValues} from 'react-hook-form/dist/types';
import TextInputPrimary from './TextInputPrimary';
import {Control, UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {StylesDialog} from '../../dialogs/StylesDialog';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {InputLabelProps} from '../input-label/InputLabel';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

export interface TextInputNameControls<T> {
  name?: Path<T>;
  nameEn?: Path<T>;
}

export interface TextInputObjectNameProps<
  TFieldValues extends FieldValues & {name?: string; nameEn?: string},
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputLabelProps {
  control?: Control<TFieldValues>;
  selectedLanguage?: ContentLanguage;
  className?: string;
  disabled?: boolean;
  notPrimaryName?: boolean;
  multiline?: boolean;
  boldLabel?: boolean;
  minRows?: string | number;
  label?: string;
  labelEn?: string;
  name?: Path<TFieldValues>;
  nameEn?: Path<TFieldValues>;
  onChangeName?: (val: string) => void;
  required?: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}
export const TextInputObjectName = <
  TFieldValues extends FieldValues & {name?: string; nameEn?: string}
>({
  boldLabel,
  control,
  selectedLanguage,
  className,
  disabled,
  notPrimaryName,
  multiline,
  minRows,
  name,
  nameEn,
  label,
  labelEn,
  rules,
  onChangeName,
  required,
}: TextInputObjectNameProps<TFieldValues>): JSX.Element => {
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();

  return (
    <Box>
      <Box
        style={{
          display: selectedLanguage === ContentLanguage.CZ ? 'block' : 'none',
        }}>
        <Controller
          name={name ? name : ('name' as Path<TFieldValues>)}
          control={control}
          rules={
            rules !== undefined
              ? rules
              : {required: true, minLength: 2, maxLength: 80}
          }
          defaultValue={
            '' as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
          }
          render={({field, fieldState: {error}}) => (
            <TextInputPrimary
              {...field}
              boldLabel={boldLabel ? boldLabel : true}
              className={
                className +
                ' ' +
                (notPrimaryName
                  ? dialogClasses.objectNameNotPrimary
                  : dialogClasses.objectName)
              }
              label={label ? label : t(`allObjects.Name`)}
              required={required}
              disabled={disabled}
              onChange={(val) => {
                onChangeName && onChangeName(val);
                field.onChange(val);
              }}
              minRows={minRows}
              multiline={multiline}
              errorType={error?.type}
              errorMessage={error?.message}
            />
          )}
        />
      </Box>
      <Box
        style={{
          display: selectedLanguage === ContentLanguage.EN ? 'block' : 'none',
        }}>
        <Controller
          name={nameEn ? nameEn : ('nameEn' as Path<TFieldValues>)}
          control={control}
          rules={{maxLength: 80}}
          defaultValue={
            '' as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
          }
          render={({field, fieldState: {error}}) => (
            <TextInputPrimary
              {...field}
              boldLabel={boldLabel ? boldLabel : true}
              className={
                className +
                ' ' +
                (notPrimaryName
                  ? dialogClasses.objectNameNotPrimary
                  : dialogClasses.objectName)
              }
              label={labelEn ? labelEn : t(`allObjects.NameEn`)}
              required={required}
              disabled={disabled}
              minRows={minRows}
              multiline={multiline}
              errorType={error?.type}
            />
          )}
        />
      </Box>
    </Box>
  );
};
