import {FilterProps} from 'react-table';
import {useAppSelector} from '../../../hooks/hooks';
import {selectSimplePoiList} from '../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {SelectPois} from '../components/selects/select-multiple/SelectPois';
import React from 'react';
import {getObjIdsByName, getObjNameByIds} from '../utils/Utils';

export const SelectPoisFilter = <T extends Record<string, unknown>>({
  column: {filterValue, render, setFilter, id},
}: FilterProps<T>): JSX.Element => {
  const simplePoiList = useAppSelector(selectSimplePoiList);

  return (
    <SelectPois
      name={id + '_id'}
      label={render('Header')}
      value={getObjIdsByName(simplePoiList, filterValue) || []}
      onChange={(val) => {
        setFilter(getObjNameByIds(simplePoiList, val as string[]));
      }}
    />
  );
};
