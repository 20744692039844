import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {DialogPrimary} from '../DialogPrimary';
import {Pannellum} from 'pannellum-react';
import ReactDOM from 'react-dom';
import {PoiDialogType, PoiHotspot} from './hoptspot/PoiHotspot';
import {CustomUtils} from '../../utils/CustomUtils';
import {
  createPoi360AndHotspot,
  getPoi360ForUpdate,
  poi360Selector,
  setPoi360,
  updatePoi360AndHotspot,
} from '../../../../redux/reducers/data-reducers/Poi360Slice';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  setOpenPoi360Dialog,
  setPoi360LocalSelection,
  setPoi360Selection,
  setPoi360SelectionFromPoi360,
  setPoi360SelectionHotspot,
} from '../../../../redux/reducers/Poi360SelectionSlice';
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from 'react-i18next';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {ObjMapper} from '@skczu/czu-frontend-library';

export interface Props {
  openDialog: boolean;
  onCloseDialog: () => void;
  poi360: Poi360;
  fromPoi360?: Poi360;
  onGoToPoi: (hotspot: Hotspot) => void;
  onRemoveHotspot: (newPi360: Poi360WithData) => void;
  onSelectGlobalPoi: () => void;
}
const useStyles = makeStyles((theme) => ({
  poi360NameContainer: {
    textAlign: 'center',
    marginTop: -10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: theme.palette.primary.dark,
  },
  poi360Name: {
    fontSize: 20,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.secondary.main,
  },
}));
export const Dialog360view = ({
  openDialog,
  onCloseDialog,
  fromPoi360,
  poi360,
  onGoToPoi,
  onRemoveHotspot,
  onSelectGlobalPoi,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  let mouseDownCords: number[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const panImage: any = React.useRef(null);
  const dispatch = useAppDispatch();
  const [fromHotspot, setFromHotspot] = useState<Hotspot>();
  const [hotsp, setHotsp] = useState<{hotspotId: string; hotspot: Hotspot}>();
  const {poi360ForUpdate} = useAppSelector(poi360Selector);

  useEffect(() => {
    const from =
      fromPoi360?.id &&
      poi360?.hotspot?.find((hotspot) => hotspot.poi360 === fromPoi360.id);
    from && setFromHotspot(from);
  }, [fromPoi360, poi360]);

  const onSetCurrentHotspots = () => {
    poi360.hotspot?.map((poiHotspot, index) => {
      if (poiHotspot.yaw && poiHotspot.pitch) {
        const hotspotId = `${poiHotspot.poi360}_#_#${index}`;
        addHotspot(hotspotId, poiHotspot, 'edit');
      }
    });
  };

  const addLocalHotspot = useCallback(
    (hotspot: Hotspot) => {
      dispatch(setPoi360SelectionHotspot(hotspot));
      dispatch(setPoi360SelectionFromPoi360(poi360));
      dispatch(setPoi360Selection(true));
      dispatch(setPoi360LocalSelection(true));
      onCloseDialog();
    },
    [dispatch, onCloseDialog, poi360]
  );

  const onAddGlobalHotspot = useCallback(
    (hotspot: Hotspot) => {
      dispatch(setPoi360SelectionHotspot(hotspot));
      dispatch(setPoi360SelectionFromPoi360(poi360));
      dispatch(setPoi360Selection(true));
      dispatch(setPoi360LocalSelection(false));
      onCloseDialog();
      onSelectGlobalPoi();
    },
    [dispatch, onCloseDialog, onSelectGlobalPoi, poi360]
  );

  const onGetPoi360ForUpdate = useCallback(
    (poi360Id: string, hotspotId: string, hotspot: Hotspot) => {
      dispatch(getPoi360ForUpdate(poi360Id));
      setHotsp({hotspotId: hotspotId, hotspot: hotspot});
    },
    [dispatch]
  );

  const removeHotspot = useCallback(
    (poiViewerId: string, removedHotspot: Hotspot) => {
      panImage?.current?.getViewer().removeHotSpot(poiViewerId);
      const newPoi360Data = Object.assign({}, poi360);
      newPoi360Data.hotspot = poi360.hotspot?.filter(
        (hotspot) =>
          hotspot.yaw !== removedHotspot.yaw &&
          hotspot.pitch !== removedHotspot.pitch
      );
      const newPoi360: Poi360WithData = {
        ...newPoi360Data,
        image: ObjMapper.getDamFileWithDataFromDamFile(newPoi360Data.image),
      };
      onRemoveHotspot(newPoi360);
      dispatch(setOpenPoi360Dialog(true));
      dispatch(setPoi360({}));
    },
    [dispatch, onRemoveHotspot, poi360]
  );

  const hotspotIconT = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hotSpotDiv: any,
      id: string,
      hotspot: Hotspot,
      startState: PoiDialogType,
      updatedPoi360?: Poi360 | null
    ) => {
      ReactDOM.render(
        <PoiHotspot
          key={id}
          hotspotId={id}
          hotspot={hotspot}
          poi360={poi360}
          poi360ForUpdate={updatedPoi360}
          startState={startState}
          onDeletePoi={(poiViewerId, oldHotspot) => {
            removeHotspot(poiViewerId, oldHotspot);
          }}
          onRemoveHotspot={(poiViewerId) => {
            panImage?.current?.getViewer().removeHotSpot(poiViewerId);
          }}
          onGoToPoi={(poiId) => onGoToPoi(poiId)}
          onAddPoi360={(newPoi360, newHotspot, showName) => {
            dispatch(
              createPoi360AndHotspot(newPoi360, poi360, newHotspot, showName)
            );
          }}
          onUpdatePoi360={(newPoi360, updatedHotspot, showName) => {
            dispatch(
              updatePoi360AndHotspot(
                newPoi360,
                poi360,
                updatedHotspot,
                showName
              )
            );
          }}
          onAddLocalHotspot={addLocalHotspot}
          onAddGlobalHotspot={onAddGlobalHotspot}
          onGetPoi360ForUpdate={onGetPoi360ForUpdate}
          onErrorForm={() =>
            dispatch(showErrorMessage(t("allObjects.EN name can't exceed")))
          }
        />,
        hotSpotDiv
      );
    },
    [
      addLocalHotspot,
      dispatch,
      onAddGlobalHotspot,
      onGetPoi360ForUpdate,
      onGoToPoi,
      poi360,
      removeHotspot,
      t,
    ]
  );

  const addHotspot = useCallback(
    (hotspotId: string, poiHotspot: Hotspot, startState: PoiDialogType) => {
      panImage?.current?.getViewer().addHotSpot({
        id: hotspotId,
        pitch: poiHotspot.pitch,
        yaw: poiHotspot.yaw,
        cssClass: 'custom-hotspot',
        createTooltipArgs: hotspotId,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createTooltipFunc: (hotSpotDiv: any) => {
          hotspotIconT(
            hotSpotDiv,
            hotspotId,
            poiHotspot,
            startState,
            poi360ForUpdate
          );
        },
      });
    },
    [hotspotIconT, poi360ForUpdate]
  );

  useEffect(() => {
    hotsp && panImage?.current?.getViewer().removeHotSpot(hotsp.hotspotId);
    hotsp && addHotspot(hotsp.hotspotId, hotsp.hotspot, 'update');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poi360ForUpdate]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMouseclick = (event: any) => {
    if (
      mouseDownCords[0] ===
        panImage?.current?.getViewer().mouseEventToCoords(event)[0] &&
      mouseDownCords[1] ===
        panImage?.current?.getViewer().mouseEventToCoords(event)[1]
    ) {
      panImage?.current?.getViewer().addHotSpot({
        id: `poi_${mouseDownCords[0]}_${mouseDownCords[1]}`,
        pitch: mouseDownCords[0],
        yaw: mouseDownCords[1],
        cssClass: 'custom-hotspot',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createTooltipFunc: (hotSpotDiv: any) => {
          hotspotIconT(
            hotSpotDiv,
            `poi_${mouseDownCords[0]}_${mouseDownCords[1]}`,
            {
              pitch: String(mouseDownCords[0]),
              yaw: String(mouseDownCords[1]),
            },
            'add'
          );
        },
        createTooltipArgs: `poi_${mouseDownCords[0]}_${mouseDownCords[1]}`,
      });
    }
  };

  return (
    <Box>
      {poi360 && (
        <DialogPrimary
          title={t('poi360.Poi 360 view')}
          open={openDialog}
          minWidth={'1000px'}
          onCloseDialog={onCloseDialog}>
          {poi360.name && (
            <Box className={classes.poi360NameContainer}>
              {poi360.showName && (
                <Typography className={classes.poi360Name}>
                  {CustomUtils.getContentByLanguage(poi360.name, poi360.nameEn)}
                </Typography>
              )}
            </Box>
          )}
          {poi360.image?.url && openDialog && (
            <Pannellum
              width="100%"
              height="600px"
              image={poi360.image.url}
              doubleClickZoom={false}
              pitch={0}
              yaw={fromHotspot?.yaw ? Number(fromHotspot.yaw) + 180 : -90}
              hfov={120}
              maxHfov={120}
              autoLoad
              showZoomCtrl={false}
              showFullscreenCtrl={false}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onMousedown={(event: any) => {
                mouseDownCords = panImage?.current
                  ?.getViewer()
                  .mouseEventToCoords(event);
              }}
              onLoad={onSetCurrentHotspots}
              onMouseup={onMouseclick}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={panImage}
            />
          )}
        </DialogPrimary>
      )}
    </Box>
  );
};
