import React, {useCallback, useEffect, useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {HeaderMenuItem} from '../../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../../shared/components/buttons/ButtonAdd';
import {DialogPoi} from '../../../../../../../shared/dialogs/dialog-poi/DialogPoi';
import {
  CustomListItems,
  ListItemRow,
} from '../../../../../../../shared/components/lists/CustomListItems';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/hooks';
import {
  selectDrawer,
  setPoiScrollIndex,
  clearObjectPreview,
} from '../../../../../../../../redux/reducers/DrawerSlice';
import {
  changeVisibleOnMap,
  createPoi,
  deletePoi,
  getBasePoiList,
  getPoi,
  getPoiForMobilePreview,
  poiActions,
  poiSelector,
  updatePoi,
} from '../../../../../../../../redux/reducers/data-reducers/PoiSlice';
import {useHistory, useParams} from 'react-router-dom';
import {
  addToPoiSelection,
  selectSelectedPoi,
  setOpenDialogAfterPoiSelection,
  setPoiSelection,
  setPoiSelectionList,
} from '../../../../../../../../redux/reducers/PoiListSelectionSlice';
import {DialogLocal360} from '../../../../../../../shared/dialogs/dialog-local360/DialogLocal360';
import {
  poi360SelectionSelector,
  setOpenLocalPoi360Dialog,
} from '../../../../../../../../redux/reducers/Poi360SelectionSlice';
import {selectPoiCategoryList} from '../../../../../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {SearchInput} from '../../../../../../../shared/components/search/SearchInput';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../../../../shared/dialogs/dialog-info/DialogInfo';
import {StylesMenu} from '../../../StylesMenu';
import {CategoryListIcon} from '../../../../../../../shared/components/lists/CategoryListIcon';
import {DialogQrCode} from '../../../../../../../shared/dialogs/dialog-qr-code/DialogQrCode';
import {useQrCode} from '../../../../../../../../hooks/useQrCode';
import {CustomUtils} from '../../../../../../../shared/utils/CustomUtils';
import {showImagePreview} from '../../../../../../../../redux/reducers/ImagePreview';
import {useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {useInfoDialog} from '../../../../../../../../hooks/useInfoDialog';
import {ObjectType} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/qrCode';
import {ListLoadingIndicator} from '@skczu/czu-react-components';
import {batch} from 'react-redux';

export const ListOfPoisTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const history = useHistory();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const dispatch = useAppDispatch();
  const {
    loadingList,
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingDetail,
    hasMore,
  } = useAppSelector(poiSelector);
  const {objectPreview, objectPreviewView, polScrollIndex} =
    useAppSelector(selectDrawer);
  const {openLocalPoi360Dialog} = useAppSelector(poi360SelectionSelector);
  const poiCategoryList = useAppSelector(selectPoiCategoryList);
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deletePoi(id)),
    });
  const {qrCodeDialog, getObjectQrCode, closeQrCodeDialog} = useQrCode();
  const {poiId} = useParams<{poiId: string}>();
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    poiActions,
    dispatch,
    baseObjSearch,
    getBasePoiList
  );

  const navigateTo = useCallback((path) => history.push(path), [history]);

  useEffect(() => {
    poiId &&
      dispatch(
        getPoiForMobilePreview(poiId, (poiObjId) =>
          dispatch(setPoiScrollIndex(poiObjId))
        )
      );
    !poiId && dispatch(clearObjectPreview());
  }, [dispatch, poiId]);

  const handleEditClick = useCallback(
    (id: string) => {
      dispatch(poiActions.setEditObjDetail());
      dispatch(getPoi(id));
    },
    [dispatch]
  );

  const handleQrCodeScan = useCallback(
    (id: string, objName: string) => {
      getObjectQrCode(id, ObjectType.Poi, objName);
    },
    [getObjectQrCode]
  );

  const handleAddToPoiSelection = useCallback(
    (id: string) => {
      dispatch(addToPoiSelection(id, true));
    },
    [dispatch]
  );

  const handleItemClick = useCallback(
    (id: string) => {
      if (!poiSelection.poiSelection) {
        navigateTo(`/map/poi/poiList/${id}`);
      } else {
        handleAddToPoiSelection(id);
      }
    },
    [handleAddToPoiSelection, navigateTo, poiSelection.poiSelection]
  );

  const handleAddPoiClick = () => {
    dispatch(setPoiSelection({poiSelection: false}));
    dispatch(setOpenDialogAfterPoiSelection(true));
    history.goBack();
  };

  const handleAddGlobalPoiClick = () => {
    if (poiSelection.poiList && poiSelection.poiList[0]) {
      dispatch(setOpenLocalPoi360Dialog(true));
    }
  };

  const handleVisibleClick = useCallback(
    (selectedPoiId: string, visible: boolean) => {
      dispatch(changeVisibleOnMap(selectedPoiId, visible));
    },
    [dispatch]
  );

  const getPoiListItems = useMemo(() => {
    return baseObjList
      ? baseObjList.map((poi) => {
          const poiCategory =
            poiCategoryList &&
            poiCategoryList.find((category) => category.id === poi.category);
          return {
            id: poi.id,
            itemRowName: CustomUtils.getContentByLanguage(poi.name, poi.nameEn),
            icon: poiCategory && <CategoryListIcon poiCategory={poiCategory} />,
            visible: poi.showOnMap,
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === poi.id
                ? true
                : undefined
              : undefined,
            activeItem:
              poiSelection.poiSelection &&
              Boolean(poiSelection.poiList?.find((item) => item.id === poi.id)),
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    poiCategoryList,
    objectPreviewView.openObjectPreview,
    objectPreview.objectPreviewData,
    poiSelection.poiSelection,
    poiSelection.poiList,
  ]);

  const getPoiList = useMemo(() => {
    return (
      <CustomListItems
        scrollTo={polScrollIndex}
        listItems={getPoiListItems}
        handleItemClick={handleItemClick}
        handleEditClick={handleEditClick}
        handleDeleteClick={showInfoDialog}
        handleVisibleClick={handleVisibleClick}
        handleQrCodeClick={handleQrCodeScan}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
    );
  }, [
    polScrollIndex,
    getPoiListItems,
    handleItemClick,
    handleEditClick,
    showInfoDialog,
    handleVisibleClick,
    handleQrCodeScan,
    handleLoadMore,
    loadingList,
    hasMore,
  ]);

  return (
    <Box>
      <ListLoadingIndicator showLoading={loadingList} />
      <HeaderMenuItem
        name={
          poiSelection.poiSelection
            ? t('leftMenu.Select POI')
            : t('leftMenu.List of POI')
        }
      />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          name={'poi-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      {getPoiList}
      <Box>
        {poiSelection.poiSelection &&
          poiSelection.poiSelectionFrom === 'route' && (
            <ButtonAdd
              name={t('poi.Add to route')}
              onClick={() => handleAddPoiClick()}
            />
          )}
        {poiSelection.poiSelection &&
          poiSelection.poiSelectionFrom === 'layer' && (
            <ButtonAdd
              name={t('poi.Add to layer')}
              onClick={() => handleAddPoiClick()}
            />
          )}
        {poiSelection.poiSelection &&
          poiSelection.poiSelectionFrom === 'news' && (
            <ButtonAdd
              name={t('poi.Add to news')}
              onClick={() => handleAddPoiClick()}
            />
          )}
        {poiSelection.poiSelection &&
          poiSelection.poiSelectionFrom === 'poi360' && (
            <ButtonAdd
              name={t('poi.Select global POI')}
              onClick={() => handleAddGlobalPoiClick()}
            />
          )}
        {!poiSelection.poiSelection && (
          <ButtonAdd
            name={t('poi.Add new POI')}
            onClick={() => {
              dispatch(clearObjectPreview());
              navigateTo('/map/poi/newPoi');
            }}
          />
        )}
        {poiSelection.poiSelection && (
          <Box className={menuClasses.disableSelection}>
            <Typography
              variant={'subtitle2'}
              color={'error'}
              onClick={() => {
                poiSelection.poiSelectionFrom === 'poi360' &&
                  dispatch(setPoiSelectionList([]));
                dispatch(setPoiSelection({poiSelection: false}));
                history.goBack();
              }}>
              {t('leftMenu.Cancel POI selection')}
            </Typography>
          </Box>
        )}
      </Box>
      {openObjDialog && (
        <DialogPoi
          poi={obj}
          addNew={addNewObj}
          openDialog={openObjDialog}
          loading={loadingDetail}
          onCloseDialog={(clearObject) => {
            clearObject && dispatch(poiActions.setObj(null));
            dispatch(poiActions.setOpenObjDialog(false));
          }}
          onAddPoi={(newPoi) => dispatch(createPoi(newPoi))}
          onUpdatePoi={(updatedPoi) => dispatch(updatePoi(updatedPoi))}
          onObjectSelection={(tmpPoi) => dispatch(poiActions.setObj(tmpPoi))}
          imagePreview={(selectedImage, images) =>
            dispatch(showImagePreview({selectedImage, images}))
          }
        />
      )}
      {poiSelection.poiList && (
        <DialogLocal360
          basePoi={poiSelection.poiList[0]}
          openDialog={openLocalPoi360Dialog}
          onCloseDialog={() => {
            dispatch(setOpenLocalPoi360Dialog(false));
          }}
          onAddLocalPoi={() => {
            batch(() => {
              dispatch(setPoiSelection({poiSelection: false}));
              dispatch(setPoiSelectionList([]));
              dispatch(setOpenDialogAfterPoiSelection(true));
              dispatch(poiActions.setOpenObjDialog(true));
            });
          }}
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.POI')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.POI'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogQrCode
        openDialog={qrCodeDialog.openDialog}
        qrCode={qrCodeDialog.qrCode}
        objName={qrCodeDialog.objName}
        onCloseDialog={closeQrCodeDialog}
      />
    </Box>
  );
};
