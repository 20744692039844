import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getBaseObjList,
  getObj,
  getObjListFromIds,
  InfiniteSearch,
  updateObj,
} from '@skczu/czu-frontend-library';
import {setLoading} from '../LoadingSlice';
import {getSimpleLayerList} from './SimpleObjectListsSlice';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {setPoiSelectionList} from '../PoiListSelectionSlice';
import {setRouteSelectionList} from '../RouteListSelectionSlice';
import {
  Configuration,
  CreateOrUpdateLayer,
  Layer,
  LayerApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/layer';
import {
  ObjectRef,
  ObjectRefFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/layer/api';
import {setFilterLayerIds} from '../MapSlice';
import {getToken} from '../../../keycloak';

export type LayersState = GenericState<ObjectRef, Layer>;

const initialState: LayersState = {
  baseObjList: [],

  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  filterDataList: [],
  filterObjSearch: {
    limit: 20,
    offset: 0,
  },
};

const layersSlice = createGenericSlice({
  name: 'layers',
  initialState,
})({});

export const getBaseLayerList =
  (newsList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<ObjectRef, ObjectRefFilterResponse>(
        getState().layers.baseObjSearch,
        getState().layers.baseObjList,
        async () =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerFilterGet(keyword, 9999, 0),
        (loading) => dispatch(layersActions.setObjLoadingList(loading)),
        () => dispatch(showErrorMessage()),
        (offset) => dispatch(layersActions.setOffset(offset)),
        (newDataList) => {
          if (newsList) {
            dispatch(
              layersActions.setBaseObjList(newDataList ? newDataList : [])
            );
          } else {
            dispatch(layersActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(layersActions.hasMore(hasMore))
      )
    );
  };

export const getFilterLayerList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<ObjectRef, ObjectRefFilterResponse>(
        getState().layers.filterObjSearch as InfiniteSearch,
        getState().layers.filterDataList as ObjectRef[],
        async () =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerFilterGet(
            newList
              ? (getState().layers.filterObjSearch as InfiniteSearch)
                  .searchQuery
              : keyword,
            999999,
            0
          ),
        (loading) => dispatch(setLoading(loading)),
        () => dispatch(showErrorMessage()),
        () => void 0,
        (newDataList) => {
          const layerIds = getState().map.mapFilter.layerIds;
          const newSelectedLayerIds: string[] = [];
          layerIds &&
            layerIds.length > 0 &&
            layerIds.forEach((layerId) => {
              const foundLayer = newDataList?.find(
                (layer) => layer.id === layerId
              );
              foundLayer?.id && newSelectedLayerIds.push(foundLayer.id);
            });
          dispatch(setFilterLayerIds(newSelectedLayerIds));
          dispatch(layersActions.setFilterDataList(newDataList));
        },
        keyword,
        () => true,
        newList
      )
    );
  };

export const getLayer =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Layer>(
        id,
        async () =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerIdGet(id),
        (loading) => dispatch(layersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(setPoiSelectionList(obj.pois ? obj.pois : []));
          dispatch(setRouteSelectionList(obj.routes ? obj.routes : []));
          dispatch(layersActions.setObj(obj));
        }
      )
    );
  };

export const getLayerListFromIds =
  (layerIds: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getObjListFromIds<Layer>(
        layerIds,
        getState().layers.objList,
        async (id) =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerIdGet(id),
        (loading) => dispatch(layersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (objList) => {
          dispatch(layersActions.setObjList(objList));
        }
      )
    );
  };

export const deleteLayer =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerIdDelete(id),
        (loading) => dispatch(layersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(layersActions.setObj(null));
          dispatch(layersActions.removeFromBaseObjList(id));
          dispatch(getSimpleLayerList());
          dispatch(getFilterLayerList(true));
        }
      )
    );
  };

export const createLayer =
  (layer: CreateOrUpdateLayer): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CreateOrUpdateLayer>(
        layer,
        async (obj) =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerPost({...obj, objectType: 'CreateOrUpdateLayer'}),
        (loading) => dispatch(layersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (id) => {
          dispatch(layersActions.setObj(null));
          dispatch(
            layersActions.addToBaseObjListAsFirst({...layer, id: id as string})
          );

          dispatch(getSimpleLayerList());
          dispatch(getFilterLayerList(true));
          dispatch(layersActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const updateLayer =
  (layer: CreateOrUpdateLayer): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<CreateOrUpdateLayer>(
        layer,
        async (obj) =>
          new LayerApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).layerPut({...obj, objectType: 'CreateOrUpdateLayer'}),
        (loading) => dispatch(layersActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(layersActions.setObj(null));
          dispatch(layersActions.updateBaseObjInList(obj));
          dispatch(getSimpleLayerList());
          dispatch(getFilterLayerList(true));
          dispatch(layersActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const layersActions = layersSlice.actions;

export const layersSelector = (state: RootState): typeof state.layers =>
  state.layers;

export default layersSlice.reducer;
