import React, {ReactElement} from 'react';
import {Box, Tooltip} from '@mui/material';
import {IconMenuItem} from '@skczu/czu-frontend-library';
import {Info} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

export interface TooltipIconProps {
  icon?: JSX.Element;
  onClick?: (instance: IconMenuItem) => void;
  label: string;
}

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1em',
  },
});
export const TooltipIcon = ({label}: TooltipIconProps): ReactElement => {
  const classes = useStyles();
  return (
    <Tooltip
      placement="right"
      title={label}
      aria-label={label}
      classes={{tooltip: classes.tooltip}}
      componentsProps={{
        tooltip: {
          sx: {
            color: 'secondary.contrastText',
            backgroundColor: 'secondary.main',
            boxShadow: 3,
            padding: 2,
            borderRadius: '10px',
            fontSize: '1em',
          },
        },
      }}>
      <Box
        sx={{
          marginLeft: 1,
        }}>
        <Info sx={{color: '#fdbc30'}} />
      </Box>
    </Tooltip>
  );
};
