import React, {useState} from 'react';
import {
  Box,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SwitchPrimary} from '../../../components/switches/SwitchPrimary';
import {Dialog360view} from '../../dialog-360view/Dialog360view';
import {
  CustomTable,
  CustomTableCell,
} from '../../../components/custom-inputs/TableBase';
import {ButtonUploadImage} from '../../../components/buttons/ButtonUploadImage';
import {AddCircleOutline, Delete, Visibility} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  addToPoi360List,
  deletePoi360,
  getPoi360,
  poi360Selector,
  setPoi360,
  updatePoi360,
} from '../../../../../redux/reducers/data-reducers/Poi360Slice';
import {
  poi360SelectionSelector,
  setOpenPoi360Dialog,
  setPoi360Selection,
} from '../../../../../redux/reducers/Poi360SelectionSlice';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../dialog-info/DialogInfo';
import {CustomUtils} from '../../../utils/CustomUtils';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {Controller} from 'react-hook-form';
import {PoiForm} from '../DialogPoi';
import {useInfoDialog} from '../../../../../hooks/useInfoDialog';
import {Poi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {ObjMapper} from '@skczu/czu-frontend-library';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {useUploadPoi360Photo} from '../../../../../hooks/useUploadPoi360';
import {LinearProgressWithLabel} from '../../../components/loading-indicator/LinearProgress';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `3px solid ${theme.palette.secondary.light}`,
  },
}));

export interface Props extends Partial<UseFormReturn<PoiForm>> {
  poi?: Poi | null;
  onAddNewPoi?: () => void;
  onCloseDialog: () => void;
  handleSelectGlobalPoi?: () => void;
}

export const Tab360view = ({
  control,
  watch,
  poi,
  onCloseDialog,
  onAddNewPoi,
  handleSelectGlobalPoi,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [fromPoi360, setFromPoi360] = useState<Poi360>();
  const {poi360List, poi360} = useAppSelector(poi360Selector);
  const {poi360Selection, openPoi360Dialog} = useAppSelector(
    poi360SelectionSelector
  );
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deletePoi360(id)),
    });
  const {uploadProgress, addPhoto} = useUploadPoi360Photo((photo) => {
    dispatch(setPoi360({}));
    dispatch(addToPoi360List(photo));
  });

  const handleGoToPoi = (hotspot: Hotspot) => {
    if (poi360) {
      poi360.id !== hotspot.poi360 &&
        hotspot.poi360 &&
        dispatch(getPoi360(hotspot.poi360));
    } else {
      hotspot.poi360 && dispatch(getPoi360(hotspot.poi360));
    }
    poi360 && setFromPoi360(poi360);
  };

  const handleUploadFile = (image: DamFileWithData) => {
    if (image) {
      let newPoi360: Poi360WithData;
      if (poi && poi.id) {
        newPoi360 = {
          name: poi.name,
          nameEn: poi.nameEn,
          poi: poi.id,
          image: ObjMapper.getDamFileWithDataFromDamFile(image),
          hotspot: [],
          showName: true,
        };
        newPoi360 && addPhoto(newPoi360);
      }
    }
  };

  const handleEditClick = (id: string) => {
    if (poi360) {
      poi360.id !== id && dispatch(getPoi360(id));
    } else {
      dispatch(getPoi360(id));
    }
    dispatch(setOpenPoi360Dialog(true));
  };

  const addLocalPoi = (poi360Obj: Poi360) => {
    const hotspot = poi360Selection && poi360Selection.hotspot;
    const newHotspot = Object.assign({}, hotspot);
    if (newHotspot) {
      const poi360WithHotspot = Object.assign({}, poi360Selection.fromPoi360);
      newHotspot.poi360 = poi360Obj.id;
      newHotspot.name = poi360Obj.name;
      newHotspot.nameEn = poi360Obj.nameEn;
      newHotspot.showName = poi360Obj.showName;
      const localPoi360: Poi360WithData = {
        ...poi360WithHotspot,
        image: ObjMapper.getDamFileWithDataFromDamFile(poi360WithHotspot.image),
        hotspot: poi360WithHotspot.hotspot
          ? [...poi360WithHotspot.hotspot, newHotspot]
          : [newHotspot],
      };
      dispatch(updatePoi360(localPoi360));
      dispatch(setPoi360Selection(false));
      dispatch(setOpenPoi360Dialog(true));
    }
  };

  const handleChange360View = () => {
    if (!poi || !poi.id) {
      onAddNewPoi && onAddNewPoi();
    }
  };

  const closeDialog = () => {
    dispatch(setOpenPoi360Dialog(false));
    dispatch(setPoi360({}));
    if (poi360Selection.localSelection && !poi360Selection.localSelection) {
      onCloseDialog();
    }
  };

  return (
    <Box>
      <Box width={800}>
        <Box marginTop={2}>
          <Controller
            name="view360"
            control={control}
            defaultValue={false}
            render={({field, fieldState: {error}}) => (
              <SwitchPrimary
                {...field}
                label={t(`poi.Enable 360 view`)}
                boldLabel
                errorType={error?.type}
                onChange={(event) => {
                  field.onChange(event);
                  handleChange360View();
                }}
              />
            )}
          />
        </Box>
        {watch && watch('view360') && (
          <>
            <Box marginBottom={2}>
              <ButtonUploadImage
                name={'poi360image'}
                boldLabel
                label={t(`poi.Upload 360 image`)}
                onChange={(event) => {
                  handleUploadFile(event);
                }}
                objectType={'DamFileWithData'}
              />
            </Box>
            <Box m={0.5}>
              <Typography variant="subtitle2" sx={{fontWeight: 600}}>
                {t('poi.List of local POI')}
              </Typography>
            </Box>
            <Box width={800} marginBottom={5}>
              <CustomTable classes={{root: classes.table}}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>{t('poi.Name')}</CustomTableCell>
                    <CustomTableCell align="right">
                      {t('poi.Action')}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadProgress && uploadProgress.state === 'upload' && (
                    <Box m={2}>
                      <LinearProgressWithLabel
                        value={uploadProgress.progress}
                      />
                    </Box>
                  )}
                  {poi360List &&
                    poi360List.map((poi360Obj: Poi360, index) => (
                      <TableRow key={poi360Obj.name + '_' + index}>
                        <CustomTableCell component="th" scope="row">
                          {poi360Selection.poi360Selection &&
                            poi360Selection.localSelection && (
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                size="small"
                                onClick={() =>
                                  poi360Obj.id && addLocalPoi(poi360Obj)
                                }>
                                <Box marginLeft={1} marginRight={1}>
                                  <AddCircleOutline
                                    fontSize="small"
                                    color={'primary'}
                                  />
                                </Box>
                              </IconButton>
                            )}
                          {CustomUtils.getContentByLanguage(
                            poi360Obj.name,
                            poi360Obj.nameEn
                          )}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            size="small"
                            onClick={() =>
                              poi360Obj.id && handleEditClick(poi360Obj.id)
                            }>
                            <Visibility fontSize="small" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              poi360Obj.id && showInfoDialog(poi360Obj.id)
                            }>
                            <Delete fontSize="small" />
                          </IconButton>
                        </CustomTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </CustomTable>
            </Box>
          </>
        )}
        {poi360 && (
          <Dialog360view
            poi360={poi360}
            fromPoi360={fromPoi360}
            onGoToPoi={handleGoToPoi}
            openDialog={openPoi360Dialog}
            onCloseDialog={closeDialog}
            onSelectGlobalPoi={() =>
              handleSelectGlobalPoi && handleSelectGlobalPoi()
            }
            onRemoveHotspot={(newPoi360) => {
              dispatch(updatePoi360(newPoi360));
            }}
          />
        )}
        <DialogInfo
          title={t('allObjects.Delete', {name: t('allObjects.360 photo')})}
          text={t('allObjects.Are you sure you want to delete this', {
            name: t('allObjects.360 photo'),
          })}
          id={infoDialog.id}
          openDialog={infoDialog.openDialog}
          onCloseDialog={onCloseInfoDialog}
          onAgree={onSetAgree}
        />
      </Box>
    </Box>
  );
};
