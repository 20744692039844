import {useCallback} from 'react';
import {
  updateGalleryPhotoInList,
  galleryPhotoSelector,
  setGalleryPhotoListFailed,
  setGalleryPhotoList,
  addToGalleryPhotoList,
} from '../redux/reducers/data-reducers/GalleryPhotoSlice';
import config from '../config';
import {AxiosResponse} from 'axios';
import {
  CreateObjectResponse,
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {useAppDispatch, useAppSelector} from './hooks';
import {
  Configuration,
  NewsApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {EventApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {
  GalleryPhotoApi,
  PoiApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {ObjMapper} from '@skczu/czu-frontend-library';
import {getToken} from '../keycloak';

export type UploadFileState = 'new' | 'upload';
export type FileObjType = 'news' | 'poi' | 'group';

export interface UploadFileHook {
  galleryPhotoList: UploadFile[];
  addGalleryPhoto: (
    objId: string | undefined,
    galleryPhoto: DamFileWithData
  ) => void;
  addGalleryPhotos: (
    objId: string | undefined,
    galleryPhotos: DamFileWithData[]
  ) => void;
  removeGalleryPhoto: (galleryPhoto: DamImage) => void;
}

export interface UploadFile {
  galleryPhoto?: DamFileWithData | DamImage;
  progress: number;
  state: UploadFileState;
}

export const useUploadPhoto = (objType: FileObjType): UploadFileHook => {
  const dispatch = useAppDispatch();
  const {galleryPhotoList} = useAppSelector(galleryPhotoSelector);

  const getObjTypeApi = async (
    filoObjType: FileObjType,
    id: string,
    galleryPhoto: DamFileWithData
  ): Promise<AxiosResponse<CreateObjectResponse>> => {
    switch (filoObjType) {
      case 'news':
        return new NewsApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).newsAdminIdPhotoPost(
          id,
          {...galleryPhoto, objectType: 'DamFileWithData'},
          {
            onUploadProgress: (data: ProgressEvent) =>
              onUploadProgress(data, galleryPhoto),
          }
        );
      case 'group':
        return new EventApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).eventIdPhotoPost(
          id,
          {...galleryPhoto, objectType: 'DamFileWithData'},
          {
            onUploadProgress: (data: ProgressEvent) =>
              onUploadProgress(data, galleryPhoto),
          }
        );
      case 'poi':
        return new PoiApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).poiIdPhotoPost(
          id,
          {...galleryPhoto, objectType: 'DamFileWithData'},
          {
            onUploadProgress: (data: ProgressEvent) =>
              onUploadProgress(data, galleryPhoto),
          }
        );
    }
  };

  const onUploadProgress = useCallback(
    (data: ProgressEvent, galleryPhoto: DamImage) => {
      const percentCompleted = Math.round((data.loaded * 100) / data.total);
      dispatch(
        updateGalleryPhotoInList({
          galleryPhoto: galleryPhoto,
          progress: percentCompleted,
          state: percentCompleted === 100 ? 'new' : 'upload',
        } as UploadFile)
      );
    },
    [dispatch]
  );

  const addGalleryPhoto = async (
    objId: string | undefined,
    galleryPhoto: DamFileWithData
  ) => {
    dispatch(
      addToGalleryPhotoList({
        galleryPhoto: galleryPhoto,
        progress: 0,
        state: 'new',
      } as UploadFile)
    );
    if (objId) {
      try {
        const {data} = await getObjTypeApi(objType, objId, galleryPhoto);
        if (data.id) {
          const newGalleryPhoto = Object.assign({}, galleryPhoto);
          newGalleryPhoto.id = data.id;
          dispatch(
            updateGalleryPhotoInList({
              galleryPhoto: newGalleryPhoto,
              progress: 0,
              state: 'new',
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(setGalleryPhotoListFailed({message: 'error'}));
      }
    } else {
      try {
        const data = await new GalleryPhotoApi(
          new Configuration({accessToken: await getToken()}),
          config.cmsRestUrl
        ).galleryPhotoPost(
          {...galleryPhoto, objectType: 'DamFileWithData'},
          {
            onUploadProgress: (progress: ProgressEvent) =>
              onUploadProgress(progress, galleryPhoto),
          }
        );
        if (data?.data.id) {
          const newGalleryPhoto = Object.assign({}, galleryPhoto);
          newGalleryPhoto.id = data.data.id;
          dispatch(
            updateGalleryPhotoInList({
              galleryPhoto: newGalleryPhoto,
              progress: 0,
              state: 'new',
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(setGalleryPhotoListFailed({message: 'error'}));
      }
    }
  };

  const addGalleryPhotos = async (
    objId: string | undefined,
    galleryPhotos: DamFileWithData[]
  ): Promise<void[]> => {
    return Promise.all(
      galleryPhotos.map(async (galleryPhoto) => {
        return addGalleryPhoto(objId, galleryPhoto);
      })
    );
  };

  const removeGalleryPhoto = (galleryPhoto: DamImage) => {
    dispatch(
      setGalleryPhotoList([
        ...galleryPhotoList.filter(
          (file) =>
            file.galleryPhoto &&
            ObjMapper.geNameFromDam(file.galleryPhoto) !== galleryPhoto.name
        ),
      ])
    );
  };

  return {
    galleryPhotoList,
    addGalleryPhoto,
    addGalleryPhotos,
    removeGalleryPhoto,
  };
};
