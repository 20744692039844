import React, {forwardRef, useEffect} from 'react';
import {Box, ListItemText, MenuItem, Switch} from '@mui/material';
import {SelectMultiplePrimary} from '../SelectMultiplePrimary';
import {useTranslation} from 'react-i18next';
import {MultipleSelect} from './SelectCategories';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  getSimpleRoleList,
  selectSimpleRoleList,
} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {CustomUtils} from '../../../utils/CustomUtils';

export interface RoleMultipleSelect extends MultipleSelect {
  defaultRole?: boolean;
  multiple?: boolean;
  selectedValueLabel?: string;
  hasCzuRoles?: boolean;
}
export const SelectRoles = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      onBlur,
      disabled,
      errorType,
      defaultRole,
      selectedValueLabel,
      multiple = true,
      selectAll = true,
      selectAny = true,
      hasCzuRoles = false,
    }: RoleMultipleSelect,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleRoleList = useAppSelector(selectSimpleRoleList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getSimpleRoleList());
    }, [dispatch]);

    return (
      <Box>
        <SelectMultiplePrimary
          id={id}
          name={name}
          ref={ref}
          selectedValue={
            t('objectInputs.Roles') +
            (multiple
              ? value
                ? ` (${
                    value.length === 0 ? t('allObjects.None') : value.length
                  })`
                : ''
              : selectedValueLabel
              ? selectedValueLabel
              : '')
          }
          disabled={disabled}
          label={label}
          className={className}
          value={value}
          boldLabel
          onBlur={onBlur}
          multiple={multiple}
          errorType={errorType}
          list={
            defaultRole
              ? simpleRoleList
                  .filter((role) => role.key !== 'default-roles-master')
                  .filter((role) => (hasCzuRoles ? role : !role.czu))
                  ?.map((role) => ({...role, id: role.key}))
              : simpleRoleList
                  ?.map((role) => ({...role, id: role.key}))
                  .filter((role) => (hasCzuRoles ? role : !role.czu))
          }
          selectAll={selectAll}
          selectAny={selectAny}
          onChange={onChange}>
          {defaultRole &&
          simpleRoleList.some((role) => role.key === 'default-roles-master') ? (
            <MenuItem
              key={'default-roles-master'}
              value={'default-roles-master'}>
              <Switch checked={true} color={'primary'} disabled={true} />
              <ListItemText
                primary={CustomUtils.getContentByLanguage(
                  simpleRoleList.find(
                    (role) => role.key === 'default-roles-master'
                  )?.name,
                  simpleRoleList.find(
                    (role) => role.key === 'default-roles-master'
                  )?.nameEn
                )}
              />
            </MenuItem>
          ) : null}
        </SelectMultiplePrimary>
      </Box>
    );
  }
);
SelectRoles.displayName = 'SelectRoles';

export default SelectRoles;
