import React from 'react';
import {IconButton} from '@mui/material';
import {CellProps} from 'react-table';
import {Delete, Edit, QrCode2} from '@mui/icons-material';
import {AdminGroupsObj} from './TableEvents';

export interface GroupsActionCellProps {
  cell: CellProps<AdminGroupsObj>;
  onQrCodeScan: (id: string) => void;
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
}
export const EventsActionCell = ({
  cell,
  onQrCodeScan,
  onEditClick,
  onDeleteClick,
}: GroupsActionCellProps): JSX.Element => {
  return (
    <>
      <IconButton
        edge="end"
        aria-label="edit"
        size="small"
        onClick={() => onQrCodeScan(cell.value)}>
        <QrCode2 fontSize="small" />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="edit"
        size="small"
        onClick={() => onEditClick(cell.value)}>
        <Edit fontSize="small" />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="delete"
        size="small"
        onClick={() => onDeleteClick(cell.value)}>
        <Delete fontSize="small" />
      </IconButton>
    </>
  );
};
