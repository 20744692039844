import moment from 'moment';
import {Box, Typography} from '@mui/material';
import React, {useCallback} from 'react';
import {AdminNewsObj} from '../../scenes/admin-menu-scene/news/table/TableNews';
import {FilterProps} from 'react-table';
import {useTranslation} from 'react-i18next';
import {DefaultDateTimeFormatBE, DefaultDateTimeFormatFE} from '../utils/Utils';
import {DateInputStyles, DateTimeInput} from '../components/date/DateTimeInput';

const styles = {
  dateRow: {
    paddingTop: '6px',
  },
};

export const DateFilter = ({
  column: {
    filterValue = [],
    render,
    setFilter,
    preFilteredRows,
    id,
    ...columnProps
  },
}: FilterProps<AdminNewsObj>): JSX.Element => {
  const {t} = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dates = preFilteredRows?.map((val: any) =>
    moment(val.original[id], DefaultDateTimeFormatBE)
  );
  const min = dates && moment.min(dates).subtract(1, 'day');
  const max = dates && moment.max(dates).add(1, 'day');

  const getDateFromValue = useCallback((value: Date) => {
    const val = moment(value, DefaultDateTimeFormatFE);
    return val && val.isValid()
      ? val.format(DefaultDateTimeFormatFE)
      : undefined;
  }, []);
  return (
    <>
      <Box sx={DateInputStyles.dateContainer}>
        <Box>
          <Typography
            variant="subtitle2"
            id="custom-input"
            sx={{
              fontWeight: 600,
            }}>
            {columnProps['FilterHeader']
              ? columnProps['FilterHeader']
              : render('Header')}
          </Typography>
        </Box>
        <Box sx={styles.dateRow}>
          <DateTimeInput
            boldLabel
            row
            label={t(`objectInputs.From`)}
            labelRowWidth={'10%'}
            inputRowWidth={'90%'}
            value={filterValue[0] ? filterValue[0] : ''}
            onChange={(date) => {
              const newVal = getDateFromValue(date);
              setFilter((old = []) => {
                return newVal || old[1] ? [newVal, old[1]] : undefined;
              });
            }}
          />
        </Box>
        <Box sx={styles.dateRow}>
          <DateTimeInput
            boldLabel
            row
            label={t(`objectInputs.To`)}
            labelRowWidth={'10%'}
            inputRowWidth={'90%'}
            value={filterValue[1] ? filterValue[1] : ''}
            onChange={(date) => {
              const newVal = getDateFromValue(date);
              setFilter((old = []) => {
                return newVal || old[0] ? [old[0], newVal] : undefined;
              });
            }}
          />
        </Box>
      </Box>
    </>
  );
};
