import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ScreenTab, ScreenTabs} from '../ScreenTabs';
import {TableEvents} from './TableEvents';

export const TabsEvents = (): JSX.Element => {
  const {t} = useTranslation();
  const tabs = useMemo(
    (): ScreenTab[] => [
      {key: 'events', tab: <TableEvents />, name: t('group.Groups')},
    ],
    [t]
  );

  return <ScreenTabs tabs={tabs} />;
};
