import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  deleteObj,
  GenericState,
  getBaseObjList,
  getObj,
  getObjListFromIds,
} from '@skczu/czu-frontend-library';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  CreateOrUpdate3dObject,
  Object3d,
  Object3DApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d';
import {
  ObjectRef,
  ObjectRefFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Tile3D} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d/api';
import {setLoading} from '../LoadingSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {getToken} from '../../../keycloak';

export interface Object3dState extends GenericState<ObjectRef, Object3d> {
  tileset3dUrlList: string[];
}

const initialState: Object3dState = {
  tileset3dUrlList: [],
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const object3dSlice = createGenericSlice({
  name: 'object3dList',
  initialState,
})({
  setTileset3dUrlList: (
    state: Object3dState,
    {payload}: PayloadAction<string[]>
  ) => {
    state.tileset3dUrlList = payload;
  },
});

export const getBaseObject3dList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<ObjectRef, ObjectRefFilterResponse>(
        getState().object3dList.baseObjSearch,
        getState().object3dList.baseObjList,
        async () =>
          new Object3DApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).object3DFilterGet(keyword, 9999, 0),
        (loading) => dispatch(object3dActions.setObjLoadingList(loading)),
        () => dispatch(showErrorMessage()),
        (offset) => dispatch(object3dActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(
              object3dActions.setBaseObjList(newDataList ? newDataList : [])
            );
          } else {
            dispatch(object3dActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(object3dActions.hasMore(hasMore))
      )
    );
  };

export const getTileset3dUrlList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await new Object3DApi(
      new Configuration({accessToken: await getToken()}),
      config.cmsRestUrl
    ).object3DTilesetsFilterGet();
    if (response?.data) {
      dispatch(object3dActions.setTileset3dUrlList(response.data));
    }
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(setLoading(false));
  }
};

export const getObject3d =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Object3d>(
        id,
        async () =>
          new Object3DApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).object3DIdGet(id),
        (loading) => dispatch(object3dActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(object3dActions.setObj(obj));
        }
      )
    );
  };

export const getObject3dListFromIds =
  (object3dIds: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getObjListFromIds<Object3d>(
        object3dIds,
        getState().object3dList.objList,
        async (id) =>
          new Object3DApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).object3DIdGet(id),
        (loading) => dispatch(object3dActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (objList) => {
          dispatch(object3dActions.setObjList(objList));
        }
      )
    );
  };

export const deleteObject3d =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new Object3DApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).object3DIdDelete(id),
        (loading) => dispatch(object3dActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(object3dActions.setObj(null));
          dispatch(object3dActions.removeFromBaseObjList(id));
          dispatch(getTileset3dUrlList());
        }
      )
    );
  };

export const createObject3dAndImages3d =
  (object3d: CreateOrUpdate3dObject, image3dList?: Tile3D[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      if (image3dList) {
        const image3dIds: string[] = [];
        for (const image3d of image3dList) {
          const response = await new Object3DApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).object3DTile3DPost(image3d);
          if (response?.data.id) {
            image3dIds.push(response.data.id);
          }
        }
        object3d.tiles3D = image3dIds ? image3dIds : [];
      }
      const id = await new Object3DApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).object3DPost({...object3d, objectType: 'CreateOrUpdate3dObject'});
      if (id?.data.id) {
        dispatch(object3dActions.setObj(null));
        dispatch(
          object3dActions.addToBaseObjListAsFirst({...object3d, id: id.data.id})
        );
        dispatch(object3dActions.setOpenObjDialog(false));
      }
      dispatch(getTileset3dUrlList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateObject3dAndImages3d =
  (object3d: CreateOrUpdate3dObject, image3dList?: Tile3D[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      if (image3dList) {
        const image3dIds: string[] = [];
        for (const image3d of image3dList) {
          if (!image3d.id) {
            const response = await new Object3DApi(
              new Configuration({accessToken: await getToken()}),
              config.cmsRestUrl
            ).object3DTile3DPost(image3d);
            if (response?.data.id) {
              image3dIds.push(response.data.id);
            }
          } else {
            image3dIds.push(image3d.id);
          }
        }
        object3d.tiles3D = image3dIds ? image3dIds : [];
      }
      const success = await new Object3DApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).object3DPut({...object3d, objectType: 'CreateOrUpdate3dObject'});
      if (success && object3d.id) {
        dispatch(object3dActions.setObj(null));
        dispatch(object3dActions.updateBaseObjInList(object3d));
        dispatch(object3dActions.setOpenObjDialog(false));
      }
      dispatch(getTileset3dUrlList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const object3dActions = object3dSlice.actions;

export const object3dSelector = (state: RootState): typeof state.object3dList =>
  state.object3dList;

export const selectTileset3dUrlList = (
  state: RootState
): typeof state.object3dList.tileset3dUrlList =>
  state.object3dList.tileset3dUrlList;

export default object3dSlice.reducer;
