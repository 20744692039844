import React, {FormEventHandler, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {useTranslation} from 'react-i18next';
import {SwitchPrimary} from '../../components/switches/SwitchPrimary';
import {Controller} from 'react-hook-form';
import SelectRoles from '../../components/selects/select-multiple/SelectRoles';
import TextInputPrimary from '../../components/text-inputs/TextInputPrimary';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {UserForm} from './DialogUser';
import {useAppSelector} from '../../../../hooks/hooks';
import {selectSimpleUserList} from '../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {KeycloakUser} from '@skczu/czu-frontend-library/build/apis/keycloak-user-service-api/generated';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 3,
    px: 8,
  },
  headerContainer: {
    // textAlign: 'center',
  },
  row: {
    ml: 4,
  },
  name: {
    py: 2,
    px: 2,
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export interface UserFormControlsProps
  extends Partial<UseFormReturn<UserForm>> {
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
  addNew: boolean;
  user?: KeycloakUser | null;
}

export const UserFormControls = ({
  onSubmit,
  control,
  user,
  watch,
  addNew,
  trigger,
}: UserFormControlsProps): JSX.Element => {
  const {t} = useTranslation();
  const simpleUserList = useAppSelector(selectSimpleUserList);

  const email = watch && watch('email');
  useEffect(() => {
    email && email !== user?.email && trigger && trigger('email');
  }, [email, trigger, user?.email]);

  return (
    <form onSubmit={onSubmit}>
      <Box sx={styles.container}>
        <Box sx={styles.headerContainer}>
          {!addNew ? (
            <Box marginTop={2} textAlign={'center'}>
              <Typography
                variant={'subtitle2'}
                sx={{fontSize: 20, fontWeight: 'bold'}}>
                {watch && watch('email')}
              </Typography>
              <Typography variant={'subtitle2'}>
                {user?.isCzuUser ? 'ČZU' : t('user.External')}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box sx={styles.name}>
          <Controller
            name={'firstName'}
            control={control}
            rules={{required: true, minLength: 2, maxLength: 80}}
            defaultValue={''}
            render={({field, fieldState: {error}}) => (
              <TextInputPrimary
                {...field}
                boldLabel
                required
                label={t(`objectInputs.First name`)}
                errorType={error?.type}
              />
            )}
          />
          <Box sx={styles.row}>
            <Controller
              name={'lastName'}
              control={control}
              rules={{required: true, minLength: 2, maxLength: 80}}
              defaultValue={''}
              render={({field, fieldState: {error}}) => (
                <TextInputPrimary
                  {...field}
                  boldLabel
                  required
                  label={t(`objectInputs.Last name`)}
                  errorType={error?.type}
                />
              )}
            />
          </Box>
        </Box>

        <Box
          sx={
            addNew ? styles.name : {...styles.name, justifyContent: 'center'}
          }>
          {addNew ? (
            <Controller
              name={'email'}
              control={control}
              rules={{
                required: true,
                minLength: 2,
                maxLength: 80,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
                validate: {
                  isUnique: (value) =>
                    isUniqueUserEmail(value, simpleUserList) ||
                    'User with this email address already exists',
                },
              }}
              defaultValue={''}
              render={({field, fieldState: {error}}) => (
                <TextInputPrimary
                  {...field}
                  boldLabel
                  required
                  label={t(`objectInputs.Email`)}
                  errorType={error?.type}
                  errorMessage={error?.message}
                />
              )}
            />
          ) : null}
          <Box sx={addNew ? styles.row : undefined}>
            <Controller
              name="roles"
              control={control}
              defaultValue={[]}
              render={({field, fieldState: {error}}) => (
                <SelectRoles
                  {...field}
                  label={t(`user.Roles`)}
                  errorType={error?.type}
                  defaultRole
                />
              )}
            />
          </Box>
        </Box>
        {!addNew ? (
          <Box marginTop={4}>
            <Controller
              name="status"
              control={control}
              defaultValue={false}
              render={({field, fieldState: {error}}) => (
                <SwitchPrimary
                  {...field}
                  boldLabel
                  label={t(`user.Active`)}
                  errorType={error?.type}
                  row
                />
              )}
            />
          </Box>
        ) : null}
        <Box marginTop={8}>
          <ButtonAdd
            name={addNew ? t('user.Add new user') : t('user.Update user')}
          />
        </Box>
      </Box>
    </form>
  );
};
export const isUniqueUserEmail = (
  email: string | undefined,
  simpleUserList: KeycloakUser[]
): boolean => {
  return !simpleUserList?.some((role) => role.email === email);
};
