import {useCallback, useRef} from 'react';
import {
  Cartesian3,
  Cartographic,
  Color,
  CustomDataSource,
  Math as CesiumMath,
  PolylineDashMaterialProperty,
  Viewer as CesiumViewer,
} from 'cesium';

export type FlyToPointEvent = (
  position: number[],
  show3dObjects: boolean,
  height: number
) => void;

interface MapEntitiesController {
  removeSelectedPoi: () => void;
  highlightSelectPoi: (lon: number, lat: number) => void;
  removeRoute: () => void;
  flyToPoint: FlyToPointEvent;
  addNewRoute: (
    positions: Cartesian3[],
    setRouteEntities: (routeDataSource: CustomDataSource) => void
  ) => void;
}

export const useMapEntitiesController = (
  viewer: CesiumViewer | null,
  show3dObjects: boolean
): MapEntitiesController => {
  const selectedPoi = useRef<CustomDataSource | null>();

  const removeSelectedPoi = useCallback(() => {
    selectedPoi?.current?.entities && selectedPoi.current.entities.removeAll();
    viewer?.scene.requestRender();
  }, [viewer?.scene]);

  const flyToPoint = useCallback(
    (position: number[], show3dObjects: boolean, height = 400) => {
      !show3dObjects
        ? viewer?.camera.flyTo({
            duration: 1.5,
            destination: Cartesian3.fromDegrees(
              position[0],
              position[1],
              height
            ),
          })
        : viewer?.camera.flyTo({
            duration: 1.5,
            destination: Cartesian3.fromDegrees(
              position[0],
              position[1] - 0.0045,
              height
            ),
            orientation: {
              heading: CesiumMath.toRadians(0.0),
              pitch: CesiumMath.toRadians(-50.0),
              roll: 0.0,
            },
          });
    },
    [viewer]
  );

  const highlightSelectPoi = useCallback(
    (lon: number, lat: number) => {
      if (viewer) {
        const position = selectedPoi.current?.entities
          .getById('SelectedDot')
          ?.position?.getValue(viewer.clock.currentTime);
        const cord = position && Cartographic.fromCartesian(position);
        const oldLat = cord && CesiumMath.toDegrees(cord.latitude).toFixed(4);
        const oldLon = cord && CesiumMath.toDegrees(cord.longitude).toFixed(4);
        if (oldLat !== lat.toFixed(4) && oldLon !== lon.toFixed(4)) {
          removeSelectedPoi();
          const poiSelectionDataSource = new CustomDataSource('poiSelection');
          poiSelectionDataSource.entities.add({
            id: 'SelectedDot',
            position: Cartesian3.fromDegrees(lon, lat),
            billboard: {
              image: '/assets/icon/DotFinal.svg',
              scale: 0.08,
              heightReference: 1,
            },
          });
          selectedPoi.current = poiSelectionDataSource;
          flyToPoint([Number(lon) - 0.003, Number(lat)], show3dObjects, 600.0);
          viewer && viewer.dataSources.add(poiSelectionDataSource);
        }
      }
    },
    [flyToPoint, removeSelectedPoi, show3dObjects, viewer]
  );

  const addNewRoute = useCallback(
    (
      positions: Cartesian3[],
      setRouteEntities: (routeDataSource: CustomDataSource) => void
    ) => {
      if (viewer) {
        const routeDataSource = new CustomDataSource('route');
        routeDataSource.entities.add({
          id: 'startRoute',
          name: 'startRoute',
          position: positions[0],
          point: {
            pixelSize: 20,
            color: Color.fromBytes(118, 255, 94),
          },
        });
        routeDataSource.entities.add({
          id: 'endRoute',
          name: 'endRoute',
          position: positions[positions.length - 1],
          point: {
            pixelSize: 10,
            color: Color.RED,
          },
        });
        routeDataSource.entities.add({
          id: 'directionRoute',
          name: 'directionRoute',
          polyline: {
            positions: positions,
            width: 7,
            material: new PolylineDashMaterialProperty({
              color: Color.DEEPSKYBLUE,
              dashLength: 10,
            }),
            clampToGround: true,
          },
        });
        setRouteEntities(routeDataSource);
      }
    },
    [viewer]
  );

  const removeRoute = useCallback(() => {
    if (viewer) {
      const source = viewer.dataSources.getByName('route');
      if (source[0]) {
        viewer.dataSources.remove(source[0]);
        viewer.scene.requestRender();
      }
    }
  }, [viewer]);

  return {
    removeSelectedPoi,
    highlightSelectPoi,
    removeRoute,
    flyToPoint,
    addNewRoute,
  };
};
