import React from 'react';
import {UploadFile} from './useUploadPhoto';
import config from '../config';
import {Configuration} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {Poi360Api} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {getToken} from '../keycloak';

export const useUploadPoi360Photo = (
  onVideoSet?: (res: Poi360WithData) => void
): {
  uploadProgress: UploadFile;
  addPhoto: (photo: Poi360WithData) => void;
} => {
  const [uploadProgress, setUploadProgress] = React.useState<UploadFile>({
    progress: 0,
    state: 'new',
  });

  const onUploadProgress = (data: ProgressEvent) => {
    const percentCompleted = Math.round((data.loaded * 100) / data.total) - 1;
    setUploadProgress({
      progress: percentCompleted,
      state: 'upload',
    });
  };

  const addPhoto = async (poi360: Poi360WithData) => {
    try {
      const response = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360Post(
        {...poi360, objectType: 'Poi360WithData'},
        {
          onUploadProgress: onUploadProgress,
        }
      );
      if (response?.data) {
        onVideoSet && onVideoSet({...poi360, id: response.data.id});
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setUploadProgress({
        progress: uploadProgress.progress,
        state: 'new',
      });
    }
  };

  return {uploadProgress, addPhoto};
};
