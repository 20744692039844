import React from 'react';
import {
  Box,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {
  CustomTable,
  CustomTableCell,
} from '../../../components/custom-inputs/TableBase';
import {ButtonPrimary} from '../../../components/buttons/ButtonPrimary';
import makeStyles from '@mui/styles/makeStyles';
import {useAppSelector} from '../../../../../hooks/hooks';
import {selectSelectedPoi} from '../../../../../redux/reducers/PoiListSelectionSlice';
import {useTranslation} from 'react-i18next';
import {CustomUtils} from '../../../utils/CustomUtils';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `3px solid ${theme.palette.secondary.light}`,
  },
}));

export interface Props {
  onPoiSelection: () => void;
  onRemove?: (poiId: string) => void;
}

export const TabRoutePoi = ({onPoiSelection, onRemove}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const poiSelection = useAppSelector(selectSelectedPoi);

  return (
    <Box>
      <Box marginTop={1}>
        <ButtonPrimary
          boldLabel
          label={t('route.Edit route')}
          name={t('route.Edit route')}
          onClick={() => onPoiSelection()}
        />
      </Box>
      <Box>
        <Box m={0.5} marginTop={4}>
          <Typography variant="subtitle2" sx={{fontWeight: 600}}>
            {t('route.List of POI')}
          </Typography>
        </Box>
        <Box width={800} marginBottom={5}>
          <CustomTable classes={{root: classes.table}}>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t('route.Name')}</CustomTableCell>
                <CustomTableCell align="right">
                  {t('route.Action')}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poiSelection.poiList?.map((poi) => (
                <TableRow key={poi.name}>
                  <CustomTableCell component="th" scope="row">
                    {CustomUtils.getContentByLanguage(poi.name, poi.nameEn)}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={() => onRemove && poi.id && onRemove(poi.id)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </CustomTable>
        </Box>
      </Box>
    </Box>
  );
};
