import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {
  ObjectPreview,
  MobilePreviewSceneData,
} from '@skczu/czu-frontend-library';
import {Language} from '@skczu/czu-frontend-library/src/types/types/CommonTypes';

export interface ObjectPreviewView {
  openObjectPreview?: boolean;
}

interface DrawerState<T> {
  openDrawer: boolean;
  openDrawerMenu: boolean;
  objectPreview: ObjectPreview<T>;
  objectPreviewView: ObjectPreviewView;
  polScrollIndex?: string;
  language: Language;
}

const initialState: DrawerState<MobilePreviewSceneData> = {
  openDrawer: false,
  openDrawerMenu: false,
  objectPreview: {
    objectMobileScene: null,
  },
  objectPreviewView: {
    openObjectPreview: false,
  },
  language: 'cs',
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setOpenDrawer: (state, {payload}: PayloadAction<boolean>) => {
      state.openDrawer = payload;
    },
    setOpenDrawerMenu: (state, {payload}: PayloadAction<boolean>) => {
      state.openDrawerMenu = payload;
    },
    setLanguage: (state, {payload}: PayloadAction<Language>) => {
      state.language = payload;
    },
    setPoiScrollIndex: (
      state,
      {payload}: PayloadAction<string | undefined>
    ) => {
      state.polScrollIndex = payload;
    },
    setObjectPreviewView: (
      state,
      {payload}: PayloadAction<ObjectPreviewView>
    ) => {
      state.objectPreviewView = payload;
    },
    setObjectToMobileScene: (
      state,
      {payload}: PayloadAction<ObjectPreview<MobilePreviewSceneData>>
    ) => {
      state.objectPreview = payload;
    },
    setActiveObjectPreview: (
      state,
      {
        payload,
      }: PayloadAction<{
        objectPreview: ObjectPreview<MobilePreviewSceneData>;
        objectPreviewView: ObjectPreviewView;
      }>
    ) => {
      state.objectPreview = payload.objectPreview;
      state.objectPreviewView = payload.objectPreviewView;
    },
    updateActiveObjectPreviewData: (
      state,
      {payload}: PayloadAction<MobilePreviewSceneData>
    ) => {
      state.objectPreview.objectPreviewData = payload;
    },
    clearObjectPreview: (state) => {
      state.objectPreview.objectMobileScene = null;
      state.objectPreview.objectPreviewData = null;
      state.objectPreviewView.openObjectPreview = false;
    },
  },
});

export const {
  setOpenDrawer,
  setOpenDrawerMenu,
  setObjectPreviewView,
  setObjectToMobileScene,
  setActiveObjectPreview,
  setPoiScrollIndex,
  clearObjectPreview,
  setLanguage,
  updateActiveObjectPreviewData,
} = drawerSlice.actions;

export const selectDrawer = (state: RootState): typeof state.drawer =>
  state.drawer;

export const appLanguageSelector = (
  state: RootState
): typeof state.drawer.language => state.drawer.language;

export const selectObjectPreview = (state: RootState) => {
  const {objectPreviewView, objectPreview} = state.drawer;
  return {objectPreviewView, objectPreview};
};

export default drawerSlice.reducer;
