import React, {MouseEventHandler} from 'react';
import {Box, Button} from '@mui/material';
import {ButtonPrimaryStyle} from './ButtonPrimary';

const styles = {
  buttonContainer: {
    margin: '0 5px 0 5px',
  },
};

export interface Props {
  name: string;
  onClick?: MouseEventHandler;
}

export const ButtonTable = ({name, onClick}: Props): JSX.Element => {
  return (
    <Box sx={styles.buttonContainer}>
      <Button
        type="submit"
        variant="contained"
        sx={ButtonPrimaryStyle}
        onClick={onClick}>
        {name}
      </Button>
    </Box>
  );
};
