import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ButtonUploadImage} from '../../../components/buttons/ButtonUploadImage';
import {useTranslation} from 'react-i18next';
import {UploadFile} from '../../../../../hooks/useUploadPhoto';
import {Control, UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {LinearProgressWithLabel} from '../../../components/loading-indicator/LinearProgress';
import {Delete} from '@mui/icons-material';
import {Controller, Path, PathValue} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {Video} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';

const DefaultVideoValue = {url: '', data: '', fileName: '', id: undefined};

export interface GalleryVideoContainerProps<
  T extends FieldValues & {video?: Video}
> {
  control?: Control<T>;
  onUploadVideo?: (event: Video) => void;
  onRemoveVideo?: (event: Video) => void;
  border?: boolean;
  video?: Video;
  videoUploadState?: UploadFile;
}
export const GalleryVideoContainer = <T extends FieldValues & {video?: Video}>({
  onUploadVideo,
  control,
  border = true,
  onRemoveVideo,
  video,
  videoUploadState,
}: GalleryVideoContainerProps<T>): JSX.Element => {
  const {t} = useTranslation();
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '50%',
    },
    listContainer: {
      marginTop: 20,
      display: 'flex',
      minHeight: 115,
      border: border ? `2px solid ${theme.palette.secondary.light}` : undefined,
      width: '100%',
      borderRadius: 10,
      boxShadow: theme.shadows[2],
      flexDirection: 'column',
    },
    videoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }));
  const classes = useStyles();
  const handleDeleteClick = () => {
    if (video) {
      onRemoveVideo && onRemoveVideo(DefaultVideoValue);
    }
  };

  return (
    <Box m={1} className={classes.container}>
      <Controller
        name={'video' as Path<T>}
        control={control}
        defaultValue={
          DefaultVideoValue as UnpackNestedValue<PathValue<T, Path<T>>>
        }
        render={({field, fieldState: {error}}) => (
          <ButtonUploadImage
            {...field}
            boldLabel
            label={t(`objectGallery.Upload gallery video`)}
            buttonText={t('objectGallery.Upload video')}
            errorType={error?.type}
            acceptedExtensions={'.mp4,.hevc,.mov'}
            onChange={(event) => {
              onUploadVideo && onUploadVideo(event);
              field.onChange(event);
            }}
          />
        )}
      />

      <Box className={classes.listContainer}>
        <Box m={1}>
          <Box m={1} className={classes.videoContainer}>
            {video?.fileName && (
              <Typography variant={'subtitle2'}>{video.fileName}</Typography>
            )}
            {videoUploadState?.state === 'upload' && (
              <LinearProgressWithLabel value={videoUploadState?.progress} />
            )}
            {video?.fileName && videoUploadState?.state !== 'upload' && (
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={handleDeleteClick}>
                <Delete fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
