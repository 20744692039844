import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../shared/components/buttons/ButtonAdd';
import {DialogLayer} from '../../../../../../shared/dialogs/dialog-layer/DialogLayer';
import {
  CustomListItems,
  ListItemRow,
} from '../../../../../../shared/components/lists/CustomListItems';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks/hooks';
import {
  layersSelector,
  layersActions,
  getBaseLayerList,
  getLayer,
  deleteLayer,
  updateLayer,
  createLayer,
} from '../../../../../../../redux/reducers/data-reducers/LayersSlice';
import {
  poiSelectionSelector,
  setPoiSelectionList,
} from '../../../../../../../redux/reducers/PoiListSelectionSlice';
import {
  routeSelectionSelector,
  setRouteSelectionList,
} from '../../../../../../../redux/reducers/RouteListSelectionSlice';
import {SearchInput} from '../../../../../../shared/components/search/SearchInput';
import {StylesMenu} from '../../StylesMenu';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../../../shared/dialogs/dialog-info/DialogInfo';
import {CustomUtils} from '../../../../../../shared/utils/CustomUtils';
import {useInfiniteListSearch} from '@skczu/czu-frontend-library';

import {useInfoDialog} from '../../../../../../../hooks/useInfoDialog';

export const ListOfLayersTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const dispatch = useAppDispatch();
  const {
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingList,
    loadingDetail,
    hasMore,
  } = useAppSelector(layersSelector);
  const {openDialogAfterPoiSelection} = useAppSelector(poiSelectionSelector);
  const {openDialogAfterRouteSelection} = useAppSelector(
    routeSelectionSelector
  );
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deleteLayer(id)),
    });
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    layersActions,
    dispatch,
    baseObjSearch,
    getBaseLayerList
  );

  useEffect(() => {
    openDialogAfterPoiSelection &&
      dispatch(layersActions.setOpenObjDialog(openDialogAfterPoiSelection));
  }, [dispatch, openDialogAfterPoiSelection]);

  useEffect(() => {
    openDialogAfterRouteSelection &&
      dispatch(layersActions.setOpenObjDialog(openDialogAfterRouteSelection));
  }, [dispatch, openDialogAfterRouteSelection]);

  const handleEditClick = (id: string) => {
    dispatch(layersActions.setEditObjDetail());
    dispatch(getLayer(id));
  };

  return (
    <Box>
      <HeaderMenuItem name={t('leftMenu.List of layers')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          name={'layer-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <CustomListItems
        listItems={
          baseObjList
            ? baseObjList.map((layer) => {
                return {
                  id: layer.id,
                  itemRowName: CustomUtils.getContentByLanguage(
                    layer.name,
                    layer.nameEn
                  ),
                  activeItem: false,
                } as ListItemRow;
              })
            : []
        }
        handleEditClick={handleEditClick}
        handleDeleteClick={showInfoDialog}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
      <Box>
        <ButtonAdd
          name={t('layer.Add new layer')}
          onClick={() => {
            dispatch(layersActions.setAddNewObjDetail());
            dispatch(layersActions.setObj(null));
          }}
        />
      </Box>
      {openObjDialog && (
        <DialogLayer
          addNew={addNewObj}
          layer={obj}
          loading={loadingDetail}
          openDialog={openObjDialog}
          onCloseDialog={() => {
            dispatch(layersActions.setOpenObjDialog(false));
            dispatch(setPoiSelectionList([]));
            dispatch(setRouteSelectionList([]));
          }}
          onAddLayer={(layer) => {
            dispatch(setPoiSelectionList([]));
            dispatch(setRouteSelectionList([]));
            dispatch(createLayer(layer));
          }}
          onUpdateLayer={(layer) => {
            dispatch(setPoiSelectionList([]));
            dispatch(setRouteSelectionList([]));
            dispatch(updateLayer(layer));
          }}
          onObjectSelection={(tmpLayer) =>
            dispatch(layersActions.setObj(tmpLayer))
          }
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.layer')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.layer'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
    </Box>
  );
};
