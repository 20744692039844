import React, {FunctionComponent, useMemo, useRef} from 'react';
import {
  BillboardGraphics,
  CesiumComponentRef,
  Entity,
  ImageryLayer,
  Viewer,
  CustomDataSource as ResiumCustomDataSource,
} from 'resium';
import {Cartesian3, Entity as CesiumEntity} from 'cesium';
import '@znemz/cesium-navigation/dist/index.css';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import './MapStyles.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Feature, Point} from 'geojson';
import {MapLoadingIndicator} from '@skczu/czu-react-components';
import config from '../../../../config';
import {getCategoryPinFromCategory, useMap} from './useMap';
import {useAppSelector} from '../../../../hooks/hooks';
import {selectMapLoading} from '../../../../redux/reducers/LoadingSlice';

export type Images = {[key: string]: HTMLImageElement};

const useStyles = makeStyles(() =>
  createStyles({
    map: {
      width: '100%',
      height: '100vh',
    },
  })
);
const imageryProviderUrl = config.osmWmsUrl;
const imageryProviderLayers = 'osm:osm';

export const MapComponent: FunctionComponent = () => {
  const isMapLoading = useAppSelector(selectMapLoading);
  const classes = useStyles();
  const {
    ref,
    viewer,
    mapLoaded,
    markersList,
    categoryPinBuilderIcons,
    onMapClick,
    defaultImageryProvider,
    handleSelectPoi,
  } = useMap(
    imageryProviderUrl,
    imageryProviderLayers
    // () => {
    //   if (ref.current) {
    //
    //   ref.current.cesiumElement = new CesiumViewer(cesiumElement.canvas
    //   }
    // )
  );

  const mapEntityRef = useRef<
    Record<string, CesiumComponentRef<CesiumEntity> | null>
  >({});

  const showEntities = useMemo(() => {
    if (mapLoaded && categoryPinBuilderIcons.length > 0) {
      return (
        <>
          {markersList?.features.map((feature: Feature<Point>, index) => {
            markersList?.features.length - 1 === index &&
              viewer?.scene.requestRender();
            return (
              <Entity
                ref={(el) =>
                  feature.id && (mapEntityRef.current[feature.id] = el)
                }
                id={'map_poi_' + feature.id}
                key={feature.id}
                position={Cartesian3.fromDegrees(
                  feature.geometry.coordinates[0],
                  feature.geometry.coordinates[1],
                  0
                )}
                onClick={() =>
                  feature.id && handleSelectPoi(String(feature.id))
                }>
                <BillboardGraphics
                  show
                  image={getCategoryPinFromCategory(
                    feature.properties?.category_id,
                    categoryPinBuilderIcons
                  )?.toDataURL('image/png')}
                  width={22}
                  height={30}
                  verticalOrigin={1}
                  heightReference={1}
                />
              </Entity>
            );
          })}
        </>
      );
    }
  }, [
    mapLoaded,
    categoryPinBuilderIcons,
    markersList?.features,
    viewer?.scene,
    handleSelectPoi,
  ]);

  const renderMap = useMemo(() => {
    try {
      return (
        <Viewer
          id={'cesiumContainer'}
          className={classes.map}
          baseLayerPicker={false}
          homeButton={false}
          navigationHelpButton={false}
          fullscreenButton={false}
          infoBox={false}
          projectionPicker={false}
          scene3DOnly={true}
          selectionIndicator={false}
          timeline={false}
          navigationInstructionsInitiallyVisible={false}
          animation={false}
          sceneModePicker={false}
          imageryProvider={false}
          geocoder={false}
          onClick={onMapClick}
          ref={ref}>
          <ImageryLayer
            key={imageryProviderUrl}
            imageryProvider={defaultImageryProvider}
          />
          <ResiumCustomDataSource>{showEntities}</ResiumCustomDataSource>
        </Viewer>
      );
    } catch (error) {
      console.log('renderMap');
      console.log(error);
      return null;
    }
  }, [classes.map, defaultImageryProvider, onMapClick, ref, showEntities]);

  return (
    <>
      <MapLoadingIndicator showLoading={isMapLoading} />
      <MapLoadingIndicator showLoading={!mapLoaded} />
      {renderMap}
    </>
  );
};
