import React, {ChangeEvent, ForwardedRef, forwardRef} from 'react';
import {Box, Button} from '@mui/material';
import {AddCircleRounded} from '@mui/icons-material';
import {FormModel} from '../../../../hooks/useForm';
import {InputCommonStyles} from '../../styles/StylesInput';
import {useTranslation} from 'react-i18next';
import {readFileContent, readMultipleFilesContent} from '../../utils/Utils';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {
  DamFile,
  DamFileWithData,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {ButtonPrimaryStyle} from './ButtonPrimary';
import {InputLabel, InputLabelProps} from '../input-label/InputLabel';

export const DefaultImageValue: DamFileWithData = {
  data: '',
  fileName: '',
  id: undefined,
};
export interface UploadImage extends FormModel, InputLabelProps {
  withIcon?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
  value?: DamFileWithData | DamFileWithData[] | DamFile | DamFile[];
  acceptedExtensions?: string;
  boldLabel?: boolean;
  multiple?: boolean;
  objectType?: string;
}

export const ButtonUploadImage = forwardRef(
  (props: UploadImage, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    const {
      buttonText,
      name,
      label,
      className,
      onChange,
      icon,
      errorType,
      withIcon,
      acceptedExtensions,
      multiple,
      objectType,
      boldLabel,
      row,
      required,
    } = props;
    const {t} = useTranslation();
    const inputStyles = InputCommonStyles;

    const handleUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (multiple) {
        files &&
          Array.from(files).length !== 0 &&
          readMultipleFilesContent(files)
            .then((result) => {
              onChange &&
                onChange(result.map((file) => ({...file, objectType})));
            })
            .catch((err) => {
              // console.log(err);
            });
      } else {
        files &&
          files[0] &&
          onChange &&
          readFileContent(files[0]).then((result) => {
            onChange({...result, objectType});
          });
      }
    };

    return (
      <Box>
        <InputLabel
          label={label}
          row={row}
          required={required}
          boldLabel={boldLabel}>
          <label htmlFor={'upload-photo_' + name}>
            <input
              accept={
                acceptedExtensions
                  ? acceptedExtensions
                  : '.jpg,.jpeg,.png,.heic'
              }
              id={'upload-photo_' + name}
              type="file"
              hidden
              ref={ref}
              multiple={multiple}
              onChange={handleUploadCapture}
            />

            <Button
              variant="contained"
              sx={{
                height: 46,
                width: 200,
                ...ButtonPrimaryStyle,
                ...(errorType && inputStyles.error),
              }}
              className={className}
              component="span"
              startIcon={withIcon && (icon ? icon : <AddCircleRounded />)}>
              {buttonText ? buttonText : t('objectGallery.Upload image')}
            </Button>
          </label>
          {errorType && (
            <InputErrorMessage inputName={label} errorType={errorType} />
          )}
        </InputLabel>
      </Box>
    );
  }
);
ButtonUploadImage.displayName = 'ButtonUploadImage';

export default ButtonUploadImage;
