import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {MobileScreenPoiDetail} from '@skczu/czu-react-components';
import {TabItem, TabsDialog} from '../shared/tabs-dialog/TabsDialog';
import {TabDetailPoi} from './tabs/TabDetailPoi';
import {StylesDialog} from '../StylesDialog';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {selectPoiCoords} from '../../../../redux/reducers/MapSlice';
import {
  poi360Selector,
  setPoi360List,
} from '../../../../redux/reducers/data-reducers/Poi360Slice';
import {
  poiSelectionSelector,
  setPoiSelection,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {useTranslation} from 'react-i18next';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {useUploadPhoto} from '../../../../hooks/useUploadPhoto';
import {useUploadVideo} from '../../../../hooks/useUploadVideo';
import {ObjMapper, SelectedImagePreview} from '@skczu/czu-frontend-library';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {Tab360view} from './tabs/Tab360view';
import {TabAr} from './tabs/tab-ar/TabAr';
import {CustomUtils} from '../../utils/CustomUtils';
import {setObjectPreviewView} from '../../../../redux/reducers/DrawerSlice';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdatePoi,
  Poi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Poi360} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {DamFile} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import {
  foodMenuActions,
  foodMenuSelector,
  getRestaurantDailyMenu,
  getRestaurantData,
  getRestaurantMenu,
} from '../../../../redux/reducers/data-reducers/FoodMenuSlice';
import moment from 'moment/moment';
import {deleteVideo} from '../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {ObjectPreviewWithFormContainer} from '../../components/mobile-preview/ObjectPreviewWithFormContainer';

export type PoiForm = Omit<
  Poi,
  | 'id'
  | 'category'
  | 'poi360s'
  | 'video'
  | 'photos'
  | 'image'
  | 'arImage'
  | 'posImage'
  | 'file3D'
> & {
  category?: string;
  poi360s?: DamFileWithData[];
  video?: DamFileWithData;
  image?: DamFileWithData | DamFile;
  arImage?: DamFileWithData | DamFile;
  posImage?: DamFileWithData | DamFile;
  file3D?: DamFileWithData | DamFile;
};

export interface Props {
  poi?: Poi | null;
  openDialog: boolean;
  addNew: boolean;
  loading: boolean;
  onCloseDialog: (clearObject: boolean) => void;
  onAddPoi?: (poi: CreateOrUpdatePoi) => void;
  onUpdatePoi?: (poi: CreateOrUpdatePoi) => void;
  onObjectSelection?: (poi: Poi) => void;
  imagePreview?: SelectedImagePreview;
}

export const DialogPoi = ({
  openDialog,
  addNew,
  onCloseDialog,
  poi,
  loading,
  onAddPoi,
  onUpdatePoi,
  onObjectSelection,
  imagePreview,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = StylesDialog();
  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = useState<TabItem>({
    name: 'Info',
    label: t('poi.Info'),
  });
  const {coords} = useAppSelector(selectPoiCoords);
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    watch,
  } = useForm<PoiForm>();
  const {
    allergens,
    restaurantDailyMenu,
    restaurantWeekMenu,
    restaurantDetail,
    restaurantMenu,
    selectedWeekDates,
  } = useAppSelector(foodMenuSelector);
  const {poi360List} = useAppSelector(poi360Selector);
  const {openDialogAfterPoiSelection} = useAppSelector(poiSelectionSelector);
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const {galleryPhotoList, addGalleryPhotos} = useUploadPhoto('poi');
  const {videoUploadProgress, addGalleryVideo} = useUploadVideo((newVideo) => {
    setValue(
      'video',
      CustomUtils.convertCreateVideoResponseToDamFile(newVideo)
    );
  });

  useEffect(() => {
    if (poi && openDialog) {
      setValue('name', poi.name);
      setValue('nameEn', poi.nameEn);
      setValue('category', poi.category?.id);
      setValue('showOnMap', poi.showOnMap);
      setValue('image', poi.image);
      setValue('coordinates.latitude', poi.coordinates?.latitude);
      setValue('coordinates.longitude', poi.coordinates?.longitude);
      setValue('layers', poi.layers);
      setValue('foodPointId', poi.foodPointId || '');
      setValue('body', poi.body);
      setValue('bodyEn', poi.bodyEn);
      setValue(
        'video',
        CustomUtils.convertCreateVideoResponseToDamFile(poi.video)
      );
      setValue('view360', poi.view360);
      setValue('enabledArImage', poi.enabledArImage);
      setValue('posImage', poi.posImage);
      setValue('file3D', poi.file3D);
      setValue('posImageWidth', poi.posImageWidth);
      setValue('object3dPosition.x', poi.object3dPosition?.x);
      setValue('object3dPosition.y', poi.object3dPosition?.y);
      setValue('object3dPosition.z', poi.object3dPosition?.z);
      setValue('scale.x', poi.scale?.x);
      setValue('scale.y', poi.scale?.y);
      setValue('scale.z', poi.scale?.z);
      setValue('rotation.x', poi.rotation?.x);
      setValue('rotation.y', poi.rotation?.y);
      setValue('rotation.z', poi.rotation?.z);
    }
    if (openDialog && !poi) {
      reset();
      if (coords) {
        setValue('coordinates.latitude', coords.latitude as string);
        setValue('coordinates.longitude', coords.longitude as string);
      }
    }
  }, [poi, coords, setValue, reset, openDialog]);

  const createPoiTmp = (): Poi => {
    const poi360Ids = poi360List.map((poiObj) => poiObj.id);
    const poi360s: Poi360[] = [];
    for (const poi360Id of poi360Ids) {
      const poi360 = poi360List.filter(
        (poi360Obj) => poi360Obj.id === poi360Id
      );
      if (poi360 && poi360.length > 0) {
        poi360s.push(poi360[0]);
      }
    }
    const photos = CustomUtils.getPhotosFromGalleryPhotos(galleryPhotoList);
    const video = CustomUtils.convertDamFileToCreateVideoResponse(
      getValues('video')
    );
    return {
      id: poi?.id ? poi?.id : undefined,
      ...getValues(),
      category: {id: getValues('category')},
      coordinates: {
        longitude: getValues('coordinates.longitude'),
        latitude: getValues('coordinates.latitude'),
      },
      poi360s: poi360s ? poi360s : undefined,
      video,
      photos,
      object3dPosition: {
        x: getValues('object3dPosition.x'),
        y: getValues('object3dPosition.y'),
        z: getValues('object3dPosition.z'),
      },
      scale: {
        x: getValues('scale.x'),
        y: getValues('scale.y'),
        z: getValues('scale.z'),
      },
      rotation: {
        x: getValues('rotation.x'),
        y: getValues('rotation.y'),
        z: getValues('rotation.z'),
      },
    };
  };

  useEffect(() => {
    openDialogAfterPoiSelection &&
      setActivePage(
        openDialogAfterPoiSelection
          ? {name: '360 view', label: t('poi.360 view')}
          : {name: 'Info', label: t('poi.Info')}
      );
  }, [openDialogAfterPoiSelection, t]);

  useEffect(() => {
    poi && poi.poi360s && openDialog && dispatch(setPoi360List(poi.poi360s));
  }, [poi, openDialog, dispatch]);

  const foodPointId = watch('foodPointId');

  useEffect(() => {
    dispatch(foodMenuActions.setRestaurantMenus());
    dispatch(foodMenuActions.setRestaurantWeekMenu());
    dispatch(foodMenuActions.setRestaurantDailyMenu());
    dispatch(foodMenuActions.setRestaurantDetail());
    foodPointId &&
      dispatch(
        getRestaurantMenu(foodPointId, () => {
          foodPointId &&
            dispatch(
              getRestaurantDailyMenu(
                foodPointId,
                moment().format('YYYY-MM-DD'),
                true,
                false
              )
            );
          foodPointId && dispatch(getRestaurantData(foodPointId));
        })
      );
  }, [foodPointId, dispatch, poi]);

  const onDailyMenuDateChanged = useCallback(
    (date: string, newWeek: boolean) => {
      foodPointId &&
        dispatch(
          getRestaurantDailyMenu(
            foodPointId,
            moment(date).format('YYYY-MM-DD'),
            newWeek,
            true
          )
        );
    },
    [dispatch, foodPointId]
  );

  const onErrorSubmit: SubmitErrorHandler<PoiForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit: SubmitHandler<PoiForm> = (data) => {
    const photoIds = galleryPhotoList.map(
      (galleryPhoto) => galleryPhoto.galleryPhoto?.id as string
    );
    const poi360Ids = poi360List.map((poi360Obj) => poi360Obj.id);
    const newPoi = {
      ...data,
      foodPointId: data?.foodPointId ? data.foodPointId : undefined,
      photos: photoIds,
      poi360s: poi360Ids ? (poi360Ids as string[]) : undefined,
      video: data?.video?.id ? data.video.id : undefined,
      image: ObjMapper.getDamFileWithDataFromDamFile(data.image),
      arImage: ObjMapper.getDamFileWithDataFromDamFile(data.arImage),
      posImage: ObjMapper.getDamFileWithDataFromDamFile(data.posImage),
      file3D: ObjMapper.getDamFileWithDataFromDamFile(data.file3D),
    };
    if (addNew) {
      onAddPoi && onAddPoi(newPoi);
    } else {
      onUpdatePoi &&
        onUpdatePoi({
          ...newPoi,
          id: poi?.id,
        });
    }
  };

  const handleSelectGlobalPoi = () => {
    dispatch(
      setObjectPreviewView({
        openObjectPreview: false,
      })
    );
    dispatch(setPoiSelection({poiSelection: true, poiSelectionFrom: 'poi360'}));
    const tmpNews = createPoiTmp();
    onObjectSelection && tmpNews && onObjectSelection(tmpNews);
    onCloseDialog(false);
  };

  const handleCloseDialog = () => {
    reset();
    dispatch(setPoi360List([]));
    onCloseDialog(true);
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const PoiTabs: TabItem[] = [
    {
      name: 'Info',
      label: t('poi.Info'),
      invalid: !!(
        errors.name ||
        errors.body ||
        errors.category ||
        errors.image ||
        errors.coordinates?.latitude ||
        errors.coordinates?.longitude
      ),
    },
    {
      name: '360 view',
      label: t('poi.360 view'),
    },
    {
      name: 'AR',
      label: t('poi.AR'),
      invalid: !!(
        errors.posImage ||
        errors.posImageWidth ||
        errors.file3D ||
        errors.object3dPosition?.x ||
        errors.object3dPosition?.y ||
        errors.object3dPosition?.z ||
        errors.scale?.x ||
        errors.scale?.y ||
        errors.scale?.z ||
        errors.rotation?.x ||
        errors.rotation?.y ||
        errors.rotation?.z
      ),
    },
  ];

  const foodMenuData = useMemo(() => {
    return {
      restaurantMenu: restaurantMenu ? restaurantMenu : undefined,
      restaurantDetail: restaurantDetail ? restaurantDetail : undefined,
      restaurantWeekMenu: restaurantWeekMenu,
      restaurantDailyMenu: restaurantDailyMenu
        ? restaurantDailyMenu
        : undefined,
      selectedWeekDates: selectedWeekDates ? selectedWeekDates : undefined,
    };
  }, [
    restaurantDailyMenu,
    restaurantDetail,
    restaurantMenu,
    restaurantWeekMenu,
    selectedWeekDates,
  ]);

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('poi.Add new POI') : t('poi.Edit POI')}
        open={openDialog}
        minWidth={'1300px'}
        onCloseDialog={handleCloseDialog}
        loading={loading}
        contentLanguage={true}>
        <Box className={classes.menu}>
          <TabsDialog
            activeItem={activePage}
            handleNavigation={(page) => setActivePage(page)}
            items={PoiTabs}
          />
        </Box>
        <ObjectPreviewWithFormContainer
          t={t}
          id={poi?.id}
          objType={'poi'}
          objPreview={
            <MobileScreenPoiDetail
              t={t}
              poiName={
                selectedLanguage === ContentLanguage.EN
                  ? watch('nameEn')
                  : watch('name')
              }
              vrButton={!!watch('view360')}
              arButton={!!watch('enabledArImage')}
              imageFile={watch('image')}
              bodyTextHtml={
                selectedLanguage === ContentLanguage.EN
                  ? watch('bodyEn')
                  : watch('body')
              }
              allergens={allergens}
              foodMenuData={watch('foodPointId') ? foodMenuData : undefined}
              galleryPhotos={galleryPhotoList.map(
                (photo) => photo.galleryPhoto as DamImage
              )}
              video={{
                id: watch('video')?.id,
                name: watch('video')?.fileName,
                url: watch('video')?.data,
              }}
              imagePreview={imagePreview}
              onDailyMenuDateChanged={onDailyMenuDateChanged}
            />
          }
          objEditInputs={
            <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
              <Box marginLeft={4}>
                <Box
                  style={{
                    display: activePage.name === 'Info' ? 'block' : 'none',
                  }}>
                  <TabDetailPoi
                    control={control}
                    addNew={addNew}
                    watch={watch}
                    setValue={setValue}
                    galleryPhotoList={galleryPhotoList}
                    selectedLanguage={selectedLanguage}
                    onUploadPhotos={(photos) =>
                      addGalleryPhotos(poi?.id, photos)
                    }
                    onUploadVideo={addGalleryVideo}
                    onRemoveVideo={(id) => dispatch(deleteVideo(id))}
                    videoUploadState={videoUploadProgress}
                  />
                </Box>
                <Box
                  style={{
                    display: activePage.name === '360 view' ? 'block' : 'none',
                  }}>
                  <Tab360view
                    poi={poi}
                    watch={watch}
                    control={control}
                    onCloseDialog={() => onCloseDialog(false)}
                    handleSelectGlobalPoi={handleSelectGlobalPoi}
                  />
                </Box>
                <Box
                  style={{
                    display: activePage.name === 'AR' ? 'block' : 'none',
                  }}>
                  <TabAr control={control} watch={watch} setValue={setValue} />
                </Box>
                <Box>
                  <ButtonAdd
                    name={addNew ? t('poi.Add new POI') : t('poi.Update POI')}
                  />
                </Box>
              </Box>
            </form>
          }
        />
      </DialogPrimary>
    </Box>
  );
};
