import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {StylesAdminTab} from '../../StylesAdminTab';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {findFilterById, useLocalStorage} from '../../../../shared/table/utils';
import {FilterParams, Table} from '../../../../shared/table';
import {getTableRolesColumns} from './TableRolesColumns';
import {DialogRole} from '../../../../shared/dialogs/dialog-role/DialogRole';
import {
  BaseRolesRequest,
  deleteRole,
  deleteRoles,
  getBaseRoleList,
  getRoleFromList,
  rolesActions,
  rolesSelector,
  updateRole,
} from '../../../../../redux/reducers/data-reducers/RolesSlice';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {useInfoDialog} from '../../../../../hooks/useInfoDialog';
import {DialogInfo} from '../../../../shared/dialogs/dialog-info/DialogInfo';
import {
  DialogItemSelect,
  DialogItemType,
  DialogItemButtonTypes,
  getTitleFromType,
} from '../../../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {TableInstance} from 'react-table';
import {InputModelValueType} from '../../../../../hooks/useForm';

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}
export enum RoleSortBy {
  Name = 'NAME',
  NumberOfUsers = 'NUMBER_OF_USERS',
  Type = 'TYPE',
}

export type AdminRoleObj = {
  name?: string;
  numberOfUsers?: number;
  roleType?: string;
  action: string;
};

export type RoleObj = AdminRoleObj & {
  subRows?: RoleObj[];
};

export const TableRoles = (): JSX.Element => {
  const {t} = useTranslation();
  const tabClasses = StylesAdminTab();
  const dispatch = useAppDispatch();
  const {obj, adminList, openObjDialog, loadingList, loadingDetail, addNewObj} =
    useAppSelector(rolesSelector);
  const [tablePageIndex, setTablePageIndex] = useState<number>(0);
  const [multiSelect, setMultiSelect] = React.useState<
    DialogItemType<AdminRoleObj>
  >({
    openDialog: false,
  });

  const [initialState, setInitialState] = useLocalStorage(
    'tableState:tableRoles',
    {
      pageSize: 10,
      pageIndex: 0,
    }
  );
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) =>
        id &&
        dispatch(
          deleteRole(id, () => {
            dispatch(getBaseRoleList(createRequest(initialState)));
          })
        ),
    });
  const setState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      setInitialState(value);
    },
    [setInitialState]
  );

  const handleEditClick = useCallback(
    (key: string) => {
      dispatch(rolesActions.setEditObjDetail());
      dispatch(getRoleFromList(key));
    },
    [dispatch]
  );

  const createRequest = useCallback(
    (params: FilterParams<AdminRoleObj>): BaseRolesRequest => {
      const filteredName = findFilterById(params.filters, 'name')?.value;
      const filteredRoleType = findFilterById(
        params.filters,
        'roleType'
      )?.value;
      const filteredMinMaxUser = findFilterById(
        params.filters,
        'numberOfUsers'
      )?.value;
      const name = filteredName ? filteredName : undefined;
      const minUser =
        filteredMinMaxUser && filteredMinMaxUser[0]
          ? filteredMinMaxUser[0]
          : undefined;
      const maxUser =
        filteredMinMaxUser && filteredMinMaxUser[1]
          ? filteredMinMaxUser[1]
          : undefined;
      console.log(filteredRoleType);
      const isCzuRole = filteredRoleType
        ? filteredRoleType === 'ČZU'
        : undefined;
      console.log(isCzuRole);
      let sortOrder;
      let orderBy;
      if (params?.sortBy && params?.sortBy[0]) {
        sortOrder = params.sortBy[0].desc ? Order.Desc : Order.Asc;
        orderBy =
          params.sortBy[0].id === 'name'
            ? RoleSortBy.Name
            : params?.sortBy[0].id === 'numberOfUsers'
            ? RoleSortBy.NumberOfUsers
            : params?.sortBy[0].id === 'roleType'
            ? RoleSortBy.Type
            : undefined;
      }
      return {
        name,
        sortOrder,
        orderBy,
        minUser,
        maxUser,
        isCzuRole,
        limit: params.pageSize,
        offset: params.pageIndex * params.pageSize,
      };
    },
    []
  );

  const getRolesData = useCallback(
    (params: FilterParams<AdminRoleObj>) => {
      if (!loadingList) {
        setState(params);
        dispatch(getBaseRoleList(createRequest(params)));
      }
    },
    [createRequest, dispatch, loadingList, setState]
  );

  const getRoutesList = useMemo(() => {
    t('');
    return adminList.list.map((dataObj) => {
      return {
        ...dataObj,
        name: CustomUtils.getContentByLanguage(dataObj.name, dataObj.nameEn),
        action: dataObj?.key as string,
        roleType: dataObj.czu ? 'ČZU' : t('role.Internal'),
      };
    });
  }, [adminList.list, t]);

  const handleMultiSelect = useCallback(
    (
        instance: TableInstance<AdminRoleObj>,
        type: DialogItemType<AdminRoleObj>
      ) =>
      () => {
        setMultiSelect({
          ...type,
          selectedRows: instance.selectedFlatRows.map((v) => v.original),
        });
      },
    []
  );

  const renderTable = useMemo(() => {
    return (
      <Table<RoleObj>
        t={t}
        name={'tableRoles'}
        multiselectButtonTypes={['delete']}
        totalCount={adminList.totalCount}
        rowSelection
        columns={getTableRolesColumns({
          onEditClick: handleEditClick,
          onDeleteClick: showInfoDialog,
          t,
        })}
        autoResetPage={false}
        tablePageIndex={tablePageIndex}
        pageCount={
          adminList?.totalCount ? Math.ceil(adminList.totalCount / 10) : 0
        }
        onChangePage={(page) => setTablePageIndex(page)}
        tableState={initialState}
        setTableState={setState}
        data={getRoutesList}
        setMultiSelect={handleMultiSelect}
        onFetchData={getRolesData}
        loadingList={loadingList}
        manualFilters
        manualSortBy
        manualPagination
      />
    );
  }, [
    adminList.totalCount,
    getRolesData,
    getRoutesList,
    handleEditClick,
    handleMultiSelect,
    initialState,
    loadingList,
    setState,
    showInfoDialog,
    t,
    tablePageIndex,
  ]);

  const onAgreeMultiSelect = useCallback(
    (
      type: DialogItemButtonTypes,
      value: InputModelValueType,
      selectedRows: Array<AdminRoleObj>
    ) => {
      switch (type) {
        case 'delete':
          dispatch(
            deleteRoles(
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseRoleList(createRequest(initialState)))
            )
          );
          break;
      }
      setMultiSelect({
        openDialog: false,
        id: undefined,
      });
    },
    [createRequest, dispatch, initialState]
  );

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('role.Add role')}
          onClick={() => {
            dispatch(rolesActions.setObj(null));
            dispatch(rolesActions.setAddNewObjDetail());
          }}
        />
      </Box>
      {renderTable}
      {openObjDialog ? (
        <DialogRole
          addNew={addNewObj}
          role={obj}
          openDialog={openObjDialog}
          loading={loadingDetail}
          onCloseDialog={() => {
            dispatch(rolesActions.setObj(null));
            dispatch(rolesActions.setOpenObjDialog(false));
          }}
          onAdd={(role) => {
            dispatch(
              updateRole(true, role, () =>
                dispatch(getBaseRoleList(createRequest(initialState)))
              )
            );
          }}
          onUpdate={(role) => {
            dispatch(
              updateRole(false, role, () =>
                dispatch(getBaseRoleList(createRequest(initialState)))
              )
            );
          }}
        />
      ) : null}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.role')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.role'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogItemSelect
        title={t(`user.${getTitleFromType(multiSelect.type)}`)}
        text={
          t(`user.${getTitleFromType(multiSelect.type)}`) +
          ', ' +
          t('user.Roles') +
          ': ' +
          (multiSelect?.selectedRows?.length || '0')
        }
        id={multiSelect.id}
        value={multiSelect.value}
        openDialog={multiSelect.openDialog}
        type={multiSelect.type}
        selectedRows={multiSelect.selectedRows}
        onCloseDialog={() =>
          setMultiSelect({
            openDialog: false,
            id: undefined,
          })
        }
        onChangeValue={(event, selectedRows) => {
          setMultiSelect((select) => {
            return {...select, value: event, selectedRows};
          });
        }}
        onAgree={(value, selectedRows) =>
          multiSelect.type &&
          onAgreeMultiSelect(multiSelect.type, value, selectedRows)
        }
      />
    </Box>
  );
};
