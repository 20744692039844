import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {setLoading} from '../LoadingSlice';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  Hotspot,
  Poi360,
  Poi360Api,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {PoiApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {ObjMapper} from '@skczu/czu-frontend-library';
import {getToken} from '../../../keycloak';

export interface Poi360ListError {
  message: string;
}

interface Poi360State {
  poi360List: Poi360[];
  poi360: Poi360 | null;
  poi360ForUpdate: Poi360 | null;
  error: Poi360ListError;
}

const initialState: Poi360State = {
  poi360List: [],
  poi360: null,
  poi360ForUpdate: null,
  error: {message: 'An Error occurred'},
};

export const poi360Slice = createSlice({
  name: 'poi360',
  initialState,
  reducers: {
    setPoi360List: (state, {payload}: PayloadAction<Poi360[]>) => {
      state.poi360List = payload;
    },
    setPoi360: (state, {payload}: PayloadAction<Poi360 | null>) => {
      state.poi360 = payload;
    },
    setPoi360ForUpdate: (state, {payload}: PayloadAction<Poi360 | null>) => {
      state.poi360ForUpdate = payload;
    },
    setPoi360ListFailed: (state, {payload}: PayloadAction<Poi360ListError>) => {
      state.error = payload;
    },
    addToPoi360List: (state, {payload}: PayloadAction<Poi360>) => {
      state.poi360List = state.poi360List
        ? [...state.poi360List, payload]
        : [payload];
    },
    updatePoi360InList: (state, {payload}: PayloadAction<Poi360>) => {
      const poi360List = state.poi360List.map((poi360) =>
        poi360.id === payload.id ? payload : poi360
      );
      state.poi360List = [...poi360List];
    },
    removeFrom360List: (state, {payload}: PayloadAction<string>) => {
      const poi360List = state.poi360List.filter(
        (poi360) => poi360.id !== payload
      );
      state.poi360List = [...poi360List];
    },
  },
});
export const getPoiPoi360Ids =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const poiDetail = await new PoiApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poiIdGet(id);
      if (poiDetail?.data.poi360s) {
        dispatch(setPoi360List(poiDetail.data.poi360s));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getPoi360 =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const groupDetail = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360IdGet(id);
      groupDetail?.data && dispatch(setPoi360(groupDetail.data));
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getPoi360ForUpdate =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const groupDetail = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360IdGet(id);
      groupDetail?.data && dispatch(setPoi360ForUpdate(groupDetail.data));
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deletePoi360 =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const success = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360IdDelete(id);
      if (success) {
        dispatch(setPoi360({}));
        dispatch(removeFrom360List(id));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createPoi360 =
  (poi360: Poi360WithData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const id = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360Post({...poi360, objectType: 'Poi360WithData'});
      if (id?.data) {
        poi360.id = id.data.id;
        dispatch(setPoi360({}));
        dispatch(addToPoi360List(poi360));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createPoi360AndHotspot =
  (
    newPoi360: Poi360WithData,
    fromPoi360: Poi360,
    newHotspot: Hotspot,
    showName: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      newPoi360.showName = showName;
      const id = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360Post({...newPoi360, objectType: 'Poi360WithData'});
      if (id?.data) {
        newPoi360.id = id.data.id;
        dispatch(setPoi360(null));
        dispatch(addToPoi360List(newPoi360));
        const poi360WithHotspot = Object.assign({}, fromPoi360);
        newHotspot.poi360 = id.data.id;
        newHotspot.name = newPoi360.name;
        newHotspot.nameEn = newPoi360.nameEn;
        newHotspot.showName = showName;

        const poi360: Poi360WithData = {
          ...poi360WithHotspot,
          image: ObjMapper.getDamFileWithDataFromDamFile(
            poi360WithHotspot.image
          ),
          hotspot: poi360WithHotspot.hotspot
            ? [...poi360WithHotspot.hotspot, newHotspot]
            : [newHotspot],
        };
        dispatch(updatePoi360(poi360));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updatePoi360AndHotspot =
  (
    updatedPoi360: Poi360WithData,
    fromPoi360: Poi360,
    updatedHotspot: Hotspot,
    showName: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const newFromPoi360 = Object.assign({}, fromPoi360);
      newFromPoi360.hotspot = fromPoi360.hotspot?.filter(
        (hotspot) =>
          hotspot.yaw !== updatedHotspot.yaw &&
          hotspot.pitch !== updatedHotspot.pitch
      );
      const newUpdatedHotspot = {
        ...updatedHotspot,
        name: updatedPoi360.name,
        nameEn: updatedPoi360.nameEn,
        showName: showName,
      };
      newFromPoi360.hotspot?.push(newUpdatedHotspot);
      const poi360: Poi360WithData = {
        ...newFromPoi360,
        image: ObjMapper.getDamFileWithDataFromDamFile(newFromPoi360.image),
      };
      dispatch(setPoi360(null));
      dispatch(updatePoi360(poi360));
      updatedPoi360.showName = showName;
      const success = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360Put({...updatedPoi360, objectType: 'Poi360WithData'});
      if (success) {
        dispatch(updatePoi360InList(updatedPoi360));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updatePoi360 =
  (poi360: Poi360WithData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const success = await new Poi360Api(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).poi360Put({...poi360, objectType: 'Poi360WithData'});
      if (success) {
        poi360.id && dispatch(getPoi360(poi360.id));
        dispatch(updatePoi360InList(poi360));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(setLoading(false));
    }
  };

export const {
  setPoi360,
  setPoi360ForUpdate,
  setPoi360List,
  addToPoi360List,
  updatePoi360InList,
  setPoi360ListFailed,
  removeFrom360List,
} = poi360Slice.actions;

export const poi360Selector = (state: RootState): typeof state.poi360 =>
  state.poi360;

export default poi360Slice.reducer;
