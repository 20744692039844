import React, {useCallback, useMemo} from 'react';
import {BaseObject} from './select-multiple/useSelectAllMultiselect';
import {Utils} from '@skczu/czu-frontend-library';

export interface SelectSearchProps<T extends BaseObject> {
  list?: T[];
}

export const useSelectSearch = <T extends BaseObject>({
  list,
}: SelectSearchProps<T>): {
  filteredList?: T[];
  setQuery?: (query: string) => void;
  handleSearchChange?: (query: string) => void;
} => {
  const [query, setQuery] = React.useState('');

  const filteredList = useMemo((): T[] | undefined => {
    return Utils.getFilteredOptionsByName(list, query || '');
  }, [list, query]);

  const handleSearchChange = useCallback((query: string) => {
    setQuery && setQuery(query);
  }, []);

  return {filteredList, setQuery, handleSearchChange};
};
