import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import React, {useCallback, useMemo} from 'react';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {ListItemsMenu} from '../ListItemsMenu';
import {HeaderMenuItem} from '../HeaderMenuItem';
import Divider from '@mui/material/Divider';
import {Box, ListItemButton} from '@mui/material';
import {ListOfLayersTab} from './tabs/ListOfLayersTab';
import {layersActions} from '../../../../../../redux/reducers/data-reducers/LayersSlice';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {StylesMenu} from '../StylesMenu';
import {useTranslation} from 'react-i18next';

export const RoutesLayerTab = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const dispatch = useAppDispatch();
  const classes = StylesMenu();

  const handleOnClick = useCallback(
    (item) => history.push(`${url}/${item}`),
    [history, url]
  );

  const handleAddNew = useCallback(() => {
    dispatch(layersActions.setAddNewObjDetail());
    handleOnClick('layerList');
  }, [dispatch, handleOnClick]);

  const renderTabs = useMemo(
    () =>
      ListItemsMenu.layers.items.map(({label, key}) => (
        <Box key={key}>
          <ListItemButton
            key={key}
            onClick={() =>
              key === 'newLayer' ? handleAddNew() : handleOnClick(key)
            }>
            <ListItemText primary={t(`leftMenu.${label}`)} />
          </ListItemButton>
          <Divider light={true} />
        </Box>
      )),
    [handleAddNew, handleOnClick, t]
  );

  return (
    <Box className={classes.menu}>
      <Route exact path={`${path}`}>
        <div>
          <HeaderMenuItem name={t('leftMenu.layers')} />
          <List>{renderTabs}</List>
        </div>
      </Route>
      <Route path={`${path}/layerList`}>
        <ListOfLayersTab />
      </Route>
    </Box>
  );
};
