import React, {forwardRef} from 'react';
import {CustomInput} from '../custom-inputs/InputBase';
import {InputStyles} from '../../styles/StylesInput';
import {FormModel} from '../../../../hooks/useForm';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {InputLabel, InputLabelProps} from '../input-label/InputLabel';

export interface TextInputPrimaryProps extends FormModel, InputLabelProps {
  multiline?: boolean;
  minRows?: string | number;
}

export const TextInputPrimary = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      disabled,
      errorType,
      errorMessageTranslationPrefix,
      style,
      errorMessage,
      boldLabel,
      row,
      required,
      labelRowWidth,
      inputRowWidth,
      multiline,
      minRows,
    }: TextInputPrimaryProps,
    ref
  ): JSX.Element => {
    const classes = InputStyles();

    return (
      <InputLabel
        label={label}
        row={row}
        required={required}
        labelRowWidth={labelRowWidth}
        inputRowWidth={inputRowWidth ? inputRowWidth : '100%'}
        boldLabel={boldLabel}>
        <CustomInput
          disabled={disabled}
          className={className + ' ' + (errorType ? classes.error : ' ')}
          style={style}
          id={id}
          ref={ref}
          name={name}
          value={value}
          minRows={minRows}
          multiline={multiline}
          onChange={onChange}
        />
        {errorType && (
          <InputErrorMessage
            inputName={label}
            errorType={errorType}
            errorMessage={errorMessage}
            errorMessageTranslationPrefix={errorMessageTranslationPrefix}
          />
        )}
      </InputLabel>
    );
  }
);

TextInputPrimary.displayName = 'TextInputPrimary';

export default TextInputPrimary;
