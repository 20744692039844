import {FilterProps} from 'react-table';
import {useAppSelector} from '../../../hooks/hooks';
import {selectSimpleRouteList} from '../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {SelectRoutes} from '../components/selects/select-multiple/SelectRoutes';
import React from 'react';
import {getObjIdsByName, getObjNameByIds} from '../utils/Utils';

export const SelectRoutesFilter = <T extends Record<string, unknown>>({
  column: {filterValue, render, setFilter, id},
}: FilterProps<T>): JSX.Element => {
  const simpleRouteList = useAppSelector(selectSimpleRouteList);

  return (
    <SelectRoutes
      name={id + '_id'}
      label={render('Header')}
      value={getObjIdsByName(simpleRouteList, filterValue) || []}
      onChange={(val) => {
        setFilter(getObjNameByIds(simpleRouteList, val as string[]));
      }}
    />
  );
};
