import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';

export interface Props {
  poiCategory?: PoiCategory;
}

export const CategoryListIcon = ({poiCategory}: Props): JSX.Element => {
  const useStyles = makeStyles(() => ({
    iconContainer: {
      borderRadius: 100,
      width: 36,
      height: 36,
      backgroundColor: poiCategory?.color,
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.iconContainer}>
      <img
        alt={poiCategory?.name}
        src={poiCategory && poiCategory.icon && poiCategory.icon.url}
        height={26}
        width={26}
      />
    </Box>
  );
};
