export const checkIsFilled = (expression?: string): string | undefined =>
  expression;

export const checkIsTrue = (expression: string): string => expression;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const checkIsNumber = (value?: any): string | boolean => {
  const rgx = /^-?(\d)*(\.(\d)*)?$/g;
  return rgx.test(value as string) || 'Input must be a number';
};
