import React, {useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {DialogPrimary} from '../DialogPrimary';
import TextInputPrimary from '../../components/text-inputs/TextInputPrimary';
import {KeycloakRole} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import {removeNonLetters} from '../../utils/Utils';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {
  getSimpleRoleList,
  selectSimpleRoleList,
} from '../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {ListItemRowWithIcons} from '@skczu/czu-react-components';
import {InfoLabel} from '../../components/info-label/InfoLabel';

const useStyles = makeStyles(() => ({
  routeName: {
    width: '100%',
    fontSize: 30,
  },
  admin: {
    // width: '60%',
  },
  container: {
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export type RoleForm = Omit<KeycloakRole, 'numberOfUsers'>;

export interface Props {
  role?: KeycloakRole | null;
  addNew: boolean;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAdd: (role: KeycloakRole) => void;
  onUpdate: (role: KeycloakRole) => void;
}
export const DialogRole = ({
  openDialog,
  role,
  loading,
  addNew,
  onCloseDialog,
  onAdd,
  onUpdate,
}: Props): JSX.Element => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {control, handleSubmit, trigger, reset, watch, setValue} =
    useForm<RoleForm>();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const dispatch = useAppDispatch();
  const simpleRoleList = useAppSelector(selectSimpleRoleList);

  useEffect(() => {
    dispatch(getSimpleRoleList());
  }, [dispatch]);

  useEffect(() => {
    if (role) {
      setValue('name', role.name);
      setValue('nameEn', role.nameEn);
      setValue('key', role.key);
      setValue('description', role.description);
      setValue('descriptionEn', role.descriptionEn);
    }
    if (!role || addNew) {
      reset();
    }
  }, [reset, setValue, role, t, addNew]);

  const onValidSubmit: SubmitHandler<RoleForm> = (formData) => {
    const data = {...formData, key: removeNonLetters(formData.key || '')};
    if (addNew) {
      onAdd && onAdd(data);
    } else {
      onUpdate && onUpdate({...data, id: role?.id});
    }
  };

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const roleName = watch('name');
  useEffect(() => {
    addNew && setValue('key', getUniqueRoleKey(roleName || '', simpleRoleList));
    roleName && roleName !== role?.name && trigger('name');
  }, [addNew, setValue, roleName, simpleRoleList, trigger, role?.name]);

  return (
    <Box>
      <DialogPrimary
        title={t('user.Role')}
        open={openDialog}
        loading={loading}
        minWidth={'600px'}
        minHeight={'450px'}
        contentLanguage={true}
        onCloseDialog={handleCloseDialog}>
        <form onSubmit={handleSubmit(onValidSubmit)}>
          <Box className={classes.container}>
            <Box marginTop={2}>
              <TextInputObjectName
                selectedLanguage={selectedLanguage}
                control={control}
                // disabled
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 40,
                  validate: {
                    isUnique: (value) => {
                      return (
                        (addNew
                          ? isUniqueRoleName(value as string, simpleRoleList)
                          : isUniqueRoleName(value as string, simpleRoleList)
                          ? true
                          : value === role?.name) ||
                        'Role with this name already exists'
                      );
                    },
                  },
                }}
                required
                boldLabel
                notPrimaryName
                onChangeName={() => {
                  addNew && trigger('key');
                }}
              />
            </Box>

            <Box marginTop={2.5}>
              <TextInputObjectName
                boldLabel
                multiline
                minRows={3}
                selectedLanguage={selectedLanguage}
                control={control}
                // disabled
                notPrimaryName
                rules={{maxLength: 250}}
                name={'description'}
                nameEn={'descriptionEn'}
                label={t(`allObjects.Description`)}
                labelEn={t(`allObjects.DescriptionEn`)}
              />
            </Box>
            <Box marginTop={2.5}>
              <Box sx={{width: '60%', marginLeft: '-18px'}}>
                <ListItemRowWithIcons
                  color={'primary.main'}
                  title={
                    <Typography
                      sx={{fontSize: 15, fontWeight: 'bold'}}
                      color={'primary'}>
                      {t('role.Advanced options')}
                    </Typography>
                  }
                  collapseContent={
                    <Controller
                      name="key"
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: true,
                        minLength: 2,
                        maxLength: 40,
                        validate: addNew
                          ? {
                              isUnique: (value) =>
                                isUniqueRoleKey(value, simpleRoleList) ||
                                'This unique identifier is already in use',
                            }
                          : undefined,
                      }}
                      render={({field, fieldState: {error}}) => {
                        return (
                          <TextInputPrimary
                            {...field}
                            boldLabel
                            required
                            onChange={(val) => {
                              field.onChange(val);
                              addNew && trigger('key');
                            }}
                            disabled={!addNew}
                            className={classes.routeName}
                            label={t(`role.Unique identifier`)}
                            errorType={error?.type}
                            errorMessage={error?.message}
                          />
                        );
                      }}
                    />
                  }
                />
              </Box>
            </Box>
            {role?.czu ? (
              <InfoLabel text={t('role.This role is ČZU role')} />
            ) : null}
            {/*<Box marginTop={4} className={classes.admin}>*/}
            {/*  <Controller*/}
            {/*    name="adminAccess"*/}
            {/*    control={control}*/}
            {/*    defaultValue={false}*/}
            {/*    render={({field, fieldState: {error}}) => (*/}
            {/*      <SwitchPrimary*/}
            {/*        {...field}*/}
            {/*        row*/}
            {/*        // label={t(`role.Administration access`)}*/}
            {/*        label={*/}
            {/*          <Typography variant={'h6'}>*/}
            {/*            {t('role.Administration access')}*/}
            {/*          </Typography>*/}
            {/*        }*/}
            {/*        errorType={error?.type}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  />*/}
            {/*  {watch('adminAccess') && (*/}
            {/*    <Box marginTop={2} style={{marginRight: -20, marginLeft: -20}}>*/}
            {/*      <ListItemRowWithIcons*/}
            {/*        title={*/}
            {/*          <Typography variant={'h6'}>*/}
            {/*            {t('role.General access')}*/}
            {/*          </Typography>*/}
            {/*        }*/}
            {/*        collapseContent={*/}
            {/*          <Box style={{marginLeft: 20}}>*/}
            {/*            <Box marginTop={2}>*/}
            {/*              <Controller*/}
            {/*                name="name"*/}
            {/*                control={control}*/}
            {/*                defaultValue={''}*/}
            {/*                render={({field, fieldState: {error}}) => (*/}
            {/*                  <SelectEvents*/}
            {/*                    {...field}*/}
            {/*                    row*/}
            {/*                    value={[field.value]}*/}
            {/*                    label={t(`user.Groups`)}*/}
            {/*                    errorType={error?.type}*/}
            {/*                    selectAll={true}*/}
            {/*                  />*/}
            {/*                )}*/}
            {/*              />*/}
            {/*            </Box>*/}
            {/*            <Box marginTop={2}>*/}
            {/*              <Controller*/}
            {/*                name="name"*/}
            {/*                control={control}*/}
            {/*                defaultValue={''}*/}
            {/*                render={({field, fieldState: {error}}) => (*/}
            {/*                  <SelectEvents*/}
            {/*                    {...field}*/}
            {/*                    row*/}
            {/*                    value={[field.value]}*/}
            {/*                    label={t(`user.Groups`)}*/}
            {/*                    errorType={error?.type}*/}
            {/*                    selectAll={true}*/}
            {/*                  />*/}
            {/*                )}*/}
            {/*              />*/}
            {/*            </Box>*/}
            {/*          </Box>*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  )}*/}
            {/*</Box>*/}
          </Box>
          <Box sx={{m: 2, mt: 10}}>
            <ButtonAdd
              name={addNew ? t('role.Add role') : t('role.Edit role')}
            />
          </Box>
        </form>
      </DialogPrimary>
    </Box>
  );
};

const isUniqueRoleName = (
  roleName: string | undefined,
  simpleRoleList: KeycloakRole[]
): boolean => {
  const name = roleName || '';
  return !simpleRoleList?.some((role) => role.name === name);
};

const isUniqueRoleKey = (
  roleKey: string | undefined,
  simpleRoleList: KeycloakRole[]
): boolean => {
  const key = removeNonLetters(roleKey || '');
  return !(!key || simpleRoleList?.some((role) => role.key === key));
};

const nextChar = (char: string): string => {
  return String.fromCharCode(((char.charCodeAt(0) + 1 - 65) % 25) + 65);
};

const getUniqueRoleKey = (
  name: string,
  simpleRoleList: KeycloakRole[]
): string => {
  let key = removeNonLetters(name);
  if (!isUniqueRoleKey(key, simpleRoleList)) {
    while (!isUniqueRoleKey(key, simpleRoleList)) {
      const secondToLastChar = key.charAt(key.length - 2);
      if (secondToLastChar === '-') {
        const lastChar = key.charAt(key.length - 1);
        key = key.slice(0, -2) + '-' + nextChar(lastChar);
      } else {
        const uniqueChar = '-' + (key.length > 0 ? nextChar('@') : '');
        key = key + uniqueChar;
      }
    }
  }

  return key === '-' ? '' : key;
};
