import React from 'react';
import {Box} from '@mui/material';
import {StylesDialog} from '../../../StylesDialog';
import {SwitchPrimary} from '../../../../components/switches/SwitchPrimary';
import {GalleryPhotoContainer} from '../../../shared/gallery/GalleryPhotoContainer';
import {GalleryVideoContainer} from '../../../shared/gallery/GalleryVideoContainer';
import {useTranslation} from 'react-i18next';
import {ContentLanguage} from '../../../../../../redux/reducers/ContentLanguage';
import {UploadFile} from '../../../../../../hooks/useUploadPhoto';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {Controller} from 'react-hook-form';
import {CustomUtils} from '../../../../utils/CustomUtils';
import {TextInputObjectName} from '../../../../components/text-inputs/TextInputObjectName';
import {TextEditorObjectBody} from '../../../../components/text-edior/TextEditorObjectBody';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Video} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import Divider from '@mui/material/Divider';
import {VisibleInNewsAppControls} from './VisibleInNewsAppControls';
import {NewsNotificationControls} from './NewsNotificationControls';
import {NewsForm} from '../../DialogNews';
import {NewsNotificationState} from '../../../../../../redux/reducers/data-reducers/NewsSlice';

export interface Props extends Partial<UseFormReturn<NewsForm>> {
  newsNotificationState?: NewsNotificationState;
  galleryPhotoList?: UploadFile[];
  onUploadPhotos: (galleryPhotos: DamFileWithData[]) => void;
  onUploadVideo: (video: Video) => void;
  selectedLanguage?: ContentLanguage;
  videoUploadState?: UploadFile;
  onRemoveVideo?: (id: string) => void;
  onNotificationActionButtonClick?: (newNotification: boolean) => void;
  addNew?: boolean;
  notificationSendDate?: string;
  newsVisibleFromDate?: string;
  newsVisibleToDate?: string;
}

export const TabDetailNews = ({
  newsNotificationState,
  control,
  setValue,
  watch,
  galleryPhotoList,
  onUploadPhotos,
  addNew,
  onUploadVideo,
  onRemoveVideo,
  onNotificationActionButtonClick,
  selectedLanguage,
  videoUploadState,
  notificationSendDate,
  newsVisibleFromDate,
  newsVisibleToDate,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();

  return (
    <Box>
      <TextInputObjectName
        selectedLanguage={selectedLanguage}
        control={control}
        required
        boldLabel
      />
      <Box sx={{width: '100%'}}>
        <Divider sx={{borderBottomWidth: 3, mt: 4}} />
        <Box sx={{width: '50%', my: 1, ml: 1}}>
          <Controller
            name="visibleInApp"
            control={control}
            defaultValue={addNew}
            render={({field, fieldState: {error}}) => (
              <SwitchPrimary
                {...field}
                label={t(`news.Visible in news app`)}
                errorType={error?.type}
                row
                boldLabel
                inputTooltipText={t(
                  'news.This option sets visibility of news in the list of miniapp'
                )}
                onChange={(event) => {
                  if (watch && setValue) {
                    watch('pushNot') && setValue('pushNot', false);
                    setValue('visibleFromNow', true);
                  }
                  field.onChange(event);
                }}
              />
            )}
          />
        </Box>
        {watch && watch('visibleInApp') ? (
          <>
            <VisibleInNewsAppControls
              control={control}
              addNew={addNew}
              watch={watch}
              setValue={setValue}
              newsVisibleFromDate={newsVisibleFromDate}
              newsVisibleToDate={newsVisibleToDate}
            />
            <Divider sx={{borderBottomWidth: 3, mb: 1}} />
          </>
        ) : (
          <Divider sx={{borderBottomWidth: 3, mt: 1}} />
        )}
        <NewsNotificationControls
          addNew={addNew}
          control={control}
          watch={watch}
          setValue={setValue}
          notificationSendDate={notificationSendDate}
          newsNotificationState={newsNotificationState}
          onNotificationActionButtonClick={onNotificationActionButtonClick}
        />
        <Box sx={{mt: 4}}>
          <TextEditorObjectBody
            control={control}
            selectedLanguage={selectedLanguage}
            boldLabel
            required
          />
        </Box>
        <Box className={dialogClasses.gallery}>
          <GalleryPhotoContainer
            // imageInput={inputs[5]}
            galleryPhotoList={galleryPhotoList}
            onUploadFiles={onUploadPhotos}
          />
          <GalleryVideoContainer
            control={control}
            video={
              watch &&
              watch('video') &&
              CustomUtils.convertCreateVideoResponseToDamFile(watch('video'))
            }
            onRemoveVideo={(emptyVideo) => {
              onRemoveVideo &&
                watch &&
                watch('video') &&
                watch('video')?.id &&
                onRemoveVideo(watch('video')?.id as string);
              setValue && setValue('video', emptyVideo);
            }}
            onUploadVideo={(event) => {
              onUploadVideo(event);
            }}
            videoUploadState={videoUploadState}
          />
        </Box>
      </Box>
    </Box>
  );
};
