import React, {useCallback, useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {StylesAdminTab} from '../../../StylesAdminTab';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {Table} from '../../../../../shared/table';
import {useTranslation} from 'react-i18next';
import {getTableAppsColumns} from './TableAppsColumns';
import {
  appsActions,
  appsSelector,
  getApp,
  getAppIconList,
  getBaseAppsList,
  updateApp,
} from '../../../../../../redux/reducers/data-reducers/AppsSlice';
import {DialogApp} from '../../../../../shared/dialogs/dialog-app/DialogApp';
import {ButtonAdd} from '../../../../../shared/components/buttons/ButtonAdd';
import {Icon} from '@skczu/czu-frontend-library/build/apis/admin-service/generated';
import {selectSimpleRoleList} from '../../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {KeycloakRole} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';

export type AdminAppsObj = {
  icon: Icon;
  nameEn: string;
  name: string;
  url: string;
  roles: string;
  visible: boolean;
  action: number;
};

export type AppsData = AdminAppsObj & {
  subRows?: AppsData[];
};

export const TableApps = (): JSX.Element => {
  const {t} = useTranslation();
  const tabClasses = StylesAdminTab();
  const dispatch = useAppDispatch();
  const {addNewObj, obj, adminList, openObjDialog, loadingDetail} =
    useAppSelector(appsSelector);
  const simpleRoleList = useAppSelector(selectSimpleRoleList);

  useEffect(() => {
    dispatch(getAppIconList());
  }, [dispatch]);

  const handleEditClick = useCallback(
    (id: string) => {
      dispatch(appsActions.setEditObjDetail());
      dispatch(getApp(Number(id)));
    },
    [dispatch]
  );

  const getRoleNameFromKey = useCallback(
    (roles: KeycloakRole[], roleKey: string): string => {
      if (roleKey === 'ANONYMOUS') return t(`userRole.${roleKey}`);
      else {
        const role = roles.find((role) => role.key === roleKey);
        return role
          ? CustomUtils.getContentByLanguage(role.name, role.nameEn)
          : roleKey;
      }
    },
    [t]
  );

  const getAppsList = useMemo(() => {
    return adminList.list.map((dataObj) => {
      return {
        ...dataObj,
        action: dataObj?.id as number,
        roles: dataObj.roles
          ?.map((role) => getRoleNameFromKey(simpleRoleList, role))
          .toString(),
      } as AdminAppsObj;
    });
  }, [adminList.list, getRoleNameFromKey, simpleRoleList]);

  const onChangeStatus = useCallback(
    (visible: boolean, appId?: number) => {
      const foundApp = adminList.list.find((app) => app.id === appId);
      dispatch(
        updateApp(false, {...foundApp, visible, icon: foundApp?.icon?.id})
      );
    },
    [adminList.list, dispatch]
  );

  useEffect(() => {
    dispatch(getBaseAppsList());
  }, [dispatch]);

  const columns = useMemo(() => {
    return getTableAppsColumns({
      onEditClick: handleEditClick,
      onChangeStatus: onChangeStatus,
      t,
    });
  }, [handleEditClick, onChangeStatus, t]);

  const renderTable = useMemo(() => {
    return (
      <Table<AppsData>
        t={t}
        name={'appsTable'}
        rowSelection={false}
        columns={columns}
        autoResetPage={false}
        data={getAppsList}
        // onFetchData={getAppsData}
        loadingList={false}
      />
    );
  }, [columns, getAppsList, t]);

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('apps.Add app')}
          onClick={() => {
            dispatch(appsActions.setObj(null));
            dispatch(appsActions.setAddNewObjDetail());
          }}
        />
      </Box>
      {renderTable}
      {openObjDialog && (
        <DialogApp
          addNew={addNewObj}
          app={obj}
          openDialog={openObjDialog}
          loading={loadingDetail}
          onCloseDialog={() => {
            dispatch(appsActions.setObj(null));
            dispatch(appsActions.setOpenObjDialog(false));
          }}
          onAddApp={(app) => {
            dispatch(updateApp(true, {...app, icon: app.icon?.id}));
          }}
          onUpdateApp={(app) => {
            dispatch(updateApp(false, {...app, icon: app.icon?.id}));
          }}
        />
      )}
    </Box>
  );
};
