import {Checkbox, Theme, styled, Box} from '@mui/material';
import MuiTableTable from '@mui/material/Table';
import {TableTypeMap} from '@mui/material/Table/Table';
import MuiTableBody from '@mui/material/TableBody';
import {TableBodyTypeMap} from '@mui/material/TableBody/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import {TableHeadTypeMap} from '@mui/material/TableHead/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import {TableRowTypeMap} from '@mui/material/TableRow/TableRow';
import React, {CSSProperties, PropsWithChildren} from 'react';
import {SxProps} from '@mui/system';

export const TableStyles = {
  tableTable: {
    borderSpacing: 0,
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
  },
  tableHead: {},
  tableHeadRow: {
    backgroundColor: 'background.paper',
    color: 'text.primary',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '&:hover $resizeHandle': {
      opacity: 1,
    },
  },
  tableHeadCell: {
    padding: '16px 1px 16px 16px',
    fontSize: '0.875rem',
    textAlign: 'left',
    verticalAlign: 'inherit',
    color: 'text.primary',
    fontWeight: 500,
    lineHeight: '1.5rem',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableBody: {},
  tableRow: {
    color: 'inherit',
    outline: 0,
    verticalAlign: 'middle',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.rowSelected': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
    },
    '&.clickable': {
      cursor: 'pointer',
    },
  },
  tableLabel: {},
  tableCell: {
    padding: '8px 16px',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 300,
    lineHeight: 1.3,
    verticalAlign: 'inherit',
    color: 'text.primary',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableSortLabel: {
    '& svg': {
      width: 16,
      height: 16,
      marginTop: 0,
      marginLeft: 2,
    },
  },
  headerIcon: {
    '& svg': {
      width: 16,
      height: 16,
      marginTop: 4,
      marginRight: 0,
    },
  },
  iconDirectionAsc: {
    transform: 'rotate(90deg)',
  },
  iconDirectionDesc: {
    transform: 'rotate(180deg)',
  },
  cellIcon: {
    '& svg': {
      width: 16,
      height: 16,
      marginTop: 3,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked &&
  prevProps.indeterminate === nextProps.indeterminate;

type CN = {className?: string; sx?: SxProps<Theme>; style?: CSSProperties};

export const TableTable = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableTypeMap> & CN>): JSX.Element => {
  return (
    <MuiTableTable
      sx={sx ? {...TableStyles.tableTable, ...sx} : TableStyles.tableTable}
      {...rest}>
      {children}
    </MuiTableTable>
  );
};

export const TableBody = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableBodyTypeMap> & CN>): JSX.Element => {
  return (
    <MuiTableBody
      sx={sx ? {...TableStyles.tableBody, ...sx} : TableStyles.tableBody}
      {...rest}>
      {children}
    </MuiTableBody>
  );
};

export const TableHead = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableHeadTypeMap> & CN>): JSX.Element => {
  return (
    <MuiTableHead
      sx={sx ? {...TableStyles.tableHead, ...sx} : TableStyles.tableHead}
      {...rest}>
      {children}
    </MuiTableHead>
  );
};

export const TableHeadRow = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableRowTypeMap> & CN>): JSX.Element => {
  return (
    <MuiTableRow
      sx={sx ? {...TableStyles.tableHeadRow, ...sx} : TableStyles.tableHeadRow}
      {...rest}>
      {children}
    </MuiTableRow>
  );
};

export const TableHeadCell = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableCellProps> & CN>): JSX.Element => {
  return (
    <MuiTableCell
      sx={
        sx ? {...sx, ...TableStyles.tableHeadCell} : TableStyles.tableHeadCell
      }
      {...rest}>
      {children}
    </MuiTableCell>
  );
};

export const TableRow = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableRowTypeMap> & CN>): JSX.Element => {
  return (
    <MuiTableRow
      sx={sx ? {...TableStyles.tableRow, ...sx} : TableStyles.tableRow}
      {...rest}>
      {children}
    </MuiTableRow>
  );
};

export const TableCell = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<Partial<TableCellProps> & CN>): JSX.Element => {
  return (
    <MuiTableCell
      sx={sx ? {...sx, ...TableStyles.tableCell} : TableStyles.tableCell}
      {...rest}>
      {children}
    </MuiTableCell>
  );
};

export const TableLabel = ({
  children,
  sx,
  ...rest
}: PropsWithChildren<CN>): JSX.Element => {
  return (
    <Box
      sx={sx ? {...TableStyles.tableLabel, ...sx} : TableStyles.tableLabel}
      {...rest}>
      {children}
    </Box>
  );
};

export const HeaderCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '1rem',
    margin: '-8px 0 -8px -15px',
    padding: '8px 9px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  areEqual
);

export const RowCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '14px',
    margin: '-9px 0 -8px -15px',
    padding: '5px 9px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: 24,
      height: 24,
    },
  }),
  areEqual
);
