import React, {useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  getLayerListFromIds,
  layersActions,
  layersSelector,
} from '../../../../redux/reducers/data-reducers/LayersSlice';
import {
  deleteVideo,
  setGalleryPhotoList,
} from '../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {useTranslation} from 'react-i18next';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import moment from 'moment';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  isBeforeVisible,
} from '../../utils/Utils';
import {useUploadPhoto} from '../../../../hooks/useUploadPhoto';
import {MobileScreenGroupDetail} from '@skczu/czu-react-components';
import {CustomUtils} from '../../utils/CustomUtils';
import {
  Utils,
  SelectedImagePreview,
  ObjMapper,
} from '@skczu/czu-frontend-library';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdateEvent,
  Event,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {
  DamFile,
  Video,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import {EventForm} from './EventForm';
import {useUploadVideo} from '../../../../hooks/useUploadVideo';
import {ObjectPreviewWithFormContainer} from '../../components/mobile-preview/ObjectPreviewWithFormContainer';

export type GroupForm = Omit<
  Event,
  'id' | 'video' | 'layers' | 'photos' | 'image'
> & {
  video?: DamFileWithData;
  image?: DamFileWithData | DamFile;
  layers?: string[];
};

export interface DialogEventProps {
  addNew?: boolean;
  obj?: Event | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddGroup?: (group: CreateOrUpdateEvent) => void;
  onUpdateGroup?: (group: CreateOrUpdateEvent) => void;
  imagePreview?: SelectedImagePreview;
}

export const DialogEvent = ({
  addNew = true,
  obj,
  openDialog,
  loading,
  onCloseDialog,
  onAddGroup,
  onUpdateGroup,
  imagePreview,
}: DialogEventProps): JSX.Element => {
  const {t} = useTranslation();
  const {control, handleSubmit, reset, setValue, watch} = useForm<GroupForm>();
  const {objList} = useAppSelector(layersSelector);
  const dispatch = useAppDispatch();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const {galleryPhotoList, addGalleryPhotos, removeGalleryPhoto} =
    useUploadPhoto('group');
  const {videoUploadProgress, addGalleryVideo} = useUploadVideo((newVideo) => {
    setValue &&
      setValue(
        'video',
        CustomUtils.convertCreateVideoResponseToDamFile(newVideo)
      );
  });

  useEffect(() => {
    if (obj && openDialog) {
      setValue('name', obj.name);
      setValue('nameEn', obj.nameEn);
      setValue('body', obj.body);
      setValue('bodyEn', obj.bodyEn);
      setValue(
        'startDate',
        obj.startDate
          ? moment(obj.startDate).format(DefaultDateTimeFormatFE)
          : undefined
      );
      setValue(
        'endDate',
        obj.endDate
          ? moment(obj.endDate).format(DefaultDateTimeFormatFE)
          : undefined
      );
      setValue('layers', obj.layers?.map((layer) => layer.id) as string[]);
      setValue('image', obj.image);
      setValue('visibleInApp', obj.visibleInApp);
      setValue('visibleOnMap', obj.visibleOnMap);
      setValue(
        'video',
        CustomUtils.convertCreateVideoResponseToDamFile(obj.video)
      );
    }
    if (openDialog && !obj) {
      reset();
      setValue('visibleInApp', true);
      setValue('visibleOnMap', true);
    }
    dispatch(layersActions.setObjList(obj && obj.layers ? obj.layers : []));
  }, [dispatch, obj, openDialog, reset, setValue]);

  const onErrorSubmit: SubmitErrorHandler<GroupForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit: SubmitHandler<GroupForm> = (data) => {
    const photoIds = galleryPhotoList.map(
      (galleryPhoto) => galleryPhoto.galleryPhoto?.id as string
    );
    const newGroup = {
      ...data,
      startDate:
        data.startDate && data.startDate !== 'Invalid date'
          ? moment(data.startDate, DefaultDateTimeFormatFE)
              .utc()
              .format(DefaultDateTimeFormatBE)
          : undefined,

      endDate:
        data.endDate && data.endDate !== 'Invalid date'
          ? moment(data.endDate, DefaultDateTimeFormatFE)
              .utc()
              .format(DefaultDateTimeFormatBE)
          : undefined,
      photos: photoIds,
      video: data?.video?.id ? data.video.id : undefined,
      image: ObjMapper.getDamFileWithDataFromDamFile(data.image),
    };
    if (addNew) {
      onAddGroup && onAddGroup(newGroup);
    } else {
      onUpdateGroup && onUpdateGroup({...newGroup, id: obj?.id});
    }
  };

  const handleCloseDialog = () => {
    reset();
    dispatch(setGalleryPhotoList([]));
    onCloseDialog();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const handleRemoveVideo = (emptyVideo: Video) => {
    watch &&
      watch('video') &&
      watch('video')?.id &&
      dispatch(deleteVideo(watch('video')?.id as string));
    setValue && setValue('video', emptyVideo);
  };

  const handleChangeLayers = (layerIds: string[]) => {
    layerIds && dispatch(getLayerListFromIds(layerIds));
  };

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('group.Add group') : t('group.Edit group')}
        open={openDialog}
        minWidth={'1280px'}
        contentLanguage={true}
        loading={loading}
        onCloseDialog={handleCloseDialog}>
        <ObjectPreviewWithFormContainer
          t={t}
          id={obj?.id}
          objType={'event'}
          objPreview={
            <MobileScreenGroupDetail
              t={t}
              groupName={
                selectedLanguage === ContentLanguage.EN
                  ? watch('nameEn')
                  : watch('name')
              }
              imageFile={watch('image')}
              layers={objList.map((layer) => {
                return {
                  ...CustomUtils.getObjByLanguage(layer),
                  pois: Utils.getObjListByLanguage(
                    CustomUtils.getObjByLanguage,
                    layer.pois
                  ),
                  routes: Utils.getObjListByLanguage(
                    CustomUtils.getObjByLanguage,
                    layer.routes
                  ),
                };
              })}
              bodyTextHtml={
                (selectedLanguage === ContentLanguage.EN
                  ? watch('bodyEn')
                  : watch('body')) as string
              }
              galleryPhotos={galleryPhotoList.map(
                (photo) => photo.galleryPhoto as DamImage
              )}
              video={{
                id: watch('video')?.id,
                name: watch('video')?.fileName,
                url: watch('video')?.data,
              }}
              imagePreview={imagePreview}
            />
          }
          objEditInputs={
            <EventForm
              id={obj?.id}
              control={control}
              watch={watch}
              setValue={setValue}
              addNew={addNew}
              onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}
              galleryPhotoList={galleryPhotoList}
              addGalleryPhotos={addGalleryPhotos}
              removeGalleryPhoto={removeGalleryPhoto}
              videoUploadProgress={videoUploadProgress}
              addGalleryVideo={addGalleryVideo}
              onChangeLayers={handleChangeLayers}
              onRemoveVideo={handleRemoveVideo}
              eventAlreadyVisible={
                isBeforeVisible(
                  moment(obj?.startDate).format(DefaultDateTimeFormatFE),
                  moment().format(DefaultDateTimeFormatFE)
                ) &&
                isBeforeVisible(
                  moment().format(DefaultDateTimeFormatFE),
                  moment(obj?.endDate).format(DefaultDateTimeFormatFE)
                )
              }
            />
          }
        />
      </DialogPrimary>
    </Box>
  );
};
