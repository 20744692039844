const config = {
  adminMapUrl:
    window._env_?.REACT_APP_ADMIN_MAP_URL || 'http://localhost:3000/map',
  keycloak: {
    authUrl: window._env_?.REACT_APP_KEYCLOAK_URL || 'https://login.demonow.eu',
    realm: window._env_?.REACT_APP_KEYCLOAK_REALM || 'master',
    clientId: window._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || 'cms-service-admin',
  },
  osmWmsUrl:
    window._env_?.REACT_APP_OSM_WMS_URL || 'https://maps.demonow.eu/osm/wms',
  czuWmsUrl:
    window._env_?.REACT_APP_CZU_WMS_URL || 'https://maps.demonow.eu/czu/wms',
  cmsRestUrl:
    window._env_?.REACT_APP_CMS_REST_URL ||
    'https://api.demonow.eu/cms-service/.rest',
  // 'http://localhost:8080/.rest',
  czuRelayRestUrl:
    window._env_?.REACT_APP_CZU_RELAY_URL ||
    'https://api.demonow.eu/czu-api-cache-service/v1',
  navigationServiceUrl:
    window._env_?.REACT_APP_NAVIGATION_SERVICE_URL ||
    'https://api.demonow.eu/navigation-service',
  userDataRestUrl:
    window._env_?.REACT_APP_USER_DATA_REST_URL ||
    'https://api.demonow.eu/user-data-service/v1',
  // 'http://localhost:8083/v1',
  keycloakDataRestUrl:
    window._env_?.REACT_APP_KEYCLOAK_USER_REST_URL ||
    'https://api.demonow.eu/keycloak-user-service',
  adminServiceRestUrl:
    window._env_?.REACT_APP_ADMIN_SERVICE_URL ||
    'https://api.demonow.eu/app-management-service/v1',
  pushNotificationServiceRestUrl:
    window._env_?.REACT_APP_PUSH_NOTIFICATION_SERVICE_URL ||
    // 'http://localhost:8087/v1',
    'https://api.demonow.eu/push-notification-service/v1',
  appVersion: window._env_?.APP_VERSION || '1.0.0',
  environmentName: window._env_?.ENVIRONMENT_NAME || 'Dev',
  environmentUrl: window._env_?.ENVIRONMENT_URL || 'demonow.eu',
};
export default config;
