import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PinDrop} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DraggingStyle,
} from 'react-beautiful-dnd';
import {PoiIcon} from '../../../shared/components/poi/PoiIcon';
import {createPortal} from 'react-dom';
import {
  reorderPoiSelectionList,
  selectSelectedPoi,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {selectPoiCategoryList} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';

const useStyles = makeStyles((theme) => ({
  routeComponent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 80,
    backgroundColor: theme.palette.secondary.main,
    border: `4px solid ${theme.palette.secondary.light}`,
    borderRadius: 20,
    marginTop: -22,
  },
  start: {
    borderRight: `4px solid ${theme.palette.secondary.light}`,
  },
  end: {
    borderLeft: `4px solid ${theme.palette.secondary.light}`,
  },
  direction: {
    width: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  route: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: -14,
    paddingBottom: -14,
    marginTop: -10,
  },
  routeLine: {
    width: 20,
    height: 15,
    borderTop: `3px dashed #BC44FA`,
    marginLeft: -5,
    marginRight: -5,
  },
}));

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  display: 'flex',
  overflow: 'auto',
  width: '100%',
  paddingTop: 20,
});

const useDraggableInPortal = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const self = useRef<any>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '99999999999';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (render: any) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (provided: DraggableProvided, ...args: any) => {
      const element = render(provided, ...args);
      if (
        provided?.draggableProps?.style &&
        (provided?.draggableProps?.style as DraggingStyle).position === 'fixed'
      ) {
        return createPortal(element, self.elt);
      }
      return element;
    };
};

export const RouteEditingContainer = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const renderDraggable = useDraggableInPortal();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const poiCategoryList = useAppSelector(selectPoiCategoryList);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    dispatch(
      reorderPoiSelectionList({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  const getCategoryIcon = useCallback(
    (categoryId: string) =>
      poiCategoryList &&
      poiCategoryList.find((category) => category.id === categoryId),
    [poiCategoryList]
  );

  const renderIcons = useMemo(() => {
    return poiSelection?.poiList?.map((poi: PoiRef, index: number) => (
      <Draggable key={poi.id} draggableId={poi.id + '_id2_'} index={index}>
        {renderDraggable((selected: DraggableProvided) => (
          <div
            ref={selected.innerRef}
            {...selected.draggableProps}
            {...selected.dragHandleProps}>
            <Box className={classes.route}>
              {poi.category && poi.name && (
                <PoiIcon
                  key={poi.category + '_id'}
                  poiCategory={getCategoryIcon(poi.category)}
                  name={poi.name}
                />
              )}
              {poiSelection?.poiList &&
                index !== poiSelection?.poiList?.length - 1 && (
                  <Box className={classes.routeLine} />
                )}
            </Box>
          </div>
        ))}
      </Draggable>
    ));
  }, [
    classes.route,
    classes.routeLine,
    getCategoryIcon,
    poiSelection?.poiList,
    renderDraggable,
  ]);

  return (
    <Box className={classes.routeComponent}>
      <Box className={classes.direction + ' ' + classes.start}>
        <PinDrop color={'primary'} />
        <Typography variant={'caption'}>Start</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}>
              {renderIcons}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box className={classes.direction + ' ' + classes.end}>
        <PinDrop color={'error'} />
        <Typography variant={'caption'}>End</Typography>
      </Box>
    </Box>
  );
};
