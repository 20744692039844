import {IdType, Row} from 'react-table';
import {AdminNewsObj} from '../../scenes/admin-menu-scene/news/table/TableNews';
import {CustomUtils} from './CustomUtils';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import moment from 'moment';
import {Duration, Moment} from 'moment/moment';

export interface ValueLocale {
  id?: string;
  name?: string;
  nameEn?: string;
}

export const DefaultDateFormat = 'DD.MM.YYYY';
export const DefaultDateTimeFormatFE = 'DD.MM.YYYY HH:mm';
export const DefaultDateTimeFormatBE = 'YYYY-MM-DDTHH:mm';

export const getMinMax = (
  rows: Row<AdminNewsObj>[],
  id: IdType<AdminNewsObj>
): number[] => {
  let min = rows && rows?.length ? rows[0].values[id] : 0;
  let max = rows && rows?.length ? rows[0].values[id] : 0;
  rows?.forEach((row) => {
    min = Math.min(row.values[id], min);
    max = Math.max(row.values[id], max);
  });
  return [min, max];
};

export const readFileContent = async (file: File): Promise<DamFileWithData> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(CustomUtils.createPhotoFromReaderResult(file, fileReader.result));
    };
    fileReader.readAsDataURL(file);
    fileReader.onerror = reject;
  });
};

export const readMultipleFilesContent = async (
  fileList: FileList
): Promise<DamFileWithData[]> => {
  return Promise.all(
    Array.from(fileList).map(async (file) => {
      return readFileContent(file);
    })
  );
};

export const getShortedString = (text: string, lenght = 20): string => {
  return text.length > lenght ? text.slice(0, lenght) + '...' : text;
};

export const instanceOfValueLocale = (object: any): object is ValueLocale => {
  return 'nameEn' in object;
};

export const getObjIdsByName = <T extends ValueLocale[]>(
  simpleObjList: T,
  values: ValueLocale[]
): string[] | undefined => {
  return values?.map(
    (value) => simpleObjList.find((obj) => obj.id === value.id)?.id as string
  );
};

export const getObjNameByIds = <T extends ValueLocale[]>(
  simpleObjList: T,
  ids: string[]
): ValueLocale[] | undefined => {
  return ids.map((objId) => {
    const foundObj = simpleObjList.find((obj) => obj?.id === objId);
    return {id: objId, name: foundObj?.name, nameEn: foundObj?.nameEn};
  });
};

export const htmlToPlainText = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const removeNonLetters = (text: string): string => {
  return text.replace(/ +/g, '-').replace(/[^A-Za-z_-]/g, '');
};

export const isBeforeVisible = (
  date?: string,
  visibleFromDate?: string
): boolean => {
  if (date && visibleFromDate) {
    if (
      moment(visibleFromDate, DefaultDateTimeFormatFE).isValid() &&
      moment(date, DefaultDateTimeFormatFE).isValid()
    ) {
      return moment(visibleFromDate, DefaultDateTimeFormatFE).isAfter(
        moment(date, DefaultDateTimeFormatFE)
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const roundTimeMinutes = (
  date: Moment | Date,
  duration: Duration
): Moment => {
  return moment(Math.ceil(+date / +duration) * +duration);
};
