import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Delete, Edit} from '@mui/icons-material';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {StylesDialog} from '../../../../shared/dialogs/StylesDialog';
import {useTranslation} from 'react-i18next';
import {Wishlist} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {MobileScreenChangesPreview} from '@skczu/czu-react-components';

export interface EditChangesTabProps {
  wishlist?: Wishlist;
  onChangeChanges?: () => void;
  onDeleteChanges?: () => void;
}

export const EditChangesTab = ({
  wishlist,
  onChangeChanges,
  onDeleteChanges,
}: EditChangesTabProps): JSX.Element => {
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();
  const classes = useStyles();
  return (
    <>
      <Box className={dialogClasses.mobileScreenContainer}>
        <MobileScreenChangesPreview
          bodyTextHtml={CustomUtils.getContentByLanguage(
            wishlist?.text,
            wishlist?.textEn
          )}
          newsName={t('changes.Upcoming changes')}
        />
      </Box>
      <Box className={classes.container}>
        <ButtonAdd
          icon={<Edit />}
          name={t('changes.Edit upcoming changes')}
          onClick={onChangeChanges}
        />
        <Box marginLeft={5} />
        <ButtonAdd
          icon={<Delete />}
          name={t('changes.Delete upcoming changes')}
          color={'red'}
          onClick={onDeleteChanges}
        />
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
}));
