import React, {useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Status} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {SubmitHandler, useForm} from 'react-hook-form';
import {BaseKeycloakUser} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated/api';
import {UserFormControls} from './UserFormControls';
import {KeycloakUser} from '@skczu/czu-frontend-library/build/apis/keycloak-user-service-api/generated';

export type UserForm = Omit<
  BaseKeycloakUser,
  'id' | 'roles' | 'group' | 'status'
> & {roles?: string[]; status?: boolean};

export interface Props {
  user?: KeycloakUser | null;
  openDialog: boolean;
  addNew: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddUser?: (user: BaseKeycloakUser) => void;
  onUpdateUser?: (user: BaseKeycloakUser) => void;
}
export const DialogUser = ({
  openDialog,
  addNew,
  user,
  loading,
  onCloseDialog,
  onAddUser,
  onUpdateUser,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const {control, handleSubmit, watch, reset, trigger, setValue} =
    useForm<UserForm>();

  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('email', user.email);
      setValue('roles', user.role?.map((role) => role.key) as string[]);
      // setValue('group', user.group?.map((group) => group.mgnlId) as string[]);
      setValue('status', user.status === 'Active');
    }
    if (openDialog && !user) {
      reset();
    }
  }, [openDialog, reset, setValue, user]);

  const onValidSubmit: SubmitHandler<UserForm> = (data) => {
    // if (
    //   (user?.role?.map((role) => role.key) as string[]).toString() !==
    //   data.role?.toString()
    // ) {
    //   const rolesToAdd: string[] = [];
    //   const rolesToRemove: string[] = [];
    //   data.role?.forEach((roleName) => {
    //     if (!user?.role?.map((role) => role.key).includes(roleName)) {
    //       rolesToAdd.push(roleName);
    //     }
    //   });
    //   user?.role?.map((role) => {
    //     if (role.key && !data.role?.includes(role.key)) {
    //       rolesToRemove.push(role.key);
    //     }
    //   });
    //   onChangeUserRoles && onChangeUserRoles(rolesToAdd, rolesToRemove);
    // }
    // if ((user?.status === 'Active') !== data.status) {
    //   onChangeUserStatus &&
    //     onChangeUserStatus(data.status ? Status.Active : Status.Inactive);
    // }
    const newUser = {
      ...data,
      status: addNew
        ? Status.Active
        : data.status
        ? Status.Active
        : Status.Inactive,
      roles: data.roles ? [...data.roles, 'user'] : ['user'],
    };

    if (addNew) {
      onAddUser && onAddUser(newUser);
    } else {
      onUpdateUser &&
        onUpdateUser({
          ...newUser,
          id: user?.id,
        });
    }
  };

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
  };

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('user.Add new user') : t('user.Edit user')}
        open={openDialog}
        loading={loading}
        minWidth={'750px'}
        onCloseDialog={handleCloseDialog}>
        <UserFormControls
          addNew={addNew}
          control={control}
          watch={watch}
          user={user}
          trigger={trigger}
          onSubmit={handleSubmit(onValidSubmit)}
        />
      </DialogPrimary>
    </Box>
  );
};
