import React from 'react';
import {Box, IconButton, TableBody, TableHead, TableRow} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SwitchPrimary} from '../../../../components/switches/SwitchPrimary';
import {
  CustomTable,
  CustomTableCell,
} from '../../../../components/custom-inputs/TableBase';
import {ButtonUploadImage} from '../../../../components/buttons/ButtonUploadImage';
import {Delete} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {Object3dProperty} from './Object3dProperty';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {PoiForm} from '../../DialogPoi';
import {Controller} from 'react-hook-form';
import TextInputPrimary from '../../../../components/text-inputs/TextInputPrimary';
import {checkIsNumber} from '../../../../validators/inputValidator';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `3px solid ${theme.palette.secondary.light}`,
  },
  object3dParams: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
}));

export interface TabArProps extends Partial<UseFormReturn<PoiForm>> {
  onAddArFile?: () => void;
}

export const TabAr = ({control, watch, setValue}: TabArProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      <Box width={800}>
        <Box marginTop={2}>
          <Controller
            name="enabledArImage"
            control={control}
            defaultValue={false}
            render={({field, fieldState: {error}}) => (
              <SwitchPrimary
                {...field}
                boldLabel
                label={t(`poi.Enable AR`)}
                errorType={error?.type}
              />
            )}
          />
        </Box>
        {watch && watch('enabledArImage') && (
          <>
            <Box marginBottom={2}>
              <Controller
                name="posImage"
                control={control}
                defaultValue={undefined}
                rules={{required: true}}
                render={({field, fieldState: {error}}) => (
                  <ButtonUploadImage
                    {...field}
                    boldLabel
                    label={t(`poi.Marker image (.jpg extension only)`)}
                    buttonText={t('poi.Upload marker')}
                    errorType={error?.type}
                    acceptedExtensions={'.jpg'}
                  />
                )}
              />
            </Box>
            <Box width={800} marginBottom={5}>
              <CustomTable classes={{root: classes.table}}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>{t('poi.Name')}</CustomTableCell>
                    <CustomTableCell align="right">
                      {t('poi.Action')}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch && watch('posImage')?.fileName && (
                    <TableRow key={watch('posImage')?.fileName}>
                      <CustomTableCell component="th" scope="row">
                        {watch('posImage')?.fileName}
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            setValue && setValue('posImage', undefined)
                          }>
                          <Delete fontSize="small" />
                        </IconButton>
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </CustomTable>
              <Controller
                name="posImageWidth"
                control={control}
                rules={{
                  required: true,
                  validate: checkIsNumber,
                }}
                defaultValue={''}
                render={({field, fieldState: {error}}) => (
                  <TextInputPrimary
                    {...field}
                    boldLabel
                    label={t(`poi.Physical width`)}
                    errorType={error?.type}
                    errorMessage={error?.message}
                  />
                )}
              />
            </Box>
            <Box marginBottom={2}>
              <Controller
                name="file3D"
                control={control}
                defaultValue={undefined}
                rules={{required: true}}
                render={({field, fieldState: {error}}) => (
                  <ButtonUploadImage
                    {...field}
                    boldLabel
                    label={t(`poi.AR 3D object (.glb extension only)`)}
                    buttonText={t('poi.Upload 3D object')}
                    errorType={error?.type}
                    acceptedExtensions={'.glb'}
                  />
                )}
              />
            </Box>
            <Box width={800} marginBottom={5}>
              <CustomTable classes={{root: classes.table}}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>{t('poi.Name')}</CustomTableCell>
                    <CustomTableCell align="right">
                      {t('poi.Action')}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch && watch('file3D')?.fileName && (
                    <TableRow key={watch('file3D')?.fileName}>
                      <CustomTableCell component="th" scope="row">
                        {watch('file3D')?.fileName}
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            setValue && setValue('file3D', undefined)
                          }>
                          <Delete fontSize="small" />
                        </IconButton>
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </CustomTable>
              <Box className={classes.object3dParams}>
                <Object3dProperty
                  name={t('poi.Position')}
                  control={control}
                  type={'object3dPosition'}
                />
                <Object3dProperty
                  name={t('poi.Scale')}
                  control={control}
                  type={'scale'}
                />
                <Object3dProperty
                  name={t('poi.Rotation')}
                  control={control}
                  type={'rotation'}
                  // x={inputs[21]}
                  // y={inputs[22]}
                  // z={inputs[23]}
                  // handleChange={handleChange}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
