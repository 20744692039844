import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {ArrowRightAlt, Delete} from '@mui/icons-material';
import {PoiDialogType, usePoiHotspotStyles} from '../PoiHotspot';
import {useTranslation} from 'react-i18next';
import {Hotspot} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

export interface EditPoi360TabProps {
  hotspotId: string;
  hotspot: Hotspot;
  onDeletePoi: (poiViewerId: string, hotspot: Hotspot) => void;
  onGoToPoi: (hotspot: Hotspot) => void;
  onGetPoi360ForUpdate?: (
    poi360Id: string,
    hotspotId: string,
    hotspot: Hotspot
  ) => void;
  handleClose: () => void;
  setDialogType: (type: PoiDialogType) => void;
}

export const EditPoi360Tab = ({
  hotspotId,
  hotspot,
  onDeletePoi,
  onGoToPoi,
  onGetPoi360ForUpdate,
  handleClose,
  setDialogType,
}: EditPoi360TabProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = usePoiHotspotStyles();
  return (
    <Box className={classes.dialogMenu}>
      <Box className={classes.menuItem}>
        <IconButton
          onClick={() => {
            hotspot?.poi360 && onGoToPoi(hotspot);
            handleClose();
          }}
          size="large">
          <ArrowRightAlt color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Go to point of interest')}
          </Typography>
        </IconButton>
      </Box>
      <Box className={classes.menuItem}>
        <IconButton
          onClick={() => {
            setDialogType('update');
            hotspot.poi360 &&
              onGetPoi360ForUpdate &&
              onGetPoi360ForUpdate(hotspot.poi360, hotspotId, hotspot);
            handleClose();
          }}
          size="large">
          <ArrowRightAlt color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Update point of interest')}
          </Typography>
        </IconButton>
      </Box>
      <Box className={classes.menuItem}>
        <IconButton
          onClick={() => {
            onDeletePoi(hotspotId, hotspot);
            handleClose();
          }}
          size="large">
          <Delete color={'secondary'} />
          <Typography className={classes.typography}>
            {t('poi360.Delete point of interest')}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};
