import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

interface Poi360Selection {
  poi360Selection: boolean;
  localSelection: boolean;
  fromPoi360?: Poi360;
  hotspot?: Hotspot;
}

interface Poi360SelectionState {
  poi360Selection: Poi360Selection;
  openPoi360Dialog: boolean;
  openLocalPoi360Dialog: boolean;
}

const initialState: Poi360SelectionState = {
  poi360Selection: {poi360Selection: false, localSelection: true},
  openPoi360Dialog: false,
  openLocalPoi360Dialog: false,
};

export const poi360SelectionSlice = createSlice({
  name: 'poi360Selection',
  initialState,
  reducers: {
    setPoi360Selection: (state, {payload}: PayloadAction<boolean>) => {
      state.poi360Selection.poi360Selection = payload;
    },
    setPoi360SelectionFromPoi360: (state, {payload}: PayloadAction<Poi360>) => {
      state.poi360Selection.fromPoi360 = payload;
    },
    setPoi360SelectionHotspot: (state, {payload}: PayloadAction<Hotspot>) => {
      state.poi360Selection.hotspot = payload;
    },
    setOpenPoi360Dialog: (state, {payload}: PayloadAction<boolean>) => {
      state.openPoi360Dialog = payload;
    },
    setOpenLocalPoi360Dialog: (state, {payload}: PayloadAction<boolean>) => {
      state.openLocalPoi360Dialog = payload;
    },
    setPoi360LocalSelection: (state, {payload}: PayloadAction<boolean>) => {
      state.poi360Selection.localSelection = payload;
    },
  },
});

export const {
  setPoi360Selection,
  setOpenPoi360Dialog,
  setPoi360SelectionFromPoi360,
  setPoi360SelectionHotspot,
  setOpenLocalPoi360Dialog,
  setPoi360LocalSelection,
} = poi360SelectionSlice.actions;

export const poi360SelectionSelector = (
  state: RootState
): typeof state.poi360Selection => state.poi360Selection;

export default poi360SelectionSlice.reducer;
