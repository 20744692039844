import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import React, {useCallback} from 'react';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {ListItemsMenu} from '../ListItemsMenu';
import {HeaderMenuItem} from '../HeaderMenuItem';
import Divider from '@mui/material/Divider';
import {ListOfRoutesTab} from './tabs/ListOfRoutesTab';
import {Box} from '@mui/material';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {routeActions} from '../../../../../../redux/reducers/data-reducers/RouteSlice';
import {StylesMenu} from '../StylesMenu';
import {useTranslation} from 'react-i18next';

export const RoutesRouteTab = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const classes = StylesMenu();
  const {path, url} = useRouteMatch();
  const dispatch = useAppDispatch();

  const handleOnClick = useCallback(
    (item) => history.push(`${url}/${item}`),
    [history, url]
  );

  const handleAddNew = () => {
    dispatch(routeActions.setAddNewObj(true));
    dispatch(routeActions.setOpenObjDialog(true));
    handleOnClick('routeList');
  };

  return (
    <Box className={classes.menu}>
      <Route exact path={`${path}`}>
        <Box>
          <HeaderMenuItem name={t('leftMenu.routes')} />
          <List>
            {ListItemsMenu.routes.items.map(({label, key}) => (
              <Box key={key}>
                <ListItem
                  button
                  key={key}
                  onClick={() =>
                    key === 'newRoute' ? handleAddNew() : handleOnClick(key)
                  }>
                  <ListItemText primary={t(`leftMenu.${label}`)} />
                </ListItem>
                <Divider light={true} />
              </Box>
            ))}
          </List>
        </Box>
      </Route>
      <Route path={`${path}/routeList/:routeId?`}>
        <ListOfRoutesTab />
      </Route>
    </Box>
  );
};
