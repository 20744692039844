import React from 'react';
import {Typography} from '@mui/material';
import {InputStyles} from '../../styles/StylesInput';
import {LiteralUnion} from 'react-hook-form/dist/types/utils';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {useTranslation} from 'react-i18next';

export interface InputErrorMessageProps {
  errorType?: LiteralUnion<keyof RegisterOptions, string>;
  inputName?: string | React.ReactNode;
  errorMessage?: string;
  errorMessageTranslationPrefix?: string;
}
export const InputErrorMessage = ({
  errorType,
  inputName,
  errorMessage,
  errorMessageTranslationPrefix,
}: InputErrorMessageProps): JSX.Element => {
  const classes = InputStyles();
  const {t} = useTranslation();

  const getErrorFromType = (
    type?: LiteralUnion<keyof RegisterOptions, string>
  ): string => {
    switch (type) {
      case 'required':
        return 'Field is required';
      case 'minLength':
        return 'Too short field';
      case 'maxLength':
        return 'Too long field';
      case 'isUnique':
        return 'Already exists';
      case 'validate':
      case 'pattern':
        return errorMessage || '';
      default:
        return '';
    }
  };

  return (
    <Typography
      className={classes.errorMessage}
      variant="subtitle2"
      id={inputName + '-input-error'}>
      {t(
        `${
          errorMessage && errorMessageTranslationPrefix
            ? errorMessageTranslationPrefix
            : 'allObjects'
        }.${
          errorMessage && errorMessage.length > 1
            ? errorMessage
            : getErrorFromType(errorType)
        }`
      )}
    </Typography>
  );
};
