import {FilterProps} from 'react-table';
import React from 'react';
import {ListItemText, MenuItem} from '@mui/material';
import SelectPrimary from '../components/selects/SelectPrimary';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    marginTop: 6,
    minHeight: 20,
  },
}));

export interface SelectOption {
  value: string;
  name: string;
}

export const SelectColumnFilter = <T extends Record<string, unknown>>({
  column: {filterValue, render, setFilter, id},
  options,
}: FilterProps<T> & {options?: SelectOption[]}): JSX.Element => {
  const classes = useStyles();

  return (
    <SelectPrimary
      id={id}
      name={id}
      label={render('Header')}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter((e.target.value as string) || undefined);
      }}>
      {options?.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          <ListItemText classes={{root: classes.text}} primary={option.name} />
        </MenuItem>
      ))}
    </SelectPrimary>
  );
};
