import React from 'react';
import {
  Box,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {
  CustomTable,
  CustomTableCell,
} from '../../../../components/custom-inputs/TableBase';
import {ButtonPrimary} from '../../../../components/buttons/ButtonPrimary';
import makeStyles from '@mui/styles/makeStyles';
import {
  removeFromPoiSelectionList,
  selectSelectedPoi,
} from '../../../../../../redux/reducers/PoiListSelectionSlice';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {useTranslation} from 'react-i18next';
import {CustomUtils} from '../../../../utils/CustomUtils';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `3px solid ${theme.palette.secondary.light}`,
  },
}));

export interface Props {
  onPoiSelection: () => void;
}

export const TabNewsPoi = ({onPoiSelection}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box marginTop={1}>
        <ButtonPrimary
          boldLabel
          label={t('news.Add POI to news')}
          name={t('news.Add POI')}
          onClick={() => onPoiSelection()}
        />
      </Box>
      <Box>
        <Box m={0.5} marginTop={4}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
            }}>
            {t('news.List of POI')}
          </Typography>
        </Box>
        <Box width={800} marginBottom={5}>
          <CustomTable classes={{root: classes.table}}>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t('news.Name')}</CustomTableCell>
                <CustomTableCell align="right">
                  {t('news.Action')}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poiSelection.poiList &&
                poiSelection.poiList.map((poi) => (
                  <TableRow key={poi.name}>
                    <CustomTableCell component="th" scope="row">
                      {CustomUtils.getContentByLanguage(poi.name, poi.nameEn)}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        size="small"
                        onClick={() =>
                          poi.id && dispatch(removeFromPoiSelectionList(poi.id))
                        }>
                        <Delete fontSize="small" />
                      </IconButton>
                    </CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </CustomTable>
        </Box>
      </Box>
    </Box>
  );
};
