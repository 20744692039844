import {FilterProps} from 'react-table';
import {useAppSelector} from '../../../hooks/hooks';
import {selectSimpleRoleList} from '../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {SelectRoles} from '../components/selects/select-multiple/SelectRoles';
import React from 'react';
import {CustomUtils} from '../utils/CustomUtils';
import {useTranslation} from 'react-i18next';
import {KeycloakRole} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';

const getRoleLocalizedNameValue = (
  simpleRoleList?: KeycloakRole[],
  id?: string
) => {
  const role = id && simpleRoleList?.find((role) => role.id === String(id));
  return role ? CustomUtils.getContentByLanguage(role.name, role.nameEn) : '';
};

export const SelectRolesFilter = <T extends Record<string, unknown>>({
  column: {filterValue, render, setFilter, id},
}: FilterProps<T>): JSX.Element => {
  const {t} = useTranslation();
  const simpleRoleList = useAppSelector(selectSimpleRoleList);
  const roleValue =
    (filterValue &&
      (simpleRoleList?.find((obj) => obj.id === filterValue.id)
        ?.id as string)) ||
    undefined;

  return (
    <SelectRoles
      name={id + '_id'}
      multiple={false}
      selectAll={false}
      label={render('Header')}
      value={roleValue}
      selectedValueLabel={` (${
        !roleValue
          ? t('allObjects.None')
          : getRoleLocalizedNameValue(simpleRoleList, roleValue)
      })`}
      onChange={(val) => {
        const foundObj = simpleRoleList.find((obj) => obj?.key === val);
        const obj = {
          id: foundObj?.id,
          name: foundObj?.name,
          nameEn: foundObj?.nameEn,
          key: foundObj?.key,
        };
        setFilter(obj);
      }}
    />
  );
};
