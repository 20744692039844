import React, {forwardRef} from 'react';
import {Box, ClickAwayListener, Popover, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ChromePicker, ColorResult} from 'react-color';
import {InputStyles} from '../../styles/StylesInput';
import {FormModel} from '../../../../hooks/useForm';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';

export const SelectColor = forwardRef(
  ({
    value,
    id,
    name,
    label,
    className,
    onChange,
    errorType,
  }: FormModel): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClickColor = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseColor = () => {
      setAnchorEl(null);
    };

    const handleSelectColor = (newColor: ColorResult) => {
      if (name && onChange) {
        const event = newColor.hex;
        onChange(event);
      }
    };

    const useStyles = makeStyles(() =>
      createStyles({
        color: {
          width: '100%',
          height: '100%',
          borderRadius: '40px',
          background: value,
        },
        swatch: {
          padding: '5px',
          height: '30px',
          width: '65px',
          background: '#fff',
          borderRadius: '20px',
          boxShadow: '0 0 0 2px rgba(0,0,0,.2)',
          cursor: 'pointer',
          display: 'inline-block',
        },
        popover: {
          position: 'absolute',
          zIndex: 2,
        },
        cover: {
          position: 'fixed',
          top: '58%',
          left: '48%',
        },
      })
    );
    const classes = useStyles();
    const inputClasses = InputStyles();

    const open = Boolean(anchorEl);

    return (
      <Box id={id}>
        <Box>
          <Box m={0.5}>
            <Typography variant="subtitle2" id="color-picker-label">
              {label}
            </Typography>
          </Box>
          <Box
            id="color-picker"
            aria-owns={open ? 'add-category-color' : undefined}
            className={
              classes.swatch + ' ' + (errorType ? inputClasses.error : ' ')
            }
            aria-haspopup="true"
            onClick={handleClickColor}>
            <Box className={classes.color} />
          </Box>
        </Box>
        <Popover
          id="add-category-color"
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseColor}
          className={classes.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <ClickAwayListener onClickAway={handleCloseColor}>
            <ChromePicker
              className={className}
              color={value}
              onChange={handleSelectColor}
            />
          </ClickAwayListener>
        </Popover>
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);

SelectColor.displayName = 'SelectColor';

export default SelectColor;
