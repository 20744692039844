import React, {useCallback, useEffect, useMemo} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {DialogDefaultEvent} from './DialogDefaultEvent';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  createDefaultEvent,
  getDefaultEvent,
  groupActions,
  groupsSelector,
} from '../../../../../redux/reducers/data-reducers/GroupsSlice';
import {useTranslation} from 'react-i18next';
import {Place} from '@mui/icons-material';
import {ButtonPrimaryStyle} from '../../../../shared/components/buttons/ButtonPrimary';

export const DefaultsTab = (): JSX.Element => {
  const {t} = useTranslation();
  const {defaultEvent} = useAppSelector(groupsSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDefaultEvent());
  }, [dispatch]);

  const handleOpenDefaultLayerDialog = useCallback(
    (open: boolean) => {
      dispatch(groupActions.setOpenDefaultEventDialog(open));
    },
    [dispatch]
  );

  const renderDefaultEvent = useMemo(
    () => (
      <DialogDefaultEvent
        defaultEvent={defaultEvent.defaultEvent}
        open={defaultEvent.openDialog}
        onCloseDialog={() => handleOpenDefaultLayerDialog(false)}
        onChangeDefaultLayer={(layerIds) => {
          dispatch(createDefaultEvent({layers: layerIds}));
        }}
      />
    ),
    [defaultEvent, dispatch, handleOpenDefaultLayerDialog]
  );

  return (
    <Box sx={styles.container}>
      <Button
        variant="contained"
        sx={styles.button}
        component="span"
        onClick={() => handleOpenDefaultLayerDialog(true)}
        startIcon={<Place />}>
        <Typography>{t('group.Default event settings')}</Typography>
      </Button>
      {renderDefaultEvent}
    </Box>
  );
};

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'start',
  },
  button: {
    height: 50,
    textTransform: 'none',
    margin: '50px',
    ...ButtonPrimaryStyle,
  },
};
