import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {ButtonAdd} from '../../../shared/components/buttons/ButtonAdd';
import {DialogEvent} from '../../../shared/dialogs/dialog-event/DialogEvent';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  groupsSelector,
  groupActions,
  getBaseGroupsList,
  getGroup,
  getGroupForMobilePreview,
  deleteGroup,
  createGroup,
  updateGroup,
  deleteEvents,
} from '../../../../redux/reducers/data-reducers/GroupsSlice';
import {StylesAdminTab} from '../StylesAdminTab';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../shared/dialogs/dialog-info/DialogInfo';
import {setGalleryPhotoList} from '../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {DialogQrCode} from '../../../shared/dialogs/dialog-qr-code/DialogQrCode';
import {useQrCode} from '../../../../hooks/useQrCode';
import {FilterParams, Table} from '../../../shared/table';
import moment from 'moment';
import {Cell, Row, TableInstance} from 'react-table';
import {CustomUtils} from '../../../shared/utils/CustomUtils';
import {
  DialogItemSelect,
  DialogItemType,
  DialogItemButtonTypes,
  getTitleFromType,
} from '../../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {InputModelValueType} from '../../../../hooks/useForm';
import {showImagePreview} from '../../../../redux/reducers/ImagePreview';
import {
  findFilterById,
  getDateRangeFilterString,
  getSortDirection,
  useLocalStorage,
} from '../../../shared/table/utils';
import {useInfoDialog} from '../../../../hooks/useInfoDialog';
import {getTableGroupsColumns} from './TableEventsColumns';
import {ObjectType} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/qrCode';
import {EventFilterRequest} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {SortDirection} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {ValueLocale} from '../../../shared/utils/Utils';

export type AdminGroupsObj = {
  name: string;
  action: string;
  pois: string;
  routes: string;
  layers: string;
  roles: string;
  startDate: Date;
  endDate: Date;
};

export type GroupsData = AdminGroupsObj & {
  subRows?: GroupsData[];
};

export const TableEvents = (): JSX.Element => {
  const {t} = useTranslation();
  const tabClasses = StylesAdminTab();
  const dispatch = useAppDispatch();
  const {adminList, obj, openObjDialog, addNewObj, loadingDetail, loadingList} =
    useAppSelector(groupsSelector);
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) =>
        id &&
        dispatch(
          deleteGroup(id, () => {
            dispatch(getBaseGroupsList(createRequest(initialState)));
          })
        ),
    });
  const [multiSelect, setMultiSelect] = React.useState<
    DialogItemType<AdminGroupsObj>
  >({
    openDialog: false,
  });
  const {qrCodeDialog, getObjectQrCode, closeQrCodeDialog} = useQrCode();

  const [tablePageIndex, setTablePageIndex] = useState<number>(0);
  const [initialState, setInitialState] = useLocalStorage(
    'tableState:tableGroups',
    {
      pageSize: 10,
      pageIndex: 0,
    }
  );

  const setState = useCallback(
    (value: FilterParams<GroupsData>) => {
      setInitialState(value);
    },
    [setInitialState]
  );

  const handleEditClick = useCallback(
    (id: string) => {
      dispatch(groupActions.setEditObjDetail());
      dispatch(getGroup(id));
    },
    [dispatch]
  );

  const handleQrCodeScan = useCallback(
    (id: string, objName = '') => {
      getObjectQrCode(id, ObjectType.Event, objName);
    },
    [getObjectQrCode]
  );

  const handleItemClick = useCallback(
    (row: Row<GroupsData>, cell: Cell<GroupsData>) => {
      const actionColumn = row.cells.find((cel) => cel.column.id === 'action');
      cell.column.id !== 'action' &&
        actionColumn?.value &&
        dispatch(getGroupForMobilePreview(actionColumn.value));
    },
    [dispatch]
  );

  const createRequest = useCallback(
    (params: FilterParams<AdminGroupsObj>): EventFilterRequest => {
      const filteredName = findFilterById(params.filters, 'name')?.value;
      const filteredPois = findFilterById(params.filters, 'pois')?.value;
      const filteredRoutes = findFilterById(params.filters, 'routes')?.value;
      const filteredLayers = findFilterById(params.filters, 'layers')?.value;
      const filteredFromDate = findFilterById(
        params.filters,
        'startDate'
      )?.value;
      const filteredToDate = findFilterById(params.filters, 'endDate')?.value;
      const poiIds = filteredPois?.map((obj: ValueLocale) => obj.id);
      const routeIds = filteredRoutes?.map((obj: ValueLocale) => obj.id);
      const layerIds = filteredLayers?.map((obj: ValueLocale) => obj.id);
      const name = filteredName ? filteredName : undefined;
      const sortBy: {[key: string]: SortDirection} = getSortDirection(
        params?.sortBy
      );
      const orderBy = params?.sortBy ? sortBy : undefined;
      const betweenValidFromDates = filteredFromDate
        ? getDateRangeFilterString(
            filteredFromDate[0],
            filteredFromDate[1],
            true
          )
        : undefined;
      const betweenValidToDates = filteredToDate
        ? getDateRangeFilterString(filteredToDate[0], filteredToDate[1], true)
        : undefined;
      return {
        orderBy,
        name,
        poiIds,
        routeIds,
        layerIds,
        // roles
        betweenValidFromDates,
        betweenValidToDates,
        limit: params.pageSize,
        offset: params.pageIndex * params.pageSize,
      };
    },
    []
  );

  const getGroups = useCallback(
    (params: FilterParams<AdminGroupsObj>) => {
      if (!loadingList) {
        dispatch(getBaseGroupsList(createRequest(params)));
      }
    },
    [createRequest, dispatch, loadingList]
  );

  const onAgreeMultiSelect = useCallback(
    (
      type: DialogItemButtonTypes,
      value: InputModelValueType,
      selectedRows: Array<AdminGroupsObj>
    ) => {
      switch (type) {
        case 'delete':
          dispatch(
            deleteEvents(
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseGroupsList(createRequest(initialState)))
            )
          );
          break;
      }
      setMultiSelect({
        openDialog: false,
        id: undefined,
      });
    },
    [createRequest, dispatch, initialState]
  );

  const getGroupsList = useMemo(() => {
    t('');
    return adminList.list.map((data0bj) => {
      return {
        action: data0bj.id,
        name: CustomUtils.getContentByLanguage(data0bj.name, data0bj.nameEn),
        startDate: data0bj.startDate
          ? moment(data0bj.startDate).toDate()
          : null,
        endDate: data0bj.endDate ? moment(data0bj.endDate).toDate() : null,
        layers: data0bj.layers
          ?.map((layer) =>
            CustomUtils.getContentByLanguage(layer.name, layer.nameEn)
          )
          .toString(),
        pois: data0bj.pois
          ?.map((poi) => CustomUtils.getContentByLanguage(poi.name, poi.nameEn))
          .toString(),
        routes: data0bj.routes
          ?.map((route) =>
            CustomUtils.getContentByLanguage(route.name, route.nameEn)
          )
          .toString(),
        // roles: data0bj.roles?.toString(),
      } as AdminGroupsObj;
    });
  }, [adminList.list, t]);

  const handleMultiSelect = useCallback(
    (
        instance: TableInstance<AdminGroupsObj>,
        type: DialogItemType<AdminGroupsObj>
      ) =>
      () => {
        setMultiSelect({
          ...type,
          selectedRows: instance.selectedFlatRows.map((v) => v.original),
        });
      },
    []
  );

  const renderTable = useMemo(() => {
    return (
      <Table<GroupsData>
        t={t}
        name={'groupsTable'}
        rowSelection
        setMultiSelect={handleMultiSelect}
        multiselectButtonTypes={['delete']}
        totalCount={adminList.totalCount}
        autoResetPage={false}
        tablePageIndex={tablePageIndex}
        pageCount={
          adminList?.totalCount ? Math.ceil(adminList.totalCount / 10) : 0
        }
        onChangePage={(page) => setTablePageIndex(page)}
        tableState={initialState}
        setTableState={(val) => setState(val)}
        columns={getTableGroupsColumns({
          onQrCodeScan: handleQrCodeScan,
          onEditClick: handleEditClick,
          onDeleteClick: showInfoDialog,
          t,
        })}
        data={getGroupsList}
        onFetchData={getGroups}
        onClick={handleItemClick}
        loadingList={loadingList}
        manualFilters
        manualPagination
        manualSortBy
      />
    );
  }, [
    adminList.totalCount,
    getGroups,
    getGroupsList,
    handleEditClick,
    handleItemClick,
    handleMultiSelect,
    handleQrCodeScan,
    initialState,
    loadingList,
    setState,
    showInfoDialog,
    t,
    tablePageIndex,
  ]);

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('group.Add group')}
          onClick={() => {
            dispatch(groupActions.setObj(null));
            dispatch(groupActions.setAddNewObjDetail());
            dispatch(setGalleryPhotoList([]));
          }}
        />
      </Box>
      {renderTable}
      {openObjDialog && (
        <DialogEvent
          addNew={addNewObj}
          obj={obj}
          loading={loadingDetail}
          openDialog={openObjDialog}
          onCloseDialog={() => {
            dispatch(groupActions.setObj(null));
            dispatch(groupActions.setOpenObjDialog(false));
          }}
          onAddGroup={(group) => {
            dispatch(
              createGroup(group, () => {
                dispatch(getBaseGroupsList(createRequest(initialState)));
              })
            );
          }}
          onUpdateGroup={(group) => {
            dispatch(
              updateGroup(group, () => {
                dispatch(getBaseGroupsList(createRequest(initialState)));
              })
            );
          }}
          imagePreview={(selectedImage, images) =>
            dispatch(showImagePreview({selectedImage, images}))
          }
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.group')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.group'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogItemSelect
        title={t(`user.${getTitleFromType(multiSelect.type)}`)}
        text={
          t(`user.${getTitleFromType(multiSelect.type)}`) +
            ', ' +
            t('user.groups') +
            ' ' +
            multiSelect?.selectedRows?.length || '0'
        }
        id={multiSelect.id}
        value={multiSelect.value}
        openDialog={multiSelect.openDialog}
        type={multiSelect.type}
        selectedRows={multiSelect.selectedRows}
        onCloseDialog={() =>
          setMultiSelect({
            openDialog: false,
            id: undefined,
          })
        }
        onChangeValue={(event, selectedRows) => {
          setMultiSelect((select) => {
            return {...select, value: event, selectedRows};
          });
        }}
        onAgree={(value, selectedRows) =>
          multiSelect.type &&
          onAgreeMultiSelect(multiSelect.type, value, selectedRows)
        }
      />
      <DialogQrCode
        openDialog={qrCodeDialog.openDialog}
        qrCode={qrCodeDialog.qrCode}
        objName={qrCodeDialog.objName}
        onCloseDialog={closeQrCodeDialog}
      />
    </Box>
  );
};
