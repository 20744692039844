import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getObj,
  updateObj,
} from '@skczu/czu-frontend-library';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  Order,
  RoleSortBy,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated/api';
import {
  Configuration,
  Notification,
  NotificationApi,
} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated';
import {getToken} from '../../../keycloak';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  NotificationFilterRequest,
  SaveOrEditNotificationRequest,
} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated/api';
import {NewsApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {setActiveObjectPreview} from '../DrawerSlice';

export interface BaseRolesRequest {
  name?: string;
  minUser?: number;
  maxUser?: number;
  limit?: number;
  offset?: number;
  orderBy?: RoleSortBy;
  sortOrder?: Order;
}
export interface BaseRolesList {
  totalCount?: number;
  list: Notification[];
}

export interface NotificationState
  extends GenericState<SaveOrEditNotificationRequest, Notification> {
  adminList: BaseRolesList;
}

const initialState: NotificationState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {list: []},
};

const notificationSlice = createGenericSlice({
  name: 'notification',
  initialState,
})({
  setAdminList: (
    state: NotificationState,
    {payload}: PayloadAction<BaseRolesList>
  ) => {
    state.adminList = payload;
  },
  addToAdminList: (
    state: NotificationState,
    {payload}: PayloadAction<Notification[]>
  ) => {
    state.adminList.list =
      state.baseObjSearch.offset !== 0
        ? state.adminList && [...state.adminList.list, ...payload]
        : payload;
  },
});

export const getBaseNotificationList =
  ({
    name,
    uri,
    visibleInApp,
    readRequest,
    send,
    sortBy,
    sortDirection,
    betweenSendDates,
    betweenScheduledSendDates,
    betweenCreatedDates,
    limit,
    offset,
  }: NotificationFilterRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(notificationActions.setObjLoadingList(true));
      const response = await new NotificationApi(
        new Configuration({accessToken: await getToken()}),
        config.pushNotificationServiceRestUrl
      ).adminFilterNotificationGet(
        name,
        uri,
        visibleInApp,
        readRequest,
        send,
        sortBy,
        sortDirection,
        betweenSendDates,
        betweenScheduledSendDates,
        betweenCreatedDates,
        limit,
        offset
      );
      if (response?.data) {
        dispatch(
          notificationActions.setAdminList({
            list: response.data.objects ? response.data.objects : [],
            totalCount: response.data.totalCount,
          })
        );
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(notificationActions.setObjLoadingList(false));
    }
  };

export const createNotification =
  (obj: SaveOrEditNotificationRequest, onCreate?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<SaveOrEditNotificationRequest>(
        obj,
        async (obj) =>
          new NotificationApi(
            new Configuration({accessToken: await getToken()}),
            config.pushNotificationServiceRestUrl
          ).adminNotificationPost(obj),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(notificationActions.setObj(null));
          dispatch(notificationActions.setOpenObjDialog(false));
          onCreate && onCreate();
        }
      )
    );
  };

export const deleteNotifications =
  (id: number, onDelete?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new NotificationApi(
            new Configuration({accessToken: await getToken()}),
            config.pushNotificationServiceRestUrl
          ).adminNotificationIdDelete(id),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(notificationActions.setObj(null));
          dispatch(notificationActions.removeFromBaseObjList(String(id)));
          onDelete && onDelete();
        }
      )
    );
  };

export const updateNotification =
  (
    id: number,
    notification: SaveOrEditNotificationRequest,
    onUpdate?: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<SaveOrEditNotificationRequest>(
        notification,
        async (obj) =>
          new NotificationApi(
            new Configuration({accessToken: await getToken()}),
            config.pushNotificationServiceRestUrl
          ).adminNotificationIdPut(id, obj),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        (error) => dispatch(showErrorMessage(error)),
        () => {
          dispatch(notificationActions.setObj(null));
          dispatch(notificationActions.setOpenObjDialog(false));
          onUpdate && onUpdate();
        }
      )
    );
  };

export const getNotification =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<SaveOrEditNotificationRequest>(
        id,
        async () =>
          new NotificationApi(
            new Configuration({accessToken: await getToken()}),
            config.pushNotificationServiceRestUrl
          ).adminNotificationIdGet(id),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(notificationActions.setObj(obj));
        }
      )
    );
  };

export const getNotificationForMobilePreview =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<SaveOrEditNotificationRequest>(
        id,
        async () =>
          new NotificationApi(
            new Configuration({accessToken: await getToken()}),
            config.pushNotificationServiceRestUrl
          ).adminNotificationIdGet(id),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'notification',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
        }
      )
    );
  };

export const createNewsNotification =
  (
    newsId: string,
    notification: Notification,
    onCreate: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<Notification>(
        notification,
        async (obj) =>
          new NewsApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).newsAdminIdNotificationPost(newsId, obj),
        (loading) => dispatch(notificationActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          onCreate();
        }
      )
    );
  };

export const deleteMultipleNotifications =
  (ids: number[], onDeleteUser: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(notificationActions.setObjLoadingList(true));
      await new NotificationApi(
        new Configuration({accessToken: await getToken()}),
        config.pushNotificationServiceRestUrl
      ).adminNotificationDelete(ids);
      onDeleteUser();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(notificationActions.setObjLoadingList(false));
    }
  };

export const notificationActions = notificationSlice.actions;

export const notificationSelector = (
  state: RootState
): typeof state.notification => state.notification;

export default notificationSlice.reducer;
