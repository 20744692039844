import React, {useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {useTranslation} from 'react-i18next';
import {SwitchPrimary} from '../../components/switches/SwitchPrimary';
import makeStyles from '@mui/styles/makeStyles';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import SelectRoles from '../../components/selects/select-multiple/SelectRoles';
import {SelectIcon} from '../../components/selects/select-icon/SelectIcon';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import TextInputPrimary from '../../components/text-inputs/TextInputPrimary';
import {MiniApplication} from '@skczu/czu-frontend-library/build/apis/admin-service/generated';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  appsSelector,
  createAppIcon,
} from '../../../../redux/reducers/data-reducers/AppsSlice';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputs: {
    width: 400,
  },
}));

export type AppForm = Omit<MiniApplication, 'id' | 'icon'> & {icon?: number};

export interface Props {
  app?: MiniApplication | null;
  addNew: boolean;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddApp: (app: MiniApplication) => void;
  onUpdateApp: (app: MiniApplication) => void;
}
export const DialogApp = ({
  addNew,
  openDialog,
  app,
  loading,
  onAddApp,
  onUpdateApp,
  onCloseDialog,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {control, handleSubmit, reset, setValue} = useForm<AppForm>();
  const dispatch = useAppDispatch();
  const appsState = useAppSelector(appsSelector);

  useEffect(() => {
    if (app) {
      setValue('nameEn', app?.nameEn);
      setValue('name', app?.name, {shouldValidate: true});
      setValue('url', app?.url);
      setValue('icon', app?.icon?.id);
      setValue('roles', app.roles);
      setValue('visible', app.visible);
    }
    if (!app) {
      reset();
    }
  }, [app, reset, setValue]);

  const onValidSubmit: SubmitHandler<AppForm> = (data) => {
    if (addNew) {
      onAddApp && onAddApp({...data, icon: {id: data.icon}});
    } else {
      onUpdateApp &&
        onUpdateApp({
          ...data,
          icon: {id: data.icon},
          id: app?.id,
        });
    }
  };

  const onErrorSubmit: SubmitErrorHandler<AppForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const handleCloseDialog = () => {
    reset({});
    onCloseDialog();
  };

  const handleAddNewIcon = (fileData: any) => {
    dispatch(createAppIcon(fileData));
  };

  return (
    <Box>
      <DialogPrimary
        title={t('apps.Edit app')}
        open={openDialog}
        loading={loading}
        onCloseDialog={handleCloseDialog}>
        <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
          <Box marginLeft={8} marginRight={8}>
            <Box className={classes.container}>
              <Controller
                name="visible"
                control={control}
                defaultValue={false}
                render={({field, fieldState: {error}}) => (
                  <SwitchPrimary
                    {...field}
                    boldLabel
                    label={t(`apps.Visible in mobile`)}
                    errorType={error?.type}
                  />
                )}
              />
              <Box marginTop={2}>
                <Controller
                  name={'name'}
                  control={control}
                  rules={{required: true, minLength: 2, maxLength: 80}}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputPrimary
                      {...field}
                      boldLabel
                      className={classes.inputs}
                      label={t(`apps.nameCZ`)}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box marginTop={2}>
                <Controller
                  name={'nameEn'}
                  control={control}
                  rules={{required: true, minLength: 2, maxLength: 80}}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputPrimary
                      {...field}
                      boldLabel
                      label={t(`apps.nameEN`)}
                      className={classes.inputs}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box marginTop={2}>
                <Controller
                  name={'url'}
                  control={control}
                  rules={{required: true, minLength: 2, maxLength: 80}}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputPrimary
                      {...field}
                      boldLabel
                      label={t(`apps.url`)}
                      className={classes.inputs}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box marginTop={2}>
                <Controller
                  name="icon"
                  control={control}
                  defaultValue={undefined}
                  render={({field, fieldState: {error}}) => (
                    <SelectIcon
                      {...field}
                      label={t(`apps.icon`)}
                      onAddNewIcon={handleAddNewIcon}
                      value={field.value}
                      errorType={error?.type}
                      icons={appsState.adminList.iconList}
                    />
                  )}
                />
              </Box>
              <Box marginTop={2}>
                <Controller
                  name="roles"
                  control={control}
                  defaultValue={[]}
                  render={({field, fieldState: {error}}) => (
                    <SelectRoles
                      {...field}
                      label={t(`apps.roles`)}
                      errorType={error?.type}
                      hasCzuRoles
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{m: 2, mt: 10}}>
            <ButtonAdd
              name={addNew ? t('apps.Add app') : t('apps.Update app')}
            />
          </Box>
        </form>
      </DialogPrimary>
    </Box>
  );
};
