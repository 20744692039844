import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../store';
import {getPoi} from './data-reducers/PoiSlice';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';

interface PoisOrder {
  startIndex: number;
  endIndex: number;
}

export interface PoiSelection {
  poiSelection: boolean;
  poiSelectionFrom?: 'layer' | 'news' | 'route' | 'poi360';
  poiList?: PoiRef[];
}

interface PoiSelectionState {
  poiSelection: PoiSelection;
  openDialogAfterPoiSelection: boolean;
}

const initialState: PoiSelectionState = {
  poiSelection: {poiSelection: false},
  openDialogAfterPoiSelection: false,
};

export const poiSelectionSlice = createSlice({
  name: 'poiSelection',
  initialState,
  reducers: {
    setPoiSelection: (state, {payload}: PayloadAction<PoiSelection>) => {
      state.poiSelection.poiSelection = payload.poiSelection;
      state.poiSelection.poiSelectionFrom = payload.poiSelectionFrom;
    },
    addToPoiSelectionList: (state, {payload}: PayloadAction<PoiRef>) => {
      state.poiSelection.poiList = state.poiSelection.poiList
        ? [...state.poiSelection.poiList, payload]
        : [payload];
    },
    addListToPoiSelectionList: (state, {payload}: PayloadAction<PoiRef[]>) => {
      state.poiSelection.poiList = state.poiSelection.poiList
        ? [...current(state.poiSelection.poiList), ...payload].filter(
            (item, i, array) => array.findIndex((t) => t.id === item.id) === i
          )
        : payload;
    },
    reorderPoiSelectionList: (state, {payload}: PayloadAction<PoisOrder>) => {
      if (state.poiSelection.poiList) {
        const result = state.poiSelection.poiList;
        const [removed] = result.splice(payload.startIndex, 1);
        result.splice(payload.endIndex, 0, removed);
        state.poiSelection.poiList = result;
      }
    },
    setPoiSelectionList: (state, {payload}: PayloadAction<PoiRef[]>) => {
      state.poiSelection.poiList = payload;
    },
    removeFromPoiSelectionList: (state, {payload}: PayloadAction<string>) => {
      state.poiSelection.poiList = state.poiSelection.poiList?.filter(
        (poi) => poi.id !== payload
      );
    },
    setOpenDialogAfterPoiSelection: (
      state,
      {payload}: PayloadAction<boolean>
    ) => {
      state.openDialogAfterPoiSelection = payload;
    },
  },
});

export const addToPoiSelection =
  (poiId: string, setNewPoi: boolean): AppThunk =>
  async (dispatch, getState) => {
    const poiSelection = getState().poiSelection.poiSelection;
    const basePoiList = getState().poiList.baseObjList;
    const selectedPoi = poiSelection.poiList?.find((item) => item.id === poiId);
    const foundPoi = basePoiList && basePoiList.find((poi) => poi.id === poiId);
    if (!selectedPoi) {
      poiSelection.poiList &&
        poiSelection.poiSelectionFrom === 'poi360' &&
        poiSelection.poiList.length > 0 &&
        poiSelection.poiList[0].id &&
        dispatch(removeFromPoiSelectionList(poiSelection.poiList[0].id));
      if (!foundPoi) {
        dispatch(getPoi(poiId, setNewPoi, true));
      } else {
        dispatch(addToPoiSelectionList(foundPoi));
      }
    } else {
      dispatch(removeFromPoiSelectionList(poiId));
    }
  };

export const {
  setPoiSelection,
  addListToPoiSelectionList,
  addToPoiSelectionList,
  setPoiSelectionList,
  reorderPoiSelectionList,
  removeFromPoiSelectionList,
  setOpenDialogAfterPoiSelection,
} = poiSelectionSlice.actions;

export const poiSelectionSelector = (
  state: RootState
): typeof state.poiSelection => state.poiSelection;

export const selectSelectedPoi = (
  state: RootState
): typeof state.poiSelection.poiSelection => state.poiSelection.poiSelection;

export default poiSelectionSlice.reducer;
