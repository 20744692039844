import {CellProps, Column} from 'react-table';
import React from 'react';
import {TFunction} from 'react-i18next';
import {AdminGroupsObj, GroupsData} from './TableEvents';
import {EventsActionCell} from './EventsActionCell';
import {TextFilter} from '../../../shared/table-fiter-inputs/TextFilter';
import {TableDateCell} from '../news/table/TableNews';
import {DateFilter} from '../../../shared/table-fiter-inputs/DateFilter';
import {SelectRoutesFilter} from '../../../shared/table-filter-selects/SelectRoutesFilter';
import {SelectLayersFilter} from '../../../shared/table-filter-selects/SelectLayersFilter';
import {SelectPoisFilter} from '../../../shared/table-filter-selects/SelectPoisFilter';

export interface TableGroupsColumnsProps {
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onQrCodeScan: (id: string) => void;
  t: TFunction;
}

export const getTableGroupsColumns = ({
  onEditClick,
  onDeleteClick,
  onQrCodeScan,
  t,
}: TableGroupsColumnsProps): Column<GroupsData>[] => {
  return [
    {
      id: 'groups-table',
      Header: '',
      columns: [
        {
          Header: t(`objectInputs.Name`) + '',
          accessor: 'name',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Names`,
        },
        {
          Header: t(`objectInputs.Poi`) + '',
          accessor: 'pois',
          aggregate: 'uniqueCount',
          Filter: SelectPoisFilter,
          filter: 'includesSome',
          disableSortBy: true,
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Unique Names`,
        },
        {
          Header: t(`objectInputs.Routes`) + '',
          accessor: 'routes',
          aggregate: 'uniqueCount',
          Filter: SelectRoutesFilter,
          filter: 'includesSome',
          disableSortBy: true,
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Unique Names`,
        },
        {
          Header: t(`objectInputs.Layers`) + '',
          accessor: 'layers',
          aggregate: 'uniqueCount',
          Filter: SelectLayersFilter,
          disableSortBy: true,
          filter: 'includesSome',
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Unique Names`,
        },
        {
          Header: t(`user.Start`) + '',
          accessor: 'startDate',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`user.End`) + '',
          accessor: 'endDate',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminGroupsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`objectInputs.Action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          // eslint-disable-next-line react/display-name
          Cell: (cell: CellProps<AdminGroupsObj>) => (
            <EventsActionCell
              cell={cell}
              onQrCodeScan={onQrCodeScan}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ),
        },
      ],
    },
  ];
};
