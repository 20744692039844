import React from 'react';
import {CellProps, Column, FilterProps, Renderer} from 'react-table';
import {TFunction} from 'react-i18next';
import {TextFilter} from '../../../../shared/table-fiter-inputs/TextFilter';
import {NotificationObj} from './TableNotifications';
import {DateFilter} from '../../../../shared/table-fiter-inputs/DateFilter';
import {AdminNewsObj, TableDateCell} from '../../news/table/TableNews';
import {SelectColumnFilter} from '../../../../shared/table-fiter-inputs/SelectColumnFilter';

export interface TableNotificationColumnsProps {
  t: TFunction;
  actionCell: Renderer<CellProps<NotificationObj, string>> | undefined;
}

export const getTableNotificationColumns = ({
  actionCell,
  t,
}: TableNotificationColumnsProps): Column<NotificationObj>[] => {
  return [
    {
      id: 'users-table',
      Header: '',
      columns: [
        {
          Header: t(`notification.Title`) + '',
          accessor: 'title',
          Filter: TextFilter,
        },
        // {
        //   Header: t(`notification.Body text`) + '',
        //   accessor: 'body',
        //   Filter: TextFilter,
        // },
        {
          Header: t(`notification.Body text`) + '',
          accessor: 'miniBody',
          maxWidth: 160,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`notification.Object URI`) + '',
          accessor: 'uri',
          disableSortBy: true,
          Filter: TextFilter,
        },
        {
          Header: t(`notification.Status`) + '',
          accessor: 'send',
          filter: 'tValue',
          Filter: (props: FilterProps<AdminNewsObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {value: 'notification.Sent', name: t('notification.Sent')},
                {
                  value: 'notification.Not sent',
                  name: t('notification.Not sent'),
                },
              ]}
            />
          ),
        },
        {
          Header: t(`notification.Visible in app`) + '',
          accessor: 'visibleInApp',
          filter: 'tValue',
          Filter: (props: FilterProps<AdminNewsObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {
                  value: 'notification.Visible',
                  name: t('notification.Visible'),
                },
                {
                  value: 'notification.Invisible',
                  name: t('notification.Invisible'),
                },
              ]}
            />
          ),
        },
        {
          Header: t(`notification.Read request`) + '',
          accessor: 'confirmationRequired',
          filter: 'tValue',
          Filter: (props: FilterProps<AdminNewsObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {value: 'allObjects.Yes', name: t('allObjects.Yes')},
                {value: 'allObjects.No', name: t('allObjects.No')},
              ]}
            />
          ),
        },
        {
          Header: t(`notification.Scheduled send date`) + '',
          accessor: 'scheduledSendDate',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`notification.Send date`) + '',
          accessor: 'sendDate',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`objectInputs.Action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          Cell: actionCell,
        },
      ],
    },
  ];
};
