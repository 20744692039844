import React, {useCallback, useEffect, useState} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box, Typography} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {SwitchPrimary} from '../../components/switches/SwitchPrimary';
import {MultipleFileViewer} from './MultipleFileViewer';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {contentLanguageSelector} from '../../../../redux/reducers/ContentLanguage';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import {ButtonHeader} from '../../components/buttons/ButtonHeader';
import {StylesAdminTab} from '../../../scenes/admin-menu-scene/StylesAdminTab';
import TextInputPrimary from '../../components/text-inputs/TextInputPrimary';
import {StylesDialog} from '../StylesDialog';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdate3dObject,
  Object3d,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Tile3D} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d/api';
import {ObjMapper} from '@skczu/czu-frontend-library';
import {ButtonUploadObject3dFiles} from '../../components/buttons/ButtonUploadMultipleFiles';

export type Object3dForm = Omit<Object3d, 'id' | 'layers' | 'tiles3D'> & {
  // layers?: string[];
  tiles3D?: Tile3D[] | DamFileWithData[];
};

export interface Props {
  addNew?: boolean;
  object3d?: Object3d | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddObject3d?: (
    object3d: CreateOrUpdate3dObject,
    image3ds?: Tile3D[]
  ) => void;
  onUpdateObject3d?: (
    object3d: CreateOrUpdate3dObject,
    image3ds?: Tile3D[]
  ) => void;
}

export const DialogObject = ({
  addNew,
  object3d,
  loading,
  openDialog,
  onCloseDialog,
  onAddObject3d,
  onUpdateObject3d,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const [validArea, setValidArea] = React.useState(true);
  const {control, handleSubmit, reset, setValue, watch} =
    useForm<Object3dForm>();
  const classes = StylesAdminTab();
  const dialogClasses = StylesDialog();
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const [uploadType, setUploadType] = useState<'file' | 'url'>('file');
  const dispatch = useAppDispatch();

  const clearInputs = useCallback(() => {
    reset();
    setValidArea(true);
  }, [reset]);

  useEffect(() => {
    if (object3d && openDialog) {
      setValue('name', object3d.name);
      setValue('nameEn', object3d.nameEn);
      setValue('applyOnMap', object3d.applyOnMap);
      setValue('tilesetUrl', object3d.tilesetUrl);
      // setValue('layers', object3d.layers?.map((layer) => layer.id) as string[]);
      const object3ds = object3d.tiles3d?.map((tile3d) => {
        return {id: tile3d.id, fileName: tile3d.name, data: tile3d.data};
      }) as Tile3D[];
      object3d.tileset &&
        object3ds.push({
          data: object3d.tileset,
          name: 'tileset.json',
        });
      setValue('tiles3D', object3ds);

      object3d.tilesetUrl && setUploadType('file');
    }
    if (openDialog && !object3d) {
      clearInputs();
    }
  }, [clearInputs, object3d, openDialog, setValue]);

  const onErrorSubmit: SubmitErrorHandler<Object3dForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const handleFileSubmit = (data: Object3dForm) => {
    const tilesetJson = (data?.tiles3D as DamFileWithData[])?.find(
      (file) => ObjMapper.geNameFromDam(file)?.toLowerCase() === 'tileset.json'
    )?.data;
    if (tilesetJson || data.tilesetUrl) {
      const tiles3Ds = (data?.tiles3D as DamFileWithData[])
        ?.filter(
          (file) =>
            ObjMapper.geNameFromDam(file)?.toLowerCase() !== 'tileset.json'
        )
        .map((file) => {
          return {
            id: file.id,
            name: ObjMapper.geNameFromDam(file),
            data: file.data,
          };
        });
      const newObject3D = {
        ...data,
        tileset: tilesetJson,
        tiles3D: [],
      };
      if (tiles3Ds || data.tilesetUrl) {
        if (addNew) {
          onAddObject3d &&
            onAddObject3d(newObject3D, tiles3Ds ? tiles3Ds : undefined);
        } else {
          onUpdateObject3d &&
            onUpdateObject3d(
              {...newObject3D, id: object3d?.id},
              tiles3Ds ? tiles3Ds : undefined
            );
        }
      }
    } else {
      setValidArea(false);
    }
  };

  const onValidSubmit: SubmitHandler<Object3dForm> = (data) => {
    handleFileSubmit(data);
  };

  const handleAreaFilesChange = (tile3Dfiles: DamFileWithData[]) => {
    const valid = tile3Dfiles?.some(
      (file) => file.fileName.toLowerCase() === 'tileset.json'
    );
    setValidArea(valid);
  };

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
  };

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('object.Add new 3D area') : t('object.Edit 3D area')}
        open={openDialog}
        loading={loading}
        contentLanguage={true}
        minWidth={'900px'}
        onCloseDialog={handleCloseDialog}>
        <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
          <Box display="flex" flexDirection="row">
            <Box>
              <Box marginLeft={4}>
                <TextInputObjectName
                  selectedLanguage={selectedLanguage}
                  control={control}
                />
              </Box>
              <Box display="flex" flexDirection="row" marginTop={2}>
                {/*<Controller*/}
                {/*  name="layers"*/}
                {/*  control={control}*/}
                {/*  defaultValue={[]}*/}
                {/*  render={({field, fieldState: {error}}) => (*/}
                {/*    <SelectLayers*/}
                {/*      {...field}*/}
                {/*      label={t(`object.Layers`)}*/}
                {/*      errorType={error?.type}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}
                <Box marginLeft={4}>
                  <Controller
                    name="applyOnMap"
                    control={control}
                    defaultValue={false}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        boldLabel
                        label={t(`object.Apply on map`)}
                        errorType={error?.type}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  height: 1,
                  marginTop: 20,
                  marginLeft: 30,
                }}
              />
              <Box className={classes.tabHeader} paddingLeft={1.5}>
                <ButtonHeader
                  name={t('object.Upload from file')}
                  active={uploadType === 'file'}
                  titleType={'h3'}
                  onClick={() => setUploadType('file')}
                />
                <ButtonHeader
                  name={t('object.Upload from URL')}
                  active={uploadType === 'url'}
                  titleType={'h3'}
                  onClick={() => setUploadType('url')}
                />
              </Box>
              <Box
                style={{
                  display: uploadType === 'file' ? 'block' : 'none',
                }}>
                <Box marginLeft={4}>
                  <Box marginBottom={2}>
                    <Controller
                      name="tiles3D"
                      control={control}
                      defaultValue={[]}
                      render={({field, fieldState: {error}}) => (
                        <ButtonUploadObject3dFiles
                          {...field}
                          label={t(`object.Area files`)}
                          errorType={error?.type}
                          onChange={(event) => {
                            field.onChange(event);
                            handleAreaFilesChange(event);
                          }}
                        />
                      )}
                    />
                  </Box>
                  {!validArea && (
                    <Typography color={'error'} variant={'subtitle2'}>
                      {t('object.File tileset.json is missing.')}
                    </Typography>
                  )}
                  <MultipleFileViewer
                    files={watch('tiles3D')}
                    dirName={watch('name')}
                  />
                </Box>
              </Box>
              <Box
                marginLeft={4}
                style={{
                  display: uploadType === 'url' ? 'block' : 'none',
                  minHeight: 380,
                }}>
                <Controller
                  name="tilesetUrl"
                  control={control}
                  rules={{minLength: 10, maxLength: 140}}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <TextInputPrimary
                      {...field}
                      boldLabel
                      label={t(`URL`)}
                      className={dialogClasses.objectName}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box marginTop={6}>
                <ButtonAdd
                  name={
                    addNew
                      ? t('object.Add 3D area')
                      : t('object.Update 3D area')
                  }
                />
              </Box>
            </Box>
          </Box>
        </form>
      </DialogPrimary>
    </Box>
  );
};
