import {Chip} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {ReactElement, useCallback} from 'react';
import {
  ColumnInstance,
  Filters,
  FilterValue,
  IdType,
  TableInstance,
} from 'react-table';
import {TFunction, useTranslation} from 'react-i18next';
import {CustomUtils} from '../utils/CustomUtils';
import {instanceOfValueLocale, ValueLocale} from '../utils/Utils';

const FilterChipLocaleObj = (filterValue: FilterValue) => {
  const value = filterValue.filterValue;
  if (value && Array.isArray(value)) {
    return (
      <>
        {value?.map((value: string | ValueLocale, index: number) => (
          <>
            <span
              key={'FilterChip_' + index}
              style={{
                textDecoration: 'underline',
              }}>
              {instanceOfValueLocale(value)
                ? CustomUtils.getContentByLanguage(value.name, value.nameEn)
                : value}
            </span>
            {filterValue.filterValue.length - 1 !== index && (
              <span
                key={'FilterChipBar_' + index}
                style={{
                  marginRight: 8,
                  marginLeft: 8,
                  fontSize: 16,
                  fontWeight: 'bold',
                }}>
                |
              </span>
            )}
          </>
        ))}
      </>
    );
  } else {
    return (
      <>
        <span
          key={'FilterLocaleChip'}
          style={{
            textDecoration: 'underline',
          }}>
          {instanceOfValueLocale(value)
            ? CustomUtils.getContentByLanguage(value.name, value.nameEn)
            : String(value)}
        </span>
      </>
    );
  }
};

const useStyles = makeStyles(
  createStyles({
    filtersActiveLabel: {
      color: '#998',
      fontSize: 15,
      marginTop: 5,
      paddingRight: 10,
    },
    chipZone: {
      padding: '18px 0 5px 10px',
      width: '100%',
      display: 'flex',
    },
    chipLabel: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    filterChip: {
      borderRadius: 10,
      marginRight: 5,
      color: '#000000',
    },
  })
);

type FilterChipBarProps<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  filters?: Filters<T>;
  onRemoveFilter?: (id: IdType<T>) => void;
};

const getFilterValue = (
  t: TFunction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: ColumnInstance<any>,
  filterValue: FilterValue
) => {
  switch (column.filter) {
    case 'between':
    case 'minMax':
      const min = filterValue[0];
      const max = filterValue[1];
      return min ? (max ? `${min} - ${max}` : `>=${min}`) : `<=${max}`;
    case 'dateRange':
      const from = filterValue[0];
      const to = filterValue[1];
      return from ? (to ? `${from} - ${to}` : `>=${from}`) : `<=${to}`;
    case 'tValue':
      return t(filterValue);
    case 'some':
    case 'includesSome':
      return <FilterChipLocaleObj filterValue={filterValue} />;
  }
  return filterValue;
};

export function FilterChipBar<T extends Record<string, unknown>>({
  instance,
  filters,
  onRemoveFilter,
}: FilterChipBarProps<T>): ReactElement | null {
  const {t} = useTranslation();
  const classes = useStyles({});
  const {
    allColumns,
    setFilter,
    // state: {filters},
  } = instance;
  const handleDelete = useCallback(
    (id: string | number) => {
      setFilter(id as IdType<T>, undefined);
      onRemoveFilter && onRemoveFilter(id as IdType<T>);
    },
    [onRemoveFilter, setFilter]
  );

  return filters && filters.length > 0 ? (
    <div className={classes.chipZone}>
      <span className={classes.filtersActiveLabel}>
        {t('allObjects.Active filters')}
      </span>
      {filters &&
        allColumns.map((column, index) => {
          const filter = filters.find((f) => f.id === column.id);
          const value = filter && filter.value;
          return (
            value && (
              <Chip
                className={classes.filterChip}
                key={column.id + ' ' + index}
                label={
                  <>
                    <span className={classes.chipLabel}>
                      {column.render('Header')}:{' '}
                    </span>
                    {getFilterValue(t, column, value)}
                  </>
                }
                onDelete={() => handleDelete(column.id)}
                variant="outlined"
              />
            )
          );
        })}
    </div>
  ) : null;
}
