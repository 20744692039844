import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Close} from '@mui/icons-material';

export interface Props {
  showError: boolean;
  errorMessage: string;
  onClose?: () => void;
}

export const ErrorNotification = ({
  showError,
  errorMessage,
  onClose,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  return showError ? (
    <Box className={classes.container}>
      <Box className={classes.bodyContent}>
        <Typography className={classes.body}>{errorMessage}</Typography>
      </Box>
      <IconButton
        aria-label="close"
        className={classes.cancelButton}
        onClick={onClose}
        size="large">
        <Close />
      </IconButton>
    </Box>
  ) : null;
};
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      maxWidth: '50%',
      right: 10,
      top: 10,
      zIndex: 9999,
      backgroundColor: 'rgba(255,0,0,0.85);',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'row',
    },
    bodyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    body: {
      color: 'white',
      fontSize: 15,
      marginLeft: 15,
      maxWidth: '81%',
    },
    cancelButton: {
      marginLeft: 20,
      color: 'white',
    },
  })
);
