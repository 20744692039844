import {setLoading} from '../redux/reducers/LoadingSlice';
import {setPoi360ListFailed} from '../redux/reducers/data-reducers/Poi360Slice';
import {useAppDispatch} from './hooks';
import {useState} from 'react';
import {getToken} from '../keycloak';
import config from '../config';
import {
  Configuration,
  ObjectType,
  QrCodeApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/qrCode';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

export interface QrCodeHook {
  qrCodeDialog: QrCodeDialog;
  getObjectQrCode: (id: string, object: ObjectType, objName: string) => void;
  closeQrCodeDialog: () => void;
}

export interface QrCodeDialog {
  qrCode?: DamFileWithData;
  openDialog: boolean;
  objName?: string;
  id?: string;
}

export const useQrCode = (): QrCodeHook => {
  const dispatch = useAppDispatch();
  const [qrCodeDialog, setQrCodeDialog] = useState<QrCodeDialog>({
    openDialog: false,
  });

  const getObjectQrCode = async (
    id: string,
    object: ObjectType,
    objName: string
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await new QrCodeApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).qrCodeObjectTypeIdGet(id, object);
      if (response.data) {
        setQrCodeDialog({
          openDialog: true,
          qrCode: response.data,
          objName: objName,
          id: id,
        });
      }
    } catch (error) {
      dispatch(setPoi360ListFailed({message: error as string}));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const closeQrCodeDialog = () => {
    setQrCodeDialog({
      openDialog: false,
      qrCode: undefined,
      id: undefined,
      objName: undefined,
    });
  };
  return {qrCodeDialog, getObjectQrCode, closeQrCodeDialog};
};
