import {CellProps, Column} from 'react-table';
import React from 'react';
import {TFunction} from 'react-i18next';
import {AdminAppsObj, AppsData} from './TableApps';
import {AppsActionCell} from './cells/AppsActionCell';
import {AppIconMemo} from './cells/AppIcon';
import {AppsStatus} from './cells/AppStatus';

export interface TableAppsColumnsProps {
  onEditClick: (value: string) => void;
  onChangeStatus: (value: boolean, appId?: number) => void;
  t: TFunction;
}

export const getTableAppsColumns = ({
  onEditClick,
  onChangeStatus,
  t,
}: TableAppsColumnsProps): Column<AppsData>[] => {
  return [
    {
      id: 'apps-table',
      Header: '',
      columns: [
        {
          Header: t(`apps.icon`) + '',
          accessor: 'icon',
          disableSortBy: true,
          disableFilters: true,
          Cell: (cell: CellProps<AdminAppsObj>) => <AppIconMemo cell={cell} />,
          maxWidth: 70,
          minWidth: 70,
        },
        {
          Header: t(`apps.nameCZ`) + '',
          accessor: 'name',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`apps.nameEN`) + '',
          accessor: 'nameEn',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`apps.url`) + '',
          accessor: 'url',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`apps.roles`) + '',
          accessor: 'roles',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`apps.status`) + '',
          accessor: 'visible',
          disableSortBy: true,
          disableFilters: true,
          Cell: (cell: CellProps<AdminAppsObj>) => (
            <AppsStatus cell={cell} onEditClick={onChangeStatus} />
          ),
        },
        {
          Header: t(`apps.action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          // eslint-disable-next-line react/display-name
          Cell: (cell: CellProps<AdminAppsObj>) => (
            <AppsActionCell cell={cell} onEditClick={onEditClick} />
          ),
        },
      ],
    },
  ];
};
