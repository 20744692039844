import React, {useCallback, useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {MobileScreenNewsDetail} from '@skczu/czu-react-components';
import {TabItem, TabsDialog} from '../shared/tabs-dialog/TabsDialog';
import {TabDetailNews} from './tabs/info-tab/TabDetailNews';
import {TabNewsPoi} from './tabs/pois-tab/TabNewsPoi';
import {StylesDialog} from '../StylesDialog';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  PoiSelection,
  poiSelectionSelector,
  setOpenDialogAfterPoiSelection,
  setPoiSelection,
  setPoiSelectionList,
} from '../../../../redux/reducers/PoiListSelectionSlice';
import {useHistory} from 'react-router-dom';
import {
  RouteSelection,
  routeSelectionSelector,
  setOpenDialogAfterRouteSelection,
  setRouteSelection,
  setRouteSelectionList,
} from '../../../../redux/reducers/RouteListSelectionSlice';
import {TabNewsRoutes} from './tabs/routes-tab/TabNewsRoutes';
import {
  deleteVideo,
  setGalleryPhotoList,
} from '../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {useTranslation} from 'react-i18next';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {UploadFile, useUploadPhoto} from '../../../../hooks/useUploadPhoto';
import {useUploadVideo} from '../../../../hooks/useUploadVideo';
import {SelectedImagePreview, Utils} from '@skczu/czu-frontend-library';
import {CustomUtils} from '../../utils/CustomUtils';
import {SubmitErrorHandler, useForm} from 'react-hook-form';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdateNews,
  News,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {
  DamFileWithData,
  DamImage,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  htmlToPlainText,
  isBeforeVisible,
  roundTimeMinutes,
} from '../../utils/Utils';
import {ObjectPreviewWithFormContainer} from '../../components/mobile-preview/ObjectPreviewWithFormContainer';
import moment from 'moment/moment';
import {SaveOrEditNotificationRequest} from '@skczu/czu-frontend-library/build/apis/push-notification-service/generated/api';
import {
  getNotificationState,
  newsActions,
  updateNews,
} from '../../../../redux/reducers/data-reducers/NewsSlice';
import {getMobileObjDetailUrl} from '../../components/mobile-preview/MobileObjDetailUrlType';

export type NewsTmp = News & {pushNot?: boolean; visibleFromNow?: boolean};
export type NewsForm = Omit<
  CreateOrUpdateNews,
  | 'id'
  | 'events'
  | 'categories'
  | 'video'
  | 'photos'
  | 'miniBody'
  | 'miniBodyEn'
> & {
  // events: string[];
  pushNot: boolean;
  video?: DamFileWithData;
  visibleFromNow?: boolean;
};

const getCreateOrUpdateNews = (
  formData: UnpackNestedValue<NewsForm>,
  galleryPhotoList: UploadFile[],
  poiSelection: PoiSelection,
  routeSelection: RouteSelection,
  id?: string
): CreateOrUpdateNews => {
  const photoIds = galleryPhotoList.map(
    (galleryPhoto) => galleryPhoto.galleryPhoto?.id as string
  );
  const poiIds = poiSelection.poiList?.map((poi) => poi.id);
  const routeIds = routeSelection.baseRouteList?.map((route) => route.id);

  const {
    pushNot,
    visibleFromNow,
    visibleFrom,
    visibleTo,
    notification,
    ...data
  } = formData;
  const notifi = {
    sendDate:
      notification?.sendDate && notification.sendDate !== 'Invalid date'
        ? isBeforeVisible(
            notification.sendDate,
            moment().add(1, 'minutes').format(DefaultDateTimeFormatFE)
          )
          ? roundTimeMinutes(
              moment().add(1, 'minutes'),
              moment.duration(5, 'minutes')
            )
              .utc()
              .format(DefaultDateTimeFormatBE)
          : moment(notification.sendDate, DefaultDateTimeFormatFE)
              .utc()
              .format(DefaultDateTimeFormatBE)
        : undefined,
    visibleInApp: notification?.visibleInApp,
    sendNow: notification?.sendNow,
  };
  return {
    ...data,
    id,
    notification: pushNot ? notifi : undefined,
    photos: photoIds,
    miniBody: data.body ? htmlToPlainText(data.body).substring(0, 99) : '',
    miniBodyEn: data.bodyEn
      ? htmlToPlainText(data.bodyEn).substring(0, 99)
      : '',
    visibleFrom: data.visibleInApp
      ? visibleFromNow
        ? moment().utc().format(DefaultDateTimeFormatBE)
        : visibleFrom && visibleFrom !== 'Invalid date'
        ? moment(visibleFrom, DefaultDateTimeFormatFE)
            .utc()
            .format(DefaultDateTimeFormatBE)
        : undefined
      : undefined,
    visibleTo: data.visibleInApp
      ? visibleTo && visibleTo !== 'Invalid date'
        ? moment(visibleTo, DefaultDateTimeFormatFE)
            .utc()
            .format(DefaultDateTimeFormatBE)
        : undefined
      : undefined,
    pois: poiIds ? (poiIds as string[]) : [],
    routes: routeIds ? (routeIds as string[]) : [],
    video: data?.video?.id ? data.video.id : undefined,
  };
};

export interface DialogNewsProps {
  addNew?: boolean;
  news?: News | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddNews?: (news: CreateOrUpdateNews) => void;
  onUpdateNews?: (news: CreateOrUpdateNews) => void;
  onObjectSelection?: (news: News) => void;
  imagePreview?: SelectedImagePreview;
}

export const DialogNews = ({
  addNew,
  openDialog,
  onCloseDialog,
  news,
  loading,
  onAddNews,
  onUpdateNews,
  onObjectSelection,
  imagePreview,
}: DialogNewsProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = StylesDialog();
  const [activePage, setActivePage] = React.useState<TabItem>({
    name: 'Info',
    label: t('news.Info'),
  });
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm<NewsForm>();
  const history = useHistory();
  const {openDialogAfterPoiSelection, poiSelection} =
    useAppSelector(poiSelectionSelector);
  const {routeSelection, openDialogAfterRouteSelection} = useAppSelector(
    routeSelectionSelector
  );
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const {galleryPhotoList, addGalleryPhotos} = useUploadPhoto('news');
  const {videoUploadProgress, addGalleryVideo} = useUploadVideo((newVideo) => {
    setValue(
      'video',
      CustomUtils.convertCreateVideoResponseToDamFile(newVideo)
    );
  });

  const navigateTo = useCallback((path) => history.push(path), [history]);

  const clearInputs = useCallback(() => {
    reset({});
    dispatch(setPoiSelectionList([]));
    dispatch(setRouteSelectionList([]));
  }, [dispatch, reset]);

  useEffect(() => {
    if (news && openDialog) {
      // news.notification.
      setValue('name', news?.name);
      setValue('nameEn', news?.nameEn);
      setValue('body', news?.body);
      setValue('bodyEn', news?.bodyEn);
      // setValue('events', news.events?.map((group) => group.id) as string[]);
      setValue('notification.sendNow', news.notification?.sendNow);
      setValue(
        'notification.sendDate',
        news.notification?.sendDate
          ? moment(news.notification.sendDate).format(DefaultDateTimeFormatFE)
          : undefined
      );
      setValue('notification.visibleInApp', news.notification?.visibleInApp);
      setValue(
        'video',
        CustomUtils.convertCreateVideoResponseToDamFile(news.video)
      );
      const afterVisibleTo = !addNew
        ? news.visibleTo
          ? isBeforeVisible(
              moment(news.visibleTo).format(DefaultDateTimeFormatFE),
              moment().format(DefaultDateTimeFormatFE)
            )
          : false
        : false;
      if (afterVisibleTo) {
        setValue('visibleInApp', false);
        setValue('visibleFrom', undefined);
        setValue('visibleTo', undefined);
      } else {
        setValue('visibleInApp', news.visibleInApp);
        setValue(
          'visibleFrom',
          news.visibleFrom
            ? moment(news.visibleFrom).format(DefaultDateTimeFormatFE)
            : undefined
        );
        setValue(
          'visibleTo',
          news.visibleTo
            ? moment(news.visibleTo).format(DefaultDateTimeFormatFE)
            : undefined
        );
      }
      if (openDialogAfterPoiSelection || openDialogAfterRouteSelection) {
        setValue('pushNot', news ? !!(news as NewsTmp)?.pushNot : true);
        setValue(
          'visibleFromNow',
          news ? !!(news as NewsTmp)?.visibleFromNow : true
        );
      }
    }
    if (openDialog && !news) {
      clearInputs();

      setValue('visibleInApp', true);
      setValue('pushNot', true);
    }
    dispatch(setOpenDialogAfterPoiSelection(false));
    dispatch(setOpenDialogAfterRouteSelection(false));
  }, [
    addNew,
    clearInputs,
    dispatch,
    news,
    openDialog,
    openDialogAfterPoiSelection,
    openDialogAfterRouteSelection,
    setValue,
  ]);

  useEffect(() => {
    openDialogAfterPoiSelection &&
      setActivePage(
        openDialogAfterPoiSelection
          ? {
              name: 'Poi',
              label: t('news.Poi'),
            }
          : {
              name: 'Info',
              label: t('news.Info'),
            }
      );
  }, [openDialogAfterPoiSelection, t]);

  useEffect(() => {
    openDialogAfterRouteSelection &&
      setActivePage(
        openDialogAfterRouteSelection
          ? {
              name: 'Routes',
              label: t('news.Routes'),
            }
          : {
              name: 'Info',
              label: t('news.Info'),
            }
      );
  }, [openDialogAfterRouteSelection, t]);

  const onErrorSubmit: SubmitErrorHandler<NewsForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit = (formData: UnpackNestedValue<NewsForm>) => {
    const newNews = getCreateOrUpdateNews(
      formData,
      galleryPhotoList,
      poiSelection,
      routeSelection
    );

    if (addNew) {
      onAddNews && onAddNews(newNews);
    } else {
      onUpdateNews && onUpdateNews({...newNews, id: news?.id});
    }
  };

  const createNewsTmp = (): Omit<News, 'events' | 'categories'> | null => {
    const pois = poiSelection.poiList;
    const routes = routeSelection.baseRouteList
      ? routeSelection.baseRouteList
      : [];
    const photos = CustomUtils.getPhotosFromGalleryPhotos(galleryPhotoList);
    const video = CustomUtils.convertDamFileToCreateVideoResponse(
      getValues('video')
    );
    const notification = {
      ...getValues('notification'),
      sendDate: getValues('notification.sendDate')
        ? moment(
            getValues('notification.sendDate'),
            DefaultDateTimeFormatFE
          ).format(DefaultDateTimeFormatBE)
        : undefined,
    };
    const newNews: NewsTmp = {
      ...getValues(),
      notification,
      visibleFrom: getValues('visibleFrom')
        ? moment(getValues('visibleFrom'), DefaultDateTimeFormatFE).format(
            DefaultDateTimeFormatBE
          )
        : undefined,
      visibleTo: getValues('visibleTo')
        ? moment(getValues('visibleTo'), DefaultDateTimeFormatFE).format(
            DefaultDateTimeFormatBE
          )
        : undefined,
      routes,
      pois,
      photos,
      video,
    };
    if (news?.id) {
      newNews.id = news.id;
    }
    return newNews;
  };

  const handleNotificationActionButtonClick = () => {
    const notification: SaveOrEditNotificationRequest = {
      title: watch('name'),
      titleEn: watch('nameEn'),
      sendNow: watch('notification.sendNow'),
      sendDate: watch('notification.sendDate'),
      uri: getMobileObjDetailUrl('news', news?.id),
    };
    const id = news?.pushNotId;
    dispatch(
      newsActions.setNewsNotificationSelection({
        newsNotificationSelection: true,
        newsNotificationState: getNotificationState(
          addNew,
          !!news?.pushNotId,
          news?.notification?.sendDate
        ),
        notificationData: notification,
        notificationId: Number(id),
        newsId: news?.id,
      })
    );
    const createOrUpdateNewsTmp = getCreateOrUpdateNews(
      getValues(),
      galleryPhotoList,
      poiSelection,
      routeSelection,
      news?.id
    );
    createOrUpdateNewsTmp &&
      dispatch(updateNews(createOrUpdateNewsTmp, undefined, false));
    history.push(`/notifications`);
  };

  const handleChoosePoi = () => {
    news?.pois &&
      news.pois.length > 0 &&
      dispatch(setPoiSelectionList(news.pois));
    dispatch(setPoiSelection({poiSelection: true, poiSelectionFrom: 'news'}));
    const tmpNews = createNewsTmp();
    onObjectSelection && tmpNews && onObjectSelection(tmpNews);
    navigateTo('/map/poi/poiList');
  };

  const handleChooseRoute = () => {
    news?.routes &&
      news.routes.length > 0 &&
      dispatch(setRouteSelectionList(news.routes));
    dispatch(setRouteSelection({routeSelection: true, from: 'news'}));
    const tmpNews = createNewsTmp();
    onObjectSelection && tmpNews && onObjectSelection(tmpNews);
    navigateTo('/map/routes/routeList');
  };

  const handleCloseDialog = () => {
    dispatch(setGalleryPhotoList([]));
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
    clearInputs();
    onCloseDialog();
  };

  const NewsTabs = [
    {
      name: 'Info',
      label: t('news.Info'),
      invalid: !!(errors.name || errors.body),
    },
    {name: 'Poi', label: t('news.Poi')},
    {name: 'Routes', label: t('news.Routes')},
  ];

  return (
    <Box>
      <DialogPrimary
        title={addNew ? t('news.Add news') : t('news.Update news')}
        open={openDialog}
        minWidth={'1350px'}
        contentLanguage={true}
        loading={loading}
        onCloseDialog={handleCloseDialog}>
        <Box className={classes.menu}>
          <TabsDialog
            activeItem={activePage}
            handleNavigation={(page) => setActivePage(page)}
            items={NewsTabs}
          />
        </Box>

        <ObjectPreviewWithFormContainer
          t={t}
          id={news?.id}
          objType={'news'}
          objPreview={
            <MobileScreenNewsDetail
              t={t}
              newsName={
                selectedLanguage === ContentLanguage.EN
                  ? watch('nameEn')
                  : watch('name')
              }
              poiList={Utils.getObjListByLanguage(
                CustomUtils.getObjByLanguage,
                poiSelection.poiList ? poiSelection.poiList : undefined
              )}
              routeList={Utils.getObjListByLanguage(
                CustomUtils.getObjByLanguage,
                routeSelection.baseRouteList
                  ? routeSelection.baseRouteList
                  : undefined
              )}
              bodyTextHtml={
                (selectedLanguage === ContentLanguage.EN
                  ? watch('bodyEn')
                  : watch('body')) as string
              }
              video={{
                id: watch('video')?.id,
                name: watch('video')?.fileName,
                url: watch('video')?.data,
              }}
              parentActiveTab={activePage}
              onSetActiveTab={(page) => setActivePage(page)}
              galleryPhotos={galleryPhotoList.map(
                (photo) => photo.galleryPhoto as DamImage
              )}
              imagePreview={imagePreview}
            />
          }
          objEditInputs={
            <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
              <Box sx={{width: '100%', ml: 4, pr: 2}}>
                <Box
                  style={{
                    display: activePage.name === 'Info' ? 'block' : 'none',
                  }}>
                  <TabDetailNews
                    newsNotificationState={getNotificationState(
                      addNew,
                      !!news?.pushNotId,
                      news?.notification?.sendDate
                    )}
                    notificationSendDate={news?.notification?.sendDate}
                    newsVisibleFromDate={news?.visibleFrom}
                    newsVisibleToDate={news?.visibleTo}
                    addNew={addNew}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    galleryPhotoList={galleryPhotoList}
                    selectedLanguage={selectedLanguage}
                    onNotificationActionButtonClick={
                      handleNotificationActionButtonClick
                    }
                    onUploadPhotos={(photos) =>
                      addGalleryPhotos(news?.id, photos)
                    }
                    onRemoveVideo={(id) => dispatch(deleteVideo(id))}
                    onUploadVideo={addGalleryVideo}
                    videoUploadState={videoUploadProgress}
                  />
                </Box>
                <Box
                  style={{
                    display: activePage.name === 'Poi' ? 'block' : 'none',
                  }}>
                  <TabNewsPoi onPoiSelection={handleChoosePoi} />
                </Box>
                <Box
                  style={{
                    display: activePage.name === 'Routes' ? 'block' : 'none',
                  }}>
                  <TabNewsRoutes onPoiSelection={handleChooseRoute} />
                </Box>
                <Box marginTop={3}>
                  <ButtonAdd
                    name={addNew ? t('news.Add news') : t('news.Save changes')}
                  />
                </Box>
              </Box>
            </form>
          }
        />
      </DialogPrimary>
    </Box>
  );
};
