import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TableApps} from './apps/table/TableApps';
import {ScreenTab, ScreenTabs} from '../ScreenTabs';
import {DefaultsTab} from './defaults/DefaultsTab';
import {ChangesTab} from './changes/ChangesTab';

export const TabsSettings = (): JSX.Element => {
  const {t} = useTranslation();
  const tabs = useMemo(
    (): ScreenTab[] => [
      {key: 'apps', tab: <TableApps />, name: t('apps.Apps')},
      {key: 'changes', tab: <ChangesTab />, name: t('settings.Changes')},
      {key: 'defaults', tab: <DefaultsTab />, name: t('settings.Defaults')},
    ],
    [t]
  );

  return <ScreenTabs tabs={tabs} />;
};
