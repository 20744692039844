export const ListItemsMenu = {
  poi: {
    items: [
      {
        label: 'Categories',
        key: 'categories',
      },
      {
        label: 'List of POI',
        key: 'poiList',
      },
      {
        label: 'Add new POI',
        key: 'newPoi',
      },
    ],
  },
  objects: {
    items: [
      {
        label: 'List of 3D areas',
        key: 'objectList',
      },
      {
        label: 'Add 3D area',
        key: 'newObject',
      },
    ],
  },
  routes: {
    items: [
      {
        label: 'List of routes',
        key: 'routeList',
      },
      {
        label: 'Add new route',
        key: 'newRoute',
      },
    ],
  },
  layers: {
    items: [
      {
        label: 'List of layers',
        key: 'layerList',
      },
      {
        label: 'Add new layer',
        key: 'newLayer',
      },
    ],
  },
};
