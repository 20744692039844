import React, {ForwardedRef, forwardRef} from 'react';
import {Switch} from '@mui/material';
import {FormModel} from '../../../../hooks/useForm';
import {InputStyles} from '../../styles/StylesInput';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {InputLabel, InputLabelProps} from '../input-label/InputLabel';

export interface SwitchPrimaryProps extends FormModel, InputLabelProps {}

export const SwitchPrimary = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      disabled,
      className,
      onChange,
      errorType,
      boldLabel,
      row,
      labelRowWidth,
      inputRowWidth,
      inputTooltipText,
    }: SwitchPrimaryProps,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const classes = InputStyles();

    return (
      <InputLabel
        label={label}
        row={row}
        labelRowWidth={labelRowWidth}
        inputRowWidth={inputRowWidth}
        inputTooltipText={inputTooltipText}
        boldLabel={boldLabel}>
        <Switch
          id={id}
          disabled={disabled}
          name={name}
          ref={ref}
          checked={value}
          className={className + ' ' + (errorType ? classes.error : ' ')}
          onChange={onChange}
          value={value}
          color={'primary'}
        />
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </InputLabel>
    );
  }
);

SwitchPrimary.displayName = 'SwitchPrimary';

export default SwitchPrimary;
