import {Box, IconButton, Typography} from '@mui/material';
import {TextLanguage} from '../../../TextLanguage';
import {ContentLanguage} from '../../../../../../redux/reducers/ContentLanguage';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {TextInputHotspot} from '../TextInputHotspot';
import SwitchHotspot from '../SwitchHotspot';
import {FileInputHotspot} from '../FileInputHotspot';
import {Delete} from '@mui/icons-material';
import {ButtonAdd} from '../../../../components/buttons/ButtonAdd';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PoiDialogType, usePoiHotspotStyles} from '../PoiHotspot';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {
  DamFile,
  DamFileWithData,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';
import {ObjMapper} from '@skczu/czu-frontend-library';

export type Poi360Form = Omit<Poi360, 'id' | 'image' | 'name' | 'nameEn'> & {
  poi360Image?: DamFileWithData | DamFile;
  namePoi360?: string;
  namePoi360En?: string;
};

export interface EditHotspotTabProps {
  poi360?: Poi360;
  hotspot: Hotspot;
  dialogType: PoiDialogType;
  poi360ForUpdate?: Poi360 | null;
  onAddPoi360: (
    poi360: Poi360WithData,
    hotspot: Hotspot,
    showName: boolean
  ) => void;
  onUpdatePoi360: (
    poi360: Poi360WithData,
    hotspot: Hotspot,
    showName: boolean
  ) => void;
  onErrorForm: () => void;
  closeDialog: () => void;
}
export const AddPoi360Tab = ({
  poi360,
  hotspot,
  dialogType,
  poi360ForUpdate,
  onAddPoi360,
  onUpdatePoi360,
  onErrorForm,
  closeDialog,
}: EditHotspotTabProps): JSX.Element => {
  const classes = usePoiHotspotStyles();
  const {t} = useTranslation();
  const [contentLanguage, setContentLanguage] = useState<ContentLanguage>(
    ContentLanguage.CZ
  );
  const {control, handleSubmit, reset, setValue, watch} = useForm<Poi360Form>();

  useEffect(() => {
    if (poi360ForUpdate && poi360ForUpdate.name) {
      setValue('namePoi360', poi360ForUpdate.name);
      setValue('namePoi360En', poi360ForUpdate.nameEn);
      setValue('poi360Image', poi360ForUpdate.image);
      setValue('showName', !!hotspot.showName);
    }
    if (!poi360ForUpdate) {
      reset();
    }
  }, [hotspot.showName, poi360ForUpdate, reset, setValue]);

  const onErrorSubmit: SubmitErrorHandler<Poi360Form> = (data) => {
    data.namePoi360En?.type === 'maxLength' && onErrorForm();
  };

  const onValidSubmit: SubmitHandler<Poi360Form> = (data) => {
    const image = data.poi360Image;
    const name = data.namePoi360;
    const nameEn = data.namePoi360En;
    delete data.poi360Image;
    delete data.namePoi360;
    delete data.namePoi360En;
    const newPoi360: Poi360WithData = {
      ...data,
      image: ObjMapper.getDamFileWithDataFromDamFile(image),
      name: name ? name : image?.fileName,
      nameEn,
    };
    if (dialogType === 'upload') {
      poi360?.poi && (newPoi360.poi = poi360.poi);
      onAddPoi360 && onAddPoi360(newPoi360, hotspot, !!data.showName);
    } else if (dialogType === 'update' && poi360ForUpdate?.id) {
      newPoi360.hotspot = poi360ForUpdate.hotspot;
      poi360ForUpdate?.poi && (newPoi360.poi = poi360ForUpdate.poi);
      onUpdatePoi360 &&
        onUpdatePoi360(
          {...newPoi360, id: poi360ForUpdate?.id},
          hotspot,
          !!data.showName
        );
    }
    closeDialog();
  };

  const getButtonName = useCallback((): string => {
    return dialogType === 'upload'
      ? t('poi360.Add POI')
      : dialogType === 'update'
      ? t('poi360.Update POI')
      : '';
  }, [dialogType, t]);

  return (
    <Box className={classes.dialogMenu}>
      <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
        {!!watch('showName') && (
          <>
            <Box className={classes.menuItem} marginBottom={1}>
              <TextLanguage
                onChangeLanguage={(language) => setContentLanguage(language)}
                selectedLanguage={contentLanguage}
                blackBackground
              />
            </Box>
            <Box className={classes.menuItem}>
              <Box
                style={{
                  display:
                    contentLanguage === ContentLanguage.CZ ? 'block' : 'none',
                }}>
                <Controller
                  name="namePoi360"
                  control={control}
                  rules={{required: true, minLength: 1, maxLength: 80}}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputHotspot
                      {...field}
                      label={t(`poi360.Poi name`)}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
              <Box
                style={{
                  display:
                    contentLanguage === ContentLanguage.EN ? 'block' : 'none',
                }}>
                <Controller
                  name="namePoi360En"
                  control={control}
                  rules={{maxLength: 80}}
                  defaultValue={''}
                  render={({field, fieldState: {error}}) => (
                    <TextInputHotspot
                      {...field}
                      label={t(`poi360.Poi nameEn`)}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
            </Box>
          </>
        )}
        <Box className={classes.menuItem} marginTop={1}>
          <Controller
            name="showName"
            control={control}
            defaultValue={false}
            render={({field, fieldState: {error}}) => (
              <SwitchHotspot
                {...field}
                label={t(`poi360.Show name`)}
                errorType={error?.type}
              />
            )}
          />
        </Box>
        <Box className={classes.menuItem} marginTop={1}>
          <Controller
            name="poi360Image"
            control={control}
            rules={{required: true}}
            render={({field, fieldState: {error}}) => (
              <FileInputHotspot
                {...field}
                label={t(`poi360.Upload 360 image`)}
                buttonText={t(`poi360.Upload 360 image`)}
                errorType={error?.type}
                objectType={'DamFileWithData'}
              />
            )}
          />
        </Box>
        <Box className={classes.menuItem} marginLeft={2}>
          <Typography className={classes.typography}>
            {watch('poi360Image')?.fileName}
            {watch('poi360Image')?.fileName && (
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={() =>
                  setValue('poi360Image', {
                    // data: '',
                    fileName: '',
                    id: undefined,
                  })
                }>
                <Delete fontSize="small" color={'secondary'} />
              </IconButton>
            )}
          </Typography>
        </Box>
        <Box m={3}>
          <ButtonAdd name={getButtonName()} />
        </Box>
      </form>
    </Box>
  );
};
