import {CellProps, Column, FilterProps} from 'react-table';
import React from 'react';
import {TFunction} from 'react-i18next';
import {TextFilter} from '../../../../shared/table-fiter-inputs/TextFilter';
import {AdminRoleObj, RoleObj} from './TableRoles';
import {UsersActionCell} from '../users/UsersActionCell';
import {MinMaxNumberFilter} from '../../../../shared/table-fiter-inputs/MinMaxNumberFilter';
import {AdminUserObj} from '../users/TableUsers';
import {SelectColumnFilter} from '../../../../shared/table-fiter-inputs/SelectColumnFilter';

export interface TableRolesColumnsProps {
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  t: TFunction;
}

export const getTableRolesColumns = ({
  onEditClick,
  onDeleteClick,
  t,
}: TableRolesColumnsProps): Column<RoleObj>[] => {
  return [
    {
      id: 'users-table',
      Header: '',
      columns: [
        {
          Header: t(`role.Role name`) + '',
          accessor: 'name',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminRoleObj>) =>
            `${value} Names`,
        },
        {
          Header: t(`role.Number of users`) + '',
          accessor: 'numberOfUsers',
          aggregate: 'count',
          Filter: MinMaxNumberFilter,
          filter: 'minMax',
          Aggregated: ({cell: {value}}: CellProps<AdminRoleObj>) =>
            `${value} email`,
        },
        {
          Header: t(`role.Type of role`) + '',
          accessor: 'roleType',
          filter: 'tValue',
          // eslint-disable-next-line react/display-name
          Filter: (props: FilterProps<AdminUserObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {value: 'ČZU', name: t('ČZU')},
                {value: 'role.Internal', name: t('role.Internal')},
              ]}
            />
          ),
        },
        {
          Header: t(`objectInputs.Action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          // eslint-disable-next-line react/display-name
          Cell: (cell: CellProps<AdminRoleObj>) => (
            <UsersActionCell<AdminRoleObj>
              cell={cell}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ),
        },
      ],
    },
  ];
};
