import {FilterProps} from 'react-table';
import React from 'react';
import {AdminNewsObj} from '../../scenes/admin-menu-scene/news/table/TableNews';
import TextInputPrimary from '../components/text-inputs/TextInputPrimary';

export const TextFilter = ({
  column: {filterValue, render, setFilter, id},
}: FilterProps<AdminNewsObj>): JSX.Element => {
  return (
    <TextInputPrimary
      name={id}
      boldLabel
      label={render('Header')}
      value={filterValue || ''}
      onChange={(event) => {
        setFilter((event.target.value as string) || undefined);
      }}
    />
  );
};
