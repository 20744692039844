import React, {useCallback, useEffect, useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {HeaderMenuItem} from '../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../shared/components/buttons/ButtonAdd';
import {DialogRoute} from '../../../../../../shared/dialogs/dialog-route/DialogRoute';
import {
  CustomListItems,
  ListItemRow,
} from '../../../../../../shared/components/lists/CustomListItems';
import {
  clearObjectPreview,
  selectObjectPreview,
} from '../../../../../../../redux/reducers/DrawerSlice';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks/hooks';
import {
  routeSelector,
  routeActions,
  getBaseRouteList,
  getRoute,
  deleteRoute,
  updateRoute,
  createRoute,
  getRouteForMobilePreview,
} from '../../../../../../../redux/reducers/data-reducers/RouteSlice';
import {
  addToRouteSelectionList,
  removeFromRouteSelectionList,
  setRouteSelection,
  setOpenDialogAfterRouteSelection,
  selectSelectedRoute,
} from '../../../../../../../redux/reducers/RouteListSelectionSlice';
import {useHistory, useParams} from 'react-router-dom';
import {
  poiSelectionSelector,
  setPoiSelection,
  setPoiSelectionList,
} from '../../../../../../../redux/reducers/PoiListSelectionSlice';
import {SearchInput} from '../../../../../../shared/components/search/SearchInput';
import {StylesMenu} from '../../StylesMenu';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../../../shared/dialogs/dialog-info/DialogInfo';
import {DialogQrCode} from '../../../../../../shared/dialogs/dialog-qr-code/DialogQrCode';
import {useQrCode} from '../../../../../../../hooks/useQrCode';
import {CustomUtils} from '../../../../../../shared/utils/CustomUtils';
import {useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {useInfoDialog} from '../../../../../../../hooks/useInfoDialog';
import {ObjectType} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/qrCode';

export const ListOfRoutesTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    loadingList,
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingDetail,
    hasMore,
  } = useAppSelector(routeSelector);
  const routeSelection = useAppSelector(selectSelectedRoute);
  const {openDialogAfterPoiSelection} = useAppSelector(poiSelectionSelector);
  const {objectPreview, objectPreviewView} =
    useAppSelector(selectObjectPreview);
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deleteRoute(id)),
    });

  const {qrCodeDialog, getObjectQrCode, closeQrCodeDialog} = useQrCode();
  const navigateTo = useCallback((path) => history.push(path), [history]);
  const {routeId} = useParams<{routeId: string}>();
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    routeActions,
    dispatch,
    baseObjSearch,
    getBaseRouteList
  );

  useEffect(() => {
    routeId && dispatch(getRouteForMobilePreview(routeId));
    !routeId && dispatch(clearObjectPreview());
  }, [dispatch, routeId]);

  useEffect(() => {
    openDialogAfterPoiSelection &&
      dispatch(routeActions.setOpenObjDialog(openDialogAfterPoiSelection));
  }, [dispatch, openDialogAfterPoiSelection]);

  const handleEditClick = (id: string) => {
    navigateTo(`/map/routes/routeList`);
    dispatch(clearObjectPreview());
    dispatch(routeActions.setEditObjDetail());
    dispatch(getRoute(id));
  };

  const handleQrCodeScan = (id: string, objName: string) => {
    getObjectQrCode(id, ObjectType.Route, objName);
  };

  const handleAddToRouteSelection = (id: string) => {
    if (
      baseObjList &&
      !routeSelection.baseRouteList?.find((item) => item.id === id)
    ) {
      const route = baseObjList.find((routeObj) => routeObj.id === id);
      route && dispatch(addToRouteSelectionList(route));
    } else {
      const route =
        baseObjList && baseObjList.find((routeObj) => routeObj.id === id);
      route && dispatch(removeFromRouteSelectionList(route));
    }
  };

  const handleItemClick = (id: string) => {
    if (!routeSelection.routeSelection) {
      navigateTo(`/map/routes/routeList/${id}`);
    } else {
      handleAddToRouteSelection(id);
    }
  };

  const handleAddRouteClick = () => {
    dispatch(setRouteSelection({routeSelection: false}));
    dispatch(setOpenDialogAfterRouteSelection(true));
    history.goBack();
  };

  const getPoiListItems = useMemo((): ListItemRow[] => {
    return baseObjList
      ? baseObjList.map((route) => {
          return {
            id: route.id,
            itemRowName: CustomUtils.getContentByLanguage(
              route.name,
              route.nameEn
            ),
            activeItem:
              routeSelection.routeSelection &&
              Boolean(
                routeSelection.baseRouteList?.find(
                  (item) => item.id === route.id
                )
              ),
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === route.id
                ? true
                : undefined
              : undefined,
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    routeSelection.routeSelection,
    routeSelection.baseRouteList,
    objectPreviewView.openObjectPreview,
    objectPreview.objectPreviewData,
  ]);

  return (
    <Box>
      <HeaderMenuItem
        name={
          routeSelection.routeSelection
            ? t('leftMenu.Select routes')
            : t('leftMenu.List of routes')
        }
      />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          name={'route-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <CustomListItems
        listItems={getPoiListItems}
        handleItemClick={handleItemClick}
        handleEditClick={handleEditClick}
        handleDeleteClick={showInfoDialog}
        handleQrCodeClick={handleQrCodeScan}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
      <Box>
        {routeSelection.routeSelection && routeSelection.from === 'layer' && (
          <ButtonAdd
            name={t('route.Add to layer')}
            onClick={() => handleAddRouteClick()}
          />
        )}
        {routeSelection.routeSelection && routeSelection.from === 'news' && (
          <ButtonAdd
            name={t('route.Add to news')}
            onClick={() => handleAddRouteClick()}
          />
        )}
        {!routeSelection.routeSelection && (
          <ButtonAdd
            name={t('route.Add new route')}
            onClick={() => {
              dispatch(setPoiSelection({poiSelection: false}));
              dispatch(clearObjectPreview());
              dispatch(routeActions.setAddNewObjDetail());
              dispatch(routeActions.setObj(null));
            }}
          />
        )}
        {routeSelection.routeSelection && (
          <Box className={menuClasses.disableSelection}>
            <Typography
              variant={'subtitle2'}
              color={'error'}
              onClick={() => {
                dispatch(setRouteSelection({routeSelection: false}));
                history.goBack();
              }}>
              {t('leftMenu.Cancel route selection')}
            </Typography>
          </Box>
        )}
      </Box>
      {openObjDialog && (
        <DialogRoute
          addNew={addNewObj}
          route={obj}
          openDialog={openObjDialog}
          loading={loadingDetail}
          onCloseDialog={() => {
            !routeSelection.routeSelection &&
              dispatch(routeActions.setObj(null));
            dispatch(routeActions.setOpenObjDialog(false));
            dispatch(setPoiSelectionList([]));
          }}
          onAddRoute={(route) => {
            dispatch(setPoiSelectionList([]));
            dispatch(setPoiSelection({poiSelection: false}));
            dispatch(createRoute(route));
          }}
          onUpdateRoute={(route) => {
            dispatch(setPoiSelectionList([]));
            dispatch(updateRoute(route));
          }}
          onObjectSelection={(tmpRoute) =>
            dispatch(routeActions.setObj(tmpRoute))
          }
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.route')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.route'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogQrCode
        openDialog={qrCodeDialog.openDialog}
        qrCode={qrCodeDialog.qrCode}
        objName={qrCodeDialog.objName}
        onCloseDialog={closeQrCodeDialog}
      />
    </Box>
  );
};
