import React, {ReactNode, useCallback, useMemo} from 'react';
import {Box, ListItemSecondaryAction, Switch} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  setFilter,
  setFilterLayerId,
  setFilterCategoryId,
  setShow3dObjects,
  setFilterSelectedGroup,
  selectMapFilter,
} from '../../../../redux/reducers/MapSlice';
import {
  layersSelector,
  layersActions,
  getFilterLayerList,
} from '../../../../redux/reducers/data-reducers/LayersSlice';
import {
  getEventCategoriesList,
  selectSelectedEventsCategories,
} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {useTranslation} from 'react-i18next';
import {groupsSelector} from '../../../../redux/reducers/data-reducers/GroupsSlice';
import {
  CategoryListItem,
  FilterPanel,
  FilterList,
  MapFilterContainer,
  SwitchListItem,
  SearchInput,
  FilterSearchTab,
  FilterTabPanel,
} from '@skczu/czu-react-components';
import {useFilterListSearch} from '@skczu/czu-frontend-library';
import {CustomUtils} from '../../../shared/utils/CustomUtils';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';

const useStyles = makeStyles((theme) => ({
  filterPanelMenu: {
    backgroundColor: theme.palette.secondary.main,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 1,
    marginTop: -5,
    marginBottom: -5,
  },
}));

export const MapFilter = (): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState('Events');
  const dispatch = useAppDispatch();
  const layerState = useAppSelector(layersSelector);
  const groupState = useAppSelector(groupsSelector);
  const selectedEventsCategories = useAppSelector(
    selectSelectedEventsCategories
  );
  const {mapFilter, isFilter} = useAppSelector(selectMapFilter);
  const [categoryQuery, setCategoryQuery] = React.useState('');
  const [eventQuery, setEventQuery] = React.useState('');

  const layerFilter = useFilterListSearch(
    layersActions,
    dispatch,
    layerState.filterObjSearch,
    getFilterLayerList
  );

  const handleCategorySelection = useCallback(
    (category: PoiCategory) => {
      category.id && dispatch(setFilterCategoryId(category.id));
    },
    [dispatch]
  );

  const renderCategoryList = useMemo<ReactNode>(() => {
    const filteredOptions = CustomUtils.getFilteredOptionsByName(
      selectedEventsCategories,
      categoryQuery
    );
    return (
      <FilterList>
        {!!t &&
          filteredOptions?.map((category, index) => (
            <CategoryListItem
              key={category.id + ':_filer_' + index}
              category={CustomUtils.getObjByLanguage(category)}
              onSelectCategory={handleCategorySelection}
              checked={category.id === mapFilter.selectedCategoryId}
            />
          ))}
      </FilterList>
    );
  }, [
    categoryQuery,
    handleCategorySelection,
    mapFilter.selectedCategoryId,
    selectedEventsCategories,
    t,
  ]);

  const renderGroupList = useMemo(() => {
    const filteredOptions =
      groupState.filterDataList &&
      CustomUtils.getFilteredOptionsByName(
        groupState.filterDataList,
        eventQuery
      );
    return (
      <FilterList>
        {!!t &&
          filteredOptions?.map(
            (group, index) =>
              group.name && (
                <SwitchListItem
                  key={group.id + ':_filer_' + index}
                  item={CustomUtils.getObjByLanguage(group)}
                  onSelectItem={() => {
                    group.name && dispatch(setFilterSelectedGroup(group));
                    dispatch(getEventCategoriesList());
                  }}
                  checked={
                    mapFilter.selectedGroups
                      .map((selectedGroup) => selectedGroup.id)
                      .indexOf(group.id) > -1
                  }
                />
              )
          )}
      </FilterList>
    );
  }, [
    dispatch,
    eventQuery,
    groupState.filterDataList,
    mapFilter.selectedGroups,
    t,
  ]);

  const renderLayerList = useMemo<ReactNode>(() => {
    return (
      <FilterList>
        {!!t &&
          layerState.filterDataList &&
          layerState.filterDataList.map(
            (layer, index) =>
              layer.name &&
              layer.id && (
                <SwitchListItem
                  key={layer.id + ':_filer_' + index}
                  item={CustomUtils.getObjByLanguage(layer)}
                  onSelectItem={() =>
                    layer.id && dispatch(setFilterLayerId(layer.id))
                  }
                  checked={mapFilter.layerIds.indexOf(layer.id) > -1}
                />
              )
          )}
      </FilterList>
    );
  }, [dispatch, layerState.filterDataList, mapFilter.layerIds, t]);

  const filterTabs: FilterSearchTab[] = useMemo(
    () => [
      {
        name: 'Events',
        label: t('filter.Groups'),
        searchInput: () => (
          <SearchInput
            placeholder={t('leftMenu.search')}
            name={'groups-search'}
            value={eventQuery}
            onChangeValue={({value}) => setEventQuery(value)}
          />
        ),
        panelList: () => renderGroupList,
      },
      {
        name: 'Layers',
        label: t('filter.Layers'),
        searchInput: () => (
          <SearchInput
            placeholder={t('leftMenu.search')}
            name={'layers-search'}
            value={layerState.filterObjSearch?.searchQuery}
            onChangeValue={layerFilter.onChangeValue}
            onSearchClick={layerFilter.handleSearch}
          />
        ),
        panelList: () => renderLayerList,
      },
    ],
    [
      eventQuery,
      layerFilter.handleSearch,
      layerFilter.onChangeValue,
      layerState.filterObjSearch?.searchQuery,
      renderGroupList,
      renderLayerList,
      t,
    ]
  );

  return (
    <MapFilterContainer
      title={t('filter.Filter')}
      open={!!isFilter}
      onChangeOpen={(open) => dispatch(setFilter(open))}>
      <FilterPanel
        key={'filter-poi-categories'}
        filterPanelHeader={t('filter.Categories')}
        searchValue={{searchQuery: categoryQuery, limit: 99999, offset: 0}}
        panelList={() => renderCategoryList}
        onChangeValue={({value}) => setCategoryQuery(value)}
        searchPlaceholder={t('leftMenu.search')}
      />
      {groupState.filterObjSearch && (
        <FilterTabPanel
          key={'filter-groups'}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          filterTabs={filterTabs}
        />
      )}
      <Box className={classes.filterPanelMenu}>
        <List>
          <ListItem className={classes.listItem} key={'map-3d'}>
            <ListItemText primary={t('filter.3D areas')} />
            <ListItemSecondaryAction>
              <Switch
                checked={mapFilter.show3dObjects}
                color={'primary'}
                onChange={() =>
                  dispatch(setShow3dObjects(!mapFilter.show3dObjects))
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </MapFilterContainer>
  );
};
