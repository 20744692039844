import React, {ReactElement} from 'react';
import {Box, Typography} from '@mui/material';
import {Info} from '@mui/icons-material';

export interface InfoLabelProps {
  text?: string;
}

export const InfoLabel = ({text}: InfoLabelProps): ReactElement => {
  return (
    <Box sx={{display: 'flex', mt: 2}}>
      <Box
        sx={{
          marginRight: 1,
        }}>
        <Info sx={{color: '#fdbc30'}} />
      </Box>
      <Typography>{text}</Typography>
    </Box>
  );
};
