import React, {MouseEventHandler} from 'react';
import {Box, Button, ButtonProps} from '@mui/material';
import {AddCircleRounded} from '@mui/icons-material';

export interface ButtonAddProps extends Omit<ButtonProps, 'color'> {
  id?: string;
  name: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  withoutIcon?: true;
}

export const ButtonAdd = ({
  id,
  name,
  onClick,
  icon,
  color,
  sx,
  withoutIcon,
  ...otherProps
}: ButtonAddProps): JSX.Element => {
  return (
    <Box sx={{textAlign: 'center'}}>
      <Button
        id={id}
        type="submit"
        variant="contained"
        // color="primary"
        sx={{
          borderRadius: '13px',
          backgroundColor: color ? color : 'palette.primary.dark',
          textTransform: 'none',
          ...sx,
        }}
        // classes={{root: classes.button}}
        onClick={onClick}
        startIcon={
          !withoutIcon ? icon ? icon : <AddCircleRounded /> : undefined
        }
        {...otherProps}>
        {name}
      </Button>
    </Box>
  );
};
