import React from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../../shared/components/buttons/ButtonAdd';
import {DialogCategory} from '../../../../../../../shared/dialogs/dialog-category/DialogCategory';
import {
  CustomListItems,
  ListItemRow,
} from '../../../../../../../shared/components/lists/CustomListItems';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/hooks';
import {
  createPoiCategory,
  deletePoiCategory,
  getBasePoiCategoryList,
  getPoiCategory,
  poiCategoryActions,
  selectPoiCategories,
  updatePoiCategory,
} from '../../../../../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {SearchInput} from '../../../../../../../shared/components/search/SearchInput';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../../../../../../../shared/dialogs/dialog-info/DialogInfo';
import {CategoryListIcon} from '../../../../../../../shared/components/lists/CategoryListIcon';
import {CustomUtils} from '../../../../../../../shared/utils/CustomUtils';
import {StylesMenu} from '../../../StylesMenu';
import {useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {useInfoDialog} from '../../../../../../../../hooks/useInfoDialog';

export const CategoryTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deletePoiCategory(id)),
    });
  const dispatch = useAppDispatch();
  const {
    obj,
    baseObjList,
    openObjDialog,
    addNewObj,
    baseObjSearch,
    loadingDetail,
    loadingList,
    hasMore,
  } = useAppSelector(selectPoiCategories);
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    poiCategoryActions,
    dispatch,
    baseObjSearch,
    getBasePoiCategoryList
  );

  const handleEditClick = (id: string) => {
    dispatch(poiCategoryActions.setEditObjDetail());
    dispatch(getPoiCategory(id));
  };

  const onCloseDialog = () => {
    dispatch(poiCategoryActions.setObj(null));
    dispatch(poiCategoryActions.setOpenObjDialog(false));
  };

  return (
    <Box>
      <HeaderMenuItem name={t('leftMenu.Categories')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          name={'category-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <CustomListItems
        listItems={baseObjList.map((category) => {
          return {
            id: category.id,
            itemRowName: CustomUtils.getContentByLanguage(
              category.name,
              category.nameEn
            ),
            activeItem: false,
            icon: category && <CategoryListIcon poiCategory={category} />,
          } as ListItemRow;
        })}
        handleEditClick={handleEditClick}
        handleDeleteClick={showInfoDialog}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
      <ButtonAdd
        name={t('category.Add new category')}
        onClick={() => {
          dispatch(poiCategoryActions.setObj(null));
          dispatch(poiCategoryActions.setAddNewObjDetail());
        }}
      />
      {openObjDialog && (
        <DialogCategory
          addNew={addNewObj}
          category={obj}
          loading={loadingDetail}
          openDialog={openObjDialog}
          onCloseDialog={onCloseDialog}
          onAddCategory={(category) => dispatch(createPoiCategory(category))}
          onUpdateCategory={(category) => dispatch(updatePoiCategory(category))}
        />
      )}
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.category')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.category'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
    </Box>
  );
};
