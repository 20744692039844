import React from 'react';
import {Box, LinearProgress, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    width: 100,
    marginRight: 1,
  },
  progress: {
    minWidth: 35,
  },
}));

export interface Props {
  value?: number;
}
export const LinearProgressWithLabel = ({value}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.progressBar}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box className={classes.progress}>
        <Typography variant="body2" color="primary">{`${
          value && Math.round(value)
        }%`}</Typography>
      </Box>
    </Box>
  );
};
