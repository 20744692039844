import {FilterProps} from 'react-table';
import React from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AdminRoleObj} from '../../scenes/admin-menu-scene/users/roles/TableRoles';
import {useAppSelector} from '../../../hooks/hooks';
import {rolesSelector} from '../../../redux/reducers/data-reducers/RolesSlice';

const styles = {
  dateContainer: {
    position: 'relative',
  },
  date: {
    borderRadius: '10px',
    backgroundColor: 'secondary.light',
    height: 40,
    width: 185,
    paddingLeft: '14px',
    boxShadow: 2,
    '&:hover': {
      background: '#cccccc',
    },
  },
  dateInput: {
    '& .MuiInput-input': {
      paddingTop: '10px',
      fontSize: 16,
      marginLeft: '2px',
      marginRight: '8px',
    },
  },
  dateRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingTop: '5px',
  },
};

export const MinMaxNumberFilter = ({
  column: {filterValue = [], render, setFilter, id},
}: FilterProps<AdminRoleObj>): JSX.Element => {
  const {t} = useTranslation();
  //TODO: remove loading list selector
  const {loadingList} = useAppSelector(rolesSelector);

  return (
    <>
      <Box sx={styles.dateContainer}>
        <Box>
          <Typography
            variant="subtitle2"
            id="custom-input"
            sx={{fontWeight: 600}}>
            {render('Header')}
          </Typography>
        </Box>
        <Box sx={styles.dateRow}>
          <Typography variant="subtitle2" id="custom-input">
            {t('objectInputs.From')}
          </Typography>
          <TextField
            id={`${id}_1`}
            sx={styles.date}
            type="number"
            variant={'standard'}
            value={filterValue[0] || ''}
            onChange={(event) => {
              const newVal = (event.target.value as string) || undefined;
              !loadingList &&
                setFilter((old = []) => {
                  return newVal || old[1] ? [newVal, old[1]] : undefined;
                });
            }}
            InputProps={{
              disableUnderline: true,
              sx: styles.dateInput,
              inputProps: {min: 0, max: 999999},
            }}
            // placeholder={`From (${min})`}
          />
        </Box>
        <Box sx={styles.dateRow}>
          <Typography variant="subtitle2" id="custom-input">
            {t('objectInputs.To')}
          </Typography>
          <TextField
            id={`${id}_2`}
            type="number"
            sx={styles.date}
            variant={'standard'}
            value={filterValue[1] || ''}
            onChange={(event) => {
              const newVal = (event.target.value as string) || undefined;
              !loadingList &&
                setFilter((old = []) => {
                  return newVal || old[0] ? [old[0], newVal] : undefined;
                });
            }}
            InputProps={{
              disableUnderline: true,
              sx: styles.dateInput,
              inputProps: {min: 0, max: 999999},
            }}
          />
        </Box>
      </Box>
    </>
  );
};
