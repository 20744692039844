import {
  CellProps,
  Column,
  FilterProps,
  UseFiltersColumnOptions,
  UseGlobalFiltersColumnOptions,
  UseGroupByColumnOptions,
  UseResizeColumnsColumnOptions,
  UseSortByColumnOptions,
} from 'react-table';
import React from 'react';
import {TextFilter} from '../../../../shared/table-fiter-inputs/TextFilter';
import {SelectPoisFilter} from '../../../../shared/table-filter-selects/SelectPoisFilter';
import {SelectRoutesFilter} from '../../../../shared/table-filter-selects/SelectRoutesFilter';
import {SelectColumnFilter} from '../../../../shared/table-fiter-inputs/SelectColumnFilter';
import {DateFilter} from '../../../../shared/table-fiter-inputs/DateFilter';
import {AdminNewsObj, NewsData, TableDateCell} from './TableNews';
import {TFunction} from 'react-i18next';
import {NewsActionCell} from './NewsActionCell';

export interface TableNewsColumnsProps {
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  t: TFunction;
}
export interface ColumnInterface<
  D extends Record<string, unknown> = Record<string, unknown>
> extends UseFiltersColumnOptions<D>,
    UseGlobalFiltersColumnOptions<D>,
    UseGroupByColumnOptions<D>,
    UseResizeColumnsColumnOptions<D>,
    UseSortByColumnOptions<D> {
  hidden?: boolean;
  className?: string;
  align?: 'left' | 'center' | 'right';
}
export const getTableNewsColumns = ({
  onEditClick,
  onDeleteClick,
  t,
}: TableNewsColumnsProps): Column<NewsData>[] => {
  return [
    {
      id: 'news-table',
      Header: '',
      columns: [
        {
          Header: t(`objectInputs.Name`) + '',
          accessor: 'name',
          aggregate: 'count',
          Filter: TextFilter,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Names`,
        },
        {
          Header: t(`news.Body text`) + '',
          accessor: 'miniBody',
          maxWidth: 160,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: t(`objectInputs.Poi`) + '',
          accessor: 'pois',
          aggregate: 'uniqueCount',
          Filter: SelectPoisFilter,
          disableSortBy: true,
          filter: 'includesSome',
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Unique Names`,
        },
        {
          Header: t(`objectInputs.Routes`) + '',
          accessor: 'routes',
          aggregate: 'uniqueCount',
          Filter: SelectRoutesFilter,
          disableSortBy: true,
          filter: 'includesSome',
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Unique Names`,
        },
        // {
        //   Header: t(`objectInputs.Groups`) + '',
        //   accessor: 'groups',
        //   aggregate: 'uniqueCount',
        //   Filter: SelectGroupsFilter,
        //   filter: 'includesSome',
        //   Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
        //     `${value} Unique Names`,
        // },
        {
          Header: t(`news.Visible in app`) + '',
          filter: 'tValue',
          accessor: 'visibleInApp',
          Filter: (props: FilterProps<AdminNewsObj>) => (
            <SelectColumnFilter
              {...props}
              options={[
                {value: '', name: ''},
                {value: 'news.Visible', name: t('news.Visible')},
                {value: 'news.Invisible', name: t('news.Invisible')},
              ]}
            />
          ),
        },
        {
          Header: t(`news.Visible from`) + '',
          accessor: 'visibleFrom',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`news.Visible to`) + '',
          accessor: 'visibleTo',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        {
          Header: t(`news.Last update`) + '',
          accessor: 'lastUpdateDate',
          Filter: DateFilter,
          filter: 'dateRange',
          Cell: TableDateCell,
          Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
            `${value} Between`,
        },
        // {
        //   Header: t(`news.Last update to`) + '',
        //   accessor: 'lastUpdateTo',
        //   disableFilters: true,
        //   Cell: TableDateCell,
        //   Aggregated: ({cell: {value}}: CellProps<AdminNewsObj>) =>
        //     `${value} Between`,
        // },
        {
          Header: t(`objectInputs.Action`) + '',
          accessor: 'action',
          maxWidth: 90,
          minWidth: 90,
          disableSortBy: true,
          disableFilters: true,
          Cell: (cell) => (
            <NewsActionCell
              cell={cell}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ),
        },
      ],
    },
  ];
};
