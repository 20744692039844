import React, {forwardRef} from 'react';
import {Box, InputBase, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {InputStyles} from '../../../styles/StylesInput';
import {FormModel} from '../../../../../hooks/useForm';
import {InputErrorMessage} from '../../../components/custom-inputs/InputErrorMessage';

const useStyles = makeStyles(() => ({
  fileName: {
    color: '#ffffff',
    backgroundColor: '#626262',
    borderRadius: 20,
    justifyContent: 'center',
    padding: '5px 10px 5px 20px',
    margin: '10px -10px 5px 10px',
  },
  label: {
    margin: '10px 0 0 15px',
  },
}));

export const TextInputHotspot = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      disabled,
      errorType,
    }: FormModel,
    ref
  ): JSX.Element => {
    const inputClasses = InputStyles();
    const classes = useStyles();

    return (
      <Box>
        <Box className={classes.label}>
          <Typography variant="subtitle2" id="custom-input" color={'secondary'}>
            {label}
          </Typography>
        </Box>
        <InputBase
          disabled={disabled}
          className={
            classes.fileName +
            ' ' +
            className +
            ' ' +
            (errorType ? inputClasses.error : ' ')
          }
          id={id}
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
        />
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);

TextInputHotspot.displayName = 'TextInputHotspot';

export default TextInputHotspot;
