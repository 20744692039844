import {Box, Button, Popover, Typography} from '@mui/material';
import React, {FormEvent, ReactElement, useCallback} from 'react';
import {TableInstance} from 'react-table';
import {TFunction} from 'react-i18next';

const styles = {
  columnsPopOver: {
    padding: '24px',
    // width: 800,
  },
  filtersResetButton: {
    position: 'absolute',
    top: 18,
    right: 21,
  },
  popoverTitle: {
    fontWeight: 500,
    padding: '0 24px 24px 0',
    textTransform: 'uppercase',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 270px)',
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 190px)',
    },
    gridColumnGap: 32,
    gridRowGap: 24,
  },
  cell: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  submit: {
    marginTop: '35px',
    textAlign: 'center',
  },
  submitButton: {
    height: 40,
    width: 150,
    borderRadius: '10px',
    color: 'secondary.main',
    backgroundColor: 'primary.main',
  },
};

type FilterPageProps<T extends Record<string, unknown>> = {
  t?: TFunction;
  instance: TableInstance<T>;
  anchorEl?: Element;
  onClose: () => void;
  onFilterData?: () => void;
  show: boolean;
};

export function FilterPage<T extends Record<string, unknown>>({
  t,
  instance,
  anchorEl,
  onClose,
  onFilterData,
  show,
}: FilterPageProps<T>): ReactElement {
  const {allColumns, setAllFilters} = instance;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onFilterData && onFilterData();
      onClose();
    },
    [onClose, onFilterData]
  );

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    onFilterData && onFilterData();
  }, [onFilterData, setAllFilters]);

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        id={'popover-filters'}
        onClose={onClose}
        open={show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Box sx={styles.columnsPopOver}>
          <Typography sx={styles.popoverTitle}>
            {t ? t('filter.Filters') : 'Filters'}
          </Typography>
          <form onSubmit={onSubmit}>
            <Button
              sx={styles.filtersResetButton}
              color="primary"
              onClick={resetFilters}>
              {t ? t('filter.Reset') : 'Reset'}
            </Button>
            <Box sx={styles.grid}>
              {allColumns
                .filter((it) => it.canFilter)
                .map((column) => (
                  <Box key={column.id} sx={styles.cell}>
                    {column.render('Filter')}
                  </Box>
                ))}
            </Box>
            <Box sx={styles.submit}>
              <Button
                variant="contained"
                sx={styles.submitButton}
                type={'submit'}>
                {t ? t('filter.Confirm') : 'Confirm'}
              </Button>
            </Box>
          </form>
        </Box>
      </Popover>
    </div>
  );
}
