import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Box,
  IconButton,
  Popover,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Room} from '@mui/icons-material';
import {theme} from '../../../../../App';
import {CustomUtils} from '../../../utils/CustomUtils';
import {AddPoi360Tab} from './hotspot-tabs/AddPoi360Tab';
import {EditPoi360Tab} from './hotspot-tabs/EditPoi360Tab';
import {AddHotspotTab} from './hotspot-tabs/AddHotspotTab';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';
import {Poi360WithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360/api';

export const usePoiHotspotStyles = makeStyles(() =>
  createStyles({
    typography: {
      paddingLeft: 15,
      color: '#ffffff',
    },
    poiImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#73be46',
      borderRadius: 100,
      padding: 8,
    },
    dialogMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 30px 5px 5px',
    },
    nameLanguage: {},
    menuItem: {
      justifyContent: 'center',
      marginLeft: 5,
    },
    poiName: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      borderRadius: 10,
      color: '#fff',
      textAlign: 'center',
      maxWidth: 160,
      minWidth: 100,
      padding: '5px 10px',
      marginLeft: -20,
      marginTop: -5,
    },
  })
);

export type PoiDialogType = 'add' | 'upload' | 'edit' | 'update';

export interface Props {
  hotspotId: string;
  poi360?: Poi360;
  startState: PoiDialogType;
  hotspot: Hotspot;
  onDeletePoi: (poiViewerId: string, hotspot: Hotspot) => void;
  onGoToPoi: (hotspot: Hotspot) => void;
  onGetPoi360ForUpdate?: (
    poi360Id: string,
    hotspotId: string,
    hotspot: Hotspot
  ) => void;
  onRemoveHotspot: (poiViewerId: string) => void;
  poi360ForUpdate?: Poi360 | null;
  onAddPoi360: (
    poi360: Poi360WithData,
    hotspot: Hotspot,
    showName: boolean
  ) => void;
  onUpdatePoi360: (
    poi360: Poi360WithData,
    hotspot: Hotspot,
    showName: boolean
  ) => void;
  onErrorForm: () => void;
  onAddLocalHotspot: (hotspot: Hotspot) => void;
  onAddGlobalHotspot: (hotspot: Hotspot) => void;
}

export const PoiHotspot = ({
  hotspotId,
  poi360,
  startState,
  hotspot,
  onDeletePoi,
  onGoToPoi,
  onGetPoi360ForUpdate,
  onRemoveHotspot,
  onAddLocalHotspot,
  onAddGlobalHotspot,
  poi360ForUpdate,
  onAddPoi360,
  onUpdatePoi360,
  onErrorForm,
}: Props): JSX.Element => {
  const classes = usePoiHotspotStyles();
  const [anchorEl, setAnchorEl] = useState<
    HTMLElement | HTMLButtonElement | null
  >(null);
  const [dialogType, setDialogType] = useState<PoiDialogType>('edit');
  const [firstState, setFirstState] = useState<PoiDialogType>(startState);
  const poiRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    if (!anchorEl) {
      setOpen(false);
    } else {
      setAnchorEl(null);
    }
    if (dialogType !== 'edit' && dialogType !== 'update') {
      onRemoveHotspot(hotspotId);
    }
  };

  const getId = useCallback((): string | undefined => {
    return open ? (poi360?.id ? poi360?.id : undefined) : undefined;
  }, [open, poi360]);

  useEffect(() => {
    if (
      !anchorEl &&
      !open &&
      dialogType === 'edit' &&
      (firstState === 'add' || firstState === 'update')
    ) {
      setDialogType(firstState);
      setOpen(true);
    } else {
      setOpen(Boolean(anchorEl));
      if (firstState === 'update' && dialogType === 'update') {
        setFirstState('edit');
        setDialogType('edit');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const addLocalHotspot = () => {
    onAddLocalHotspot(hotspot);
    handleClose();
  };

  const addGlobalHotspot = () => {
    onAddGlobalHotspot({...hotspot, showName: true});
    handleClose();
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div>
          <IconButton
            aria-describedby={getId()}
            onClick={handleClick}
            ref={poiRef}
            size="large">
            <Box className={classes.poiImage}>
              <Room fontSize={'large'} color={'secondary'} />
            </Box>
          </IconButton>
          {hotspot.showName && hotspot.name && (
            <Box className={classes.poiName}>
              {CustomUtils.getContentByLanguage(hotspot.name, hotspot.nameEn)}
            </Box>
          )}
          <Popover
            id={getId()}
            open={open}
            anchorEl={
              !anchorEl
                ? (poiRef && poiRef.current && firstState === 'add') ||
                  firstState === 'update'
                  ? poiRef.current
                  : anchorEl
                : anchorEl
            }
            onClose={handleClose}
            PaperProps={{
              style: {backgroundColor: '#303030', borderRadius: 20},
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            {dialogType === 'add' && (
              <AddHotspotTab
                addLocalHotspot={addLocalHotspot}
                addGlobalHotspot={addGlobalHotspot}
                handleClose={handleClose}
                setDialogType={setDialogType}
              />
            )}
            {dialogType === 'edit' && (
              <EditPoi360Tab
                hotspotId={hotspotId}
                hotspot={hotspot}
                onDeletePoi={onDeletePoi}
                onGetPoi360ForUpdate={onGetPoi360ForUpdate}
                onGoToPoi={onGoToPoi}
                handleClose={handleClose}
                setDialogType={setDialogType}
              />
            )}
            {(dialogType === 'upload' || dialogType === 'update') && (
              <AddPoi360Tab
                poi360={poi360}
                hotspot={hotspot}
                dialogType={dialogType}
                poi360ForUpdate={poi360ForUpdate}
                onAddPoi360={onAddPoi360}
                onUpdatePoi360={onUpdatePoi360}
                onErrorForm={onErrorForm}
                closeDialog={handleClose}
              />
            )}
          </Popover>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
