import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {LeftDrawer} from './drawer-left/drawer/DrawerLeft';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {Route} from 'react-router';
import {MapComponent} from './map/MapComponent';
import {useRouteMatch} from 'react-router-dom';
import {RouteEditingContainer} from './route-editing-container/RouteEditingContainer';
import {MapFilter} from './filter/MapFilter';
import {selectSelectedPoi} from '../../../redux/reducers/PoiListSelectionSlice';
import makeStyles from '@mui/styles/makeStyles';
import {
  getBasePoiCategoryList,
  getEventCategoriesList,
  getFilterPoiCategoryList,
} from '../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {getCategoryIconList} from '../../../redux/reducers/data-reducers/CategoryIconSlice';
import {
  getSimpleCategoryList,
  getSimpleGroupList,
  getSimpleLayerList,
  getSimplePoiList,
  getSimpleRoleList,
  getSimpleRouteList,
  getSimpleUserList,
} from '../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import {getBasePoiList} from '../../../redux/reducers/data-reducers/PoiSlice';
import {
  getBaseObject3dList,
  getTileset3dUrlList,
} from '../../../redux/reducers/data-reducers/Object3dSlice';
import {getBaseRouteList} from '../../../redux/reducers/data-reducers/RouteSlice';
import {
  getBaseLayerList,
  getFilterLayerList,
} from '../../../redux/reducers/data-reducers/LayersSlice';
import {
  getDefaultEvent,
  getFilterGroupsList,
} from '../../../redux/reducers/data-reducers/GroupsSlice';
import {ImgPreview} from '@skczu/czu-react-components';
import {
  changeSelectedImage,
  closeImagePreview,
  imagePreviewSelector,
} from '../../../redux/reducers/ImagePreview';

const useStyles = makeStyles((theme) => ({
  routeContainer: {
    position: 'absolute',
    left: '60%',
    top: 55,
    transform: 'translate(-50%, -50%)',
    width: '50%',
    zIndex: 3,
  },
  filterButton: {
    zIndex: 100,
    position: 'absolute',
    right: 0,
    top: '2%',
    backgroundColor: theme.palette.primary.dark,
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
  },
}));

export const MapPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();
  const poiSelection = useAppSelector(selectSelectedPoi);
  const {preview, imagePreview} = useAppSelector(imagePreviewSelector);

  useEffect(() => {
    dispatch(getCategoryIconList());
    dispatch(getEventCategoriesList());

    dispatch(getDefaultEvent());
    dispatch(getBasePoiCategoryList(true));
    dispatch(getBasePoiList(true));
    dispatch(getBaseObject3dList(true));
    dispatch(getBaseRouteList(true));
    dispatch(getBaseLayerList(true));
    dispatch(getTileset3dUrlList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSimpleGroupList());
    dispatch(getSimplePoiList());
    dispatch(getSimpleRouteList());
    dispatch(getSimpleCategoryList());
    dispatch(getSimpleLayerList());
    dispatch(getSimpleRoleList());
    dispatch(getSimpleUserList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterPoiCategoryList(true));
    dispatch(getFilterLayerList(true));
    dispatch(getFilterGroupsList(true));
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" style={{overflowX: 'hidden'}}>
      <Box
        sx={{
          zIndex: 3,
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}>
        <LeftDrawer />
        <Box sx={{overflowX: 'hidden'}}>
          <MapComponent />
        </Box>
      </Box>
      <Box style={{overflowX: 'hidden'}}>
        <Route
          path={[
            `/map`,
            `${path}/menu`,
            `${path}/poi`,
            `${path}/routes`,
            `${path}/objects`,
            `${path}/layers`,
          ]}>
          <Route path={`${path}/poi/poiList`}>
            {poiSelection.poiSelection &&
              poiSelection.poiSelectionFrom === 'route' && (
                <Box className={classes.routeContainer}>
                  <RouteEditingContainer />
                </Box>
              )}
          </Route>
          <Box style={{overflowX: 'hidden'}}>
            <MapFilter />
          </Box>
        </Route>
      </Box>
      <ImgPreview
        onCloseDialog={() => dispatch(closeImagePreview())}
        preview={preview}
        imagePreview={imagePreview}
        onChangeSelected={(index) => {
          dispatch(changeSelectedImage(index));
        }}
      />
    </Box>
  );
};
