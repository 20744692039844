import React from 'react';
import {IconButton} from '@mui/material';
import {CellProps, Row} from 'react-table';
import {Delete, Edit} from '@mui/icons-material';
import {useKeycloak} from '@react-keycloak-fork/web';

export interface UsersActionCellProps<T extends Record<string, unknown>> {
  cell: CellProps<T>;
  onEditClick: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}
export const UsersActionCell = <T extends Record<string, unknown>>({
  cell,
  onEditClick,
  onDeleteClick,
}: UsersActionCellProps<T>): JSX.Element => {
  const isCZU = (row: Row<T>): boolean | undefined => {
    const val = row.cells?.find(
      (tableCell) => tableCell.column.id === 'roleType'
    )?.value;
    return val === 'ČZU';
  };
  const {keycloak} = useKeycloak();

  const czu = isCZU(cell.row);
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore*/}
      {keycloak?.userInfo?.sub === cell.value ? null : (
        <>
          <IconButton
            edge="end"
            aria-label="edit"
            size="small"
            onClick={() => onEditClick(cell.value)}>
            <Edit fontSize="small" />
          </IconButton>
          {onDeleteClick && !czu && (
            <IconButton
              edge="end"
              aria-label="delete"
              size="small"
              onClick={() => onDeleteClick(cell.value)}>
              <Delete fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </>
  );
};
