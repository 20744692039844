import React, {ReactNode, useState} from 'react';
import {Box} from '@mui/material';
import {StylesAdminTab} from './StylesAdminTab';
import {ButtonHeader} from '../../shared/components/buttons/ButtonHeader';

export interface ScreenTab {
  tab: ReactNode;
  key: string;
  name: string;
}
export interface ScreenTabsProps {
  tabs: ScreenTab[];
}

export const ScreenTabs = ({tabs}: ScreenTabsProps): JSX.Element => {
  const classes = StylesAdminTab();
  const [selectedSubCategory, setSelectedSubCategory] = useState(tabs[0].key);

  return (
    <Box className={classes.menuContainer}>
      <Box className={classes.tabHeader}>
        {tabs.map((tab) => (
          <ButtonHeader
            key={tab.key}
            name={tab.name}
            active={selectedSubCategory === tab.key}
            onClick={() => setSelectedSubCategory(tab.key)}
          />
        ))}
      </Box>
      <Box className={classes.menuContainerItems}>
        {tabs.map((tab) => selectedSubCategory === tab.key && <>{tab.tab}</>)}
      </Box>
    </Box>
  );
};
