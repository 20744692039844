import {Select} from '@mui/material';
import {styled} from '@mui/material/styles';
import {SecondaryLightHover} from '../../../../App';

export const CustomSelect = styled(
  Select,
  {}
)(({}) => ({
  '& .MuiSelect-icon': {
    width: '50%',
    color: 'black',
    minHeight: 35,
    left: '25%',
    position: 'absolute',
    top: 'calc(85% - 18px)',
  },
  '&:hover': {
    background: SecondaryLightHover,
  },
}));
