import React, {forwardRef, ReactNode, useCallback} from 'react';
import {Box, ListItemText, MenuItem, Switch} from '@mui/material';
import {CustomSelectInput} from '../custom-inputs/InputBase';

import {FormModel} from '../../../../hooks/useForm';
import {InputStyles} from '../../styles/StylesInput';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {SearchInput} from './SelectSearch';
import {InputLabel} from '../input-label/InputLabel';
import {useTranslation} from 'react-i18next';
import {CustomSelect} from '../custom-inputs/SelectBase';
import {SelectHeaderMenuItem} from './select-multiple/SelectHeaderMenuItem';
import {
  BaseObject,
  useSelectAllMultiselect,
} from './select-multiple/useSelectAllMultiselect';
import {CustomUtils} from '../../utils/CustomUtils';
import {useSelectSearch} from './useSelectSearch';

export interface MultipleSelectPrimary<T extends BaseObject> extends FormModel {
  children?: ReactNode;
  renderCustomMenuItem?: (item: T) => ReactNode;
  selectedValue?: string | ReactNode;
  value?: string[];
  searchFieldPlaceholder?: string;
  // query?: string;
  // setQuery?: (value: string) => void;
  row?: boolean;
  boldLabel?: boolean;
  selectAny?: boolean;
  selectAnyText?: string;
  selectAll?: boolean;
  itemPrimaryTextFormatFunc?: (obj: BaseObject) => string | undefined;
  itemSecondaryTextFormatFunc?: (obj: BaseObject) => string | undefined;
  list?: T[];
  multiple?: boolean;
}

export const SelectMultiplePrimary = forwardRef(
  <T extends BaseObject>({
    id,
    name,
    label,
    disabled,
    value,
    multiple = true,
    className,
    children,
    selectedValue,
    onChange,
    onBlur,
    errorType,
    searchFieldPlaceholder,
    row,
    renderCustomMenuItem,
    boldLabel,
    list,
    selectAnyText,
    selectAll = false,
    selectAny = false,
    itemPrimaryTextFormatFunc,
    itemSecondaryTextFormatFunc,
  }: MultipleSelectPrimary<T>): JSX.Element => {
    const {t} = useTranslation();
    const inputClasses = InputStyles();

    const {filteredList, setQuery, handleSearchChange} = useSelectSearch({
      list,
    });
    const {all, handleChangeMultiselect, setAll} = useSelectAllMultiselect({
      list: filteredList,
      onChange,
      multiple,
    });

    const handleSearch = useCallback(
      (query: string) => {
        handleSearchChange && handleSearchChange(query);
        all && setAll(false);
      },
      [all, handleSearchChange, setAll]
    );

    return (
      <InputLabel
        label={label}
        row={row}
        disabled={disabled}
        boldLabel={boldLabel ? boldLabel : true}>
        <CustomSelect
          id={id}
          name={name}
          multiple={multiple}
          value={
            multiple
              ? value || (value && (value as string[])?.length > 0)
                ? value
                : []
              : value || ''
          }
          disabled={disabled}
          className={className + ' ' + (errorType ? inputClasses.error : ' ')}
          onChange={handleChangeMultiselect}
          onBlur={onBlur}
          displayEmpty={true}
          SelectDisplayProps={{
            style: {
              height: 42,
              paddingLeft: '32px',
              width: 190,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
            },
          }}
          renderValue={() => {
            return (
              <Box
                sx={{
                  fontSize: 18,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  textAlign: 'center',
                }}>
                {selectedValue}
              </Box>
            );
          }}
          MenuProps={{
            sx: {
              '& .MuiMenu-paper': {
                maxHeight: '50%',
              },
            },
            TransitionProps: {
              onExit: () => {
                setQuery && setQuery('');
              },
            },
            disableAutoFocusItem: true,
            MenuListProps: {
              disableListWrap: true,
            },
          }}
          input={<CustomSelectInput />}>
          <SearchInput
            name={name + '_search'}
            placeholder={searchFieldPlaceholder || t('leftMenu.search')}
            onChangeValue={handleSearch}
          />
          {selectAny &&
            SelectHeaderMenuItem({
              checked: !!(value && value.length === 0),
              key: 'Any',
              value: 'Any',
              multiple,
              text: selectAnyText ? selectAnyText : t('allObjects.None'),
              sx: {
                textAlign: multiple ? undefined : 'center',
                mt: 0.5,
                borderBottom: selectAll ? undefined : '1px solid',
              },
            })}
          {selectAll &&
            SelectHeaderMenuItem({
              checked: all,
              key: 'Select all',
              value: 'Select all',
              text: t('allObjects.Select all'),
              multiple,
              sx: {
                borderBottom: '1px solid',
              },
            })}
          {filteredList?.map((obj) => {
            if (renderCustomMenuItem) {
              return renderCustomMenuItem && renderCustomMenuItem(obj);
            } else {
              if (!obj.id) {
                return null;
              } else {
                return multiple ? (
                  <MenuItem key={obj.id} value={obj.id}>
                    <Switch
                      checked={value && value.indexOf(String(obj.id)) > -1}
                      color={'primary'}
                    />
                    <ListItemText
                      secondary={
                        itemSecondaryTextFormatFunc
                          ? itemSecondaryTextFormatFunc(obj)
                          : undefined
                      }
                      primary={
                        itemPrimaryTextFormatFunc
                          ? itemPrimaryTextFormatFunc(obj)
                          : CustomUtils.getContentByLanguage(
                              obj.name,
                              obj.nameEn
                            )
                      }
                    />
                  </MenuItem>
                ) : (
                  <MenuItem
                    value={obj.id}
                    key={obj.id}
                    sx={{textAlign: 'center'}}>
                    <ListItemText
                      secondary={
                        itemSecondaryTextFormatFunc
                          ? itemSecondaryTextFormatFunc(obj)
                          : undefined
                      }
                      primary={
                        itemPrimaryTextFormatFunc
                          ? itemPrimaryTextFormatFunc(obj)
                          : CustomUtils.getContentByLanguage(
                              obj.name,
                              obj.nameEn
                            )
                      }
                    />
                    {/*<ListItemIcon>*/}
                    {/*  <ListItemText*/}
                    {/*    primary={CustomUtils.getContentByLanguage(*/}
                    {/*      obj.name,*/}
                    {/*      obj.nameEn*/}
                    {/*    )}*/}
                    {/*  />*/}
                    {/*</ListItemIcon>*/}
                  </MenuItem>
                );
              }
            }
          })}
          {children ? children : null}
        </CustomSelect>
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </InputLabel>
    );
  }
);

SelectMultiplePrimary.displayName = 'SelectMultiplePrimary';

export default SelectMultiplePrimary;
