import React from 'react';
import {IconButton} from '@mui/material';
import {CellProps} from 'react-table';
import {Edit} from '@mui/icons-material';
import {AdminAppsObj} from '../TableApps';

export interface AppsActionCellProps {
  cell: CellProps<AdminAppsObj>;
  onEditClick: (id: string) => void;
}
export const AppsActionCell = ({
  cell,
  onEditClick,
}: AppsActionCellProps): JSX.Element => {
  return (
    <>
      <IconButton
        edge="end"
        aria-label="edit"
        size="small"
        onClick={() => onEditClick(cell.value)}>
        <Edit fontSize="small" />
      </IconButton>
    </>
  );
};
