import makeStyles from '@mui/styles/makeStyles';

export const StylesAdminTab = makeStyles((theme) => ({
  tabHeader: {
    display: 'flex',
    marginLeft: 20,
  },
  menuContainer: {
    width: '100%',
    textAlign: 'center',
  },
  menuContainerItems: {
    borderTop: `3px solid ${theme.palette.secondary.light}`,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.main,
  },
  objectPreviewSelection: {
    cursor: 'pointer',
  },
  tabContainer: {
    marginLeft: '0.7%',
    width: '98.5%',
    position: 'relative',
  },
  tabButtonsContainer: {
    display: 'flex',
    position: 'absolute',
    left: 8,
    top: 15,
    zIndex: 1,
  },
}));
