import React, {useEffect} from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {useTranslation} from 'react-i18next';
import {DialogInfo} from '../dialog-info/DialogInfo';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  ContentLanguage,
  contentLanguageSelector,
  setSelectedLanguage,
} from '../../../../redux/reducers/ContentLanguage';
import {
  categoriesIconSelector,
  createCategoryIcon,
  deleteCategoryIcon,
  getCategoryIconList,
} from '../../../../redux/reducers/data-reducers/CategoryIconSlice';
import {
  SubmitHandler,
  useForm,
  Controller,
  SubmitErrorHandler,
} from 'react-hook-form';
import {SelectColor} from '../../components/selects/SelectColor';
import {SelectIcon} from '../../components/selects/select-icon/SelectIcon';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import makeStyles from '@mui/styles/makeStyles';
import {useInfoDialog} from '../../../../hooks/useInfoDialog';
import {showErrorMessage} from '../../../../redux/reducers/ErrorSlice';
import {
  CreateOrUpdateCategory,
  PoiCategory,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 400,
    },
  })
);

export type CategoryForm = Omit<PoiCategory, 'id' | 'icon'> & {icon?: string};

export interface DialogCategoryProps {
  addNew?: boolean;
  category?: PoiCategory | null;
  openDialog: boolean;
  loading: boolean;
  onCloseDialog: () => void;
  onAddCategory?: (category: CreateOrUpdateCategory) => void;
  onUpdateCategory?: (category: CreateOrUpdateCategory) => void;
}
export const DialogCategory = ({
  addNew,
  openDialog,
  category,
  loading,
  onCloseDialog,
  onAddCategory,
  onUpdateCategory,
}: DialogCategoryProps): JSX.Element => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {control, handleSubmit, reset, setValue} = useForm<CategoryForm>();
  const dispatch = useAppDispatch();
  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) => id && dispatch(deleteCategoryIcon(id)),
    });
  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const categoryState = useAppSelector(categoriesIconSelector);

  useEffect(() => {
    if (!categoryState.objList || categoryState.objList.length === 0) {
      dispatch(getCategoryIconList());
    }
  }, [categoryState.objList, dispatch]);

  useEffect(() => {
    if (category && openDialog) {
      setValue('name', category?.name);
      setValue('nameEn', category?.nameEn, {shouldValidate: true});
      setValue('icon', category?.icon?.id);
      setValue('color', category?.color);
    }
  }, [category, setValue, openDialog]);

  const handleCloseDialog = () => {
    reset();
    onCloseDialog();
    dispatch(setSelectedLanguage(ContentLanguage.CZ));
  };

  const onErrorSubmit: SubmitErrorHandler<CategoryForm> = (data) => {
    data.nameEn?.type === 'maxLength' &&
      dispatch(showErrorMessage(t("allObjects.EN name can't exceed")));
  };

  const onValidSubmit: SubmitHandler<CategoryForm> = (data) => {
    if (addNew) {
      onAddCategory && onAddCategory({...data});
    } else {
      onUpdateCategory && onUpdateCategory({...data, id: category?.id});
    }
    handleCloseDialog();
  };

  const handleAddNewIcon = (fileData: DamFileWithData) =>
    dispatch(createCategoryIcon(fileData));

  return (
    <Box>
      <DialogPrimary
        title={
          addNew ? t('category.Add new category') : t('category.Edit category')
        }
        open={openDialog}
        loading={loading}
        onCloseDialog={handleCloseDialog}
        contentLanguage={true}>
        <form onSubmit={handleSubmit(onValidSubmit, onErrorSubmit)}>
          <Box m={2}>
            <Box m={2}>
              <TextInputObjectName
                className={classes.button}
                selectedLanguage={selectedLanguage}
                control={control}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              marginTop={3}
              m={2}
              alignItems={'center'}>
              <Box marginRight={15}>
                <Controller
                  name="icon"
                  control={control}
                  defaultValue={''}
                  rules={{required: true}}
                  render={({field, fieldState: {error}}) => (
                    <SelectIcon
                      {...field}
                      label={t(`category.Icon`)}
                      handleDeleteIcon={showInfoDialog}
                      onAddNewIcon={handleAddNewIcon}
                      errorType={error?.type}
                      icons={categoryState.objList}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="color"
                  control={control}
                  defaultValue=""
                  rules={{required: true}}
                  render={({field, fieldState: {error}}) => (
                    <SelectColor
                      {...field}
                      label={t(`category.Color`)}
                      errorType={error?.type}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box marginTop={10} m={2}>
            <ButtonAdd
              name={
                addNew
                  ? t('category.Add category')
                  : t('category.Update category')
              }
            />
          </Box>
        </form>
      </DialogPrimary>
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.category icon')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.category icon'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
    </Box>
  );
};
