import React, {forwardRef} from 'react';

import {Box, MenuItem, Typography} from '@mui/material';
import {InputStyles} from '../../../styles/StylesInput';
import {FormModel} from '../../../../../hooks/useForm';
import {ButtonCategoryIconUpdate} from '../../buttons/ButtonCategoryIconUpdate';
import makeStyles from '@mui/styles/makeStyles';
import {ButtonCategoryIconDelete} from '../../buttons/ButtonCategoryIconDelete';
import {InputErrorMessage} from '../../custom-inputs/InputErrorMessage';
import {Icon} from '@skczu/czu-frontend-library/build/apis/admin-service/generated';
import {Utils} from '@skczu/czu-frontend-library';
import {CategoryIcon} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {styled} from '@mui/material/styles';
import {SecondaryLightHover} from '../../../../../App';
import {CustomSelect} from '../../custom-inputs/SelectBase';
import {CustomSelectInput} from '../../custom-inputs/InputBase';

const StyledMenuItem = styled(
  MenuItem,
  {}
)(({theme}) => ({
  '& .MuiMenuItem-root': {
    '&:focus': {
      backgroundColor: SecondaryLightHover,
    },
    '&:hover': {
      background: SecondaryLightHover,
    },
    '&:selected': {
      background: 'blue',
    },
    backgroundColor: theme.palette.secondary.light,
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center',
  },
}));

const useStyles = makeStyles(() => ({
  iconSelect: {
    display: 'flex',
  },
}));

export interface SelectIconProps<T extends CategoryIcon> extends FormModel {
  handleDeleteIcon?: (iconId: string) => void;
  onAddNewIcon?: (event: DamFileWithData) => void;
  icons: CategoryIcon[] | Icon[];
}

export const SelectIcon = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      disabled,
      handleDeleteIcon,
      onAddNewIcon,
      errorType,
      icons,
    }: SelectIconProps<CategoryIcon>,
    ref
  ): JSX.Element => {
    const classes = InputStyles();
    const selectClasses = useStyles();

    return (
      <Box>
        <Box m={0.5}>
          <Typography
            variant="subtitle2"
            id="icon-select"
            sx={{fontWeight: 600}}>
            {label}
          </Typography>
        </Box>
        <Box className={selectClasses.iconSelect}>
          <CustomSelect
            id={id}
            ref={ref}
            name={name}
            className={className + ' ' + (errorType ? classes.error : ' ')}
            value={value}
            onChange={onChange}
            disabled={disabled}
            MenuProps={{
              sx: {
                '& .MuiMenu-paper': {
                  maxHeight: '50%',
                },
                '&& .Mui-selected': {
                  backgroundColor: 'primary.main',
                },
              },
              MenuListProps: {
                disablePadding: true,
                disableListWrap: true,
                sx: {
                  backgroundColor: SecondaryLightHover,
                  '&.Mui-selected': {color: '#ffffff'},
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                height: 46,
                paddingLeft: '32px',
                width: 25,
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                alignSelf: 'center',
                alignItems: 'center',
              },
            }}
            input={<CustomSelectInput />}>
            {icons.map((icon) => {
              return (
                <StyledMenuItem value={icon.id} key={icon.id}>
                  <img
                    alt={
                      (icon as CategoryIcon).name
                        ? (icon as CategoryIcon).name
                        : (icon as Icon).fileName
                    }
                    src={
                      (icon as CategoryIcon).url
                        ? (icon as CategoryIcon).url
                        : Utils.imageBackgroundBase64Source(
                            icon as DamFileWithData
                          )
                    }
                    height={28}
                    width={28}
                  />
                </StyledMenuItem>
              );
            })}
          </CustomSelect>
          <Box>
            <Box className={selectClasses.iconSelect}>
              {onAddNewIcon && (
                <ButtonCategoryIconUpdate onChangeValue={onAddNewIcon} />
              )}
              {handleDeleteIcon && (
                <ButtonCategoryIconDelete
                  handleDeleteIcon={handleDeleteIcon}
                  value={value as string}
                />
              )}
            </Box>
          </Box>
        </Box>
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);

SelectIcon.displayName = 'SelectIcon';

export default SelectIcon;
