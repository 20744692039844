import React, {ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
import {TooltipIcon} from '../tooltip-icon/TooltipIcon';

export interface InputLabelProps {
  label?: ReactNode;
  inputTooltipText?: string;
  inputRowWidth?: string;
  row?: boolean;
  required?: boolean;
  labelRowWidth?: string;
  boldLabel?: boolean;
  disabled?: boolean;
}

export const InputLabel = ({
  label,
  inputTooltipText,
  row,
  boldLabel,
  labelRowWidth,
  inputRowWidth,
  required,
  children,
  disabled,
}: React.PropsWithChildren<InputLabelProps>): JSX.Element => {
  const styles = {
    row: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      flex: 1,
    },
    inputComponent: {
      width: inputRowWidth ? inputRowWidth : '50%',
    },
    disabledText: {
      color: 'secondary.light',
    },
    contentWidth: {
      width: labelRowWidth ? labelRowWidth : '50%',
    },
  };

  return (
    <Box sx={row ? styles.row : undefined}>
      <Box m={row ? 0 : 0.5} sx={row ? styles.contentWidth : undefined}>
        <Typography
          // className={boldLabel ? classes.bold : ''}
          sx={{
            ...(disabled ? styles.disabledText : undefined),
            fontWeight: boldLabel ? 600 : undefined,
            display: 'flex',
            flexDirection: 'row',
          }}
          variant="subtitle2"
          id="select-primary">
          {label}
          {required ? (
            <Typography
              sx={{
                color: 'red',
                fontSize: 18,
                fontWeight: 'bold',
                ml: 0.5,
                lineHeight: 0.95,
              }}>
              *
            </Typography>
          ) : null}
          {inputTooltipText ? <TooltipIcon label={inputTooltipText} /> : null}
        </Typography>
      </Box>
      <Box sx={styles.inputComponent}>{children}</Box>
    </Box>
  );
};
