import React from 'react';
import Divider from '@mui/material/Divider';
import {Box} from '@mui/material';
import {Controller} from 'react-hook-form';
import DateTimeInput from '../../../../components/date/DateTimeInput';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import SwitchPrimary from '../../../../components/switches/SwitchPrimary';
import {useTranslation} from 'react-i18next';
import {DialogStyles} from '../../../StylesDialog';
import {NewsForm} from '../../DialogNews';
import moment from 'moment/moment';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  roundTimeMinutes,
} from '../../../../utils/Utils';
export interface SendNotificationControls
  extends Partial<UseFormReturn<NewsForm>> {
  addNew?: boolean;
}

export const SendNotificationControls = ({
  control,
  watch,
  addNew,
  setValue,
}: SendNotificationControls): JSX.Element => {
  const {t} = useTranslation();

  return (
    <>
      <Box
        sx={{
          width: '50%',
          my: 1,
          ml: 1,
        }}>
        <Controller
          name="pushNot"
          control={control}
          // defaultValue={addNew}
          render={({field, fieldState: {error}}) => (
            <SwitchPrimary
              {...field}
              row
              label={t(`news.Send notification`)}
              errorType={error?.type}
              boldLabel
              onChange={(event) => {
                watch && !watch('visibleInApp') && !watch('pushNot');
                setValue && setValue('visibleInApp', true);
                field.onChange(event);
              }}
            />
          )}
        />
      </Box>
      {watch && watch('pushNot') ? (
        <>
          <Divider />
          <Box sx={{mx: 3}}>
            <Box sx={DialogStyles.rowContainer}>
              <Box style={{width: '50%'}}>
                <Controller
                  name="notification.sendNow"
                  control={control}
                  defaultValue={addNew}
                  render={({field, fieldState: {error}}) => (
                    <SwitchPrimary
                      {...field}
                      label={t(`news.Send now`)}
                      errorType={error?.type}
                      disabled={watch && !!watch('visibleFrom')}
                      row
                      boldLabel
                      onChange={(event) => {
                        if (setValue && watch && !watch('visibleFrom')) {
                          setValue(
                            'notification.sendDate',
                            roundTimeMinutes(
                              moment(),
                              moment.duration(5, 'minutes')
                            ).format(DefaultDateTimeFormatFE)
                          );
                        } else if (setValue && watch && watch('visibleFrom')) {
                          setValue(
                            'notification.sendDate',
                            watch('visibleFrom')
                          );
                        }
                        field.onChange(event);
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{width: '50%'}}>
                <Controller
                  name="notification.visibleInApp"
                  control={control}
                  defaultValue={addNew}
                  render={({field, fieldState: {error}}) => (
                    <SwitchPrimary
                      {...field}
                      label={t(`news.Save for user in notification app`)}
                      errorType={error?.type}
                      row
                      boldLabel
                      inputTooltipText={t(
                        'news.This option saves notification for users in the list of miniapp'
                      )}
                      labelRowWidth={'90%'}
                      inputRowWidth={'30%'}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{width: '50%', mt: 2, mb: 2}}>
              <Controller
                name="notification.sendDate"
                control={control}
                defaultValue={''}
                render={({field, fieldState: {error}}) => (
                  <DateTimeInput
                    {...field}
                    disabled={watch && watch('notification.sendNow')}
                    label={t(`news.Schedule sending date`)}
                    errorType={error?.type}
                    boldLabel
                    minutesStep={5}
                    value={
                      watch && watch('notification.sendNow') ? '' : field.value
                    }
                    min={
                      watch && watch('visibleFromNow')
                        ? moment().format(DefaultDateTimeFormatBE)
                        : watch && watch('visibleFrom')
                        ? moment(
                            watch && (watch('visibleFrom') as string),
                            DefaultDateTimeFormatFE
                          ).format(DefaultDateTimeFormatBE)
                        : moment().format(DefaultDateTimeFormatBE)
                    }
                    max={
                      watch && watch('visibleTo')
                        ? moment(
                            watch('visibleTo'),
                            DefaultDateTimeFormatFE
                          ).format(DefaultDateTimeFormatBE)
                        : undefined
                    }
                  />
                )}
              />
            </Box>
          </Box>
        </>
      ) : null}
      <Divider sx={{borderBottomWidth: 3, mt: 1}} />
    </>
  );
};
