import React, {ChangeEvent, ForwardedRef, forwardRef} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {AddCircleRounded} from '@mui/icons-material';
import {FormModel} from '../../../../hooks/useForm';
import {InputStyles} from '../../styles/StylesInput';
import {CustomUtils} from '../../utils/CustomUtils';
import {useTranslation} from 'react-i18next';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

export interface UploadImage extends FormModel {
  withIcon?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
}

export const ButtonUploadObject3dFiles = forwardRef(
  (
    {
      buttonText,
      name,
      label,
      className,
      icon,
      errorType,
      onChange,
      withIcon = true,
    }: UploadImage,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const {t} = useTranslation();
    const inputStyles = InputStyles();

    const readFileContents = async (file: File): Promise<DamFileWithData> => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        file.name.toLowerCase() !== 'tileset.json'
          ? fileReader.readAsDataURL(file)
          : fileReader.readAsText(file);
        fileReader.onload = () => {
          file.name.toLowerCase() !== 'tileset.json'
            ? resolve(
                CustomUtils.createPhotoFromObject3dReaderResult(
                  file,
                  fileReader.result
                )
              )
            : resolve({fileName: file.name, data: fileReader.result as string});
        };
        fileReader.onerror = reject;
      });
    };

    const readAllFiles = async (
      fileList: FileList
    ): Promise<DamFileWithData[]> => {
      return Promise.all(
        Array.from(fileList).map(async (file) => {
          return readFileContents(file);
        })
      );
    };

    const handleUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      files &&
        Array.from(files).length !== 0 &&
        readAllFiles(files)
          .then((result) => {
            onChange && onChange(result);
          })
          .catch((err) => {
            // console.log(err);
          });
    };

    return (
      <Box>
        <label htmlFor={'upload-file_' + name}>
          <input
            id={'upload-file_' + name}
            type="file"
            ref={ref}
            hidden
            multiple
            onChange={handleUploadCapture}
          />
          <Box m={0.5}>
            <Typography variant="subtitle2" sx={{fontWeight: 600}}>
              {label}
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={className + ' ' + (errorType ? inputStyles.error : ' ')}
            sx={{
              height: 50,
              width: 220,
              borderRadius: '10px',
              backgroundColor: 'secondary.light',
              color: 'secondary.contrastText',
            }}
            component="span"
            startIcon={withIcon && (icon ? icon : <AddCircleRounded />)}>
            {buttonText ? buttonText : t('objectInputs.Upload files')}
          </Button>
        </label>
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);

ButtonUploadObject3dFiles.displayName = 'ButtonUploadObject3dFiles';

export default ButtonUploadObject3dFiles;
