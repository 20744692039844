import React, {FormEventHandler} from 'react';
import {Box, Typography} from '@mui/material';
import {TextInputObjectName} from '../../components/text-inputs/TextInputObjectName';
import {Controller} from 'react-hook-form';
import {DateTimeInput} from '../../components/date/DateTimeInput';
import {TextEditorObjectBody} from '../../components/text-edior/TextEditorObjectBody';
import {GalleryPhotoContainer} from '../shared/gallery/GalleryPhotoContainer';
import {GalleryVideoContainer} from '../shared/gallery/GalleryVideoContainer';
import {CustomUtils} from '../../utils/CustomUtils';
import {ButtonAdd} from '../../components/buttons/ButtonAdd';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {GroupForm} from './DialogEvent';
import {UploadVideoHook} from '../../../../hooks/useUploadVideo';
import {useAppSelector} from '../../../../hooks/hooks';
import {contentLanguageSelector} from '../../../../redux/reducers/ContentLanguage';
import {StylesDialog} from '../StylesDialog';
import ButtonUploadImage from '../../components/buttons/ButtonUploadImage';
import {useTranslation} from 'react-i18next';
import SelectLayers from '../../components/selects/select-multiple/SelectLayers';
import {UploadFileHook} from '../../../../hooks/useUploadPhoto';
import {Video} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi/api';
import SwitchPrimary from '../../components/switches/SwitchPrimary';
import moment from 'moment';
import {
  DefaultDateTimeFormatBE,
  DefaultDateTimeFormatFE,
  isBeforeVisible,
} from '../../utils/Utils';
import {ListItemRowWithIcons} from '@skczu/czu-react-components';

export interface DialogGroupFormProps
  extends Partial<UseFormReturn<GroupForm>>,
    Partial<UploadFileHook>,
    Partial<UploadVideoHook> {
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
  onChangeLayers: (layerIds: string[]) => void;
  onRemoveVideo?: (event: Video) => void;
  addNew: boolean;
  id?: string;
  eventAlreadyVisible?: boolean;
  visibleInApp?: boolean;
}

export const EventForm = ({
  id,
  onSubmit,
  control,
  watch,
  addNew,
  galleryPhotoList,
  addGalleryPhotos,
  removeGalleryPhoto,
  addGalleryVideo,
  videoUploadProgress,
  onRemoveVideo,
  onChangeLayers,
  eventAlreadyVisible,
  setValue,
}: DialogGroupFormProps): JSX.Element => {
  const {t} = useTranslation();

  const {selectedLanguage} = useAppSelector(contentLanguageSelector);
  const dialogClasses = StylesDialog();

  const addMinuteFromNow = moment()
    .add(1, 'minutes')
    .format(DefaultDateTimeFormatBE);

  return (
    <form onSubmit={onSubmit}>
      <Box
        style={{
          width: '100%',
          marginLeft: '3%',
        }}>
        <TextInputObjectName
          selectedLanguage={selectedLanguage}
          control={control}
          boldLabel
          required
        />
        <Box className={dialogClasses.rowContainer}>
          <Box style={{width: '50%'}}>
            <Controller
              name="image"
              control={control}
              rules={{required: true}}
              render={({field, fieldState: {error}}) => (
                <ButtonUploadImage
                  {...field}
                  boldLabel
                  required
                  name={'image'}
                  label={t(`group.Cover image`)}
                  errorType={error?.type}
                  objectType={'DamFileWithData'}
                />
              )}
            />
          </Box>
          <Box style={{width: '50%'}}>
            <Controller
              name="layers"
              control={control}
              defaultValue={[]}
              render={({field, fieldState: {error}}) => (
                <SelectLayers
                  {...field}
                  selectAll
                  selectAny
                  label={t(`group.Layers`)}
                  errorType={error?.type}
                  onChange={(event) => {
                    field.onChange(event);
                    onChangeLayers(event);
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box style={{width: '100%'}}>
          <Box className={dialogClasses.rowContainer}>
            <Box style={{width: '50%'}}>
              <Controller
                name="startDate"
                control={control}
                defaultValue={undefined}
                render={({
                  field: {onChange, ...field},
                  fieldState: {error},
                }) => (
                  <DateTimeInput
                    {...field}
                    boldLabel
                    disabled={
                      !addNew
                        ? watch && watch('startDate')
                          ? eventAlreadyVisible
                          : false
                        : false
                    }
                    label={t(`group.Start date`)}
                    onChange={(startDate) => {
                      watch &&
                        watch('endDate') &&
                        isBeforeVisible(watch && watch('endDate'), startDate) &&
                        setValue &&
                        setValue('endDate', '');
                      onChange(startDate);
                    }}
                    errorType={error?.type}
                    min={moment().format(DefaultDateTimeFormatBE)}
                  />
                )}
              />
            </Box>
            <Box style={{width: '50%'}}>
              <Controller
                name="endDate"
                control={control}
                defaultValue={undefined}
                render={({field, fieldState: {error}}) => (
                  <DateTimeInput
                    {...field}
                    boldLabel
                    label={t(`group.End date`)}
                    errorType={error?.type}
                    min={
                      watch && watch('startDate')
                        ? eventAlreadyVisible
                          ? addMinuteFromNow
                          : moment(
                              watch && (watch('startDate') as string),
                              DefaultDateTimeFormatFE
                            )
                              .add(1, 'minutes')
                              .format(DefaultDateTimeFormatBE)
                        : addMinuteFromNow
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{width: '40%', marginLeft: '-18px', mt: 3}}>
            <ListItemRowWithIcons
              color={'primary.main'}
              title={
                <Typography
                  sx={{fontSize: 15, fontWeight: 'bold'}}
                  color={'primary'}>
                  {t('role.Advanced options')}
                </Typography>
              }
              collapseContent={
                <Box paddingTop={1}>
                  <Controller
                    name="visibleOnMap"
                    control={control}
                    defaultValue={false}
                    render={({field, fieldState: {error}}) => (
                      <SwitchPrimary
                        {...field}
                        row
                        labelRowWidth={'90%'}
                        inputRowWidth={'10%'}
                        boldLabel
                        label={t(`group.Visible on map`)}
                        inputTooltipText={t(
                          "group.This option sets visibility of event's places on map during the duration of the event"
                        )}
                        errorType={error?.type}
                        onChange={(event) => {
                          event.target.value &&
                            watch &&
                            !watch('visibleInApp') &&
                            setValue &&
                            setValue('visibleInApp', true);
                          field.onChange(event);
                        }}
                      />
                    )}
                  />
                  <Box sx={{mt: 1}}>
                    <Controller
                      name="visibleInApp"
                      control={control}
                      defaultValue={false}
                      render={({field, fieldState: {error}}) => (
                        <SwitchPrimary
                          {...field}
                          row
                          inputTooltipText={t(
                            'group.This option sets visibility of the event in miniapp'
                          )}
                          labelRowWidth={'90%'}
                          inputRowWidth={'10%'}
                          boldLabel
                          label={t(`group.Visible in event app`)}
                          errorType={error?.type}
                          onChange={(event) => {
                            event.target.value &&
                              watch &&
                              watch('visibleOnMap') &&
                              setValue &&
                              setValue('visibleOnMap', false);
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              }
            />
          </Box>
        </Box>
        <Box className={dialogClasses.textEditor}>
          <TextEditorObjectBody
            control={control}
            boldLabel
            required
            selectedLanguage={selectedLanguage}
          />
          <Box className={dialogClasses.gallery}>
            <GalleryPhotoContainer
              galleryPhotoList={galleryPhotoList}
              onUploadFiles={(photos) =>
                addGalleryPhotos && addGalleryPhotos(id, photos)
              }
              onRemove={removeGalleryPhoto}
            />
            <GalleryVideoContainer
              control={control}
              video={
                watch &&
                watch('video') &&
                CustomUtils.convertCreateVideoResponseToDamFile(watch('video'))
              }
              onRemoveVideo={onRemoveVideo}
              onUploadVideo={addGalleryVideo}
              videoUploadState={videoUploadProgress}
            />
          </Box>
        </Box>
        <Box>
          <ButtonAdd
            name={addNew ? t('group.Add group') : t('group.Update group')}
          />
        </Box>
      </Box>
    </form>
  );
};
