import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CellProps} from 'react-table';
import {AdminAppsObj} from '../TableApps';
import {Utils} from '@skczu/czu-frontend-library';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

export interface AppIconProps {
  cell?: CellProps<AdminAppsObj>;
}

export const AppIcon = ({cell}: AppIconProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    iconContainer: {
      borderRadius: 100,
      width: 35,
      height: 35,
      backgroundColor: 'black',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.iconContainer} key={cell?.value.id}>
      <img
        key={cell?.value.id}
        alt={cell?.value.name}
        // src={icon && icon.icon && icon.icon.url}
        src={Utils.imageBackgroundBase64Source(cell?.value as DamFileWithData)}
        height={25}
        width={25}
      />
    </Box>
  );
};

function appPropsAreEqual(prevMovie: any, nextMovie: any) {
  return (
    prevMovie?.value?.id === nextMovie?.value?.id &&
    prevMovie?.value?.fileName === nextMovie?.value?.fileName
  );
}

export const AppIconMemo = React.memo(AppIcon, appPropsAreEqual);
