import React, {MouseEventHandler, PropsWithChildren, ReactElement} from 'react';
import {TableInstance} from 'react-table';
import {ButtonTable} from '../../shared/components/buttons/ButtonTable';
import {
  DialogItemButtonTypes,
  DialogItemType,
} from '../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {Box} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from 'react-i18next';

export const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      position: 'absolute',
      right: 1,
      top: -55,
    },
  })
);

export type TableGetSelect = <T extends Record<string, unknown>>(
  instance: TableInstance<T>,
  type: DialogItemType<T>
) => MouseEventHandler;

type TableButtonContainerProps<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  setMultiSelect?: (
    instance: TableInstance<T>,
    type: DialogItemType<T>
  ) => MouseEventHandler;
  multiselectButtonTypes?: DialogItemButtonTypes[];
};

export function TableButtonContainer<T extends Record<string, unknown>>({
  instance,
  setMultiSelect,
  multiselectButtonTypes,
}: PropsWithChildren<TableButtonContainerProps<T>>): ReactElement | null {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Box className={classes.toolbar}>
      {setMultiSelect && Object.keys(instance.state.selectedRowIds).length > 0 && (
        <>
          {multiselectButtonTypes?.map((type) => {
            switch (type) {
              case 'enabled':
                return (
                  <ButtonTable
                    name={
                      t('user.Change status') +
                      ` (${Object.keys(instance.state.selectedRowIds).length})`
                    }
                    onClick={
                      setMultiSelect &&
                      setMultiSelect(instance, {
                        type: 'enabled',
                        openDialog: true,
                        value: '',
                        id: '123',
                      })
                    }
                  />
                );
              case 'role':
                return (
                  <ButtonTable
                    name={
                      t('user.Add roles') +
                      ` (${Object.keys(instance.state.selectedRowIds).length})`
                    }
                    onClick={
                      setMultiSelect &&
                      setMultiSelect(instance, {
                        type: 'role',
                        openDialog: true,
                        value: [],
                        id: '123',
                      })
                    }
                  />
                );
              case 'delete':
                return (
                  <ButtonTable
                    name={
                      t('user.Delete') +
                      ` (${Object.keys(instance.state.selectedRowIds).length})`
                    }
                    onClick={
                      setMultiSelect &&
                      setMultiSelect(instance, {
                        type: 'delete',
                        openDialog: true,
                        value: [],
                        id: '123',
                      })
                    }
                  />
                );
            }
          })}

          {/*{tableName === 'usersTable' && (*/}
          {/*  <ButtonTable*/}
          {/*    name={*/}
          {/*      t('user.Add to group') +*/}
          {/*      ` (${Object.keys(instance.state.selectedRowIds).length})`*/}
          {/*    }*/}
          {/*    onClick={*/}
          {/*      setMultiSelect &&*/}
          {/*      setMultiSelect(instance, {*/}
          {/*        type: 'group',*/}
          {/*        openDialog: true,*/}
          {/*        value: [],*/}
          {/*        id: '123',*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*)}*/}
        </>
      )}
    </Box>
  );
}
