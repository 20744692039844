import React from 'react';
import {Box, Button} from '@mui/material';

export interface TabItem {
  name: string;
  label: string;
  invalid?: boolean;
}

export interface Props {
  handleNavigation: (page: TabItem) => void;
  activeItem: TabItem;
  items: TabItem[];
}

export const TabsDialog = ({
  handleNavigation,
  activeItem,
  items,
}: Props): JSX.Element => {
  return (
    <Box sx={{display: 'flex'}}>
      {items.map((item, index) => {
        const itemColor = item.name === activeItem.name;
        return (
          <Button
            key={item.name}
            variant="contained"
            onClick={() => handleNavigation(item)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 34,
              minWidth: 120,
              border: `${item.invalid ? 3 : 1}px solid`,
              borderColor: item.invalid ? `red` : 'secondary.light',
              backgroundColor: itemColor ? 'secondary.main' : '#eeeeee',
              borderBottom: itemColor ? '2px solid white' : undefined,
              borderTopLeftRadius: index === 0 ? 10 : undefined,
              borderTopRightRadius:
                index === items.length - 1 ? '10px' : undefined,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'secondary.light',
                boxShadow: 'none',
              },
              color: 'secondary.contrastText',
            }}>
            {item.label}
          </Button>
        );
      })}
    </Box>
  );
};
