import {AppThunk, RootState} from '../../../store';
import {createGenericSlice, GenericState} from '@skczu/czu-frontend-library';
import {showErrorMessage} from '../ErrorSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  Icon,
  AppApi,
  CreateApplicationRequest,
  IconApi,
  MiniApplication,
  Configuration,
} from '@skczu/czu-frontend-library/build/apis/admin-service/generated';
import config from '../../../config';
import {setLoading} from '../LoadingSlice';
import {getToken} from '../../../keycloak';

export interface BaseList {
  totalCount?: number;
  list: MiniApplication[];
  iconList: Icon[];
}

export interface AppsState extends GenericState<any, any> {
  adminList: BaseList;
}

const initialState: AppsState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {
    list: [],
    iconList: [],
  },
};

const appsSlice = createGenericSlice({
  name: 'apps',
  initialState,
})({
  setAdminList: (
    state: AppsState,
    {payload}: PayloadAction<MiniApplication[]>
  ) => {
    state.adminList.list = payload;
  },
  setAppIconList: (state: AppsState, {payload}: PayloadAction<Icon[]>) => {
    state.adminList.iconList = payload;
  },
});

export const getBaseAppsList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(appsActions.setObjLoadingList(true));
    const response = await new AppApi(
      new Configuration({accessToken: await getToken()}),
      config.adminServiceRestUrl
    ).adminApplicationGet();
    if (response.data) {
      dispatch(appsActions.setAdminList([]));
      dispatch(appsActions.setAdminList(response.data));
    }
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(appsActions.setObjLoadingList(false));
  }
};

export const getApp =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    const foundApp = getState().apps.adminList.list.find(
      (app) => app.id === id
    );
    foundApp && dispatch(appsActions.setObj(foundApp));
  };

export const updateApp =
  (
    newApp: boolean,
    app: CreateApplicationRequest,
    onAddApp?: () => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(appsActions.setObjLoadingList(true));
      if (newApp) {
        await new AppApi(
          new Configuration({accessToken: await getToken()}),
          config.adminServiceRestUrl
        ).adminApplicationPost(app);
      } else {
        if (app.id) {
          await new AppApi(
            new Configuration({accessToken: await getToken()}),
            config.adminServiceRestUrl
          ).adminApplicationIdPut(app.id, app);
        }
      }
      onAddApp && onAddApp();
      dispatch(appsActions.setOpenObjDialog(false));
      dispatch(getBaseAppsList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(appsActions.setObjLoadingList(false));
    }
  };

export const getAppIconList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const appIconList = await new IconApi(
      new Configuration({accessToken: await getToken()}),
      config.adminServiceRestUrl
    ).adminIconGet();
    dispatch(appsActions.setAppIconList(appIconList.data || []));
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAppIcon =
  (categoryIcon: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(appsActions.setObjLoadingDetail(true));
      await new IconApi(
        new Configuration({accessToken: await getToken()}),
        config.adminServiceRestUrl
      ).adminIconPost(categoryIcon);
      dispatch(getAppIconList());
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(appsActions.setObjLoadingDetail(false));
    }
  };

export const appsActions = appsSlice.actions;

export const appsSelector = (state: RootState): typeof state.apps => state.apps;

export default appsSlice.reducer;
