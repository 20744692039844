import React, {forwardRef, useEffect} from 'react';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';

import {FormModel} from '../../../../hooks/useForm';

import {
  getFoodPoints,
  poiSelector,
} from '../../../../redux/reducers/data-reducers/PoiSlice';
import SelectMultiplePrimary from './SelectMultiplePrimary';
import {useTranslation} from 'react-i18next';
import {CustomUtils} from '../../utils/CustomUtils';

const SelectFoodPoint = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      onChange,
      className,
      disabled,
      onBlur,
      errorType,
    }: FormModel,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const {foodPoints} = useAppSelector(poiSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!foodPoints || foodPoints.length === 0) {
        dispatch(getFoodPoints());
      }
    }, [dispatch, foodPoints]);
    return (
      <Box>
        <SelectMultiplePrimary
          id={id}
          name={name}
          selectedValue={
            value
              ? `${
                  foodPoints?.find((point) => point.id === value)
                    ? CustomUtils.getContentByLanguage(
                        foodPoints?.find((obj) => obj.id === value)?.name,
                        foodPoints?.find((obj) => obj.id === value)?.nameEn
                      )
                    : ''
                }`
              : t('poi.Foodpoint') + ` (${t('allObjects.None')}) `
          }
          disabled={disabled}
          label={label}
          className={className}
          ref={ref}
          value={value}
          multiple={false}
          onBlur={onBlur}
          errorType={errorType}
          onChange={onChange}
          list={foodPoints}
          selectAny></SelectMultiplePrimary>
      </Box>
    );
  }
);

SelectFoodPoint.displayName = 'SelectFoodPoint';

export default SelectFoodPoint;
