import {AppThunk, RootState} from '../../../store';
import {clearObjectPreview, setActiveObjectPreview} from '../DrawerSlice';
import {setPoiSelection, setPoiSelectionList} from '../PoiListSelectionSlice';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getBaseObjList,
  getObj,
  updateObj,
} from '@skczu/czu-frontend-library';
import {getSimpleRouteList} from './SimpleObjectListsSlice';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  Configuration,
  CreateOrUpdateRoute,
  Route,
  RouteApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {
  ObjectRef,
  ObjectRefFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route/api';
import {getToken} from '../../../keycloak';

const initialState: GenericState<ObjectRef, Route> = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 20,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const routeSlice = createGenericSlice({
  name: 'route',
  initialState,
})({});

export const getBaseRouteList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<ObjectRef, ObjectRefFilterResponse>(
        getState().routeList.baseObjSearch,
        getState().routeList.baseObjList,
        async () =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routeFilterGet(
            keyword,
            getState().routeList.baseObjSearch.limit,
            getState().routeList.baseObjSearch.offset
          ),
        (loading) => dispatch(routeActions.setObjLoadingList(loading)),
        () => dispatch(showErrorMessage()),
        (offset) => dispatch(routeActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(
              routeActions.setBaseObjList(newDataList ? newDataList : [])
            );
          } else {
            dispatch(routeActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(routeActions.hasMore(hasMore))
      )
    );
  };

export const getRoute =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Route>(
        id,
        async () =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routeIdGet(id),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(routeActions.setObj(obj));
          dispatch(setPoiSelectionList(obj.pois ? obj.pois : []));
        }
      )
    );
  };

export const getRouteForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(clearObjectPreview());
    dispatch(setPoiSelectionList([]));
    dispatch(
      getObj<Route>(
        id,
        async () =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routeIdGet(id),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'route',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
          dispatch(setPoiSelectionList(obj.pois ? obj.pois : []));
          dispatch(
            setPoiSelection({poiSelection: true, poiSelectionFrom: 'route'})
          );
        }
      )
    );
  };

export const deleteRoute =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routeIdDelete(id),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(routeActions.setObj(null));
          dispatch(routeActions.removeFromBaseObjList(id));
          dispatch(setPoiSelectionList([]));
          dispatch(getSimpleRouteList());
          dispatch(clearObjectPreview());
        }
      )
    );
  };

export const createRoute =
  (route: CreateOrUpdateRoute): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CreateOrUpdateRoute>(
        route,
        async (obj) =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routePost({...obj, objectType: 'CreateOrUpdateRoute'}),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (id) => {
          dispatch(routeActions.setObj(null));
          dispatch(
            routeActions.addToBaseObjListAsFirst({...route, id: id as string})
          );
          dispatch(getSimpleRouteList());
          dispatch(routeActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const updateRoute =
  (route: CreateOrUpdateRoute): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<CreateOrUpdateRoute>(
        route,
        async (obj) =>
          new RouteApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).routePut({...obj, objectType: 'CreateOrUpdateRoute'}),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(routeActions.setObj(null));
          dispatch(routeActions.updateBaseObjInList(obj));
          dispatch(getSimpleRouteList());
          dispatch(routeActions.setOpenObjDialog(false));
        }
      )
    );
  };

export const routeActions = routeSlice.actions;

export const routeSelector = (state: RootState): typeof state.routeList =>
  state.routeList;

export default routeSlice.reducer;
