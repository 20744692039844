import {AppThunk, RootState} from '../../../store';
import {setActiveObjectPreview} from '../DrawerSlice';
import {setGalleryPhotoList} from './GalleryPhotoSlice';
import {
  createGenericSlice,
  createObj,
  deleteObj,
  GenericState,
  getBaseObjList,
  getObj,
  InfiniteSearch,
  updateObj,
} from '@skczu/czu-frontend-library';
import {PayloadAction} from '@reduxjs/toolkit';
import {setLoading} from '../LoadingSlice';
import {getSimpleGroupList} from './SimpleObjectListsSlice';
import config from '../../../config';
import {showErrorMessage} from '../ErrorSlice';
import {
  AdminEvent,
  Configuration,
  CreateOrUpdateEvent,
  Event,
  EventApi,
  EventFilterRequest,
  EventFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event/api';
import {ObjectRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {setFilterSelectedGroups} from '../MapSlice';
import {DefaultEventApi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/defaultEvent';
import {DefaultEvent} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/defaultEvent/api';
import {getToken} from '../../../keycloak';

export interface DefaultEventDialog {
  defaultEvent?: DefaultEvent;
  openDialog: boolean;
}

export interface BaseGroupList {
  totalCount?: number;
  list: AdminEvent[];
}

export interface GroupState extends GenericState<EventFilterRefObject, Event> {
  adminList: BaseGroupList;
  defaultEvent: DefaultEventDialog;
}

const initialState: GroupState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  adminList: {list: []},
  filterDataList: [],
  filterObjSearch: {
    limit: 10,
    offset: 0,
  },
  defaultEvent: {openDialog: false},
};
const groupsSlice = createGenericSlice({
  name: 'groups',
  initialState,
})({
  setDefaultEvent: (
    state: GroupState,
    {payload}: PayloadAction<DefaultEvent>
  ) => {
    state.defaultEvent.defaultEvent = payload;
  },
  setOpenDefaultEventDialog: (
    state: GroupState,
    {payload}: PayloadAction<boolean>
  ) => {
    state.defaultEvent.openDialog = payload;
  },
  setAdminList: (
    state: GroupState,
    {payload}: PayloadAction<BaseGroupList>
  ) => {
    state.adminList = payload;
  },
  addToAdminList: (
    state: GroupState,
    {payload}: PayloadAction<AdminEvent[]>
  ) => {
    state.adminList.list =
      state.baseObjSearch.offset !== 0
        ? state.adminList && [...state.adminList.list, ...payload]
        : payload;
  },
});

export const getBaseGroupsList =
  (request: EventFilterRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(groupActions.setObjLoadingList(true));
      const response = await new EventApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).eventAdminFilterPost(
        {...request}
        // getState().newsList.baseObjSearch.limit,
        // getState().newsList.baseObjSearch.offset
      );
      if (response?.data) {
        dispatch(
          groupActions.setAdminList({
            list: response.data.objects ? response.data.objects : [],
            totalCount: response.data.totalCount,
          })
        );
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(groupActions.setObjLoadingList(false));
    }
  };

export const getFilterGroupsList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<EventFilterRefObject, EventFilterResponse>(
        getState().groupsList.filterObjSearch as InfiniteSearch,
        getState().groupsList.filterDataList as EventFilterRefObject[],
        async () =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventFilterGet(
            newList
              ? (getState().groupsList.filterObjSearch as InfiniteSearch)
                  .searchQuery
              : keyword,
            999999,
            0,
            true,
            true
          ),
        (loading) => dispatch(setLoading(loading)),
        () => dispatch(showErrorMessage()),
        () => void 0,
        (data) => {
          const selectedGroups = getState().map.mapFilter.selectedGroups;
          const newSelectedGroups: ObjectRef[] = [];
          selectedGroups &&
            selectedGroups.length > 0 &&
            selectedGroups.forEach((selectedGroup) => {
              const foundGroup = data?.find(
                (group) => group.id === selectedGroup.id
              );
              foundGroup && newSelectedGroups.push(foundGroup);
            });
          dispatch(setFilterSelectedGroups(newSelectedGroups));
          dispatch(groupActions.setFilterDataList(data));
        },
        keyword,
        () => true,
        newList
      )
    );
  };

export const getGroup =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Event>(
        id,
        async () =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventIdGet(id),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(groupActions.setObj(obj));
          dispatch(
            setGalleryPhotoList(
              obj.photos
                ? obj.photos?.map((photo) => {
                    return {galleryPhoto: photo, progress: 0, state: 'new'};
                  })
                : []
            )
          );
        }
      )
    );
  };

export const getGroupForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Event>(
        id,
        async () =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventIdGet(id),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'group',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
          dispatch(
            setGalleryPhotoList(
              obj.photos
                ? obj.photos?.map((photo) => {
                    return {galleryPhoto: photo, progress: 0, state: 'new'};
                  })
                : []
            )
          );
        }
      )
    );
  };

export const deleteGroup =
  (id: string, onDelete?: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      deleteObj(
        id,
        async () =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventIdDelete(id),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(groupActions.setObj(null));
          dispatch(groupActions.removeFromBaseObjList(id));
          dispatch(getSimpleGroupList());
          dispatch(getFilterGroupsList(true));
          onDelete && onDelete();
        }
      )
    );
  };

export const createGroup =
  (group: CreateOrUpdateEvent, onCreate: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      createObj<CreateOrUpdateEvent>(
        group,
        async (obj) =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventPost({
            ...obj,
            objectType: 'CreateOrUpdateEvent',
          }),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        () => {
          dispatch(groupActions.setObj(null));
          dispatch(groupActions.setOpenObjDialog(false));
          dispatch(getSimpleGroupList());
          dispatch(getFilterGroupsList(true));
          onCreate();
        }
      )
    );
  };

export const updateGroup =
  (group: CreateOrUpdateEvent, onUpdate: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(
      updateObj<CreateOrUpdateEvent>(
        group,
        async (obj) =>
          new EventApi(
            new Configuration({accessToken: await getToken()}),
            config.cmsRestUrl
          ).eventPut({
            ...obj,
            objectType: 'CreateOrUpdateEvent',
          }),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        () => dispatch(showErrorMessage()),
        (obj) => {
          dispatch(groupActions.updateBaseObjInList(obj));
          dispatch(getSimpleGroupList());
          dispatch(getFilterGroupsList(true));
          dispatch(groupActions.setObj(null));
          dispatch(groupActions.setOpenObjDialog(false));
          onUpdate();
        }
      )
    );
  };

export const getDefaultEvent = (): AppThunk => async (dispatch) => {
  try {
    dispatch(groupActions.setObjLoadingList(true));
    const response = await new DefaultEventApi(
      new Configuration({accessToken: await getToken()}),
      config.cmsRestUrl
    ).defaultEventGet();
    if (response?.data) {
      dispatch(groupActions.setDefaultEvent(response?.data));
    }
  } catch (error) {
    dispatch(showErrorMessage());
  } finally {
    dispatch(groupActions.setObjLoadingList(false));
  }
};

export const createDefaultEvent =
  (defaultEvent: DefaultEvent): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(groupActions.setObjLoadingList(true));
      const response = await new DefaultEventApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).defaultEventPut(defaultEvent);
      if (response?.data) {
        dispatch(getDefaultEvent());
        dispatch(groupActions.setOpenDefaultEventDialog(false));
      }
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(groupActions.setObjLoadingList(false));
    }
  };

export const deleteEvents =
  (ids: string[], onDeleteUser: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(groupActions.setObjLoadingList(true));
      await new EventApi(
        new Configuration({accessToken: await getToken()}),
        config.cmsRestUrl
      ).eventAdminDelete(ids);
      onDeleteUser();
    } catch (error) {
      dispatch(showErrorMessage());
    } finally {
      dispatch(groupActions.setObjLoadingList(false));
    }
  };
export const groupActions = groupsSlice.actions;

export const groupsSelector = (state: RootState): typeof state.groupsList =>
  state.groupsList;

export const defaultEventSelector = (
  state: RootState
): typeof state.groupsList.defaultEvent.defaultEvent =>
  state.groupsList.defaultEvent.defaultEvent;

export default groupsSlice.reducer;
