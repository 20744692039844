import React, {ChangeEvent, ForwardedRef, forwardRef} from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {Publish} from '@mui/icons-material';
import {FormModel} from '../../../../../hooks/useForm';
import {InputErrorMessage} from '../../../components/custom-inputs/InputErrorMessage';
import {readFileContent} from '../../../utils/Utils';

export interface UploadImage extends FormModel {
  withIcon?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
  objectType?: string;
}

export const FileInputHotspot = forwardRef(
  (
    {
      buttonText = 'Upload image',
      name,
      label,
      className,
      onChange,
      errorType,
      objectType,
    }: UploadImage,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const handleUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      files &&
        files[0] &&
        onChange &&
        readFileContent(files[0]).then((result) => {
          onChange({...result, objectType});
        });
    };
    return (
      <Box>
        <label htmlFor={'upload-360photo_' + name}>
          <input
            accept={'.jpg,.jpeg,.png'}
            id={'upload-360photo_' + name}
            type="file"
            ref={ref}
            hidden
            onChange={handleUploadCapture}
          />
          <IconButton component="span" className={className} size="large">
            <Publish color={'secondary'} />
            <Typography color={'secondary'}>{buttonText}</Typography>
          </IconButton>
        </label>
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </Box>
    );
  }
);

FileInputHotspot.displayName = 'FileInputHotspot';

export default FileInputHotspot;
