import {DialogPrimary} from '../../../../shared/dialogs/DialogPrimary';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {Box, Typography} from '@mui/material';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import SelectLayers from '../../../../shared/components/selects/select-multiple/SelectLayers';
import {useAppSelector} from '../../../../../hooks/hooks';
import {selectSimpleLayerList} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';
import makeStyles from '@mui/styles/makeStyles';
import {DefaultEvent} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/defaultEvent/api';

type DefaultEventForm = DefaultEvent;

export interface DialogDefaultEventProps {
  open: boolean;
  defaultEvent?: DefaultEvent;
  onCloseDialog: () => void;
  onChangeDefaultLayer: (layerIds: string[]) => void;
}

export const DialogDefaultEvent = ({
  open,
  defaultEvent,
  onCloseDialog,
  onChangeDefaultLayer,
}: DialogDefaultEventProps): JSX.Element => {
  const {control, handleSubmit, watch, setValue} = useForm<DefaultEventForm>();
  const simpleLayerList = useAppSelector(selectSimpleLayerList);
  const classes = useStyles();

  useEffect(() => {
    if (defaultEvent && open) {
      setValue('layers', defaultEvent?.layers || []);
    }
  }, [defaultEvent, open, setValue]);

  const {t} = useTranslation();

  const onValidSubmit: SubmitHandler<DefaultEventForm> = (data) => {
    data.layers && onChangeDefaultLayer(data.layers);
  };

  return (
    <DialogPrimary
      title={t('group.Default event settings')}
      open={open}
      minWidth={'800px'}
      minHeight={'600px'}
      onCloseDialog={onCloseDialog}>
      <form onSubmit={handleSubmit(onValidSubmit)}>
        <Box display="flex" marginTop={2} marginBottom={10}>
          <Box className={classes.dialogColumn}>
            <Box className={classes.dialogColumnContent}>
              <Controller
                name="layers"
                control={control}
                defaultValue={[]}
                render={({field}) => (
                  <SelectLayers {...field} label={t('group.Layers')} />
                )}
              />
            </Box>
          </Box>
          <Box className={classes.line} />
          <Box className={classes.dialogColumn}>
            <Box className={classes.dialogColumnContent}>
              <Typography className={classes.listTitle} sx={{fontWeight: 600}}>
                {t(`group.List of layers`)}
              </Typography>
              {watch('layers') && (watch('layers') as string[]).length > 0 ? (
                watch('layers')?.map((id) => (
                  <Box marginTop={1} key={'defaultLayer_' + id}>
                    <Typography>
                      {simpleLayerList.find((layer) => id === layer.id)?.name}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography className={classes.noneText}>
                  {t('group.None')}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <ButtonAdd name={t('group.Save changes')} />
        </Box>
      </form>
    </DialogPrimary>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogColumn: {width: '50%'},
  dialogColumnContent: {marginLeft: 30},
  listTitle: {fontWeight: 'bold', marginBottom: 20},
  noneText: {color: theme.palette.secondary.light},
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    height: 60,
    paddingTop: 10,
    width: '94.5%',
    backgroundColor: 'white',
  },
  line: {
    width: 1,
    marginTop: 40,
    minHeight: 350,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}));
