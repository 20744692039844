import {FilterValue, IdType, Row} from 'react-table';
import moment from 'moment';
import {DefaultDateFormat} from '../../utils/Utils';

export function dateBetweenFilter<T extends Record<string, unknown>>(
  rows: Array<Row<T>>,
  id: IdType<T>,
  filterValue: FilterValue
): Array<Row<T>> {
  const start = moment(
    filterValue[0],
    filterValue[0] ? DefaultDateFormat : undefined
  ).subtract(1, 'day');
  const end = moment(
    filterValue[1],
    filterValue[1] ? DefaultDateFormat : undefined
  ).add(1, 'day');
  return rows.filter((row) =>
    moment(row.values[id], DefaultDateFormat).isBetween(start, end)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
dateBetweenFilter.autoRemove = (val: any) => !val;
