export const MobileDomain = 'czumobile';

export type MobileObjDetailUrlTypes = 'event' | 'route' | 'poi' | 'news';

export const getMobileObjDetailUrl = (
  objType?: MobileObjDetailUrlTypes,
  id?: string
): string => {
  if (objType) {
    return MobileDomain + '://' + objType + '/' + id;
  } else return MobileDomain + '://';
};
