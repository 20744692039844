import React, {ForwardedRef, forwardRef} from 'react';
import {FormModel} from '../../../../hooks/useForm';
import {InputStyles} from '../../styles/StylesInput';
import {DefaultDateTimeFormatFE, roundTimeMinutes} from '../../utils/Utils';
import moment from 'moment';
import {InputErrorMessage} from '../custom-inputs/InputErrorMessage';
import {InputLabel, InputLabelProps} from '../input-label/InputLabel';
import {DateTimePicker} from '@mui/x-date-pickers';
import {TextField} from '@mui/material';

export const DateInputStyles = {
  dateContainer: {
    position: 'relative',
  },
  dateSwitch: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  date: {
    borderRadius: '10px',
    backgroundColor: 'secondary.light',
    height: 42,
    paddingLeft: '5px',
    boxShadow: 2,
    '&:hover': {
      background: '#cccccc',
    },
  },
  dateInput: {
    // marginRight: '8px',
    // '& .MuiInput-input': {
    paddingTop: '4px',
    fontSize: 18,
    marginLeft: '8px',
    marginRight: '12px',
    width: 212,
    cursor: 'text',
    // },
  },
};
export interface DateTimeInputProps extends FormModel, InputLabelProps {
  min?: string;
  max?: string;
  removeDate?: boolean;
  minutesStep?: number;
}

export const DateTimeInput = forwardRef(
  (
    {
      value,
      label,
      errorType,
      onChange,
      disabled,
      boldLabel,
      row,
      labelRowWidth,
      inputRowWidth,
      min,
      max,
      removeDate = true,
      minutesStep,
    }: DateTimeInputProps,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const inputClasses = InputStyles();
    const styles = DateInputStyles;

    return (
      <InputLabel
        label={label}
        row={row}
        labelRowWidth={labelRowWidth}
        inputRowWidth={inputRowWidth}
        disabled={disabled}
        boldLabel={boldLabel}>
        <DateTimePicker
          componentsProps={{
            actionBar: {
              actions: removeDate ? ['clear'] : [],
              onClick: removeDate
                ? () => onChange && onChange(undefined)
                : undefined,
            },
          }}
          InputProps={{onKeyDown: (e) => e.preventDefault()}}
          renderInput={(props) => (
            <TextField
              {...props}
              variant={'standard'}
              onKeyDown={(e) => e.preventDefault()}
              sx={{
                ...styles.date,
                '& .MuiInputBase-root': {
                  pointerEvents: 'none',
                },
                '& .MuiInputBase-root button': {
                  pointerEvents: 'all',
                },
              }}
              InputProps={{
                ...props.InputProps,
                disableUnderline: true,
                sx: styles.dateInput,
              }}
              className={errorType ? inputClasses.error : ' '}
            />
          )}
          value={value ? moment(value, DefaultDateTimeFormatFE).toDate() : null}
          minDateTime={min && moment(min)}
          maxDateTime={max && moment(max)}
          minutesStep={minutesStep}
          ref={ref}
          disabled={disabled}
          onChange={(newValue) => {
            const val = minutesStep
              ? roundTimeMinutes(
                  moment(newValue),
                  moment.duration(5, 'minutes')
                ).format(DefaultDateTimeFormatFE)
              : moment(newValue).format(DefaultDateTimeFormatFE);
            onChange && onChange(val);
          }}
        />
        {errorType && (
          <InputErrorMessage inputName={label} errorType={errorType} />
        )}
      </InputLabel>
    );
  }
);
DateTimeInput.displayName = 'DateTimeInput';

export default DateTimeInput;
