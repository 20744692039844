import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  Configuration,
  Wishlist,
  WishlistApi,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';
import {getToken} from '../../../keycloak';

interface WishlistState {
  wishlist?: Wishlist;
  openDialog: boolean;
}

const initialState: WishlistState = {
  openDialog: false,
};

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setOpenDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.openDialog = payload;
    },
    setWishlist: (state, {payload}: PayloadAction<Wishlist>) => {
      state.wishlist = payload;
    },
  },
});

export const getWishlist = (): AppThunk => async (dispatch) => {
  try {
    const response = await new WishlistApi(
      new Configuration(),
      config?.userDataRestUrl
    ).publicWishlistGet();
    if (response) {
      dispatch(setWishlist(response.data));
    }
  } catch (error) {
    // dispatch(showErrorMessage('Failed delete user from group'));
  }
};

export const createWishlist =
  (wishlist: Wishlist): AppThunk =>
  async (dispatch) => {
    try {
      const response = await new WishlistApi(
        new Configuration({accessToken: await getToken()}),
        config?.userDataRestUrl
      ).wishlistPost(wishlist);
      if (response) {
        dispatch(getWishlist());
        dispatch(setOpenDialog(false));
      }
    } catch (error) {
      // dispatch(showErrorMessage('Failed delete user from group'));
    }
  };

export const deleteWishlist = (): AppThunk => async (dispatch) => {
  try {
    const response = await new WishlistApi(
      new Configuration({accessToken: await getToken()}),
      config?.userDataRestUrl
    ).wishlistDelete();
    if (response) {
      dispatch(getWishlist());
    }
  } catch (error) {
    // dispatch(showErrorMessage('Failed delete user from group'));
  }
};

export const {setWishlist, setOpenDialog} = wishlistSlice.actions;

export const wishlistSelector = (state: RootState) => state.wishlist;

export default wishlistSlice.reducer;
