import React, {forwardRef, useEffect} from 'react';
import {Box} from '@mui/material';
import {SelectMultiplePrimary} from '../SelectMultiplePrimary';
import {MultipleSelect} from './SelectCategories';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';

import {useTranslation} from 'react-i18next';
import {
  getSimpleGroupList,
  selectSimpleGroupList,
} from '../../../../../redux/reducers/data-reducers/SimpleObjectListsSlice';

export interface SelectGroupsProps extends MultipleSelect {
  row?: boolean;
  getChangedGroup?: (id: string, name: string) => void;
}

export const SelectEvents = forwardRef(
  (
    {
      value,
      id,
      name,
      label,
      className,
      onChange,
      onBlur,
      disabled,
      errorType,
      selectAll = true,
      selectAny = true,
      row = false,
    }: SelectGroupsProps,
    ref
  ): JSX.Element => {
    const {t} = useTranslation();
    const simpleGroupList = useAppSelector(selectSimpleGroupList);
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getSimpleGroupList());
    }, [dispatch]);

    return (
      <Box>
        <SelectMultiplePrimary
          id={id}
          name={name}
          ref={ref}
          selectedValue={
            t('objectInputs.Groups') +
            (value
              ? ` (${value.length === 0 ? t('allObjects.None') : value.length})`
              : '')
          }
          disabled={disabled}
          label={label}
          className={className}
          value={value}
          onBlur={onBlur}
          row={row}
          errorType={errorType}
          onChange={onChange}
          list={simpleGroupList}
          selectAll={selectAll}
          selectAny={selectAny}></SelectMultiplePrimary>
      </Box>
    );
  }
);
SelectEvents.displayName = 'SelectEvents';

export default SelectEvents;
