import React from 'react';
import {DialogPrimary} from '../DialogPrimary';
import {Box, Button, DialogActions, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

export interface Props {
  id?: string;
  title: string;
  text: string;
  openDialog: boolean;
  onCloseDialog: () => void;
  onAgree?: (id?: string) => void;
}
export const DialogInfo = ({
  id,
  openDialog,
  title,
  text,
  onCloseDialog,
  onAgree,
}: Props): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Box>
      <DialogPrimary
        title={title}
        open={openDialog}
        minHeight={'100px'}
        onCloseDialog={onCloseDialog}>
        <Box m={1}>
          <Typography>{text}</Typography>
        </Box>
        <Box marginTop={2}>
          <DialogActions>
            <Button onClick={() => onAgree && onAgree(id)} color={'error'}>
              {t('allObjects.Yes')}
            </Button>
            <Button
              autoFocus
              onClick={onCloseDialog}
              sx={{color: 'secondary.contrastText'}}>
              {t('allObjects.Cancel')}
            </Button>
          </DialogActions>
        </Box>
      </DialogPrimary>
    </Box>
  );
};
