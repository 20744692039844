import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box} from '@mui/material';

export interface Props {
  titleType?: 'h1' | 'h2' | 'h3';
  name: string;
  active?: boolean;
  onClick?: () => void;
}

export const ButtonHeader = ({
  titleType = 'h1',
  name,
  onClick,
  active,
}: Props): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    tabButton: {
      backgroundColor: theme.palette.secondary.main,
      marginRight: 14,
      color: active
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.light,
      borderBottom: active ? `3px solid ${theme.palette.primary.main}` : '',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        cursor: 'pointer',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box>
      {titleType === 'h1' && (
        <h1 className={classes.tabButton} onClick={onClick}>
          {name}
        </h1>
      )}
      {titleType === 'h2' && (
        <h3 className={classes.tabButton} onClick={onClick}>
          {name}
        </h3>
      )}
      {titleType === 'h3' && (
        <h3 className={classes.tabButton} onClick={onClick}>
          {name}
        </h3>
      )}
    </Box>
  );
};
