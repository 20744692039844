import React from 'react';
import {Box, Typography} from '@mui/material';
import {StylesDialog} from '../../StylesDialog';
import {SelectLayers} from '../../../components/selects/select-multiple/SelectLayers';
import {useTranslation} from 'react-i18next';
import {ContentLanguage} from '../../../../../redux/reducers/ContentLanguage';
import {UploadFile} from '../../../../../hooks/useUploadPhoto';
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {Controller} from 'react-hook-form';
import TextInputPrimary from '../../../components/text-inputs/TextInputPrimary';
import SelectCategory from '../../../components/selects/SelectCategory';
import {ButtonUploadImage} from '../../../components/buttons/ButtonUploadImage';
import {GalleryPhotoContainer} from '../../shared/gallery/GalleryPhotoContainer';
import {GalleryVideoContainer} from '../../shared/gallery/GalleryVideoContainer';
import {PoiForm} from '../DialogPoi';
import {CustomUtils} from '../../../utils/CustomUtils';
import {TextInputObjectName} from '../../../components/text-inputs/TextInputObjectName';
import {TextEditorObjectBody} from '../../../components/text-edior/TextEditorObjectBody';
import {DamFileWithData} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import SelectFoodPoint from '../../../components/selects/SelectFoodPoint';
import {SwitchPrimary} from '../../../components/switches/SwitchPrimary';

export interface Props extends Partial<UseFormReturn<PoiForm>> {
  addNew: boolean;
  onUploadPhotos: (galleryPhotos: DamFileWithData[]) => void;
  galleryPhotoList?: UploadFile[];
  selectedLanguage?: ContentLanguage;
  onUploadVideo: (video: DamFileWithData) => void;
  onRemoveVideo?: (id: string) => void;
  videoUploadState?: UploadFile;
}

export const TabDetailPoi = ({
  addNew,
  control,
  setValue,
  watch,
  onUploadPhotos,
  galleryPhotoList,
  selectedLanguage,
  onUploadVideo,
  onRemoveVideo,
  videoUploadState,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const dialogClasses = StylesDialog();

  return (
    <Box>
      <Box>
        <TextInputObjectName
          selectedLanguage={selectedLanguage}
          control={control}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignSelf={'flex-start'}
        marginTop={2}>
        <Box>
          <Controller
            name="image"
            control={control}
            rules={{required: true}}
            defaultValue={undefined}
            render={({field, fieldState: {error}}) => (
              <ButtonUploadImage
                {...field}
                boldLabel
                name={'image'}
                label={t(`poi.Cover image`)}
                errorType={error?.type}
                objectType={'DamFileWithData'}
              />
            )}
          />
          <Box marginTop={2}>
            <Controller
              name="category"
              control={control}
              defaultValue=""
              rules={{required: true}}
              render={({field, fieldState: {error}}) => (
                <SelectCategory
                  {...field}
                  label={t(`poi.Primary category`)}
                  errorType={error?.type}
                />
              )}
            />
          </Box>
          {/*<Box marginTop={2}>*/}
          {/*  <Controller*/}
          {/*    name="layers"*/}
          {/*    control={control}*/}
          {/*    defaultValue={[]}*/}
          {/*    rules={{required: true}}*/}
          {/*    render={({field, fieldState: {error}}) => (*/}
          {/*      <SelectCategories*/}
          {/*        {...field}*/}
          {/*        label={t(`poi.Secondary categories`)}*/}
          {/*        errorType={error?.type}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  />*/}
          {/*</Box>*/}
        </Box>
        <Box marginLeft={4}>
          <Box marginTop={2}>
            <Controller
              name="showOnMap"
              control={control}
              defaultValue={addNew}
              render={({field, fieldState: {error}}) => (
                <SwitchPrimary
                  {...field}
                  boldLabel
                  label={t(`object.Apply on map`)}
                  errorType={error?.type}
                />
              )}
            />
            <Box marginTop={2}>
              <Typography variant="subtitle2" sx={{fontWeight: 600}}>
                {t('poi.Coordinates')}
              </Typography>
              <Controller
                name="coordinates.latitude"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/,
                    message: 'Incorrect latitude',
                  },
                }}
                defaultValue=""
                render={({field, fieldState: {error}}) => (
                  <TextInputPrimary
                    {...field}
                    label={t(`poi.Latitude`)}
                    errorType={error?.type}
                    errorMessage={error?.message}
                    errorMessageTranslationPrefix={'poi'}
                  />
                )}
              />
            </Box>
            <Controller
              name="coordinates.longitude"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value:
                    /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/,
                  message: 'Incorrect longitude',
                },
              }}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <TextInputPrimary
                  {...field}
                  label={t(`poi.Longitude`)}
                  errorType={error?.type}
                  errorMessage={error?.message}
                  errorMessageTranslationPrefix={'poi'}
                />
              )}
            />
          </Box>
        </Box>
        <Box marginLeft={4}>
          <Controller
            name="layers"
            control={control}
            defaultValue={[]}
            render={({field, fieldState: {error}}) => (
              <SelectLayers
                {...field}
                label={t(`poi.Layers`)}
                errorType={error?.type}
              />
            )}
          />
          <Box marginTop={2}>
            <Controller
              name="foodPointId"
              control={control}
              defaultValue={''}
              render={({field, fieldState: {error}}) => (
                <SelectFoodPoint
                  {...field}
                  label={t(`poi.Foodpoint`)}
                  errorType={error?.type}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box className={dialogClasses.textEditor}>
        <TextEditorObjectBody
          boldLabel
          control={control}
          selectedLanguage={selectedLanguage}
        />
      </Box>
      <Box className={dialogClasses.gallery}>
        <GalleryPhotoContainer
          galleryPhotoList={galleryPhotoList}
          onUploadFiles={onUploadPhotos}
        />
        <GalleryVideoContainer
          control={control}
          video={
            watch &&
            watch('video') &&
            CustomUtils.convertCreateVideoResponseToDamFile(watch('video'))
          }
          onRemoveVideo={(emptyVideo) => {
            onRemoveVideo &&
              watch &&
              watch('video') &&
              watch('video')?.id &&
              onRemoveVideo(watch('video')?.id as string);
            setValue && setValue('video', emptyVideo);
          }}
          onUploadVideo={(event) => {
            onUploadVideo(event);
          }}
          videoUploadState={videoUploadState}
        />
      </Box>
    </Box>
  );
};
