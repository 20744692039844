import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {AddCircleRounded} from '@mui/icons-material';

export interface Props {
  name: string;
  label?: string;
  boldLabel?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: React.ReactNode;
  withIcon?: boolean;
}

export const ButtonPrimary = ({
  name,
  label,
  boldLabel,
  onClick,
  icon,
  withIcon = true,
}: Props): JSX.Element => {
  return (
    <Box>
      <Box m={0.5}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: boldLabel ? 600 : undefined,
          }}>
          {label}
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          ...ButtonPrimaryStyle,
          height: 50,
          width: 200,
        }}
        onClick={onClick}
        startIcon={withIcon && (icon ? icon : <AddCircleRounded />)}>
        {name}
      </Button>
    </Box>
  );
};

export const ButtonPrimaryStyle = {
  borderRadius: '10px',
  backgroundColor: 'secondary.light',
  color: 'secondary.contrastText',
  '&:hover': {
    // background: SecondaryLightHover,
    background: '#cccccc',
  },
};
