import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {StylesAdminTab} from '../../StylesAdminTab';
import {DialogUser} from '../../../../shared/dialogs/dialog-user/DialogUser';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  addRolesToUsers,
  BaseUserListRequest,
  changeUsersStatus,
  createUser,
  deleteUsers,
  getBaseUserList,
  getUser,
  updateUser,
  usersActions,
  usersSelector,
} from '../../../../../redux/reducers/data-reducers/UserSlice';
import {FilterParams, Table} from '../../../../shared/table';
import {TableInstance} from 'react-table';
import {DialogInfo} from '../../../../shared/dialogs/dialog-info/DialogInfo';
import {useTranslation} from 'react-i18next';
import {
  DialogItemSelect,
  DialogItemType,
  DialogItemButtonTypes,
  getTitleFromType,
} from '../../../../shared/dialogs/dialog-item-select/DialogItemSelect';
import {
  Order,
  Status,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {InputModelValueType} from '../../../../../hooks/useForm';
import {
  findFilterById,
  getDateRangeFilterString,
  useLocalStorage,
} from '../../../../shared/table/utils';
import {useInfoDialog} from '../../../../../hooks/useInfoDialog';
import {getTableUsersColumns} from './TableUsersColumns';
import {AdminNewsObj} from '../../news/table/TableNews';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {ButtonAdd} from '../../../../shared/components/buttons/ButtonAdd';
import {batch} from 'react-redux';
import moment from 'moment/moment';
import {DefaultDateFormat} from '../../../../shared/utils/Utils';

export type AdminUserObj = {
  firstName: string;
  lastName: string;
  enabled: string;
  email: string;
  roleKey: string;
  lastLogin: Date | null;
  signInType: string;
  // groups: string;
  action: string;
};

export type UsersData = AdminUserObj & {
  subRows?: UsersData[];
};

export const TableUsers = (): JSX.Element => {
  const {t} = useTranslation();
  const tabClasses = StylesAdminTab();
  const dispatch = useAppDispatch();
  const {obj, adminList, openObjDialog, addNewObj, loadingList, loadingDetail} =
    useAppSelector(usersSelector);

  const {infoDialog, onSetAgree, onCloseInfoDialog, showInfoDialog} =
    useInfoDialog({
      onAgree: (id) =>
        id &&
        dispatch(
          deleteUsers([id], () =>
            dispatch(getBaseUserList(createRequest(initialState)))
          )
        ),
    });
  const [multiSelect, setMultiSelect] = React.useState<
    DialogItemType<AdminUserObj>
  >({
    openDialog: false,
  });

  const [tablePageIndex, setTablePageIndex] = useState<number>(0);

  const [initialState, setInitialState] = useLocalStorage(
    'tableState:tableUsers',
    {
      pageSize: 10,
      pageIndex: 0,
    }
  );

  const setState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      setInitialState(value);
    },
    [setInitialState]
  );

  const createRequest = useCallback(
    (params: FilterParams<AdminUserObj>): BaseUserListRequest => {
      const filteredFirstName = findFilterById(
        params.filters,
        'firstName'
      )?.value;
      const filteredLastName = findFilterById(
        params.filters,
        'lastName'
      )?.value;
      const filteredEmail = findFilterById(params.filters, 'email')?.value;
      const filteredStatus = findFilterById(params.filters, 'enabled')?.value;
      const filteredSignInType = findFilterById(
        params.filters,
        'signInType'
      )?.value;
      const filteredLastLoginDate = findFilterById(
        params.filters,
        'lastLogin'
      )?.value;
      const filteredRoleKey = findFilterById(params.filters, 'roleKey')?.value;
      const roleKey = filteredRoleKey?.key;
      const firstName = filteredFirstName ? filteredFirstName : undefined;
      const lastName = filteredLastName ? filteredLastName : undefined;
      const email = filteredEmail ? filteredEmail : undefined;
      const active = filteredStatus
        ? filteredStatus === 'user.Active'
        : undefined;
      const orderBy = params?.sortBy[0]?.id ? params?.sortBy[0].id : undefined;
      const sortOrder = params?.sortBy[0]?.id
        ? params?.sortBy[0].desc
          ? Order.Desc
          : Order.Asc
        : undefined;
      const betweenLoginDate = filteredLastLoginDate
        ? getDateRangeFilterString(
            filteredLastLoginDate[0],
            filteredLastLoginDate[1],
            true
          )
        : undefined;
      const isCzuUser =
        filteredSignInType !== undefined
          ? filteredSignInType === 'ČZU'
          : undefined;
      return {
        firstName,
        lastName,
        email,
        betweenLoginDate,
        offset: params.pageIndex * params.pageSize,
        limit: params.pageSize,
        roleKey,
        active,
        orderBy,
        sortOrder,
        isCzuUser,
      };
    },
    []
  );

  const getUsersData = useCallback(
    (params: FilterParams<AdminNewsObj>) => {
      if (!loadingList) {
        setState(params);
        dispatch(getBaseUserList(createRequest(params)));
      }
    },
    [createRequest, dispatch, loadingList, setState]
  );

  const handleEditClick = useCallback(
    (id: string) => {
      dispatch(usersActions.setEditObjDetail());
      dispatch(getUser(id));
    },
    [dispatch]
  );

  const handleMultiSelect = useCallback(
    (
        instance: TableInstance<AdminUserObj>,
        type: DialogItemType<AdminUserObj>
      ) =>
      () => {
        setMultiSelect({
          ...type,
          selectedRows: instance.selectedFlatRows.map((v) => v.original),
        });
      },
    []
  );

  const onAgreeMultiSelect = useCallback(
    (
      type: DialogItemButtonTypes,
      value: InputModelValueType,
      selectedRows: Array<AdminUserObj>
    ) => {
      switch (type) {
        case 'enabled':
          dispatch(
            changeUsersStatus(
              value === 'Active' || value === 'Aktivní'
                ? Status.Active
                : Status.Inactive,
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseUserList(createRequest(initialState)))
            )
          );
          break;
        case 'delete':
          dispatch(
            deleteUsers(
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseUserList(createRequest(initialState)))
            )
          );
          break;
        case 'role':
          dispatch(
            addRolesToUsers(
              value as string[],
              selectedRows.map((row) => row.action),
              () => dispatch(getBaseUserList(createRequest(initialState)))
            )
          );
          break;
      }
      setMultiSelect({
        openDialog: false,
        id: undefined,
      });
    },
    [createRequest, dispatch, initialState]
  );

  const getUsersList = useMemo(() => {
    return adminList.list.map((dataObj) => {
      return {
        action: dataObj.id,
        firstName: dataObj.firstName,
        lastName: dataObj.lastName,
        roleKey: dataObj.role
          ?.map((role) =>
            CustomUtils.getContentByLanguage(role.name, role.nameEn)
          )
          .join(', '),
        enabled: t(`user.${dataObj.status}`),
        email: dataObj.email,
        lastLogin: dataObj.lastLogin
          ? moment(new Date(dataObj.lastLogin), DefaultDateFormat).toDate()
          : null,
        signInType: dataObj.isCzuUser ? 'ČZU' : t(`user.External`),
      } as AdminUserObj;
    });
  }, [adminList.list, t]);

  const renderTable = useMemo(() => {
    return (
      <Table<UsersData>
        t={t}
        name={'usersTable'}
        multiselectButtonTypes={['delete', 'role', 'enabled']}
        totalCount={adminList.totalCount}
        rowSelection
        setMultiSelect={handleMultiSelect}
        columns={getTableUsersColumns({
          onEditClick: handleEditClick,
          onDeleteClick: showInfoDialog,
          t,
        })}
        autoResetPage={false}
        tablePageIndex={tablePageIndex}
        pageCount={
          adminList?.totalCount ? Math.ceil(adminList.totalCount / 10) : 0
        }
        onChangePage={(page) => setTablePageIndex(page)}
        tableState={initialState}
        setTableState={setState}
        data={getUsersList}
        onFetchData={getUsersData}
        loadingList={loadingList}
        manualFilters
        manualSortBy
        manualPagination
      />
    );
  }, [
    adminList.totalCount,
    getUsersData,
    getUsersList,
    handleEditClick,
    handleMultiSelect,
    initialState,
    loadingList,
    setState,
    showInfoDialog,
    t,
    tablePageIndex,
  ]);

  return (
    <Box className={tabClasses.tabContainer}>
      <Box className={tabClasses.tabButtonsContainer}>
        <ButtonAdd
          name={t('user.Add new user')}
          onClick={() => {
            batch(() => {
              dispatch(usersActions.setObj(null));
              dispatch(usersActions.setAddNewObjDetail());
              dispatch(usersActions.setOpenObjDialog(true));
            });
          }}
        />
      </Box>
      {renderTable}
      <DialogUser
        addNew={addNewObj}
        user={obj}
        openDialog={openObjDialog}
        loading={loadingDetail}
        onCloseDialog={() => {
          batch(() => {
            dispatch(usersActions.setObj(null));
            dispatch(usersActions.setOpenObjDialog(false));
          });
        }}
        onAddUser={(user) => {
          dispatch(
            createUser(user, () =>
              dispatch(getBaseUserList(createRequest(initialState)))
            )
          );
        }}
        onUpdateUser={(user) => {
          obj?.id &&
            dispatch(
              updateUser(obj.id, user, () =>
                dispatch(getBaseUserList(createRequest(initialState)))
              )
            );
        }}
      />
      <DialogInfo
        title={t('allObjects.Delete', {name: t('allObjects.user')})}
        text={t('allObjects.Are you sure you want to delete this', {
          name: t('allObjects.user'),
        })}
        id={infoDialog.id}
        openDialog={infoDialog.openDialog}
        onCloseDialog={onCloseInfoDialog}
        onAgree={onSetAgree}
      />
      <DialogItemSelect
        title={t(`user.${getTitleFromType(multiSelect.type)}`)}
        text={
          t(`user.${getTitleFromType(multiSelect.type)}`) +
          ', ' +
          t('user.users') +
          ': ' +
          (multiSelect?.selectedRows?.length || '0')
        }
        id={multiSelect.id}
        value={multiSelect.value}
        openDialog={multiSelect.openDialog}
        type={multiSelect.type}
        selectedRows={multiSelect.selectedRows}
        onCloseDialog={() =>
          setMultiSelect({
            openDialog: false,
            id: undefined,
          })
        }
        onChangeValue={(event, selectedRows) => {
          setMultiSelect((select) => {
            return {...select, value: event, selectedRows};
          });
        }}
        onAgree={(value, selectedRows) =>
          multiSelect.type &&
          onAgreeMultiSelect(multiSelect.type, value, selectedRows)
        }
      />
    </Box>
  );
};
