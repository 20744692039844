import React, {useCallback, useEffect} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../../../HeaderMenuItem';
import {ButtonAdd} from '../../../../../../../shared/components/buttons/ButtonAdd';
import {
  selectPoiCoords,
  setPoiCoords,
} from '../../../../../../../../redux/reducers/MapSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../hooks/hooks';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {setGalleryPhotoList} from '../../../../../../../../redux/reducers/data-reducers/GalleryPhotoSlice';
import {poiActions} from '../../../../../../../../redux/reducers/data-reducers/PoiSlice';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import TextInputPrimary from '../../../../../../../shared/components/text-inputs/TextInputPrimary';
import {Coordinates} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {setPoi360List} from '../../../../../../../../redux/reducers/data-reducers/Poi360Slice';

export const AddNewPoiTab = (): JSX.Element => {
  const {t} = useTranslation();
  const {coords} = useAppSelector(selectPoiCoords);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const navigateTo = useCallback((path) => history.push(path), [history]);
  const {control, handleSubmit, setValue, trigger} = useForm<Coordinates>();

  useEffect(() => {
    setValue('latitude', coords?.latitude);
    setValue('longitude', coords?.longitude);
    (coords?.latitude || coords?.longitude) && trigger();
  }, [coords, setValue, trigger]);

  const onValidSubmit: SubmitHandler<Coordinates> = (data) => {
    dispatch(poiActions.setObj(null));
    dispatch(poiActions.setAddNewObjDetail());
    dispatch(setGalleryPhotoList([]));
    dispatch(setPoi360List([]));
    dispatch(setPoiCoords(data));
    navigateTo('/map/poi/poiList');
  };

  return (
    <Box>
      <HeaderMenuItem name={t('leftMenu.Add new POI')} />
      <form onSubmit={handleSubmit(onValidSubmit)}>
        <Box
          sx={{
            margin: '20px',
            marginLeft: '45px',
          }}>
          <Controller
            name="latitude"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/,
                message: 'Incorrect latitude',
              },
            }}
            defaultValue={''}
            render={({field, fieldState: {error}}) => (
              <TextInputPrimary
                {...field}
                boldLabel
                label={t(`poi.Latitude`)}
                errorType={error?.type}
                errorMessage={error?.message}
                errorMessageTranslationPrefix={'poi'}
              />
            )}
          />
          <Controller
            name="longitude"
            control={control}
            rules={{
              required: true,
              pattern: {
                value:
                  /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/,
                message: 'Incorrect longitude',
              },
            }}
            defaultValue={''}
            render={({field, fieldState: {error}}) => (
              <TextInputPrimary
                {...field}
                boldLabel
                label={t(`poi.Longitude`)}
                errorType={error?.type}
                errorMessage={error?.message}
                errorMessageTranslationPrefix={'poi'}
              />
            )}
          />
        </Box>
        <ButtonAdd name={t('poi.Add new POI')} />
      </form>
    </Box>
  );
};
