import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  getNotification,
  notificationActions,
} from '../../../../../redux/reducers/data-reducers/NotificationSlice';
import {
  getNews,
  newsActions,
  newsNotificationSelector,
} from '../../../../../redux/reducers/data-reducers/NewsSlice';
import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export const useNotificationNewsSelection = () => {
  const dispatch = useAppDispatch();
  const newsNotificationSelection = useAppSelector(newsNotificationSelector);

  const history = useHistory();

  useEffect(() => {
    if (newsNotificationSelection.newsNotificationSelection) {
      switch (newsNotificationSelection.newsNotificationState) {
        case 'scheduledNotification':
          dispatch(notificationActions.setEditObjDetail());
          newsNotificationSelection.notificationId &&
            dispatch(getNotification(newsNotificationSelection.notificationId));
          break;
        case 'sentNotification':
        case 'withoutNotification':
          dispatch(notificationActions.setAddNewObjDetail());
          if (newsNotificationSelection.notificationData) {
            const {title, titleEn, uri} =
              newsNotificationSelection.notificationData;
            dispatch(notificationActions.setObj({title, titleEn, uri}));
          }
          break;
      }
    }
  }, [dispatch, newsNotificationSelection]);

  const handleGoBack = useCallback(
    (submitted: boolean) => {
      history.goBack();
      dispatch(
        newsActions.setNewsNotificationSelection({
          newsNotificationSelection: false,
        })
      );
      submitted &&
        newsNotificationSelection.newsId &&
        dispatch(getNews(newsNotificationSelection.newsId));
    },
    [dispatch, history, newsNotificationSelection.newsId]
  );

  return {
    handleGoBack,
    selectedNewsId: newsNotificationSelection.newsId,
    newsNotificationSelection:
      newsNotificationSelection.newsNotificationSelection,
  };
};
