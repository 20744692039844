import React from 'react';
import {Box, CircularProgress} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export interface Props {
  showLoading?: boolean;
}

export const DialogLoading = ({showLoading}: Props): JSX.Element | null => {
  const classes = useStyles();
  return showLoading ? (
    <Box className={classes.loading}>
      <CircularProgress color="secondary" />
    </Box>
  ) : null;
};

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      position: 'absolute',
      zIndex: 9,
      backgroundColor: 'rgba(0,0,0,0.4)',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
