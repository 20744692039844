import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {ObjectRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';

export interface RouteSelection {
  routeSelection: boolean;
  from?: 'layer' | 'news';
  baseRouteList?: ObjectRef[];
}

interface RouteSelectionState {
  routeSelection: RouteSelection;
  openDialogAfterRouteSelection: boolean;
}

const initialState: RouteSelectionState = {
  routeSelection: {routeSelection: false},
  openDialogAfterRouteSelection: false,
};

export const routeSelectionSlice = createSlice({
  name: 'routeSelection',
  initialState,
  reducers: {
    setRouteSelection: (state, {payload}: PayloadAction<RouteSelection>) => {
      state.routeSelection.routeSelection = payload.routeSelection;
      state.routeSelection.from = payload.from;
    },
    addToRouteSelectionList: (state, {payload}: PayloadAction<ObjectRef>) => {
      state.routeSelection.baseRouteList = state.routeSelection.baseRouteList
        ? [...state.routeSelection.baseRouteList, payload]
        : [payload];
    },
    addListToRouteSelectionList: (
      state,
      {payload}: PayloadAction<ObjectRef[]>
    ) => {
      state.routeSelection.baseRouteList = state.routeSelection.baseRouteList
        ? [...current(state.routeSelection.baseRouteList), ...payload].filter(
            (item, i, array) => array.findIndex((t) => t.id === item.id) === i
          )
        : payload;
    },
    setRouteSelectionList: (state, {payload}: PayloadAction<ObjectRef[]>) => {
      state.routeSelection.baseRouteList = payload;
    },
    removeFromRouteSelectionList: (
      state,
      {payload}: PayloadAction<ObjectRef>
    ) => {
      state.routeSelection.baseRouteList =
        state.routeSelection.baseRouteList?.filter(
          (route) => route.id !== payload.id
        );
    },
    setOpenDialogAfterRouteSelection: (
      state,
      {payload}: PayloadAction<boolean>
    ) => {
      state.openDialogAfterRouteSelection = payload;
    },
  },
});

export const {
  setRouteSelection,
  addListToRouteSelectionList,
  addToRouteSelectionList,
  setRouteSelectionList,
  removeFromRouteSelectionList,
  setOpenDialogAfterRouteSelection,
} = routeSelectionSlice.actions;

export const routeSelectionSelector = (
  state: RootState
): typeof state.routeSelection => state.routeSelection;

export const selectSelectedRoute = (
  state: RootState
): typeof state.routeSelection.routeSelection =>
  state.routeSelection.routeSelection;

export default routeSelectionSlice.reducer;
